import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ProfileRole, useGetProfileDataQuery, useLazyGetProfileDataQuery } from "features/viewer"
import { mainApi } from "features/api"
import { axiosInstanceUser } from "shared/api"

export const isLoggedIn = (wasLogin: boolean) => {
    const [fetch, state] = useLazyGetProfileDataQuery()
    localStorage.setItem("role", state?.data?.role ?? ProfileRole.PUBLIC)
    localStorage.setItem("auth", state?.data ? "true" : "false")
    return async () => {
        try {
            const { role } = await fetch("").unwrap()
            if (!wasLogin) {
                return {
                    role: ProfileRole.PUBLIC,
                    isAuth: false,
                }
            }
            return {
                role,
                isAuth: true,
            }
        } catch (e) {
            return {
                role: ProfileRole.PUBLIC,
                isAuth: false,
            }
        }
    }
}

export const useAuth = () => {
    const [isNeedUpdateToken, setNeedUpdateToken] = useState<boolean>(true)
    const [token, setToken] = useState<string | null>(null)
    const [fetch] = useLazyGetProfileDataQuery()
    const [wasLogin, setWasLogin] = useState<boolean>(false)
    const dispatch = useDispatch()

    useEffect(() => {
        if (isNeedUpdateToken) {
            dispatch(mainApi.util.invalidateTags(["Profile"]))
        }
    }, [isNeedUpdateToken])

    useEffect(() => {
        if (isNeedUpdateToken) {
            setToken(localStorage.getItem("access_token"))
            setTimeout(() => {
                setNeedUpdateToken(false)
            }, 0)
        }
    }, [isNeedUpdateToken])

    useEffect(() => {
        if (token) {
            axiosInstanceUser.defaults.headers["Authorization"] = `Bearer ${token}`
            fetch("")
        } else {
            axiosInstanceUser.defaults.headers.common["Authorization"] = null
        }
    }, [token])

    const { data: dataProfile } = useGetProfileDataQuery("")

    useEffect(() => {
        if (dataProfile && token) {
            setWasLogin(true)
        } else {
            setWasLogin(false)
        }
    }, [wasLogin, dataProfile, token, isNeedUpdateToken])

    return {
        updateToken: () => {
            setNeedUpdateToken(true)
        },
        wasLogin,
    }
}
