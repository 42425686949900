import styled, { css } from "styled-components"
import { conditions } from "shared/lib"
import { PartnersCardSize } from "./types"

const isHover = css`
    outline: 1px solid var(--assets-card-common-colors-stroke-hover);
`

const fontSizeCondition = (size: PartnersCardSize) => {
    return conditions<PartnersCardSize>(size, [
        [PartnersCardSize.S, "16px"],
        [PartnersCardSize.L, "20px"],
    ])
}

const StyledPartnerCard = styled.div<{
    $size: PartnersCardSize
    $hover: boolean
}>`
    position: relative;
    display: flex;
    padding: var(--assets-card-custom-partner-${({ $size }) => $size}-padding);
    flex-direction: column;
    justify-content: center;
    height: 100%;
    align-items: center;
    gap: var(--assets-card-custom-partner-${({ $size }) => $size}-gap);
    border-radius: var(--assets-card-common-sizes-s-radius);
    background: #fafafa;
    &:hover {
        ${({ $hover }) => $hover && isHover}
    }
    & > svg {
        position: absolute;
        top: 12px;
        right: 12px;
    }
`

const StyledPartnerCardPicture = styled.div<{
    $size: PartnersCardSize
}>`
    display: flex;
    width: var(--assets-card-custom-partner-${({ $size }) => $size}-picture, 96px);
    height: var(--assets-card-custom-partner-${({ $size }) => $size}-picture, 96px);
    & > img {
        width: 100%;
        object-fit: contain;
    }

    padding: 0px 3.514px;
    justify-content: center;
    align-items: center;
`

const StyledPartnerCardName = styled.div<{
    $size: PartnersCardSize
}>`
    color: var(--layout-text-colors-header);
    text-align: center;
    font-size: ${({ $size }) => fontSizeCondition($size)};
`

export { StyledPartnerCard, StyledPartnerCardName, StyledPartnerCardPicture }
