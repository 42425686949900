import React, { type FC, useMemo } from "react"
import { ChoiceGroup, Slider } from "shared/ui"
import { StyledSection } from "shared/styles"
import { transformUrlVideo } from "shared/lib"
import { StyledContainer, StyledImage, StyledOpportunityPhoto, StyledVideoContainer } from "./styles"
import { type IPhotoSection } from "./types"

export const PhotoSection: FC<IPhotoSection> = (props) => {
    const { images, isSliderMode = false, videoUrl } = props
    const formattedvideoUrl = useMemo(() => {
        if (Array.isArray(videoUrl)) {
            return videoUrl.map((el) => transformUrlVideo(el))
        }
        return [transformUrlVideo(videoUrl ?? "") ?? ""]
    }, [videoUrl])
    return (
        <>
            {isSliderMode ? (
                <StyledSection>
                    <StyledContainer>
                        <ChoiceGroup scroll={true} heightAuto={true}>
                            {images.map((imageSrc, i) => (
                                <StyledOpportunityPhoto key={`opp-photo-${i}`}>
                                    <StyledImage src={imageSrc.url} alt={"opportunity photo"} />
                                </StyledOpportunityPhoto>
                            ))}
                            {formattedvideoUrl &&
                                formattedvideoUrl.map((el, i) =>
                                    el && el.length ? (
                                        <StyledOpportunityPhoto key={`opp-video-${i}`}>
                                            <StyledVideoContainer
                                                src={el}
                                                title="Video player"
                                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            ></StyledVideoContainer>
                                        </StyledOpportunityPhoto>
                                    ) : null
                                )}
                        </ChoiceGroup>
                    </StyledContainer>
                </StyledSection>
            ) : (
                <Slider videoUrl={videoUrl} images={images}></Slider>
            )}
        </>
    )
}
