import React, { type FC } from "react"
import { CardStatus, HeadingType, Typography } from "shared/ui"
import { OrganizationSvg } from "shared/assets/svg"
import { type OrganizationCardProps } from "./types"
import {
    StyledOrganizationCard,
    StyledOrganizationCardContent,
    StyledOrganizationCardImg,
    StyledOrganizationCardInn,
    StyledOrganizationCardMain,
} from "./styles"

export const OrganizationCard: FC<OrganizationCardProps> = (props) => {
    const { statusType, cardType, inn, name } = props
    return (
        <StyledOrganizationCard>
            <CardStatus statusType={statusType} cardType={cardType} />
            <StyledOrganizationCardMain>
                <StyledOrganizationCardImg>
                    <OrganizationSvg />
                </StyledOrganizationCardImg>
                <StyledOrganizationCardContent>
                    <Typography.Heading typeUI={HeadingType.H4}>{name}</Typography.Heading>
                    <StyledOrganizationCardInn>{inn}</StyledOrganizationCardInn>
                </StyledOrganizationCardContent>
            </StyledOrganizationCardMain>
        </StyledOrganizationCard>
    )
}
