import React, { type FC } from "react"
import { useAcceptGoalMutation, useGetGoalQuery } from "features/viewer"
import { GoalStatusType, GoalTag, ProfileData, SizeGoalTag, useMediaProfileData } from "entities/viewer"
import { BackButtonText, BasicType, Button, ModalDefault, Typography, useMediaSizeButton } from "shared/ui"
import { conditions } from "shared/lib"
import { StyledModalGoal, StyledModalGoalDate } from "./styles"
import { type ModalGoalProps } from "./types"

export const ModalGoal: FC<ModalGoalProps> = (props) => {
    const { toggleGoodLackModal, toggleActivity, type = GoalStatusType.NEW, id } = props
    const { fromMtoL } = useMediaSizeButton()
    const getButtonText = conditions<GoalStatusType>(type, [
        [GoalStatusType.NEW, "Принять в работу"],
        [GoalStatusType.IN_PROGRESS, "Завершить"],
    ])

    const { data } = useGetGoalQuery(id, {
        skip: !id,
    })
    const [mutationFetch] = useAcceptGoalMutation()

    const getButtonHandler = conditions<GoalStatusType>(type, [
        [
            GoalStatusType.NEW,
            () => {
                mutationFetch(id)
            },
        ],
        [GoalStatusType.IN_PROGRESS, () => {}],
    ])
    const { SizeStoL } = useMediaProfileData()
    return (
        <>
            <ModalDefault
                back={toggleActivity}
                backButtonText={BackButtonText.CANCEL}
                beforeAdditionalHeadComponent={() => (
                    <div>
                        <GoalTag typeCard={type} size={SizeGoalTag.BIG} />
                    </div>
                )}
                footerBtn={() => (
                    <Button
                        size={fromMtoL}
                        onClick={() => {
                            toggleGoodLackModal()
                            toggleActivity()
                            getButtonHandler(id)
                        }}
                    >
                        {getButtonText}
                    </Button>
                )}
                title={data?.title}
            >
                <StyledModalGoal>
                    <ProfileData size={SizeStoL} caption={"Проект"}>
                        {data?.project.name}
                    </ProfileData>
                    <StyledModalGoalDate>
                        <ProfileData size={SizeStoL} caption={"Начало"}>
                            {data?.startTime}
                        </ProfileData>
                        <ProfileData size={SizeStoL} caption={"Завершение"}>
                            {data?.endTime}
                        </ProfileData>
                    </StyledModalGoalDate>
                </StyledModalGoal>
                <Typography.Basic typeUI={BasicType.SECOND}>{data?.description}</Typography.Basic>
            </ModalDefault>
        </>
    )
}
