import { GoalStatusType } from "entities/viewer"
import { Tag, type TagSize } from "shared/ui"
import { conditions } from "shared/lib"

export const tagCard = (typeCard: GoalStatusType, TagSizeProps: TagSize) =>
    conditions<GoalStatusType>(typeCard, [
        [
            GoalStatusType.NEW,
            () => (
                <Tag size={TagSizeProps} bgColor={"var(--assets-switch-checked-orange-fill, #FF8100)"}>
                    Новая
                </Tag>
            ),
        ],
        [GoalStatusType.IN_PROGRESS, () => <Tag size={TagSizeProps}>В работе</Tag>],
        [GoalStatusType.IN_REVIEW, () => <Tag size={TagSizeProps}>На проверке</Tag>],
        [
            GoalStatusType.COMPLETED,
            () => (
                <Tag size={TagSizeProps} bgColor={"var(--assets-tag-colors-green-fill, #5FDD4A)"}>
                    Выполнено
                </Tag>
            ),
        ]
    ])
