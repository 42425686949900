import styled from "styled-components"

const StyledRestorePasswordLink = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-link-gap, 8px);
    font-size: 14px;
    font-weight: 400;
`

const StyledInputs = styled.div`
    display: grid;
    gap: var(--layout-mobile-form-gap, 24px);
`

const StyledPasswordField = styled.div`
    display: grid;
    gap: var(--assets-input-password-auth-gap, 8px);
`

const StyledMainContent = styled.div`
    display: flex;
    flex-direction: column;
    align-content: space-between;
    height: 100%;
    gap: var(--layout-desktop-gap, 32px);
`

export { StyledRestorePasswordLink, StyledPasswordField, StyledInputs, StyledMainContent }
