import React, { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useNavigate } from "react-router-dom"
import { ChoiceInputAuth, useChoiceInputAuth } from "entities/user"
import { Button, ButtonSize, ButtonType, FormLayout, InputPassword, Modal, ModalDefault } from "shared/ui"
import { PasswordSvg } from "shared/assets/svg"
import { useModal } from "shared/hook"
import { StyledModalDescription } from "./styles"
import { changePasswordSchema, type FormChangePasswordI } from "./types"

export const EditAccount: FC = () => {
    const navigate = useNavigate()
    const FormAccount = useForm()
    const FormChangePassword = useForm<FormChangePasswordI>({
        mode: "onChange",
        criteriaMode: "all",
        resolver: yupResolver(changePasswordSchema),
    })
    const { isActive: isActivePassword, toggleActivity: toggleActivityPassword } = useModal(false)
    const { isActive: isActiveAccept, toggleActivity: toggleActivityAccept } = useModal(false)
    const choice = useChoiceInputAuth()
    return (
        <>
            <FormProvider {...FormAccount}>
                <FormLayout
                    main={() => (
                        <>
                            {" "}
                            <ChoiceInputAuth {...choice} />
                            <Button
                                onClick={toggleActivityPassword}
                                typeUI={ButtonType.GHOST}
                                size={ButtonSize.M}
                                leftIcon={() => <PasswordSvg />}
                            >
                                Изменить пароль
                            </Button>
                        </>
                    )}
                    footer={() => (
                        <>
                            <Button typeUI={ButtonType.PRIMARY} size={ButtonSize.M}>
                                Сохранить
                            </Button>
                            <Button
                                typeUI={ButtonType.GHOST}
                                size={ButtonSize.M}
                                onClick={() => navigate("/personal-account")}
                            >
                                Отменить
                            </Button>
                        </>
                    )}
                />
                <Modal isActive={isActivePassword} toggleActivity={toggleActivityPassword}>
                    <FormProvider {...FormChangePassword}>
                        <ModalDefault title={"Изменение пароля"} back={toggleActivityPassword}>
                            <InputPassword
                                name={"newPassword"}
                                validWindow={true}
                                labelText={"Новый пароль"}
                                required={true}
                                placeholder={"Новый пароль"}
                            />
                            <InputPassword
                                name={"confirmPassword"}
                                labelText={"Подтверждение пароля"}
                                required={true}
                                placeholder={"Подтвердите пароль"}
                            />
                            <InputPassword
                                name={"currentPassword"}
                                labelText={"Текущий пароль"}
                                required={true}
                                placeholder={"Укажите текущий пароль"}
                            />
                            <Button
                                onClick={() => {
                                    toggleActivityAccept()
                                    toggleActivityPassword()
                                }}
                                typeUI={ButtonType.PRIMARY}
                                size={ButtonSize.M}
                            >
                                Сохранить
                            </Button>
                        </ModalDefault>
                    </FormProvider>
                </Modal>
                <Modal isActive={isActiveAccept} toggleActivity={toggleActivityAccept}>
                    <ModalDefault title={"Изменение пароля"} backButton={false}>
                        <StyledModalDescription>
                            На указанный вами адрес электронной почты была отправлена ссылка для подтверждения изменения
                            пароля. <br /> <br />
                            До перехода по ссылке вход в аккаунт будет осуществляться по прежнему паролю.
                        </StyledModalDescription>
                        <Button onClick={toggleActivityAccept} typeUI={ButtonType.PRIMARY} size={ButtonSize.M}>
                            Хорошо
                        </Button>
                    </ModalDefault>
                </Modal>
            </FormProvider>
        </>
    )
}
