import { type FC, useEffect, useState } from "react"
import { Navigate, useLocation, useOutletContext } from "react-router-dom"
import { ProfileRole } from "features/viewer"
import { isLoggedIn } from "../../lib"
import { type PrivateRouteProps } from "./types"

export const PrivateRoute: FC<PrivateRouteProps> = (props) => {
    const { children, role = [ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG] } = props
    const { wasLogin } = useOutletContext<{ updateToken: () => void; wasLogin: boolean }>()
    const isLogged = isLoggedIn(wasLogin)
    const [authRole, setAuthRole] = useState<boolean>(false)
    const [userRole, setUserRole] = useState<ProfileRole | undefined>(undefined)
    const [isAuth, setIsAuth] = useState<boolean>(false)
    const { pathname } = useLocation()
    useEffect(() => {
        setUserRole(undefined)
        isLogged().then((data) => {
            if (data) {
                setAuthRole(role.includes(data.role))
                setUserRole(data.role)
                setIsAuth(data.isAuth)
                return
            }
        })
        localStorage.setItem("role", String(userRole))
        localStorage.setItem("auth", String(isAuth))
    }, [pathname])

    if (!userRole) {
        return <div></div>
    }
    if (!isAuth && !authRole) {
        return <Navigate to={"/auth"} />
    }
    if (isAuth && !authRole) {
        return <Navigate to={"/personal-account"} />
    }
    return <>{children}</>
}
