import { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { OpportunityInformer, OpportunityType } from "entities/user"
import { Button, ButtonType } from "shared/ui"
import { OpportunitySvg } from "shared/assets/svg"
import { SectionGapSize, StyledSection } from "shared/styles"
import { useResize } from "shared/hook"
import { type OfferOpportunityButtonProps } from "./types"

export const OfferOpportunityButton: FC<OfferOpportunityButtonProps> = ({ isShowInformer }) => {
    const navigate = useNavigate()
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return (
        <StyledSection $gapSize={width < isTablet ? SectionGapSize.S : SectionGapSize.M}>
            <Button
                typeUI={!isShowInformer ? ButtonType.GHOST : ButtonType.PRIMARY}
                onClick={() => {
                    navigate("/offer-opportunities-form")
                }}
            >
                <OpportunitySvg />
                Предложить возможность
            </Button>
            {isShowInformer && <OpportunityInformer informerType={OpportunityType.OPPORTUNITY} />}
        </StyledSection>
    )
}
