import React, { type FC } from "react"
import { Input, InputNumber, TypeInput } from "shared/ui"
import { VkIconSvg } from "shared/assets/svg"
import { StyledSection } from "shared/styles"

export const PlaceFormContactsFields: FC = () => {
    return (
        <StyledSection>
            <InputNumber
                name={"phone"}
                onInput={(e) => {
                    let input = e.target as HTMLInputElement
                    const regex = /^(\d{3})(\d{3})(\d{2})(\d{2})$/g
                    const subst = "$1 $2-$3-$4"
                    input.value = input.value.replace(regex, subst)
                }}
                labelText={"Телефон"}
                placeholder={"912 345-67-89"}
            />
            <Input name={"email"} labelText={"Email"} type={TypeInput.EMAIL} placeholder={"example@mail.com"} />
            <Input name={"linkSite"} required={false} placeholder={"https://place.ru"} labelText={"Официальный сайт"} />
            <Input
                name={"vkSocialUrl"}
                iconSvg={() => <VkIconSvg />}
                required={false}
                labelText={"Сообщество ВК"}
                hasIcon={() => <VkIconSvg />}
                placeholder={"https://vk.com/community"}
            />
        </StyledSection>
    )
}
