import React, { type FC, useEffect } from "react"
import { useOutletContext } from "react-router-dom"
import { useGetTypeParameters } from "features/user"
import { OpportunityCard, OpportunityType } from "entities/user"
import { CardStatusType, Informer } from "shared/ui"
import { ChevronDownSvg } from "shared/assets/svg"
import { StyledPatternPageBackground } from "shared/styles"
import { type ContextType } from "../../types"
import {
    StyledButtonRubricatorPagination,
    StyledCardContainer,
    StyledCardsContainer,
    StyledInformerNotResultContainer,
    StyledTextInformer,
} from "./styles"
import { type OpportunityItemWithType } from "./types"
import { getRubricatorCards } from "./lib"
import { useRubricator } from "./useRubricator"
import { TAKE_PART } from "./constants"

export const RubricatorSection: FC = () => {
    const { filters } = useOutletContext<ContextType>()
    const { type = 0, countPage, setCountPage } = useGetTypeParameters()
    const { data, isFetching, isUninitialized } = useRubricator({ filters, countPage, type, takePart: TAKE_PART })

    useEffect(() => {
        setCountPage(0)
    }, [filters?.opCats, filters?.sort, filters?.placeCats, filters?.userCats, filters?.onlyOpen, type])

    const totalOpportunitiesOpp = [
        ...(data?.opportunities?.map((el) => ({ ...el, type: "OPPORTUNITY" })) ?? []),
    ] as OpportunityItemWithType[]

    const totalOpportunitiesPlaces = [
        ...(data?.places?.map((el) => ({ ...el, type: "PLACE" })) ?? []),
    ] as OpportunityItemWithType[]

    const sortCards = getRubricatorCards(
        type === 1
            ? [...totalOpportunitiesPlaces]
            : type === 2
            ? [...totalOpportunitiesOpp]
            : [...totalOpportunitiesOpp, ...totalOpportunitiesPlaces],
        type!,
        filters && "sort" in filters ? filters.sort : undefined
    )
    const buttonVisible =
        (type === 0 ? countPage * TAKE_PART * 2 : countPage * TAKE_PART) <
        [...totalOpportunitiesOpp, ...totalOpportunitiesPlaces].length
    return (
        <>
            <StyledPatternPageBackground
                $height={"1420px"}
                $position={"right -1270px bottom -27px"}
                $color={"opportunityFill"}
            />
            {sortCards.length ? (
                <StyledCardsContainer>
                    {sortCards?.map((el: OpportunityItemWithType, i) => (
                        <StyledCardContainer key={`${el.id}${i}`}>
                            <OpportunityCard
                                statusType={el.openForRegistration ? CardStatusType.REGISTRATION : null}
                                id={el.id}
                                title={el.name}
                                typeCard={el.type && OpportunityType[el.type]}
                                imgSrc={el.images?.map(({ url }) => url)[0]}
                            >
                                {el.description}
                            </OpportunityCard>
                        </StyledCardContainer>
                    ))}
                </StyledCardsContainer>
            ) : (
                <></>
            )}
            {!!data && buttonVisible && !isFetching && (
                <StyledButtonRubricatorPagination
                    onClick={() => {
                        setCountPage?.((prevState) => prevState + 1)
                    }}
                >
                    <ChevronDownSvg />
                    Показать еще
                </StyledButtonRubricatorPagination>
            )}
            {!data && filters && Object.keys(filters) && !isFetching && !isUninitialized && (
                <StyledInformerNotResultContainer>
                    <Informer title={"Ничего не найдено :("}>
                        <StyledTextInformer>Попробуйте изменить настройки фильтрации</StyledTextInformer>
                    </Informer>
                </StyledInformerNotResultContainer>
            )}
        </>
    )
}
