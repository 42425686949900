import React, { createContext, useState, useContext, type ReactNode, useEffect } from "react"
// eslint-disable-next-line no-restricted-imports
import { useAuth } from "../../app/providers/router/lib"

interface ForMeContextType {
    isOn: boolean
    toggle: () => void
}

const ForMeContext = createContext<ForMeContextType | undefined>(undefined)

interface ForMeProviderProps {
    children: ReactNode
}

export const ForMeProvider: React.FC<ForMeProviderProps> = ({ children }) => {
    const { wasLogin } = useAuth()
    const [isOn, setIsOn] = useState(wasLogin)

    useEffect(() => {
        setIsOn(wasLogin)
    }, [wasLogin])

    const toggle = () => setIsOn((prevState) => !prevState)

    return <ForMeContext.Provider value={{ isOn, toggle }}>{children}</ForMeContext.Provider>
}

export const useForMeContext = (): ForMeContextType => {
    const context = useContext(ForMeContext)
    if (!context) {
        throw new Error()
    }
    return context
}
