import React from "react"
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import {
    type IEditProfileData,
    useChangeProfileDataMutation,
    useDeleteAvatarMutation,
    useGetProfileDataQuery,
} from "features/viewer"
import { Button, ButtonSize, ButtonType, FileAccept, FileLoad, FileType, FormLayout, Input, InputDate } from "shared/ui"
import { formDataHandler, dateConverter } from "shared/api"
import { StyledInputSection, StyledRowInput } from "./styles"
import { ChangeProfileDataNames } from "./types"
import { EditPersonalDataConfig } from "./lib/EditPersonalDataConfig"

export const EditPersonal = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useGetProfileDataQuery("")
    const [fetch, state] = useChangeProfileDataMutation()
    const [fetchDeleteAvatar] = useDeleteAvatarMutation()
    const methods = useForm<IEditProfileData>(
        EditPersonalDataConfig({
            firstname: data?.firstname,
            lastname: data?.lastname,
            midname: data?.midname,
            locality: data?.locality,
            birthday: dateConverter(data?.birthday as string).yyy_mmm_ddd("-"),
        })
    )

    const requestDelete = async () => {
        await fetchDeleteAvatar("")
    }
    const handleSubmit: SubmitHandler<IEditProfileData> = async (data) => {
        await fetch(formDataHandler(data, ChangeProfileDataNames.avatar))
        navigate("/personal-account")
    }

    return (
        <FormProvider {...methods}>
            <FormLayout
                main={() => (
                    <>
                        {data && (
                            <FileLoad
                                name={ChangeProfileDataNames.avatar}
                                accept={FileAccept.PICTURE}
                                FLType={FileType.PICTURE}
                                title={"Аватар"}
                                uploadFile={
                                    data?.avatar && {
                                        url: data?.avatar?.url,
                                        name: data?.avatar?.filename,
                                        requestDelete,
                                    }
                                }
                            />
                        )}
                        <StyledInputSection>
                            <Input
                                name={ChangeProfileDataNames.lastname}
                                labelText={"Фамилия"}
                                required={true}
                                placeholder={"Фамилия"}
                                clearable={false}
                            />
                            <StyledRowInput>
                                <Input
                                    name={ChangeProfileDataNames.firstname}
                                    labelText={"Имя"}
                                    required={true}
                                    placeholder={"Имя"}
                                    clearable={false}
                                />
                                <Input
                                    name={ChangeProfileDataNames.midname}
                                    labelText={"Отчество"}
                                    placeholder={"Отчество"}
                                    clearable={false}
                                />
                            </StyledRowInput>
                        </StyledInputSection>

                        <StyledInputSection>
                            <Input
                                name={ChangeProfileDataNames.locality}
                                labelText={"Город"}
                                required={true}
                                placeholder={"Город"}
                                clearable={false}
                            />
                            <InputDate
                                name={ChangeProfileDataNames.birthday}
                                labelText={"Дата рождения"}
                                required={true}
                            />
                        </StyledInputSection>
                    </>
                )}
                footer={() => (
                    <>
                        <Button
                            typeUI={ButtonType.PRIMARY}
                            onClick={methods.handleSubmit(handleSubmit)}
                            size={ButtonSize.M}
                        >
                            Сохранить
                        </Button>
                        <Button
                            typeUI={ButtonType.GHOST}
                            size={ButtonSize.M}
                            onClick={() => navigate("/personal-account")}
                        >
                            Отменить
                        </Button>
                    </>
                )}
            />
        </FormProvider>
    )
}
