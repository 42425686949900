import React, { type FC } from "react"
import { Period, Toggle, ToggleType } from "shared/ui"
import { StyledSchedule, StyledWeekendSwitch } from "./styles"
import { type ModalScheduleProps } from "./types"

export const ModalScheduleSection: FC<ModalScheduleProps> = (props) => {
    const { children, index, isTurnOn, handlerToggle } = props

    return (
        <StyledSchedule>
            {children}
            <Period.Time
                inputNameFinish={`times.${index}.defaultTimes.finishTime`}
                inputNameStart={`times.${index}.defaultTimes.startTime`}
            />
            <StyledWeekendSwitch>
                <div>Есть перыв</div>
                <Toggle type={ToggleType.SWITCH} handlerToggle={handlerToggle} isTurnOn={isTurnOn} />
            </StyledWeekendSwitch>
            {isTurnOn && (
                <Period.Time
                    inputNameFinish={`times.${index}.breakTimes.finishTime`}
                    inputNameStart={`times.${index}.breakTimes.startTime`}
                />
            )}
        </StyledSchedule>
    )
}
