import { type OpportunityType } from "../../types"

export enum SizeOpportunityTag {
    BIG = "big",
    SMALL = "small",
}
export interface OpportunityTagProps {
    typeCard: OpportunityType
    size?: SizeOpportunityTag
}
