import styled from "styled-components"

const StyledSliderItem = styled.div<{
    $current: boolean
}>`
    position: relative;
    display: flex;
    width: 118px;
    height: 118px;
    justify-content: center;
    align-items: center;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        opacity: 0.48;
        background-color: ${({ $current }) => $current && "var(--root-colors-accent-crema, #F4EFE9)"};
        border-radius: var(--assets-opportunity-photos-radius, 24px);
    }
    &,
    & > img {
        border-radius: var(--assets-opportunity-photos-radius, 24px);
    }
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
`

export { StyledSliderItem }
