import styled from "styled-components"

export const StyledBreak = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-schedule-sizes-s-gap, 8px);
    align-self: stretch;
    justify-content: space-between;
    font-size: 12px;
    line-height: 14px;
`

export const StyledDivider = styled.div`
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
`

export const StyledScheduleRow = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    align-self: stretch;
    color: #000;
`

export const StyledMain = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-schedule-sizes-s-gap, 8px);
    align-self: stretch;
    justify-content: space-between;
    line-height: 18px;
`

export const StyledOutputSchedule = styled.div`
    display: grid;
    align-items: stretch;
    align-self: stretch;
    position: relative;
`
