import { type DragEvent, useEffect, useState } from "react"
import { conditions } from "../../lib"
import { GroupDirection } from "../Group"
import { FileLoadSize, FileType } from "./types"

interface FileInfo {
    name: string
    type?: FileType.DOCUMENT | FileType.PICTURE
    size?: number
}

const regImage = new RegExp("image")
const regDoc = new RegExp("/document|application/")
let fileCount = 0
export const useFileSet = (FLType: FileType, maxFiles = 5) => {
    const [files, setFiles] = useState<File[]>([])
    const [filesInfo, setFilesInfo] = useState<FileInfo[]>([])
    const [dragActive, setDragActive] = useState<boolean>(false)
    useEffect(() => {
        console.log(fileCount)
    }, [files])
    const setEvent = (targetFiles: FileList) => {
        if (targetFiles && files.length < 5) {
            const newFiles = Array.from(targetFiles)
            newFiles.forEach((item: File, index) => {
                const reader = new FileReader()
                const validate = {
                    image: item.type.match(regImage),
                    document: item.type.match(regDoc),
                }
                // @ts-ignore
                reader.readAsDataURL(targetFiles[index])
                reader.onloadend = () => {
                    switch (FLType) {
                        case FileType.PICTURE:
                            if (validate.image && validate.image[0]) {
                                setFiles([newFiles[0]])
                                setFilesInfo([{ name: reader.result as string, type: FileType.PICTURE }])
                            }
                            break
                        case FileType.PICTURE_LIST:
                            if (validate.image && validate.image[0] && fileCount < maxFiles) {
                                setFiles((prev) => prev && [...prev, item])
                                setFilesInfo((prev) => [
                                    ...prev,
                                    { name: reader.result as string, type: FileType.PICTURE }
                                ])
                                fileCount++
                            }
                            break
                        case FileType.DOCUMENT:
                            if (validate.document && validate.document[0]) {
                                setFiles([newFiles[0]])
                                setFilesInfo([{ name: item.name, size: item.size, type: FileType.DOCUMENT }])
                            }
                            break
                        case FileType.DOCUMENT_LIST:
                            if (validate.document && validate?.document[0] && fileCount < maxFiles) {
                                setFiles((prev) => prev && [...prev, item])
                                setFilesInfo((prev) => [
                                    ...prev,
                                    { name: item.name, size: item.size, type: FileType.DOCUMENT }
                                ])
                                fileCount++
                            }
                            break
                        case FileType.MULTIPLE:
                            if (targetFiles.length === 1) {
                                const type = validate?.document?.[0] ? FileType.DOCUMENT : FileType.PICTURE
                                const name = type === FileType.PICTURE ? (reader.result as string) : item.name
                                setFiles([newFiles[0]])
                                setFilesInfo([{ name, size: item.size, type }])
                            }
                            break
                    }
                }
            })
        }
    }
    const handleDelete = (indexID: number) => {
        setFilesInfo((prev) => prev.filter((_, index) => indexID !== index))
        setFiles((prev) => prev.filter((_, index) => indexID !== index))
        fileCount--
    }

    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true)
        } else if (event.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = function (event: DragEvent<HTMLDivElement>) {
        event.preventDefault()
        event.stopPropagation()
        setDragActive(false)
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            setEvent(event.dataTransfer.files)
        }
    }

    return { files, filesInfo, setEvent, handleDelete, dragAndDrop: { handleDrag, handleDrop, dragActive }, }
}

export const useFileDrop = () => {
    const [dragActive, setDragActive] = useState<boolean>(false)

    // handle drag events
    const handleDrag = (event: DragEvent<HTMLDivElement>) => {
        event.preventDefault()
        event.stopPropagation()
        if (event.type === "dragenter" || event.type === "dragover") {
            setDragActive(true)
        } else if (event.type === "dragleave") {
            setDragActive(false)
        }
    }

    const handleDrop = function (event: DragEvent<HTMLDivElement>) {
        event.preventDefault()
        event.stopPropagation()
        setDragActive(false)
        if (event.dataTransfer.files && event.dataTransfer.files[0]) {
            console.log(event.dataTransfer.files)
        }
    }
    return { handleDrag, handleDrop, dragActive }
}
export const useFileLoadCondition = (FLType: FileType, files: File[], size: FileLoadSize, example: string) => ({
    buttonLoad: FLType === FileType.PICTURE_LIST || FLType === FileType.DOCUMENT_LIST || Boolean(!files.length),
    isType: {
        isDocument: FLType === FileType.DOCUMENT || FLType === FileType.DOCUMENT_LIST,
        isPicture: FLType === FileType.PICTURE,
        isMultipleDocument: true,
    },
    multiple: conditions<FileType>(FLType, [
        [FileType.PICTURE_LIST, true],
        [FileType.DOCUMENT_LIST, true],
    ]),
    containerDirection: conditions<FileType>(FLType, [
        [FileType.PICTURE_LIST, GroupDirection.ROW],
        [FileType.DOCUMENT_LIST, GroupDirection.COLUMN],
    ]),
    FileLoadDirection:
        FLType === FileType.DOCUMENT_LIST || size === FileLoadSize.L
            ? GroupDirection.COLUMN_REVERSE
            : GroupDirection.COLUMN,
})
