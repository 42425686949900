import React, { type FC } from "react"
import { Button, useMediaSizeButton } from "shared/ui"
import { ArrowRight } from "shared/assets/svg"
import { type PlaceFooterButtonProps } from "./types"

export const PlaceFooterButton: FC<PlaceFooterButtonProps> = ({ onClickHandler }) => {
    const { fromMtoL } = useMediaSizeButton()
    return (
        <Button onClick={onClickHandler} size={fromMtoL}>
            Посетить <ArrowRight />
        </Button>
    )
}
