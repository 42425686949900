import { useEffect, useState } from "react"
import { FormFieldState, type useFormFieldProps, type useFormFieldValueStatus } from "./types"

export const useFormField = (props: useFormFieldProps) => {
    const [LState, setState] = useState<FormFieldState>(FormFieldState.BASIC)
    const [propsState, setPropsState] = useState<useFormFieldProps>(props)
    const { valid = true, focused = false, filled = false, isOn } = propsState

    const statusState: Record<FormFieldState, useFormFieldValueStatus> = {
        [FormFieldState.BASIC]: {
            isOn: isOn[FormFieldState.BASIC],
            isValid: valid && !focused,
            status: FormFieldState.BASIC,
        },
        [FormFieldState.FOCUSED]: {
            isOn: isOn[FormFieldState.FOCUSED],
            isValid: focused,
            status: FormFieldState.FOCUSED,
        },
        [FormFieldState.SUCCESS]: {
            isOn: isOn[FormFieldState.SUCCESS],
            isValid: valid && filled && !focused,
            status: FormFieldState.SUCCESS,
        },
        [FormFieldState.INVALID]: {
            isOn: isOn[FormFieldState.INVALID],
            isValid: !valid && filled,
            status: FormFieldState.INVALID,
        },
    }

    const switchState = () => {
        for (const key in statusState) {
            const value = statusState[key as FormFieldState]
            if (value.isOn) {
                value.isValid && setState(value.status)
            }
        }
    }

    useEffect(() => {
        switchState()
    }, [valid, focused, filled])

    return { LState, setPropsState }
}
