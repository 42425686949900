import React, { type FC } from "react"
import { Link } from "react-router-dom"
import { OpportunitySection } from "entities/user"
import { SectionGapSize, StyledSection } from "shared/styles"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { LinkSvg } from "shared/assets/svg"

interface PlaceAboutProps {
    address: string
    categories: Array<{ name: string; id: string }>
    coords: string
}
export const PlaceAbout: FC<PlaceAboutProps> = (props) => {
    const { address, categories, coords } = props
    const arrCoords = coords?.split(" ")
    const { fromStoM } = useMediaSizeButton()
    return (
        <StyledSection>
            <StyledSection $gapSize={SectionGapSize.S}>
                <OpportunitySection.CategorySection categories={categories} />
                <OpportunitySection.GeographySection address={address} isAddress={true} />
                <Link
                    to={`https://yandex.ru/maps/?pt=${arrCoords[1]},${arrCoords[0].slice(0, -1)}&z=18&l=map`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button size={fromStoM} typeUI={ButtonType.GHOST}>
                        Показать на карте <LinkSvg />
                    </Button>
                </Link>
            </StyledSection>
        </StyledSection>
    )
}
