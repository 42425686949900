import StolzlThinWoff2 from "./assets/Stolzl-Thin.woff2"
import StolzlLightWoff2 from "./assets/Stolzl-Light.woff2"
import StolzlRegularWoff2 from "./assets/Stolzl-Regular.woff2"
import StolzlMediumWoff2 from "./assets/Stolzl-Medium.woff2"
import StolzlBoldWoff2 from "./assets/Stolzl-Bold.woff2"

export const FONT_STOLZL = {
    fontFamily: "Stolz",
    types: {
        thin: StolzlThinWoff2,
        light: StolzlLightWoff2,
        regular: StolzlRegularWoff2,
        medium: StolzlMediumWoff2,
        bold: StolzlBoldWoff2,
    },
}
