import styled, { css } from "styled-components"
import { patternSmallSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "shared/styles"

export const StyledProfileDataPattern = styled.div<{
    $visibleBackground?: boolean
    $type: "interests" | "status" | "light-blue" | "middle-light-blue" | "middle-dark-blue" | "main-red"
}>`
    ${({ $type }) => {
        switch ($type) {
            case "interests":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#opportunity`});
                    background-position: right -763px bottom -763px;
                `
            case "status":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#news`});
                    background-position: right -777px bottom 830px;
                `
            case "light-blue":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#place`});
                    background-position: right -763px bottom -763px;
                `
            case "middle-dark-blue":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#info`});
                    background-position: right -777px bottom 830px;
                `
            case "middle-light-blue":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#place`});
                    background-position: right -777px bottom 830px;
                `
            case "main-red":
                return css`
                    background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#mainRed`});
                    background-position: right -763px top -763px;
                `
        }
    }}

    display: flex;
    width: 100%;
    border-radius: 16px;
    background-size: 1520px;

    ${({ $visibleBackground }) =>
        $visibleBackground
            ? css``
            : css`
                  @media all and (max-width: ${getMediaVariable("tablet")}) {
                      background-image: unset;
                  }
              `};
`
