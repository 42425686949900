import React, { type FC } from "react"
import { GiftCard, useGetOperatorsRatingQuery, useGetProfileDataQuery, UserMetric } from "features/viewer"
import { RatingPlate, RatingPlateType, useMediaSizeRatingPlate } from "entities/viewer"
import { SectionGapSize, StyledSection } from "shared/styles"
import { useResize } from "shared/hook"
import { WrapperColumn } from "shared/ui"
import { StyledData, StyledRatingList } from "./styles"

export const RatingList: FC = () => {
    const { fromStoL } = useMediaSizeRatingPlate()
    const { data: OperatorsData } = useGetOperatorsRatingQuery("")
    const { data: ProfileData } = useGetProfileDataQuery("")
    if (ProfileData && OperatorsData) {
        return (
            <StyledRatingList>
                {OperatorsData.map((item, index) => {
                    const isLeader = index === 0 ? RatingPlateType.LEADER : undefined
                    const isUser = ProfileData.id === item.id ? RatingPlateType.USER : undefined
                    return (
                        <RatingPlate
                            type={isLeader || isUser}
                            key={index}
                            size={fromStoL}
                            rating={index + 1}
                            points={item.points}
                            name={`${item.lastname} ${item.firstname}`}
                        />
                    )
                })}
            </StyledRatingList>
        )
    }
}
export const Rating = () => {
    const {
        mediaScreenSize: { width, isLaptopSm },
    } = useResize()
    return (
        <>
            <WrapperColumn.Wrapper isMobile={width < isLaptopSm}>
                <WrapperColumn.Item maxWidth={396}>
                    <StyledSection $gapSize={SectionGapSize.S}>
                        <StyledData>
                            <GiftCard />
                        </StyledData>
                        <UserMetric />
                    </StyledSection>
                </WrapperColumn.Item>
                <WrapperColumn.Item>
                    <RatingList />
                </WrapperColumn.Item>
            </WrapperColumn.Wrapper>
        </>
    )
}
