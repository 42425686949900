import { type FC } from "react"
import { CounterSize } from "shared/ui"
import { StyledEventsCalendarCounterContainer } from "./styles"
import { getCounterColor } from "./lib"
import { type EventsCounterProps } from "./types"

export const EventsCounter: FC<EventsCounterProps> = (props) => {
    const { hasEvent, isAnotherMonth, isWeekend, isLaptop, count } = props
    return (
        <>
            {hasEvent && (
                <StyledEventsCalendarCounterContainer
                    color={getCounterColor(isAnotherMonth, isWeekend)}
                    size={isLaptop ? CounterSize.S : CounterSize.L}
                    count={count}
                />
            )}
        </>
    )
}
