import React, { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { ChoiceInputAuth, ChoiceInputAuthType, useChoiceInputAuth } from "entities/user"
import { FormLayout } from "shared/ui"
import { StyledSection } from "shared/styles"
import { useModal } from "shared/hook"
import { ModelRegistrationForm, schemaRegistrationForm } from "./config"
import { type IRegistrationForm } from "./types"
import { UserDataFields } from "./UserDataFields"
import { CityDateData } from "./CityField"
import { NewPasswordFields } from "./NewPasswordFields"
import { AcceptCheck } from "./AcceptCheck"
import { BtnControl } from "./BtnControl"
import { AcceptLetterCheck } from "./AcceptLetterCheck"
import { ModalEmail } from "./ModalEmail"
import { ModalPhone } from "./ModalPhone"

export const RegistrationForm: FC = () => {
    const { choice, setChoiceHandler } = useChoiceInputAuth()
    const methods = useForm<IRegistrationForm>({
        ...ModelRegistrationForm,
        mode: "onChange",
        criteriaMode: "all",
        resolver: yupResolver(schemaRegistrationForm(choice)),
    })

    const { isActive, toggleActivity } = useModal()
    return (
        <FormProvider {...methods}>
            <FormLayout
                headerText={"Регистрация"}
                main={() => (
                    <>
                        <ChoiceInputAuth choice={choice} setChoiceHandler={setChoiceHandler} />
                        <UserDataFields />
                        <CityDateData />
                        <NewPasswordFields />
                        <StyledSection>
                            <AcceptCheck />
                            <AcceptLetterCheck />
                        </StyledSection>
                        <ModalEmail
                            toggleActivity={toggleActivity}
                            isActive={isActive && choice === ChoiceInputAuthType.EMAIL}
                        />
                        <ModalPhone
                            toggleActivity={toggleActivity}
                            isActive={isActive && choice === ChoiceInputAuthType.PHONE}
                        />
                    </>
                )}
                footer={() => <BtnControl toggleActivityModal={toggleActivity} choice={choice} />}
            ></FormLayout>
        </FormProvider>
    )
}
