import { type SyntheticEvent } from "react"

export enum EventCardSize {
    S = "small",
    M = "medium",
    L = "large",
}

export interface EventCardProps {
    link: string
    startDate: string
    endDate: string
    text: string
    size?: EventCardSize
    onClick?: (e: SyntheticEvent) => void
    withLink?: boolean
}
