import { type FC } from "react"
import { NavLink } from "react-router-dom"
import { type OpportunityCardProps, OpportunityTag, OpportunityType, SizeOpportunityTag } from "entities/user"
import { CardStatus, CardType } from "shared/ui"
import { StyledContent, StyledHeading, StyledOpportunityCard, StyledStatus, StyledTextContent } from "./styles"

export const OpportunityCard: FC<OpportunityCardProps> = (props) => {
    const { imgSrc, id, typeCard = OpportunityType.PLACE, statusType, title, children, operator = false } = props
    return (
        <NavLink
            to={typeCard === OpportunityType.PLACE ? `/place/${operator}/${id}` : `/opportunity/${operator}/${id}`}
        >
            <StyledOpportunityCard $typeCard={typeCard} $bg={imgSrc}>
                {statusType && (
                    <StyledStatus>
                        <CardStatus statusType={statusType} cardType={CardType.OPPORTUNITY_STATUS} />
                    </StyledStatus>
                )}
                <StyledContent>
                    <OpportunityTag size={SizeOpportunityTag.SMALL} typeCard={typeCard} />
                    <StyledHeading>{title}</StyledHeading>
                    {children && (
                        <StyledTextContent $lineClampCount={title.length > 26 ? 3 : 4}>{children}</StyledTextContent>
                    )}
                </StyledContent>
            </StyledOpportunityCard>
        </NavLink>
    )
}
