import React, { type FC } from "react"
import { OpportunitySection } from "entities/user"
import { SectionGapSize, StyledSection } from "shared/styles"

interface PlaceDescriptionProps {
    accessible?: boolean
    lowMobilityFriendly?: boolean
    userCategories: Array<{ id: string; name: string }>
}

export const PlaceDescription: FC<PlaceDescriptionProps> = (props) => {
    const { userCategories, ...inclusivity } = props
    return (
        <>
            <StyledSection $gapSize={SectionGapSize.S}>
                <OpportunitySection.ForWhomSection userCategories={userCategories} />
                <OpportunitySection.InclusivitySection {...inclusivity} />
            </StyledSection>
        </>
    )
}
