import * as yup from "yup"
import { type ObjectSchema } from "yup"
import { type IEditOrganization } from "features/viewer"

export const EditOrganizationSchema: ObjectSchema<IEditOrganization> = yup
    .object({
        taxId: yup.number().required("Поле обязательно для заполнения"),
        title: yup.string().required("Поле обязательно для заполнения"),
        file: yup
            .mixed<FileList>()
            .test("File", "test", (value) => value && value?.length < 2)
            .required("Доверенность не загруженна."),
    })
    .required()
