import styled, { css } from "styled-components"
import { ProfileDataSize, ProfileDataType } from "./types"

const StyledProfileData = styled.a<{
    $size: ProfileDataSize
    $type: ProfileDataType
}>`
    display: flex;
    width: 100%;
    padding: var(--assets-user-profile-data-sizes-${(props) => props.$size}-padding);
    border-radius: var(--assets-user-profile-data-sizes-${(props) => props.$size}-radius);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    gap: var(
        ${(props) =>
            props.$type === ProfileDataType.TAG
                ? "--assets-user-profile-data-sizes-s-gap-tag"
                : "--assets-user-profile-data-sizes-s-gap"}
    );
    background: var(--root-colors-main-white-light, rgba(0, 0, 0, 0.02));
    pointer-events: ${(props) => props.$type !== ProfileDataType.LINK && "none"};
`
const StyledProfileDataContent = styled.div<{
    $size: ProfileDataSize
    $type: ProfileDataType
}>`
    display: flex;
    align-items: center;
    gap: ${({ $type, $size }) =>
        $type === ProfileDataType.TAG
            ? "var( --assets-select-sizes-combo-gap)"
            : "var(--assets-user-profile-data-sizes-s-gap)"};
    align-self: stretch;
    flex-wrap: wrap;
    color: ${(props) =>
        props.$type === ProfileDataType.LINK ? "var(--layout-text-colors-link)" : "var(--layout-text-colors-header)"};
    font-size: ${(props) => props.$size === ProfileDataSize.S && "14px"};
    font-weight: ${(props) => (props.$type === ProfileDataType.ARTICLE ? 350 : 400)};
    line-height: ${(props) => props.$type === ProfileDataType.ARTICLE && "130%"};

    ${({ $type }) => {
        if ($type === ProfileDataType.TEXT)
            return css`
                & svg {
                    opacity: 0.72;
                }
            `
    }}}
`

const StyledCaption = styled.div<{
    $size: ProfileDataSize
}>`
    color: var(--assets-user-profile-data-colors-label, rgba(0, 0, 0, 0.48));
    font-size: ${(props) => (props.$size === ProfileDataSize.L ? "12px" : "10px")};
`
export { StyledProfileData, StyledProfileDataContent, StyledCaption }
