import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type InvalidSvgProps } from "./types"

export const InvalidSvg: FC<InvalidSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13 3.5C13 4.95813 11.9597 6.17329 10.5809 6.44381L11.4256 11.5121C12.1026 11.8114 12.7187 12.2334 13.2426 12.7574C13.6142 13.1289 13.9345 13.5469 14.1962 14H15.3054C16.2251 14 17.0706 14.505 17.5066 15.3148L20.4402 20.763L19.5598 21.237L16.6261 15.7889C16.3645 15.303 15.8572 15 15.3054 15H14.6569C14.8837 15.6416 15 16.3179 15 17C15 17.7879 14.8448 18.5681 14.5433 19.2961C14.2417 20.0241 13.7998 20.6855 13.2426 21.2426C12.6855 21.7998 12.0241 22.2417 11.2961 22.5433C10.5681 22.8448 9.78793 23 9 23C8.21207 23 7.43185 22.8448 6.7039 22.5433C5.97594 22.2417 5.31451 21.7998 4.75736 21.2426C4.20021 20.6855 3.75825 20.0241 3.45672 19.2961C3.15519 18.5681 3 17.7879 3 17C3 16.2719 3.13251 15.5505 3.39053 14.8707L4.28411 10.105C4.45643 9.18588 5.12726 8.43981 6.02292 8.17111L9.67248 7.07624L9.57137 6.46961C8.11756 6.26163 7 5.01133 7 3.5C7 1.84315 8.34315 0.5 10 0.5C11.6569 0.5 13 1.84315 13 3.5ZM12 3.5C12 4.60457 11.1046 5.5 10 5.5C8.89543 5.5 8 4.60457 8 3.5C8 2.39543 8.89543 1.5 10 1.5C11.1046 1.5 12 2.39543 12 3.5ZM9.8382 8.07056L6.31027 9.12893C5.77287 9.29015 5.37038 9.7378 5.26698 10.2892L4.81484 12.7006C5.3598 12.1702 6.00082 11.7479 6.7039 11.4567C7.43186 11.1552 8.21207 11 9 11C9.45619 11 9.90979 11.052 10.3522 11.1543L9.8382 8.07056ZM4.00021 17.0454L4.35625 15.1465C4.36424 15.1265 4.37235 15.1065 4.3806 15.0866C4.63188 14.48 5.00017 13.9288 5.46447 13.4645C5.92876 13.0002 6.47996 12.6319 7.08659 12.3806C7.69321 12.1293 8.34339 12 9 12C9.65661 12 10.3068 12.1293 10.9134 12.3806C11.52 12.6319 12.0712 13.0002 12.5355 13.4645C12.9998 13.9288 13.3681 14.48 13.6194 15.0866C13.8707 15.6932 14 16.3434 14 17C14 17.6566 13.8707 18.3068 13.6194 18.9134C13.3681 19.52 12.9998 20.0712 12.5355 20.5355C12.0712 20.9998 11.52 21.3681 10.9134 21.6194C10.3068 21.8707 9.65661 22 9 22C8.34339 22 7.69321 21.8707 7.08658 21.6194C6.47995 21.3681 5.92876 20.9998 5.46446 20.5355C5.00017 20.0712 4.63187 19.52 4.3806 18.9134C4.13512 18.3208 4.00602 17.6865 4.00021 17.0454Z"
                fill="black"
                fillOpacity="0.72"
            />
        </svg>
    )
}
