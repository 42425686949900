import { type FC } from "react"
import { Outlet } from "react-router-dom"
import { ProfileTop, BottomSections } from "widgets/viewer"
import { Wrapper } from "shared/ui"

export const PersonalAccountPage: FC = () => {
    return (
        <>
            <Wrapper width={100} maxWidth={640}>
                <ProfileTop />
                <BottomSections>
                    <Outlet />
                </BottomSections>
            </Wrapper>
        </>
    )
}
