import styled from "styled-components"
import { Button } from "../Button"
import { FormField } from "../FormField"

const StyledInputCounterButton = styled.button`
    display: flex;
    height: 46px;
    padding: var(--assets-input-sizes-padding-vert, 2px) var(--assets-input-sizes-padding-button, 3px);
    align-items: center;
    justify-content: center;
    width: var(--assets-button-common-s-height, 42px);
    gap: var(--assets-input-sizes-gap, 8px);
    align-self: stretch;
    color: var(--assets-input-colors-button, #000000b8);
    border-radius: var(--assets-button-common-s-radius, 10px);
    background-color: transparent;
    position: relative;

    &:first-of-type {
        left: -12px;
        top: -2px;
    }

    &:last-of-type {
        left: 12px;
        top: -2px;
    }
`

const StyledInputCounterInput = styled.input`
    width: 35%;
    display: flex;
    text-align: center;
    flex-shrink: 2;
    color: black;
`

const StyledButtonCounter = styled(Button)`
    padding: 0;
    width: 24px !important;
`

const StyledFormFieldInputCounter = styled(FormField)`
    & > *:nth-child(2) {
        justify-content: space-around;
    }
`

export { StyledInputCounterButton, StyledInputCounterInput, StyledButtonCounter, StyledFormFieldInputCounter }
