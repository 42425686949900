import { styled, css } from "styled-components"
import { conditions } from "shared/lib"
import { EventCardSize } from "./types"

export const StyledEventCardContainer = styled.div<{ $size: EventCardSize }>`
    display: flex;
    flex-direction: column;
    padding: ${({ $size }) =>
        conditions<EventCardSize>($size, [
            [EventCardSize.S, "var(--assets-card-custom-calendar-minified-padding, 8px)"],
            [EventCardSize.M, "var(--assets-card-custom-calendar-s-padding, 16px)"],
            [EventCardSize.L, "var(--assets-card-custom-calendar-l-padding, 24px)"],
        ])};
    row-gap: ${({ $size }) =>
        conditions<EventCardSize>($size, [
            [EventCardSize.S, "2px"],
            [EventCardSize.M, "var(--assets-card-custom-calendar-s-gap, 4px)"],
            [EventCardSize.L, "var(--assets-card-custom-calendar-l-gap, 8px)"],
        ])};
    cursor: pointer;
`

export const StyledEventCardHeader = styled.div`
    display: flex;
    justify-content: space-between;
`

export const StyledEventCardText = styled.p<{ $size: EventCardSize }>`
    color: var(--assets-card-common-colors-content, #000);
    font-size: 16px;
    line-height: 120%; /* 19.2px */

    ${({ $size }) =>
        $size === EventCardSize.S &&
        css`
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            overflow: hidden;
        `}

    font-size: ${({ $size }) =>
        conditions<EventCardSize>($size, [
            [EventCardSize.S, "12px"],
            [EventCardSize.M, "14px"],
            [EventCardSize.L, "16px"],
        ])};
`

export const StyledEventCardTime = styled.p<{ $size: EventCardSize }>`
    color: var(--assets-calendar-colors-events, #3e3aed);
    font-size: ${({ $size }) =>
        conditions<EventCardSize>($size, [
            [EventCardSize.S, "12px"],
            [EventCardSize.M, "12px"],
            [EventCardSize.L, "14px"],
        ])};
`
