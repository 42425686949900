import { useEffect, type FC, useCallback } from "react"
import { createPortal } from "react-dom"
import { type ModalProps } from "./types"
import { StyledModal, StyledModalBackground, StyledModalContainer } from "./styles"
import { useMediaSizeModal } from "./lib"

export const Modal: FC<ModalProps> = (props) => {
    const { fromStoL } = useMediaSizeModal()
    const { isActive, children, position = "center", toggleActivity, size = fromStoL, bigHeight = false } = props

    const handleEscKeyDown = useCallback((event: KeyboardEvent) => {
        if (event.key === "Escape") {
            event.preventDefault()
            toggleActivity()
        }
    }, [])

    useEffect(() => {
        if (isActive) {
            document.addEventListener("keydown", handleEscKeyDown)
        } else {
            document.removeEventListener("keydown", handleEscKeyDown)
        }
    }, [isActive])

    if (!isActive) return null

    return (
        <>
            {createPortal(
                <StyledModalContainer>
                    <StyledModalBackground onClick={toggleActivity} />
                    <StyledModal $bigHeight={bigHeight} $position={position} $size={size}>
                        {children}
                    </StyledModal>
                </StyledModalContainer>,
                document.body
            )}
        </>
    )
}
