import React, { type FC } from "react"
import { CrownSvg, SizeSvg, StarSvg } from "shared/assets/svg"
import { type RatingProps } from "./types"
import {
    StyledRating,
    StyledRatingChildren,
    StyledRatingContent,
    StyledRatingCount,
    StyledRatingFrom,
    StyledSvg,
} from "./styles"

export const Metric: FC<RatingProps> = (props) => {
    const { rating, hasOverall = true, fromRating, children } = props
    const Icon = hasOverall ? <CrownSvg /> : <StarSvg size={SizeSvg.L} />
    return (
        <StyledRating>
            <StyledSvg>{Icon}</StyledSvg>
            <StyledRatingContent>
                <StyledRatingCount>
                    {rating}
                    {hasOverall && <StyledRatingFrom>из {fromRating}</StyledRatingFrom>}
                </StyledRatingCount>
                <StyledRatingChildren>{children}</StyledRatingChildren>
            </StyledRatingContent>
        </StyledRating>
    )
}
