import React, { useState } from "react"
import { FillSvg, InfoSvg } from "shared/assets/svg"
import { Input } from "shared/ui"
import { StyledDropDown, StyledInfoSvg } from "./styles"

export const VideoLink = () => {
    const [isShow, setShow] = useState<boolean>(false)
    return (
        <Input
            labelIcon={() => (
                <StyledInfoSvg
                    onClick={() => {
                        setShow(!isShow)
                    }}
                    onBlur={() => {
                        setShow(false)
                    }}
                    tabIndex={8}
                >
                    <StyledDropDown $isShow={isShow}>
                        Если вы хотите добавить видео из Вконтакте, необходимо предоставить ссылку на видео,
                        опубликованное в открытой группе Вконтакте
                    </StyledDropDown>
                    <InfoSvg fill={FillSvg.CURRENT_COLOR} />
                </StyledInfoSvg>
            )}
            name={"videoUrl"}
            required={false}
            placeholder={"https://rutube.ru/..."}
            labelText={"Ссылка на видео RuTube/YouTube/Vk"}
        />
    )
}
