import styled from "styled-components"
import { patternSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "shared/styles"
import { conditions } from "shared/lib"
import { CardStatusType } from "shared/ui"
const conditionStatus = (type: CardStatusType) => {
    return (
        conditions<CardStatusType>(type, [
            [CardStatusType.WAITING, "idea-wait"],
            [CardStatusType.ACCEPTED, "idea-accept"],
        ]) || "idea-wait"
    )
}
const StyledIdeaBackground = styled.div<{
    $type: CardStatusType
}>`
    width: 100vw;
    height: 1520px;
    position: absolute;
    left: 0;
    background-image: url(${({ $type }) => `data:image/svg+xml,${patternSvgStatic}#${conditionStatus($type)}`});
    background-repeat: no-repeat;
    background-position: left -1000px bottom -276px;
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        background: unset;
    }
`
export { StyledIdeaBackground }
