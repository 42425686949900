import React, { type FC } from "react"
import { useFormContext } from "react-hook-form"
import {
    Button,
    ButtonSize,
    ButtonType,
    Choice,
    ChoiceGroup,
    ChoiceGroupSize,
    ChoiceSize,
    Input,
    InputNumber,
    TypeInput,
} from "shared/ui"
import { type ChoiceInputAuthProps, ChoiceInputAuthType } from "./types"
import { StyledChoiceInputAuth } from "./styles"

export const ChoiceInputAuth: FC<ChoiceInputAuthProps> = ({ choice, setChoiceHandler }) => {
    const isEmail = choice === ChoiceInputAuthType.EMAIL
    const isNumber = choice === ChoiceInputAuthType.PHONE

    const { unregister } = useFormContext()

    return (
        <StyledChoiceInputAuth>
            <ChoiceGroup scroll={false} size={ChoiceGroupSize.XS}>
                <Choice
                    id={"email"}
                    size={ChoiceSize.XS}
                    selected={choice}
                    onClick={() => {
                        setChoiceHandler(ChoiceInputAuthType.EMAIL)
                        unregister("phone")
                    }}
                >
                    Email
                </Choice>
                {/*<Choice*/}
                {/*    id={"phone"}*/}
                {/*    size={ChoiceSize.XS}*/}
                {/*    selected={choice}*/}
                {/*    onClick={() => {*/}
                {/*        setChoiceHandler(ChoiceInputAuthType.PHONE)*/}
                {/*        unregister("email")*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Телефон*/}
                {/*</Choice>*/}
                <div>
                    <Button typeUI={ButtonType.DISABLED} size={ButtonSize.XS}>
                        Телефон
                    </Button>
                </div>
            </ChoiceGroup>

            {isEmail ? (
                <Input
                    hasStatus={true}
                    clearable={false}
                    name={"email"}
                    type={TypeInput.EMAIL}
                    placeholder={"example@mail.com"}
                />
            ) : (
                <InputNumber
                    name={"phone"}
                    placeholder={"912 345-67-89"}
                    maxLength={13}
                    onInput={(e) => {
                        let input = e.target as HTMLInputElement
                        const regex = /^(\d{3})(\d{3})(\d{2})(\d{2})$/g
                        const subst = "$1 $2-$3-$4"
                        input.value = input.value.replace(regex, subst)
                    }}
                />
            )}
        </StyledChoiceInputAuth>
    )
}
