import { type ArrayPath, type FieldArrayWithId, type FieldValues, type UseFieldArrayAppend } from "react-hook-form"

export class ArrayFieldHandlers<TForm extends FieldValues, TValues, TArrName extends ArrayPath<TForm>> {
    constructor(
        readonly values: TValues,
        readonly append: UseFieldArrayAppend<TForm, TArrName>,
        readonly remove: (index?: number | number[]) => void,
        readonly fields: FieldArrayWithId<TForm, TArrName, "id">[],
        readonly appendObject: any,
        readonly additionalConditionAppend?: boolean
    ) {
        this.values = values
        this.append = append
        this.remove = remove
        this.fields = fields
        this.appendObject = appendObject
        this.additionalConditionAppend = additionalConditionAppend
    }

    handlerClickDelete = () => {
        if (this.fields.length !== 1) {
            this.remove(this.fields.length - 1)
        }
    }
    handlerClickAdd = () => {
        if (this.fields.length < 7 && this.additionalConditionAppend) this.append(this.appendObject)
    }
}
