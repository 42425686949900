import styled from "styled-components"
import { type InformerType } from "./types"

const StyledInformer = styled.div<{
    $type?: InformerType
}>`
    display: flex;
    width: 100%;
    padding: var(--assets-informer-sizes-padding);
    align-items: flex-start;
    gap: 10px;
    border-radius: var(--assets-informer-sizes-radius);
    border: 1px solid var(--assets-informer-colors-${(props) => props.$type}-stroke, rgba(62, 58, 237, 0.04));
    background: var(--assets-informer-colors-${(props) => props.$type}-fill, rgba(62, 58, 237, 0.04));
`

const StyledInformerContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--assets-informer-sizes-gap);
    line-height: 18px;

    font-weight: 400;
    align-items: flex-start;

    & > h3 {
        font-size: 14px;
        color: var(--assets-informer-colors-text);
        font-weight: 500;
    }
`
const StyledSvgFrame = styled.div<{
    $type: InformerType
}>`
    display: flex;
    width: 24px;
    height: 24px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--assets-informer-sizes-icon-radius, 1024px);
    color: #fff;

    background: var(--assets-informer-colors-${(props) => props.$type}-icon-bg);

    & > svg {
        min-width: 24px;
    }
`
export { StyledInformer, StyledInformerContent, StyledSvgFrame }
