import React, { type FC } from "react"
import { ProfileData } from "entities/viewer"
import { GeoSvg, useMediaSizeSvg } from "shared/assets/svg"
import { type GeographySectionProps } from "./types"

export const GeographySection: FC<GeographySectionProps> = (props) => {
    const { isAddress = false, address } = props
    const { fromMtoL } = useMediaSizeSvg()
    return (
        <ProfileData caption={isAddress ? "Адрес" : "География"}>
            <GeoSvg size={fromMtoL} />
            {address}
        </ProfileData>
    )
}
