import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type TimeSvgProps } from "./types"

export const TimeSvg: FC<TimeSvgProps> = (props) => {
    const { size = SizeSvg.XS } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path d="M7.66659 2.66659H8.33325V7.66659H11.3333V8.33325H7.66659V2.66659Z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.99992 14.6666C11.6818 14.6666 14.6666 11.6818 14.6666 7.99992C14.6666 4.31802 11.6818 1.33325 7.99992 1.33325C4.31802 1.33325 1.33325 4.31802 1.33325 7.99992C1.33325 11.6818 4.31802 14.6666 7.99992 14.6666ZM7.99992 13.9999C11.3136 13.9999 13.9999 11.3136 13.9999 7.99992C13.9999 4.68621 11.3136 1.99992 7.99992 1.99992C4.68621 1.99992 1.99992 4.68621 1.99992 7.99992C1.99992 11.3136 4.68621 13.9999 7.99992 13.9999Z"
                fill="currentColor"
            />
        </svg>
    )
}
