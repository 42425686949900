import styled from "styled-components"
import { Typography } from "shared/ui"
import { getMediaVariable } from "shared/styles"

const StyledGoalCard = styled.div`
    display: flex;
    min-width: 326px;

    padding: var(--assets-card-custom-goal-sizes-s-padding, 24px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-card-custom-goal-sizes-s-gap, 16px);

    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
    @media all and (max-width: ${getMediaVariable("laptop-l")}) {
        max-width: 326px;
    }
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        width: 422px;
    }
`

const StyledTaskCardDate = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-card-custom-goal-sizes-s-date-gap, 4px);
    color: var(--assets-card-common-colors-additional, rgba(0, 0, 0, 0.48));
`

const StyledDash = styled.div`
    width: 24px;
    & > svg {
        vertical-align: middle;
    }
`

const StyledTaskCardFooter = styled.div`
    display: flex;
    justify-content: space-between;
    align-self: stretch;
    gap: 10px;
`

const StyledProjectName = styled.div`
    color: var(--assets-card-common-colors-interactive, #3e3aed);
    font-size: 12px;
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        font-size: 14px;
    }
`

const StyledDate = styled(Typography.Basic)`
    font-size: 12px;
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        font-size: 14px;
    }
`

const StyledTitle = styled.div`
    line-height: 130%;
    font-weight: 500;
    font-size: 16px;

    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        font-size: 20px;
    }
`
export {
    StyledGoalCard,
    StyledTaskCardDate,
    StyledTaskCardFooter,
    StyledProjectName,
    StyledDate,
    StyledDash,
    StyledTitle,
}
