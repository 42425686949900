import styled from "styled-components"

const StyledFileContainer = styled.div<{
    $fullWidth: boolean | undefined
}>`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: ${({ $fullWidth }) => ($fullWidth ? "100%" : "fit-content")};
    & button:first-child {
        width: unset;
    }
    & > div > div {
        padding-right: 1px;
    }
`

export { StyledFileContainer }
