import styled, { css } from "styled-components"
import { getMediaVariable } from "shared/styles"
import { type ISelect } from "./types"

const StyledOption = styled.div`
    cursor: pointer;
    display: flex;
    align-items: start;
    gap: calc(var(--assets-header-menu-line-sizes-gap, 8px) * 2);
    color: var(--assets-header-menu-line-colors-title-basic, #292929);
`

const activeLink = css`
    color: var(--assets-header-menu-line-colors-num-active, #3e3aed);
    font-weight: 700;
`

const mixinActiveLink = css<ISelect>`
    ${StyledOption}:hover & {
        ${activeLink}
    }

    ${StyledOption}:active & {
        ${activeLink}
    }

    ${StyledOption}:focus & {
        ${activeLink}
    }

    ${({ $isSelect }) => $isSelect && activeLink}
`

const StyledMarker = styled.div<ISelect>`
    font-size: 12px;
    font-weight: 300;
    display: grid;
    position: relative;
    padding-top: 6px;
    color: var(--assets-header-menu-line-colors-num-basic);
    width: var(--assets-header-menu-line-sizes-num-width, 24px);
    flex-shrink: 0;
    ${mixinActiveLink};
    @media (min-width: ${getMediaVariable("laptop-l")}) {
        font-size: 14px;
        transform: translateY(10px);
    }
`

const StyledSelect = styled.div<ISelect>`
    font-size: 24px;
    font-weight: 400;
    padding: 0 8px 16px;
    ${mixinActiveLink};
    @media (min-width: ${getMediaVariable("laptop-l")}) {
        font-size: 24px;
        line-height: 36px;
    }
`

export { StyledOption, StyledMarker, StyledSelect }
