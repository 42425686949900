import React, { type FC } from "react"
import { type DividerSvgProps } from "./types"

export const DividerSvg: FC<DividerSvgProps> = (props) => {
    const { strokeOpacity = 1 } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="2" height="30" viewBox="0 0 2 22" fill="none">
            <path d="M1 0V35" stroke="currentColor" strokeOpacity={strokeOpacity} />
        </svg>
    )
}
