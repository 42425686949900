import styled from "styled-components"
import { RequirementStatus } from "shared/ui/Requirement"
import { WindowValidationType } from "./types"

const setStatus = (statusReq: object) => {
    const arrayStatus = Object.values(statusReq)
    const success = arrayStatus.every((value: () => RequirementStatus) => value() === RequirementStatus.SUCCESS)
    const error = arrayStatus.every((value: () => RequirementStatus) => value() === RequirementStatus.ERROR)
    if (success) {
        return WindowValidationType.SUCCESS
    }
    if (error) {
        return WindowValidationType.ERROR
    }

    return WindowValidationType.BASIC
}
const StyledWindowValid = styled.div<{ $statusReq: object }>`
    display: flex;
    //width: var(--assets-opportunity-cards-sizes-s-width, 326px);
    padding: var(--assets-input-password-reg-sizes-padding, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-input-password-reg-sizes-gap, 8px);
    border-radius: var(--assets-input-password-reg-sizes-radius, 12px);
    border: 1px solid var(--assets-input-password-reg-colors-${({ $statusReq }) => setStatus($statusReq)});
`
const StyledWindowRow = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-input-password-reg-sizes-gap);
    align-self: stretch;
    & > div {
        width: 100%;
    }
`

export { StyledWindowValid, StyledWindowRow }
