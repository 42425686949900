import { useEffect, useState } from "react"
import { type UseModalType } from "./types"

export const useModal: UseModalType = (defaultActiveState = false) => {
    const [isActive, setIsActive] = useState<boolean>(defaultActiveState)

    useEffect(() => {
        if (isActive) {
            document.body.style.overflow = "hidden"
            return () => {
                document.body.style.overflow = "unset"
            }
        }
    }, [isActive])

    const toggleActivity = () => {
        setIsActive((prev) => !prev)
    }

    const toggleActivityFixSetting = (isActiveSet?: boolean) => {
        setIsActive(isActiveSet ?? ((prev) => !prev))
    }

    return { isActive, toggleActivity, toggleActivityFixSetting }
}
