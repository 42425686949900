import styled from "styled-components"
import { getMediaVariable } from "shared/styles"
import { Row } from "shared/ui"

const StyledOpportunityList = styled.div`
    display: flex;
    gap: var(--layout-mobile-section-gap, 12px);
    align-self: stretch;
    height: 360px;
    flex-wrap: wrap;
    @media all and (min-width: ${getMediaVariable("tablet")}) {
        height: auto;
        flex-wrap: wrap;
        align-self: start;
        width: 100%;
    }
`

const StyledRowOpp = styled(Row)`
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        height: auto;
        width: 100%;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(288px, 1fr));
        justify-content: start;
        @media all and (min-width: ${getMediaVariable("desktop")}) {
            grid-template-columns: repeat(auto-fill, 371px);
        }
    }
`

export { StyledOpportunityList, StyledRowOpp }
