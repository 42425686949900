import styled, { css } from "styled-components"
import { type OpportunityType } from "entities/user"
import { StyledTypography } from "shared/ui/Typography"
import { patternSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "shared/styles"

const StyledOpportunityCard = styled.div<{
    $bg?: string
    $typeCard: OpportunityType
}>`
    display: flex;
    width: 100%;
    height: var(--assets-opportunity-cards-sizes-s-height, 360px);
    max-width: 371px;
    min-width: 288px;
    padding: var(--assets-opportunity-cards-sizes-s-padding, 8px);
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: var(--assets-opportunity-cards-sizes-s-radius, 24px);
    position: relative;

    &:hover,
    &:active {
        &::before {
            background-color: transparent;
            ${({ $bg }) =>
                $bg &&
                css`
                    opacity: 0.8;
                `}
        }
    }
    &::before {
        background-color: var(--root-colors-accent-crema, #f4efe9);
        border-radius: inherit;
        content: "";
        background-image: url(${({ $bg, $typeCard }) => $bg || `data:image/svg+xml,${patternSvgStatic}#${$typeCard}`});
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-size: ${({ $bg }) => (!$bg ? "1520px" : "100%")};
        ${({ $bg }) =>
            !$bg &&
            css`
                background-position: top 760px right 760px;
            `}
    }
`

const StyledContent = styled.div`
    display: flex;
    padding: var(--assets-opportunity-cards-sizes-s-padding-plate, 8px)
        var(--assets-opportunity-cards-sizes-s-padding-plate, 8px)
        var(--assets-opportunity-cards-sizes-s-padding-plate-bottom, 16px)
        var(--assets-opportunity-cards-sizes-s-padding-plate, 8px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--assets-opportunity-cards-sizes-s-gap-plate, 8px);
    align-self: stretch;

    border-radius: var(--assets-opportunity-cards-sizes-s-radius-plate, 16px);
    background: var(--assets-opportunity-cards-colors-plate, #fff);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    margin-top: auto;
    margin-bottom: 0px;

    & ${StyledTypography}:last-of-type {
        line-height: 120%;
    }

    height: 165px;
    @media all and (max-width: ${getMediaVariable("tablet")}) {
        height: 176px;
    }
`

const StyledTextContent = styled.div<{
    $lineClampCount: number
}>`
    line-height: 120%;

    word-break: break-word;
    color: var(--layout-text-colors-secondary, rgba(0, 0, 0, 0.72));
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ $lineClampCount }) => $lineClampCount};
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
`

const StyledStatus = styled.div`
    z-index: 1;
    display: flex;
    padding: var(--assets-opportunity-cards-sizes-s-padding-tag, 4px);
    flex-direction: column;
    align-items: flex-start;

    border-radius: var(--root-radius-round, 1024px);
    background: var(--root-colors-main-white-basic, #fff);
`

const StyledHeading = styled.div`
    font-size: 24px;
    color: black;
    font-weight: 500;
`

export { StyledOpportunityCard, StyledContent, StyledStatus, StyledHeading, StyledTextContent }
