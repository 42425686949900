import styled from "styled-components"
import { getMediaVariable } from "shared/styles"
import { Button } from "shared/ui"

export const StyledNewsList = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(288px, 371px));
    justify-content: center;
    gap: var(--layout-mobile-section-gap, 12px);
    align-self: stretch;
    flex-wrap: wrap;
    & > a > div {
        width: 100%;
    }
    @media all and (min-width: ${getMediaVariable("tablet")}) {
        width: 100%;
    }
`

export const StyledTitle = styled.div`
    align-self: start;
`

export const StyledButtonNewsList = styled(Button)`
    z-index: 2;
`
