import React, { type FC } from "react"

export const LogoSvg: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="96" height="48" viewBox="0 0 96 48" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M78.4545 41.4146L78.4532 41.4134L85.9842 33.8907C89.5408 30.3972 91.7467 25.5332 91.7467 20.154C91.7467 9.52031 83.1264 0.9 72.4927 0.9C67.2008 0.9 62.4074 3.03495 58.9271 6.49053L58.9139 6.47741L54.1388 11.2707V1.35L53.4883 0.947122L34.8848 10.2044L16.2812 0.947122L15.7625 1.03186L7.83052 8.96686L7.69878 9.285V46.89L8.14878 47.34H45.7538L46.0719 47.2083L51.2665 42.0157C54.7211 45.3129 59.403 47.34 64.5578 47.34C70.0193 47.34 74.9501 45.0664 78.4545 41.4146ZM72.4927 1.8C62.3699 1.8 54.1611 9.99498 54.1388 20.1126V20.1954C54.1611 30.313 62.3699 38.508 72.4927 38.508C77.5 38.508 82.039 36.5028 85.3505 33.2516L85.5187 33.0836L85.519 33.084C88.8122 29.7664 90.8467 25.1978 90.8467 20.154C90.8467 10.0174 82.6294 1.8 72.4927 1.8ZM72.4927 39.408C75.681 39.408 78.6882 38.6331 81.3364 37.2613L77.2597 41.3336L77.2612 41.3351C73.9627 44.4977 69.4876 46.44 64.5578 46.44C59.6514 46.44 55.1956 44.5145 51.9032 41.3792L54.0069 39.2763L53.9137 39.183L54.1388 38.958V25.9891C56.6103 33.7702 63.8931 39.408 72.4927 39.408ZM54.1388 12.5459V14.3189C54.4743 13.2626 54.8985 12.2458 55.4026 11.2773L54.1388 12.5459ZM53.2388 2.07656V20.1116L53.2387 20.154L53.2388 20.1964V38.508H16.5308V2.07656L34.6843 11.1099H35.0852L53.2388 2.07656ZM15.6308 2.4367L8.59878 9.47134V45.8036L15.6308 38.7716V2.4367ZM16.2672 39.408L9.23517 46.44H45.5674L52.6021 39.408H16.2672Z"
                fill="#292929"
            />
        </svg>
    )
}
