import { useResize } from "shared/hook"
import { ButtonSize } from "./types"

export const useMediaSizeButton = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromMtoL: width >= isTablet ? ButtonSize.L : ButtonSize.M,
        fromStoM: width >= isTablet ? ButtonSize.M : ButtonSize.S,
    }
}
