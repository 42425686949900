import { type FC } from "react"
import { Counter, CounterSize } from "shared/ui"
import { conditions } from "shared/lib"
import { StyledChoice } from "./styles"
import { type ChoiceProps, ChoiceSize, ChoiceType } from "./types"

export const Choice: FC<ChoiceProps> = (props) => {
    const {
        leftIcon,
        type = ChoiceType.BASIC,
        counter = 0,
        selected = null,
        size = ChoiceSize.L,
        children,
        id,
        ...other
    } = props

    const sizeCounter = conditions<ChoiceSize>(size, [
        [ChoiceSize.L, CounterSize.M],
        [ChoiceSize.M, CounterSize.M],
        [ChoiceSize.S, CounterSize.M],
        [ChoiceSize.XS, CounterSize.S],
    ])

    const isSelected = type === ChoiceType.BASIC ? selected === id : selected

    return (
        <StyledChoice type={"button"} $size={size} $selected={isSelected} {...other}>
            {leftIcon && leftIcon()}

            {children}

            <Counter size={sizeCounter} count={counter} />
        </StyledChoice>
    )
}
