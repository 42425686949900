import React, { type FC, useEffect } from "react"
import { useParams } from "react-router-dom"
import { ShowIdeaCard, ShowIdeaContent } from "widgets/viewer"
import { useLazyGetIdeaIdQuery } from "features/viewer"
import { WrapperColumn } from "shared/ui"
import { useResize } from "shared/hook"
import { StyledIdeaBackground } from "./styles"

export const ShowIdea: FC = () => {
    const { id } = useParams()
    const [fetch, state] = useLazyGetIdeaIdQuery()
    useEffect(() => {
        if (id) {
            fetch(id)
        }
    }, [id])
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    const media = width <= isTablet
    if (!state.isLoading && state.data) {
        return (
            <>
                <StyledIdeaBackground $type={state.data.status} />
                <WrapperColumn.Wrapper isMobile={media}>
                    <WrapperColumn.Item maxWidth={768} minWidth={350}>
                        <ShowIdeaCard {...state.data} />
                    </WrapperColumn.Item>
                    <WrapperColumn.Item maxWidth={640}>
                        <ShowIdeaContent {...state.data} />
                    </WrapperColumn.Item>
                </WrapperColumn.Wrapper>
            </>
        )
    }
}
