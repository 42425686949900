import styled from "styled-components"
import { getMediaVariable, StyledProfileDataPattern } from "shared/styles"
import { patternMainStatic } from "shared/assets/svg"

export const StyledPatternMain = styled.div<{
    $position?: string
}>`
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 0;
    height: 100%;
    background-image: url(${() => `data:image/svg+xml,${patternMainStatic}}`});
    background-repeat: no-repeat;
    background-position: ${({ $position }) => $position ?? "right -1196px bottom 0px"};
    background-image: url(${() => `data:image/svg+xml,${patternMainStatic}#opportunity`});
    background-repeat: no-repeat;
    background-position: right -488px bottom -701px;
    @media all and (max-width: ${getMediaVariable("laptop-l")}) {
        height: calc(100vh - 114px);
        background-position: right -620px bottom -751px;
    }
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        background: unset;
    }
`

export const StyledMainContainer = styled.div`
    display: grid;
    gap: 12px;
    grid-template-columns: repeat(2, minmax(250px, 586px));
    z-index: 2;
    @media (max-width: ${getMediaVariable("laptop-l")}) {
        align-items: start;
        grid-template-columns: initial;
        gap: 12px;
    }
`

export const MainColumn = styled.div`
    display: grid;
    gap: 8px;
    align-content: start;
`

export const AdditionalText = styled.div`
    font-size: 24px;
    font-weight: 500;
    @media (max-width: ${getMediaVariable("laptop-l")}) {
        font-size: 20px;
    }
`

export const MainText = styled.div`
    color: var(--root-colors-main-blue-basic, #3e3aed);
    font-size: 32px;
    font-weight: 700;
    @media (max-width: ${getMediaVariable("laptop-l")}) {
        font-size: 24px;
    }
`

export const DescriptionText = styled.div`
    line-height: 120%;
`
export const StyledScrollColumn = styled.div`
    display: grid;
    gap: 8px;
`
export const VideoContainer = styled.iframe`
    width: 100%;
    height: 100%;
    max-width: 586px;
    border-radius: 24px;
    background: lightgray 50% / cover no-repeat;
    min-width: 312px;
    min-height: 273px;
    max-height: 526px;

    @media (min-height: 700px) and (min-width: ${getMediaVariable("tablet")}) {
        aspect-ratio: 1.114;
    }

    @media (max-width: ${getMediaVariable("laptop-l")}) {
        width: 100%;
        max-width: 586px;
        height: 100%;
        border-radius: 24px;
        background-color: var(--assets-opportunity-cards-colors-fill, #f4efe9);
        background-size: contain;
        @media (min-height: 700px) {
            aspect-ratio: 0.9544;
        }
    }
`

export const ImageMain = styled.div<{
    $myUrl: string
}>`
    width: 100%;
    max-width: 586px;
    min-width: 316px;
    min-height: 278px;
    height: 100%;
    border-radius: 24px;
    background-color: var(--assets-opportunity-cards-colors-fill, #f4efe9);
    background-image: ${({ $myUrl }) => `url(${$myUrl})`};
    background-size: cover;
    @media (min-height: 700px) {
        aspect-ratio: 0.9544;
    }
`

export const CardMain = styled(StyledProfileDataPattern)`
    display: flex;
    padding: 24px;
    height: fit-content;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    align-self: stretch;
    & > * {
        max-width: 80%;
    }
    @media (max-width: ${getMediaVariable("laptop-l")}) {
        gap: 8px;
    }
`

export const CardContainerMain = styled.div`
    background: #fff;
    border-radius: 16px;
`

export const StyledRedirectPanel = styled.div`
    display: grid;
    padding: var(--assets-header-menu-personal-sizes-padding, 12px);
    align-items: center;
    gap: var(--assets-header-menu-personal-sizes-gap, 12px);
    grid-template-columns: 180px auto;
    width: 100%;
    @media (max-width: ${getMediaVariable("laptop-l")}) {
        padding: var(--assets-header-menu-personal-sizes-padding, 12px) 0;
        align-self: end;
    }
`

export const ScrollGroupContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-section-gap, 12px);
    align-self: center;
    max-height: calc(100vh - 119px);
    & > * {
        padding: 0;
    }
    max-width: 664px;
`

export const StyledRedirectProfile = styled.div`
    display: grid;
    grid-template-columns: min-content 150px 1fr;
    width: 100%;
    max-height: calc(100vh - 119px);
    max-width: 664px;

    padding: var(--assets-header-menu-personal-sizes-padding, 12px);
    justify-content: space-between;
    justify-items: start;
    align-items: center;
    gap: var(--assets-header-menu-personal-sizes-gap, 12px);

    border-radius: var(--assets-header-menu-personal-sizes-radius, 24px);
    background: #fafafa;

    & > *:nth-child(2) {
        justify-self: start;
    }
`
