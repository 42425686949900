import { object, type ObjectSchema, string } from "yup"
import { passwordValidation } from "shared/lib"

export interface FormChangePasswordI {
    newPassword?: string
    confirmPassword?: string
    currentPassword?: string
}
export const changePasswordSchema: ObjectSchema<FormChangePasswordI> = object({
    newPassword: passwordValidation,
    confirmPassword: string(),
    currentPassword: string(),
})
