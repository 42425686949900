import { BASE_URL, mainApi } from "features/api"
import { dateConverter, type RequestConfig } from "shared/api"
import { type GoalItem, type IModalGoalReportFormRequest } from "./types"

const BASE_URL_OPPORTUNITIES = `${BASE_URL.USER}/operator/tasks`

const dashboardGoalsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getGoals: builder.query<GoalItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES,
                method: "GET",
            }),
            transformResponse: (res: GoalItem[]) => {
                return res.map((el) => ({
                    ...el,
                    startTime: dateConverter(el.startTime).ddd_mmm_yyy("."),
                    endTime: dateConverter(el.endTime).ddd_mmm_yyy("."),
                }))
            },
            providesTags: ["Goals"],
        }),
        getGoal: builder.query<GoalItem, unknown>({
            query: (id: number): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + `/${id}`,
                method: "GET",
            }),
            transformResponse: (res: GoalItem) => ({
                ...res,
                startTime: dateConverter(res.startTime).ddd_mmm_yyy("."),
                endTime: dateConverter(res.endTime).ddd_mmm_yyy("."),
            }),
        }),
        acceptGoal: builder.mutation<GoalItem, unknown>({
            query: (id: number): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + `/${id}/accept`,
                method: "PATCH",
            }),
            invalidatesTags: ["Goals"],
        }),
        completeGoal: builder.mutation<GoalItem, [id: string, body: IModalGoalReportFormRequest]>({
            query: ([id, body]): RequestConfig<IModalGoalReportFormRequest, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + `/${id}/complete`,
                method: "POST",
                body,
            }),
            invalidatesTags: ["Goals"],
        }),
    }),
})

export const { useGetGoalsQuery, useGetGoalQuery, useAcceptGoalMutation, useCompleteGoalMutation } = dashboardGoalsApi
