import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const blockMixin = styled.div`
    display: flex;
    padding: var(--assets-user-profile-data-sizes-s-padding, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-gap, 4px);
    align-self: stretch;

    border-radius: var(--assets-user-profile-data-sizes-s-radius, 16px);
    background: var(--root-colors-main-white-light, rgba(0, 0, 0, 0.02));
`

const StyledModalGoal = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap, 8px);
    align-self: stretch;

    @media (min-width: ${getMediaVariable("tablet")}) {
        gap: var(--assets-user-profile-data-sizes-l-grid-gap, 12px);
    }
`

const StyledModalGoalDate = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap, 8px);
    align-self: stretch;
    @media (min-width: ${getMediaVariable("tablet")}) {
        gap: var(--assets-user-profile-data-sizes-l-grid-gap, 12px);
    }
`

export { StyledModalGoal, StyledModalGoalDate }
