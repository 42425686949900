import React, { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Button, ButtonSize, ButtonType, Row } from "shared/ui"
import { FilterSvg } from "shared/assets/svg"
import { useModal, useToggle } from "shared/hook"
import { useForMeContext } from "shared/context"
import { type FiltersBarProps, type IFiltersBarForm } from "./types"
import { StyledFiltersControl } from "./styles"
import { ModalFilters } from "./ModalFilters"
import { ForMeButton } from "./ForMeButton"
import { FiltersBarFormConfig } from "./config"

export const FiltersBar: FC<FiltersBarProps> = (props) => {
    const modal = useModal()
    const methods = useForm<IFiltersBarForm>(FiltersBarFormConfig)
    const { children, setFilters } = props
    const { isTurnOn, handlerToggle, setTurnOn } = useToggle(false, true)
    const { isOn, toggle } = useForMeContext()

    return (
        <FormProvider {...methods}>
            <StyledFiltersControl>
                <Row>
                    <Button onClick={modal.toggleActivity} typeUI={ButtonType.GHOST} size={ButtonSize.L}>
                        <FilterSvg />
                        Фильтры
                    </Button>
                    <ForMeButton toogle={toggle} isOn={isOn} />
                </Row>
            </StyledFiltersControl>
            <ModalFilters
                handlerOnlyRegReset={() => {
                    if (setTurnOn) {
                        setTurnOn(false)
                    }
                }}
                isToggleForMe={isOn}
                toggler={{ isTurnOn, handlerToggle }}
                setFilters={setFilters}
                {...modal}
            >
                {children}
                <ForMeButton toogle={toggle} isOn={isOn} />
            </ModalFilters>
        </FormProvider>
    )
}
