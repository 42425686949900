import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledProfileSection = styled.div`
    // display: flex;
    // flex-direction: column;
    // width: 100%;
    // gap: var(--assets-user-profile-data-sizes-s-grid-gap);
    //
    // @media all and (min-width: ${getMediaVariable("mobile-l")}) {
    //     flex-direction: row-reverse;
    // }
    width: 100%;
    #change-top {
        display: none;
    }
    #change-bottom {
        display: inline-flex;
    }
    @media all and (min-width: ${getMediaVariable("tablet")}) {
        #change-top {
            display: inline-flex;
        }
        #change-bottom {
            display: none;
        }
    }
`

export { StyledProfileSection }
