import React, { type FC } from "react"
import { ProfileData, ProfileDataType } from "entities/viewer"
import { PhoneSvg, useMediaSizeSvg } from "shared/assets/svg"

interface PhoneSectionProps {
    phone?: string | null
}

export const PhoneSection: FC<PhoneSectionProps> = ({ phone }) => {
    const { fromMtoL } = useMediaSizeSvg()
    if (!phone) {
        return null
    }
    return (
        <ProfileData type={ProfileDataType.LINK} caption={"Телефон"}>
            <PhoneSvg size={fromMtoL} />
            {phone}
        </ProfileData>
    )
}
