import * as process from "process"
import { createApi } from "@reduxjs/toolkit/dist/query/react"
import { axiosBaseQuery } from "shared/api"

export const MAIN_API_REDUCER_KEY = "mainApi"

export const mainApi = createApi({
    reducerPath: MAIN_API_REDUCER_KEY,
    baseQuery: axiosBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL as string,
    }),
    tagTypes: [
        "Profile",
        "Organization",
        "Ideas",
        "DashboardOpportunities",
        "Goals",
        "DashboardPlaces",
        "Notifications",
        "Rubricator",
    ],
    endpoints: () => ({}),
})
