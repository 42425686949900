import { type FC } from "react"
import { type BasicProps, BasicType, type HeadingProps, HeadingType } from "./types"
import { StyledBasic, StyledHeading } from "./styles"

export const Heading: FC<HeadingProps> = (props) => {
    const { typeUI = HeadingType.H2, children, ...rest } = props

    return (
        <StyledHeading {...rest} $type={typeUI}>
            {children}
        </StyledHeading>
    )
}

export const Basic: FC<BasicProps> = (props) => {
    const { typeUI = BasicType.SECOND, children, basicTypeSize, ...rest } = props
    return (
        <StyledBasic {...rest} $basicTypeSize={basicTypeSize} $type={typeUI}>
            {children}
        </StyledBasic>
    )
}

export const Typography = {
    Basic,
    Heading,
}
