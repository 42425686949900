import React, { type FC } from "react"
import { useFormContext } from "react-hook-form"
import { useLocation, useNavigate } from "react-router-dom"
import Qs from "qs"
import { Button, ButtonType, Row, useMediaSizeButton } from "shared/ui"
import { type FooterButtonsControlProps } from "./types"

export const FooterButtonsControl: FC<FooterButtonsControlProps> = (props) => {
    const { customHandleSubmit, placeCats, opCats, setNeedUpdate, nameCats } = props
    const { fromMtoL } = useMediaSizeButton()
    const { reset } = useFormContext()
    const location = useLocation()
    const navigate = useNavigate()
    return (
        <Row>
            <Button
                size={fromMtoL}
                onClick={() => {
                    reset()
                    setNeedUpdate(true)
                    setTimeout(() => {
                        setNeedUpdate(false)
                    })
                }}
                typeUI={ButtonType.GHOST_RED}
            >
                Сбросить
            </Button>
            <Button
                onClick={() => {
                    customHandleSubmit()
                    navigate({
                        pathname: `${location.pathname}`,
                        search: Qs.stringify(
                            { placeCats: placeCats ?? "", opCats: opCats ?? "", nameCats: nameCats ?? "" },
                            { arrayFormat: "comma" }
                        ),
                    })
                }}
                size={fromMtoL}
            >
                Применить
            </Button>
        </Row>
    )
}
