import { styled } from "styled-components"
import { StyledInput, StyledInputWrapper } from "../../styles"

export const StyledInputParent = styled.div`
    display: flex;
    width: var(--assets-input-sizes-height, 48px);
    flex-direction: column;
    align-items: flex-start;
    border-radius: var(--assets-input-sizes-radius, 12px);
    border: 1px solid var(--assets-input-colors-stroke-basic, rgba(0, 0, 0, 0.36));
`

export const StyledInputCodeWrapper = styled(StyledInputWrapper)`
    height: var(--assets-input-sizes-height, 48px);
`

export const StyledInputCode = styled(StyledInput)`
    font-weight: 400;
    line-height: 18px;
    text-align: center;
`

export const StyledRowInputCode = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-self: stretch;
`

export const StyledFrameInputCode = styled.div`
    display: flex;
    padding: var(--assets-input-code-sizes-padding, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-input-code-sizes-gap, 24px);
    align-self: stretch;

    border-radius: var(--assets-input-code-sizes-radius, 24px);
    border: 1px solid var(--assets-input-code-colors-stroke, rgba(0, 0, 0, 0.06));
`

export const StyledRepeatText = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-link-gap, 8px);
    align-self: stretch;

    color: var(--layout-text-colors-link, #3e3aed);

    font-weight: 400;
    line-height: 18px; /* 128.571% */
`
