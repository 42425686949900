import styled from "styled-components"
import { type ContentDirection, type GroupDirection, type GroupSize } from "./types"

const StyledGroup = styled.div<{
    $size: GroupSize
    $direction: GroupDirection
    $contentDirection: ContentDirection
    $wrap: boolean
}>`
    display: flex;
    justify-content: ${({ $contentDirection }) => $contentDirection};
    width: 100%;
    flex-wrap: ${({ $wrap }) => $wrap && "wrap"};
    align-items: start;
    flex-direction: ${({ $direction }) => $direction};
    gap: var(--assets-user-profile-data-sizes-${({ $size }) => $size}-grid-gap);
    align-self: stretch;
`

export { StyledGroup }
