import { type NewItem, type NextPreviousNew } from "features/user"

type keyType = "next" | "previous"
export const getDate = (key: keyType, data?: NewItem) => {
    if (data && key in data) {
        if (data[key] && "updatedAt" in (data[key] as NextPreviousNew)) {
            const formattedDate = new Date(data[key]?.updatedAt ?? "").toLocaleDateString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
            })
            const splitDate = formattedDate.split(" ")
            return {
                time: splitDate[1],
                date: splitDate[0].slice(0, -1),
            }
        }
        if (data[key] && "createdAt" in (data[key] as NextPreviousNew)) {
            const formattedDate = new Date(data[key]?.updatedAt ?? "").toLocaleDateString("ru-RU", {
                hour: "2-digit",
                minute: "2-digit",
            })
            const splitDate = formattedDate.split(" ")
            return {
                time: splitDate[1],
                date: splitDate[0].slice(0, -1),
            }
        }
    }
}
