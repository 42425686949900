import styled from "styled-components"

const StylesTitleIdeaContentCard = styled.div`
    color: var(--layout-text-colors-additional, rgba(0, 0, 0, 0.48));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 14px; /* 100% */
`
const StylesIdeaContentText = styled.div`
    color: var(--layout-text-colors-primary, #000);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
`
export { StylesTitleIdeaContentCard, StylesIdeaContentText }
