import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type CrownSvgProps } from "./types"

export const CrownSvg: FC<CrownSvgProps> = (props) => {
    const { size = SizeSvg.L } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill="none">
            <path
                d="M7.68085 24.456L2.66667 10.667L7.24482 13.8717C8.41316 14.6895 10.0182 14.4434 10.8877 13.313L16 6.66699L21.1123 13.313C21.9818 14.4434 23.5869 14.6895 24.7552 13.8717L29.3333 10.667L24.3192 24.456C24.1276 24.9829 23.6268 25.3337 23.0661 25.3337H8.93391C8.37323 25.3337 7.87246 24.9829 7.68085 24.456Z"
                fill="currentColor"
            />
        </svg>
    )
}
