import React, { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useOutletContext } from "react-router-dom"
import { type IAuthDataByEmail, type IAuthDataByPhone } from "features/user"
import { ChoiceInputAuth, useChoiceInputAuth } from "entities/user"
import { BasicType, FormLayout, StyledError, Typography } from "shared/ui"
import { AuthComeInButton, AuthRedirectButton, PasswordField } from "./ui"
import { StyledInputs, StyledMainContent } from "./styles"
import { AuthFormConfig, schemaAuthForm } from "./config"
import { AuthVkButton } from "./ui/AuthVkButton/AuthVkButton"

export const AuthForm: FC = () => {
    const { choice, setChoiceHandler } = useChoiceInputAuth()
    const methods = useForm<IAuthDataByEmail | IAuthDataByPhone>({
        ...AuthFormConfig,
        resolver: yupResolver(schemaAuthForm(choice)),
    })
    const { updateToken } = useOutletContext<{ updateToken: () => void; wasLogin: boolean }>()
    const errorMessage =
        methods.formState.errors.root?.message !== undefined ? [methods.formState.errors.root.message] : []

    return (
        <FormProvider {...methods}>
            <FormLayout
                headerText={"Вход"}
                main={() => (
                    <StyledMainContent>
                        <StyledInputs>
                            <ChoiceInputAuth choice={choice} setChoiceHandler={setChoiceHandler} />
                            <PasswordField />
                        </StyledInputs>
                        <StyledError>{errorMessage[0]}</StyledError>
                        <AuthComeInButton updateToken={updateToken} choice={choice} />
                    </StyledMainContent>
                )}
                footer={() => (
                    <>
                        <Typography.Basic typeUI={BasicType.ADDITIONAL}>Ещe нет аккаунта?</Typography.Basic>
                        <AuthRedirectButton />
                        <AuthVkButton updateToken={updateToken} />
                    </>
                )}
            />
        </FormProvider>
    )
}
