import styled from "styled-components"
import { type GiftImageSize } from "./types"

const StyledGiftImageContainer = styled.div<{
    $size: GiftImageSize
}>`
    display: flex;
    width: ${({ $size }) => `var(--assets-card-custom-gift-${$size}-pic-size)`};
    height: ${({ $size }) => `var(--assets-card-custom-gift-${$size}-pic-size)`};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    border-radius: 16px;
    border: 1px solid var(--root-colors-main-white-dark, rgba(0, 0, 0, 0.06));
    background: #fff;
`

const StyledGiftImage = styled.img`
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
`

export { StyledGiftImageContainer, StyledGiftImage }
