import styled, { css } from "styled-components"

const flexMixin = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
`

const StyledOrganizationCard = styled.div`
    ${flexMixin};
    width: 100%;
    padding: var(--assets-card-custom-organization-sizes-s-padding);
    gap: var(--assets-card-custom-organization-sizes-s-gap);
    background: var(--assets-card-common-colors-fill, rgba(0, 0, 0, 0.02));
    border-radius: var(--assets-card-common-sizes-s-radius);

    &:hover {
        background: unset;
        outline: 1px solid var(--assets-card-common-colors-stroke-hover);
    }
`

const StyledOrganizationCardMain = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-card-custom-organization-sizes-s-gap, 12px);
    align-self: stretch;
`
const StyledOrganizationCardContent = styled.div`
    ${flexMixin};
    gap: var(--assets-card-common-sizes-s-title-gap, 4px);
    flex: 1 0 0;
`
const StyledOrganizationCardInn = styled.div`
    color: var(--assets-card-common-colors-interactive, #3e3aed);
    font-size: 14px;
    line-height: 18px;
`
const StyledOrganizationCardImg = styled.div`
    ${flexMixin};
    width: var(--assets-card-custom-organization-sizes-s-pic, 48px);
    height: var(--assets-card-custom-organization-sizes-s-pic, 48px);
    align-items: center;
    border-radius: var(--assets-card-custom-organization-sizes-s-pic-radius, 12px);
    background: var(--assets-card-custom-organization-colors-pic, #000);
`

export {
    StyledOrganizationCard,
    StyledOrganizationCardMain,
    StyledOrganizationCardContent,
    StyledOrganizationCardInn,
    StyledOrganizationCardImg,
}
