import React, { type ChangeEvent, type FC, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { Button, ButtonSize, ButtonType, FormField } from "shared/ui"
import { StyledInput } from "shared/styles"
import { DateSvg, SizeSvg } from "shared/assets/svg"
import { type InputDateProps } from "./types"

export const InputDate: FC<InputDateProps> = (props) => {
    const { labelText, name, required = true, ...other } = props
    const { register, setValue, getValues } = useFormContext()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const { ref, onChange } = register(name)
    const handleLabelClick = () => {
        inputRef?.current?.showPicker()
    }
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
    }

    return (
        <FormField
            name={name}
            required={required}
            labelText={labelText}
            render={(focus) => (
                <>
                    <StyledInput
                        {...other}
                        ref={(event) => {
                            inputRef.current = event
                            ref(event)
                        }}
                        onChange={handleChange}
                        name={name}
                        type={"date"}
                        onFocus={focus}
                        onBlur={focus}
                    />
                    <Button
                        onClick={handleLabelClick}
                        size={ButtonSize.S}
                        hover={false}
                        onlyIcon={true}
                        typeUI={ButtonType.FREE}
                    >
                        <DateSvg size={SizeSvg.M} />
                    </Button>
                </>
            )}
        />
    )
}
