import { useResize } from "shared/hook"
import { GroupDirection, GroupSize } from "./types"

export const useMediaGroup = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        size: {
            sizeLtoS: width >= isTablet ? GroupSize.L : GroupSize.S,
        },
        direction: {
            RowReversToColumn: width >= isTablet ? GroupDirection.ROW_REVERSE : GroupDirection.COLUMN,
            RowToColumn: width >= isTablet ? GroupDirection.ROW : GroupDirection.COLUMN,
        },
    }
}
