import React, { type FC } from "react"
import { StyledWrapper } from "./styles"
import { type WrapperProps } from "./types"

export const Wrapper: FC<WrapperProps> = (props) => {
    const { minWidth, maxWidth, width, children, ...other } = props
    return (
        <StyledWrapper $width={width} $minWidth={minWidth} $maxWidth={maxWidth} {...other}>
            {children}
        </StyledWrapper>
    )
}
