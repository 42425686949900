import React, { type FC } from "react"
import { Button, ButtonSize, ButtonType, ContentDirection, FileType, Group, GroupDirection, GroupSize } from "shared/ui"
import { Vector, VectorType } from "shared/ui/Vector"
import { FileUpload } from "shared/assets/svg"
import { StyledFileContainer } from "./styles"
import { type FileContainerProps } from "./types"

export const FileContainer: FC<FileContainerProps> = (props) => {
    const { children, fullWidth, containerType = FileType.PICTURE, handleChange, handleDelete, ...other } = props
    const buttonChange = containerType === FileType.PICTURE_LIST || containerType === FileType.DOCUMENT_LIST
    return (
        <StyledFileContainer $fullWidth={fullWidth} {...other}>
            <Group size={GroupSize.L} direction={GroupDirection.COLUMN}>
                {children}
                <Group size={GroupSize.S} contentDirection={ContentDirection.LEFT} direction={GroupDirection.ROW}>
                    <Button
                        onClick={handleDelete}
                        size={ButtonSize.XS}
                        typeUI={ButtonType.GHOST_RED}
                        leftIcon={() => <Vector type={VectorType.CROSS} />}
                    >
                        Удалить
                    </Button>

                    {!buttonChange && (
                        <Button
                            onClick={handleChange}
                            size={ButtonSize.XS}
                            typeUI={ButtonType.SECONDARY}
                            leftIcon={() => <FileUpload />}
                        >
                            Заменить
                        </Button>
                    )}
                </Group>
            </Group>
        </StyledFileContainer>
    )
}
