import React, { type FC } from "react"
import { useParams } from "react-router-dom"
import { useGetRubricatorPlaceQuery } from "features/user"
import { useGetDashboardPlaceQuery } from "features/viewer"
import { OpportunitySection } from "entities/user"
import { TextSection } from "shared/ui"
import { useModal } from "shared/hook"
import {
    PlaceAbout,
    PlaceContacts,
    PlaceContainer,
    PlaceDescription,
    PlaceFooterButton,
    PlaceHeader,
    PlaceSchedule,
    PlaceModal,
} from "./ui"

export const Place: FC = () => {
    const modal = useModal()
    const { operator, id } = useParams()
    const { currentData: data } = useGetRubricatorPlaceQuery(id!, {
        skip: !id || operator === "true",
    })
    const { currentData: dataPrivate } = useGetDashboardPlaceQuery(id, { skip: !id || operator === "false" })
    if (!dataPrivate && !data) {
        return null
    }
    return (
        <>
            <PlaceContainer
                header={() => <PlaceHeader name={(dataPrivate || data)!.name} />}
                about={() => (
                    <PlaceAbout
                        coords={(dataPrivate || data)?.coords ?? ""}
                        categories={(dataPrivate || data)?.categories ?? []}
                        address={(dataPrivate || data)!.address}
                    />
                )}
                footerButton={() => <PlaceFooterButton onClickHandler={modal.toggleActivity} />}
                photo={() => <OpportunitySection.PhotoSection images={(dataPrivate || data)?.images ?? []} />}
                aboutText={() => <TextSection title={"О месте"}>{(dataPrivate || data)?.description}</TextSection>}
                description={() => (
                    <PlaceDescription
                        userCategories={(dataPrivate || data)?.userCategories ?? []}
                        accessible={(dataPrivate || data)?.accessible}
                        lowMobilityFriendly={(dataPrivate || data)?.lowMobilityFriendly}
                    />
                )}
                schedule={() => <PlaceSchedule workingHours={(dataPrivate || data)?.workingHours} />}
                contacts={() => (
                    <PlaceContacts
                        phone={(dataPrivate || data)?.phone}
                        email={(dataPrivate || data)?.email}
                        vkSocialUrl={(dataPrivate || data)?.vkSocialUrl}
                        websiteUrl={(dataPrivate || data)?.websiteUrl}
                    />
                )}
            />
            <PlaceModal
                coords={(dataPrivate || data)?.coords ?? ""}
                workingHours={(dataPrivate || data)?.workingHours}
                modal={modal}
                address={(dataPrivate || data)!.address}
            />
        </>
    )
}
