import styled, { css } from "styled-components"

const flexMixin = () => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
`
const StyledIdeaCard = styled.div<{
    $bg: any
}>`
    ${flexMixin()}
    position: relative;
    width: 100%;
    padding: var(--assets-card-custom-idea-sizes-s-padding);
    gap: var(--assets-card-custom-idea-sizes-s-gap);
    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
    background-color: var(--assets-card-common-colors-fill, rgba(0, 0, 0, 0.02));
    background-image: url(${({ $bg }) => $bg});
    background-size: 144px 190px;
    background-position: right bottom;
    background-repeat: no-repeat;
    &:hover {
        background-color: unset;
        outline: 1px solid var(--assets-card-common-colors-stroke-hover, #3e3aed);
    }

    & > svg {
        width: 144px;
        height: 190px;
        opacity: 0.4;
    }
`

const StyledIdeaCardContent = styled.div`
    ${flexMixin()}
    gap: var(--assets-card-common-sizes-s-title-gap, 4px);
    align-self: stretch;
`

const StyledIdeaCardChildren = styled.div`
    color: var(--assets-card-common-colors-interactive, #3e3aed);
    font-size: 14px;
`

const StyledIdeaCardBottom = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-card-custom-idea-sizes-s-data-gap);
    align-self: stretch;

    & > div:last-child {
        justify-content: flex-end;
    }
`

const StyledIdeaCardData = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-user-profile-idea-sizes-s-content-gap);
    flex: 1 0 0;
    color: var(--assets-card-common-colors-additional, rgba(0, 0, 0, 0.48));
    font-size: 14px;
    font-style: normal;
    line-height: 130%;
`

export { StyledIdeaCard, StyledIdeaCardContent, StyledIdeaCardChildren, StyledIdeaCardBottom, StyledIdeaCardData }
