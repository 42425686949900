import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledProfileSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-gap, 24px);
    align-self: stretch;
    & a {
        width: 100%;
    }
    @media all and (min-width: ${getMediaVariable("mobile-l")}) {
        & > div:first-child > a,
        & > div:first-child > a > button {
            width: 100%;
        }
    }
`
export { StyledProfileSection }
