import { days } from "../useScheduleReducer"
import { type Times } from "../../models"

export const getDaysString = (i: number, numbersDay: (string | number)[][], submitFormData: Times[]) => {
    const dayName = (i: number) => numbersDay.flatMap((el) => (el.includes(i) ? el[1] : []))
    const arrayFromDays = Array.from({ length: submitFormData.length }, (_, i) => [...dayName(i)])
    const ordinalDays = Object.values(days)
    return arrayFromDays[i].reduce((acc, el, i, array) => {
        if (
            ordinalDays.indexOf(array[i + 1] as days) - ordinalDays.indexOf(el as days) === 1 &&
            array[i - 1] &&
            ordinalDays.indexOf(el as days) - ordinalDays.indexOf(array[i - 1] as days) === 1
        ) {
            return acc
        }
        if (ordinalDays.indexOf(array[i + 1] as days) - ordinalDays.indexOf(el as days) === 1 && array[i + 1]) {
            acc += `${el}-`
            return acc
        }
        if (ordinalDays.indexOf(el as days) - ordinalDays.indexOf(array[i - 1] as days) === 1) {
            acc += `${el}`
            if (i !== array.length - 1) {
                acc += ", "
            }
            return acc
        }
        acc += `${el}`
        if (i !== array.length - 1) {
            acc += ", "
        }
        return acc
    }, "" as string)
}
