import { useResize } from "shared/hook"
import { RatingPlateSize } from "./types"

export const useMediaSizeRatingPlate = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromStoL: width >= isTablet ? RatingPlateSize.L : RatingPlateSize.S,
    }
}
