import React, { type ChangeEvent, type FC, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import {
    Button,
    ButtonSize,
    ButtonType,
    DocumentCard,
    Group,
    GroupSize,
    Modal,
    ModalDefault,
    ScrollGroup,
} from "shared/ui"
import { FileUpload } from "shared/assets/svg"
import { useModal } from "shared/hook"
import { FileContainer, ImagesCard, UploadFile } from "./ui"
import {
    StyledDescriptionSection,
    StyledFileLoad,
    StyledFileLoadDescription,
    StyledFileLoadDropZone,
    StyledFileLoadFiles,
    StyledFileLoadMain,
    StyledFileLoadTitle,
    StyledFileUnUpload,
} from "./styles"

import { FileAccept, type FileLoadProps, FileLoadSize, FileType } from "./types"
import { useFileLoadCondition, useFileSet } from "./lib"

export const FileLoad: FC<FileLoadProps> = (props) => {
    const {
        name,
        title,
        uploadFile,
        maxFiles = 5,
        size = FileLoadSize.S,
        FLType = FileType.PICTURE,
        example = "",
        accept = FileAccept.PICTURE,
        ...other
    } = props
    const { register, setValue, getValues, getFieldState, formState } = useFormContext()
    const { error } = getFieldState(name, formState)
    const { files, filesInfo, setEvent, handleDelete, dragAndDrop } = useFileSet(FLType, maxFiles)
    const condition = useFileLoadCondition(FLType, files, size, example)
    const fileInputRef = useRef<HTMLInputElement | null>(null)
    const ContainerScroll = useRef<HTMLDivElement | null>(null)
    const [typeDelete, setTypeDelete] = useState<"delete" | "replace">("delete")
    const modal = useModal()
    const { ref, onChange } = register(name)
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
        if (uploadFile) {
            uploadFile.requestDelete()
        }
        if (event.target.files) {
            setEvent(event.target.files)
        }
    }
    const refContainer = useRef<HTMLDivElement | null>(null)

    const handleClickChange = () => {
        if (uploadFile) {
            uploadFile?.requestDelete()
        }
        fileInputRef.current?.click()
    }
    const handleDeleteFromModal = (btn: "replace" | "delete") => {
        if (btn === "delete") {
            uploadFile?.requestDelete()
            return
        }
        if (btn === "replace") {
            uploadFile?.requestDelete()
            fileInputRef.current?.click()
        }
    }
    useEffect(() => {
        if (files.length <= maxFiles && !error) {
            setValue(name, files)
        }
        if (files.length) {
            refContainer?.current?.scrollIntoView()
        }
    }, [files, filesInfo])
    return (
        <StyledFileLoad ref={refContainer} onDragEnter={(event) => dragAndDrop.handleDrag(event)}>
            <StyledFileLoadTitle $size={size}>{title}</StyledFileLoadTitle>
            <Group size={GroupSize.L} direction={condition.FileLoadDirection}>
                {Boolean(filesInfo.length || uploadFile) && (
                    <StyledFileLoadFiles>
                        <ScrollGroup>
                            <Group
                                size={GroupSize.L}
                                direction={condition.containerDirection}
                                wrap={size === FileLoadSize.L && FLType === FileType.PICTURE_LIST}
                            >
                                {filesInfo.map((item, index) => (
                                    <FileContainer
                                        key={index}
                                        fullWidth={FLType !== FileType.PICTURE_LIST}
                                        containerType={FLType}
                                        handleChange={handleClickChange}
                                        handleDelete={() => handleDelete(index)}
                                    >
                                        {item.type === FileType.DOCUMENT && (
                                            <DocumentCard size={item.size} name={item.name} />
                                        )}
                                        {item.type === FileType.PICTURE && <ImagesCard key={index} img={item.name} />}
                                    </FileContainer>
                                ))}
                                {uploadFile && (
                                    <FileContainer
                                        containerType={FLType}
                                        handleDelete={() => {
                                            if (uploadFile?.modal) {
                                                setTypeDelete("delete")
                                                modal.toggleActivity()
                                            }
                                            if (!uploadFile?.modal) {
                                                uploadFile.requestDelete()
                                            }
                                        }}
                                        handleChange={() => {
                                            if (uploadFile?.modal) {
                                                setTypeDelete("replace")
                                                modal.toggleActivity()
                                            }
                                            if (!uploadFile?.modal) {
                                                handleClickChange()
                                            }
                                        }}
                                    >
                                        <UploadFile
                                            mimetype={uploadFile.mimetype}
                                            accept={accept}
                                            url={uploadFile?.url}
                                            type={FLType}
                                            name={uploadFile?.name}
                                        />
                                    </FileContainer>
                                )}
                            </Group>
                        </ScrollGroup>
                    </StyledFileLoadFiles>
                )}
                <input
                    {...other}
                    type="file"
                    hidden={true}
                    multiple={condition.multiple}
                    onChange={handleChange}
                    accept={accept}
                    name={name}
                    ref={(event) => {
                        fileInputRef.current = event
                        ref(event)
                    }}
                />
                {!uploadFile && (
                    <StyledFileLoadMain $visible={condition.buttonLoad}>
                        <StyledFileUnUpload>
                            <Button size={ButtonSize.S} onClick={handleClickChange} leftIcon={() => <FileUpload />}>
                                Загрузить
                            </Button>
                            <StyledFileLoadDescription>
                                <StyledDescriptionSection>
                                    Макс. размер: <br />
                                    <strong>5 Мб</strong>
                                </StyledDescriptionSection>
                                <StyledDescriptionSection>
                                    Допускаются форматы: <br />
                                    <strong> {accept} </strong>
                                </StyledDescriptionSection>
                            </StyledFileLoadDescription>
                        </StyledFileUnUpload>
                    </StyledFileLoadMain>
                )}

                {example && !files.length && !uploadFile && (
                    <DocumentCard size={300000} download={true} href={example} name={"Образец доверености"} />
                )}
            </Group>
            <StyledFileLoadDropZone
                $active={dragAndDrop.dragActive}
                onDrop={dragAndDrop.handleDrop}
                onDragEnter={dragAndDrop.handleDrag}
                onDragLeave={dragAndDrop.handleDrag}
                onDragOver={dragAndDrop.handleDrag}
            >
                <div>Перетащите файл сюда</div>
            </StyledFileLoadDropZone>
            {uploadFile?.modal && (
                <Modal {...modal}>
                    <ModalDefault
                        title={uploadFile.modalTitle}
                        back={modal.toggleActivity}
                        footerBtn={() => (
                            <Button
                                onClick={() => {
                                    handleDeleteFromModal(typeDelete)
                                    modal.toggleActivity()
                                }}
                                typeUI={ButtonType.PRIMARY}
                                size={ButtonSize.M}
                            >
                                Удалить
                            </Button>
                        )}
                    >
                        {uploadFile?.textModalDelete && uploadFile?.textModalDelete}
                        {uploadFile?.textModalReplace && uploadFile?.textModalReplace}
                    </ModalDefault>
                </Modal>
            )}
        </StyledFileLoad>
    )
}
