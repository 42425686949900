import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledData = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap, 8px);
    align-self: stretch;
`

export const StyledRatingList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    width: 100%;

    @media (max-width: ${getMediaVariable("laptop")}) {
        margin-top: var(--layout-mobile-gap, 24px);
    }
`
