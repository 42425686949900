import styled from "styled-components"

const StyledMenuContainer = styled.div`
    gap: var(--assets-header-menu-line-sizes-gap);
    display: block;
`

const StyledMenu = styled.div`
    display: grid;
    width: 100%;
    grid-template-rows: repeat(45px);
`

export { StyledMenuContainer, StyledMenu }
