import { CounterColor } from "shared/ui"

export const getCounterColor = (isAnotherMonth = false, isWeekend = false) => {
    switch (true) {
        case isAnotherMonth: {
            return isWeekend ? CounterColor.ANOTHER_MONTH_WEEKEND : CounterColor.ANOTHER_MONTH
        }
        default: {
            return isWeekend ? CounterColor.WEEKEND : CounterColor.EVENTS
        }
    }
}
