import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { type ForgotPasswordData } from "./types"

export const forgotPassword = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        postForgotPassword: builder.query<unknown, ForgotPasswordData>({
            query: (body): RequestConfig<ForgotPasswordData, unknown> => ({
                url: `${BASE_URL.USER}/auth/reset-password`,
                method: "POST",
                body,
            }),
        }),
    }),
})

export const { useLazyPostForgotPasswordQuery } = forgotPassword
