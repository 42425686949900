import { useEffect, type FC } from "react"
import { useNavigate } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { CAPTCHA_INPUT_NAME, YandexCaptchaInput, useYandexCaptcha } from "widgets/user/Forms/YandexCaptchaInput"
import { useLazyGetRegisterByEmailQuery, useLazyGetRegisterByPhoneQuery } from "features/user"
import { ChoiceInputAuthType } from "entities/user"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { SectionGapSize, StyledSection } from "shared/styles"

interface RegistrationSubmitButtonProps {
    choice?: ChoiceInputAuthType
    toggleActivityModal: () => void
}

export const BtnControl: FC<RegistrationSubmitButtonProps> = ({
    choice = ChoiceInputAuthType.EMAIL,
    toggleActivityModal,
}) => {
    const navigate = useNavigate()
    const { fromStoM, fromMtoL } = useMediaSizeButton()
    const {
        handleSubmit,
        formState: { isValid },
        setError,
    } = useFormContext()
    const [fetchRegistrationByEmail, stateRequestEmail] = useLazyGetRegisterByEmailQuery()
    const [fetchRegistrationByPhone, stateRequestPhone] = useLazyGetRegisterByPhoneQuery()
    useEffect(() => {
        const error = stateRequestEmail.error ?? stateRequestPhone.error
        const isError = stateRequestEmail.isError ?? stateRequestPhone.isError
        if (error && isError) {
            if (typeof error === "object" && error && "status" in error && error.status === 409) {
                choice === ChoiceInputAuthType.EMAIL
                    ? setError("email", { type: `${error.status}`, message: "Такой email уже зарегистрирован" })
                    : setError("number", {
                          type: `${error.status}`,
                          message: "Такой номер телефона уже зарегистрирован",
                      })
            }
        }
    }, [stateRequestEmail?.error, stateRequestPhone?.error, stateRequestEmail?.isError, stateRequestPhone?.isError])

    const { captchaVisible, setCaptchaVisible, onCaptchaSuccess, validateCaptcha } = useYandexCaptcha()

    const submitForm = handleSubmit(async ({ firstname, lastname, midname, password, locality, birthday, ...rest }) => {
        const requestBody = {
            firstname,
            lastname,
            midname,
            password,
            locality,
            birthday: new Date(birthday).toISOString(),
            municipality: "MUNICIPAL_AREA",
            [CAPTCHA_INPUT_NAME]: rest[CAPTCHA_INPUT_NAME],
        }

        choice === ChoiceInputAuthType.EMAIL
            ? fetchRegistrationByEmail({
                  ...requestBody,
                  email: rest.email,
              })
            : fetchRegistrationByPhone({
                  ...requestBody,
                  phone: `+7 ${rest.phone}`,
              })
        toggleActivityModal()
    })

    const handleSubmitForm = () => validateCaptcha(submitForm)

    return (
        <StyledSection $gapSize={SectionGapSize.S}>
            <YandexCaptchaInput visible={captchaVisible} setVisible={setCaptchaVisible} onSuccess={onCaptchaSuccess} />
            <Button size={fromMtoL} onClick={handleSubmitForm} typeUI={ButtonType.PRIMARY}>
                Зарегистрироваться
            </Button>
            <Button
                size={fromStoM}
                typeUI={ButtonType.GHOST}
                onClick={() => {
                    navigate("/auth")
                }}
            >
                Уже зарегистрирован
            </Button>
        </StyledSection>
    )
}
