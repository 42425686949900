import { type FC } from "react"
import { NavLink } from "react-router-dom"
import { BasicType, HeadingType, Typography } from "shared/ui"
import { StyledContent, StyledDate, StyledDescription, StyledNewsCard } from "./styles"
import { type NewsCardProps } from "./types"

export const NewsCard: FC<NewsCardProps> = (props) => {
    const { imgSrc, time, date, title, body, id } = props
    return (
        <NavLink to={`/news/${id}`}>
            <StyledNewsCard $bg={imgSrc}>
                <StyledContent>
                    <StyledDate>
                        <div>
                            <Typography.Basic typeUI={BasicType.ADDITIONAL}>{date}</Typography.Basic>
                        </div>
                        <div>
                            <Typography.Basic typeUI={BasicType.ADDITIONAL}>{time}</Typography.Basic>
                        </div>
                    </StyledDate>
                    <div>
                        <Typography.Heading typeUI={HeadingType.H3}>{title}</Typography.Heading>
                    </div>
                    <StyledDescription $lineClampCount={title.length > 26 ? 3 : 4}>{body}</StyledDescription>
                </StyledContent>
            </StyledNewsCard>
        </NavLink>
    )
}
