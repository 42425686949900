import React, { type FC } from "react"
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { textModalUpload } from "widgets/viewer/Forms/ui/EditOrganization/lib/lib"
import {
    type IEditOrganization,
    useChangePoaOrganizationMutation,
    useCreateOrganizationMutation,
    useDeleteOrgDocumentMutation,
    useGetOrgUserQuery,
} from "features/viewer"
import {
    Button,
    ButtonSize,
    ButtonType,
    CardStatusType,
    FileAccept,
    FileLoad,
    FileType,
    FormLayout,
    Informer,
    InformerType,
    Input,
} from "shared/ui"
import { StyledInputSection } from "shared/styles"
import { formDataHandler } from "shared/api"
import { OrgStatusInformer } from "./ui"
import { EditOrganizationNames } from "./types"
import { EditOrganizationConfig } from "./lib/EditOrganizationConfig"

export const EditOrganization: FC = (props) => {
    const { data: OrgUser, isLoading } = useGetOrgUserQuery("")
    const [fetchCreateOrg, stateCreateOrg] = useCreateOrganizationMutation()
    const [fetchEditOrg, stateChangeOrg] = useChangePoaOrganizationMutation()
    const [fetchDeleteDocument] = useDeleteOrgDocumentMutation()
    const methods = useForm<IEditOrganization>(EditOrganizationConfig(OrgUser))
    const navigate = useNavigate()
    const isExample = OrgUser && "examplePoaUrl" in OrgUser
    const handleSubmit: SubmitHandler<IEditOrganization> = async (data) => {
        await fetchCreateOrg(formDataHandler<IEditOrganization>(data, EditOrganizationNames.file))
        navigate("/personal-account/potential")
    }
    const requestDelete = async () => {
        if (OrgUser && "user" in OrgUser && OrgUser.user.orgStatus !== CardStatusType.NONE) {
            await fetchDeleteDocument("")
        }
    }
    if (!isLoading && OrgUser) {
        return (
            <>
                <FormProvider {...methods}>
                    <FormLayout
                        main={() => (
                            <>
                                {isExample ? (
                                    <Informer
                                        type={InformerType.BASIC}
                                        isWithSvg={false}
                                        title={"Организация не добавлена"}
                                    >
                                        Добавьте организацию, чтобы получить доступ к функционалу “Оператора
                                        возможностей”.
                                    </Informer>
                                ) : (
                                    <OrgStatusInformer type={OrgUser.user.orgStatus} />
                                )}

                                <StyledInputSection>
                                    <Input
                                        name={EditOrganizationNames.title}
                                        labelText={"Название организации"}
                                        required={true}
                                        clearable={false}
                                        aria-readonly={
                                            "user" in OrgUser && OrgUser?.user?.orgStatus === CardStatusType.NONE
                                        }
                                    />
                                    <Input
                                        name={EditOrganizationNames.taxId}
                                        labelText={"ИНН организации"}
                                        required={true}
                                        clearable={false}
                                        aria-readonly={
                                            "user" in OrgUser && OrgUser?.user?.orgStatus === CardStatusType.NONE
                                        }
                                    />
                                </StyledInputSection>

                                <FileLoad
                                    name={EditOrganizationNames.file}
                                    title={"Доверенность"}
                                    accept={FileAccept.MULTIPLE}
                                    FLType={FileType.MULTIPLE}
                                    example={isExample ? OrgUser.examplePoaUrl : undefined}
                                    uploadFile={
                                        "title" in OrgUser
                                            ? {
                                                  url: OrgUser?.user?.orgDocument?.url,
                                                  name: OrgUser?.user?.orgDocument?.filename,
                                                  mimetype: OrgUser?.user?.orgDocument?.mimetype,
                                                  size: OrgUser?.user?.orgDocument?.size,
                                                  requestDelete: requestDelete,
                                                  modal: true,
                                                  modalTitle: "Удалить доверенность?",
                                                  textModalDelete: textModalUpload(OrgUser.user.orgStatus),
                                              }
                                            : null
                                    }
                                />
                            </>
                        )}
                        footer={() => (
                            <>
                                <Button
                                    typeUI={ButtonType.PRIMARY}
                                    size={ButtonSize.M}
                                    onClick={methods.handleSubmit(handleSubmit)}
                                >
                                    Сохранить
                                </Button>
                                <Button
                                    typeUI={ButtonType.GHOST}
                                    size={ButtonSize.M}
                                    onClick={() => navigate("/personal-account")}
                                >
                                    Отменить
                                </Button>
                            </>
                        )}
                    />
                </FormProvider>
            </>
        )
    }
}
