import { type FC } from "react"
import { Choice, useMediaSizeChoice } from "shared/ui"
import { type days } from "../../../../lib"
import { StyledDays } from "./styles"
import { type WorkDaysProps } from "./types"

export const WorkDays: FC<WorkDaysProps> = (props) => {
    const { state, dispatch, arrayNumber, toggleDayAction } = props
    const { fromXStoS } = useMediaSizeChoice()
    return (
        <StyledDays key={`${arrayNumber + "days"}`}>
            {Object.keys(state).map((day, index) => (
                <Choice
                    id={day}
                    size={fromXStoS}
                    onClick={() => dispatch(toggleDayAction({ dayName: day as days, arrayNumber }))}
                    selected={
                        state[day as days].wasSelected && state[day as days].arrayNumber === arrayNumber ? day : null
                    }
                    key={`${index + day}`}
                >
                    {day}
                </Choice>
            ))}
        </StyledDays>
    )
}
