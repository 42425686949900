import React, { type FC } from "react"

export const FileUpload: FC = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.8536 8.35355L12.5 4L8.14648 8.35355L8.85359 9.06066L12 5.91425V17.5H13V5.91418L16.1465 9.06066L16.8536 8.35355Z"
                fill="white"
            />
            <path d="M5.5 16H6.5V19.5H18.5V16H19.5V20.5H5.5V16Z" fill="white" />
        </svg>
    )
}
