import { type FC } from "react"
import { TagSize } from "shared/ui"
import { useResize } from "shared/hook"
import { type OpportunityTagProps, SizeGoalTag } from "./types"
import { tagCard } from "./config"

export const GoalTag: FC<OpportunityTagProps> = (props) => {
    const { typeCard, size = SizeGoalTag.SMALL } = props
    const {
        mediaScreenSize: { width, isLaptopL },
    } = useResize()
    const TagSizeStoM = width >= isLaptopL ? TagSize.M : TagSize.S

    const TagSizeProps: TagSize = size === SizeGoalTag.BIG ? TagSize.M : TagSizeStoM

    return <>{tagCard(typeCard, TagSizeProps)()}</>
}
