import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type VkIconSvgProps } from "./types"

export const VkIconSvg: FC<VkIconSvgProps> = (props) => {
    const { size = SizeSvg.L } = props
    return (
        <svg width={size} height={size} viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.5973 2.66663H12.416C4.94396 2.66663 3.16663 4.44396 3.16663 11.9026V20.084C3.16663 27.5546 4.93063 29.3333 12.4026 29.3333H20.584C28.0546 29.3333 29.8333 27.5693 29.8333 20.0973V11.916C29.8333 4.44396 28.0693 2.66663 20.5973 2.66663ZM24.6946 21.6933H22.7493C22.0133 21.6933 21.792 21.0973 20.472 19.7773C19.32 18.6666 18.8333 18.528 18.5413 18.528C18.1386 18.528 18.028 18.6386 18.028 19.1946V20.944C18.028 21.4173 17.8746 21.6946 16.6386 21.6946C15.4391 21.614 14.2759 21.2496 13.2448 20.6313C12.2137 20.0131 11.3442 19.1587 10.708 18.1386C9.19752 16.2586 8.14655 14.0521 7.63863 11.6946C7.63863 11.4026 7.74929 11.1386 8.30529 11.1386H10.2493C10.7493 11.1386 10.9293 11.3613 11.1253 11.8746C12.0693 14.6533 13.68 17.0693 14.3333 17.0693C14.584 17.0693 14.6933 16.9586 14.6933 16.3333V13.472C14.6106 12.1666 13.9173 12.056 13.9173 11.584C13.9262 11.4595 13.9832 11.3433 14.0763 11.2602C14.1694 11.1771 14.2913 11.1335 14.416 11.1386H17.472C17.8893 11.1386 18.028 11.3466 18.028 11.8466V15.708C18.028 16.1253 18.208 16.264 18.3333 16.264C18.584 16.264 18.7773 16.1253 19.236 15.6666C20.2211 14.4652 21.026 13.1267 21.6253 11.6933C21.6866 11.5211 21.8025 11.3737 21.9553 11.2736C22.1082 11.1734 22.2896 11.126 22.472 11.1386H24.4173C25 11.1386 25.124 11.4306 25 11.8466C24.2926 13.4312 23.4174 14.9354 22.3893 16.3333C22.18 16.6533 22.096 16.82 22.3893 17.1946C22.5826 17.4866 23.264 18.056 23.7226 18.5973C24.3892 19.2621 24.9427 20.0314 25.3613 20.8746C25.528 21.416 25.2493 21.6933 24.6946 21.6933Z"
                fill="#0077FF"
            />
        </svg>
    )
}
