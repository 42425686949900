import { CoordinatorCardSize } from "features/viewer"
import { useResize } from "shared/hook"

export const useMediaCoordinatorCard = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromLtoS: width >= isTablet ? CoordinatorCardSize.L : CoordinatorCardSize.S,
    }
}
