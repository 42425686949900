import React, { type FC } from "react"
import { InputTextArea, Period } from "shared/ui"
import { StyledSection } from "shared/styles"

export const ConditionsFields: FC = () => {
    return (
        <StyledSection>
            <Period.Date />
            <InputTextArea
                labelText={"Условия получения"}
                required={true}
                placeholder={"Что должен сделать участник для получения возможности"}
                name={"condition"}
                limit={300}
            />
        </StyledSection>
    )
}
