import { type HTMLAttributes } from "react"
import { type InputModel } from "shared/ui/Input"
import { type FormFieldModel } from "shared/ui/FormField"

type ExcludeInput = "type" | "clearable" | "hasIcon"
type ExcludeFormField = "render" | "hasStatus"

interface validWindow {
    validWindow?: boolean
}

export enum InputPasswordType {
    PASSWORD = "password",
    TEXT = "text",
}

export type InputPasswordProps = Omit<InputModel & validWindow & HTMLAttributes<HTMLInputElement>, ExcludeInput> &
    Omit<FormFieldModel, ExcludeFormField>
