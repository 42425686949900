export const formOpportunityDefaultValues = {
    name: "",
    categories: "",
    address: "",
    description: "",
    userCategories: [""],
    startDate: "",
    endDate: "",
    ageRange: { from: 12, to: 35 },
    condition: "",
}
