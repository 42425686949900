import React, { type FC } from "react"
import { ProfileData, ProfileDataType } from "entities/viewer"
import { Tag, useMediaTag } from "shared/ui"
import { StyledProfileDataPattern } from "shared/styles"

interface ForWhomSectionProps {
    userCategories: Array<{ id: string; name: string }>
}
export const ForWhomSection: FC<ForWhomSectionProps> = (props) => {
    const { userCategories } = props
    const { TagSizeStoM } = useMediaTag()
    return (
        <StyledProfileDataPattern $type={"light-blue"}>
            <ProfileData caption={"Для кого"} type={ProfileDataType.TAG}>
                {userCategories.map(({ id, name }) => (
                    <Tag key={`${id}tag`} size={TagSizeStoM}>
                        {name}
                    </Tag>
                ))}
            </ProfileData>
        </StyledProfileDataPattern>
    )
}
