import React, { type FC, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { useGetAccessTokenByEmailMutation, useLazyGetAccessTokenByPhoneQuery } from "features/user"
import { ChoiceInputAuthType } from "entities/user"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { StyledSection } from "shared/styles"
import { useApiError } from "shared/hook"
import { useAccessTokenRedirect } from "../../lib"
import { type AuthComeInButtonProps } from "./types"

export const AuthComeInButton: FC<AuthComeInButtonProps> = (props) => {
    const { choice, updateToken } = props
    const {
        handleSubmit,
        setError,
        formState: { isValid },
    } = useFormContext()
    const { fromMtoL } = useMediaSizeButton()
    const [fetchAccessToken, stateRequestEmail] = useGetAccessTokenByEmailMutation()
    const [fetchAccessTokenByPhone, stateRequestPhone] = useLazyGetAccessTokenByPhoneQuery()
    const error = stateRequestEmail.error ?? stateRequestPhone.error
    const isError = stateRequestEmail.isError ?? stateRequestPhone.isError

    useAccessTokenRedirect(updateToken, stateRequestEmail.data)

    const { handlerError } = useApiError()

    useEffect(() => {
        if (error && "error" in error) {
            const errObj = handlerError(error, isError)
            setError("root", {
                type: "custom",
                message: errObj?.message.join(","),
            })
            const message =
                error.status && error.status > 300 && error.status < 500
                    ? errObj?.message ?? "Пользователь не подтвержден или не прошёл авторизацию"
                    : null
            if (message) {
                setError("email", { type: `${errObj?.statusCode}`, message: " " })
                setError("password", { type: `${errObj?.statusCode}`, message: "" })
            }
        }
    }, [error])

    return (
        <StyledSection>
            <Button
                onClick={handleSubmit(
                    (data) => {
                        choice === ChoiceInputAuthType.EMAIL
                            ? fetchAccessToken({ email: data.email.trim(), password: data.password })
                            : fetchAccessTokenByPhone({ phone: `+7 ${data.phone}`, password: data.password })
                        updateToken()
                    },
                    (err) => console.log(err)
                )}
                size={fromMtoL}
                typeUI={
                    isValid || stateRequestEmail.isLoading || stateRequestPhone.isLoading
                        ? ButtonType.PRIMARY
                        : ButtonType.DISABLED
                }
            >
                Войти
            </Button>
        </StyledSection>
    )
}
