import { useResize } from "../../hook"
import { TagSize } from "./types"

export const useMediaTag = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        TagSizeStoM: width >= isTablet ? TagSize.M : TagSize.S,
    }
}
