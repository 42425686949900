import React, { type FC } from "react"
import { Button, ButtonSize, ButtonType, Vector, VectorType } from "shared/ui"
import { FillSvg, PlusSvg, SizeSvg } from "shared/assets/svg"
import { type AddDeleteButtonsProps } from "./types"
import { StyledAddButton, StyledDeleteButton, StyledButtons } from "./styles"

export const AddDeleteButtons: FC<AddDeleteButtonsProps> = (props) => {
    const { handlerClickDelete, handlerClickAdd, showDeleteButton, showAddButton } = props
    return (
        <StyledButtons>
            <StyledDeleteButton $show={showDeleteButton}>
                <Button type={"button"} typeUI={ButtonType.GHOST_RED} size={ButtonSize.XS} onClick={handlerClickDelete}>
                    <Vector size={SizeSvg.M} type={VectorType.CROSS} fill={FillSvg.CURRENT_COLOR} />
                    Удалить
                </Button>
            </StyledDeleteButton>
            <StyledAddButton $show={showAddButton}>
                <Button
                    type={"button"}
                    typeUI={ButtonType.GHOST}
                    onClick={handlerClickAdd}
                    size={ButtonSize.XS}
                    leftIcon={() => <PlusSvg />}
                >
                    Добавить
                </Button>
            </StyledAddButton>
        </StyledButtons>
    )
}
