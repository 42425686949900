import React, { type FC } from "react"
import { LabeledSwitch } from "shared/ui"
import { useToggle } from "shared/hook"
import { StyledSection } from "shared/styles"

export const WithDisabilitiesFields: FC = () => {
    const toggler = useToggle(false)
    const togglerLimitedMobility = useToggle(false)

    return (
        <StyledSection>
            <LabeledSwitch {...toggler} name={"accessible"} title={"Для людей с ОВЗ"} />
            <LabeledSwitch
                {...togglerLimitedMobility}
                name={"lowMobilityFriendly"}
                title={"Для маломобильных граждан"}
            />
        </StyledSection>
    )
}
