import React, { type FC } from "react"
import { Informer, InformerType } from "shared/ui"

export const OpportunityRegInformer: FC = () => {
    return (
        <Informer type={InformerType.WARNING} title={"В данный момент воспользоваться возможностью нельзя"}>
            К сожалению, регистрация на данную возможность сейчас закрыта.
        </Informer>
    )
}
