import styled from "styled-components"
import { type FormFieldState } from "shared/hook/useFormField"

const StyledFormField = styled.div`
    display: grid;
    position: relative;
`
const StyledError = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    color: var(--assets-label-colors-error);
    padding: var(--assets-label-gap) var(--assets-label-padding-hor) 0px var(--assets-label-padding-hor);
    line-height: 16px;
    font-size: 12px;
`
const StyledParentInput = styled.div<{ $state?: FormFieldState }>`
    border: 1px solid var(--assets-input-colors-stroke-${(props) => props.$state});
    display: flex;
    width: 100%;
    align-items: center;
    align-self: stretch;
    gap: var(--assets-input-sizes-gap);
    min-height: var(--assets-input-sizes-height);
    padding: var(--assets-input-sizes-padding-vert) var(--assets-input-sizes-padding-hor);
    border-radius: var(--assets-input-sizes-radius);
    & > button {
        width: 42px;
    }
    & button > svg > path {
        fill: black;
    }
`
export { StyledFormField, StyledError, StyledParentInput }
