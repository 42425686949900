import { useResize } from "shared/hook"
import { ChoiceGroupSize } from "./types"

export const useMediaSizeChoiceGroup = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromStoL: width >= isTablet ? ChoiceGroupSize.L : ChoiceGroupSize.S,
    }
}
