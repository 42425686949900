import { type HTMLAttributes, type ReactNode } from "react"
import { type FormFieldModel } from "../FormField"

export interface InputModel {
    hasIcon?: () => ReactNode
    clearable?: boolean
    type?: TypeInput
    iconSvg?: () => ReactNode
    maxLength?: number
    labelIcon?: () => ReactNode
}
export enum TypeInput {
    PASSWORD = "password",
    TEXT = "text",
    EMAIL = "email",
}

type Exclude = "render"

export type InputProps = Omit<FormFieldModel, Exclude> & InputModel & HTMLAttributes<HTMLInputElement>
