import * as yup from "yup"
import { type ObjectSchema, type ObjectShape } from "yup"
import { type IRestorePasswordForm } from "features/user"
import { ChoiceInputAuthType, choiceInputSchemaObject } from "entities/user"

export const schemaRestoreForm = (choice: ChoiceInputAuthType = ChoiceInputAuthType.EMAIL) => {
    const schemaAuthFormObject = yup
        .object({
            ...choiceInputSchemaObject(choice),
        } as unknown as ObjectShape)
        .required()
    return schemaAuthFormObject as ObjectSchema<IRestorePasswordForm>
}
