import { type HTMLAttributes, type PropsWithChildren } from "react"

export enum HeadingType {
    H1 = "H1",
    H2 = "H2",
    H3 = "H3",
    H4 = "H4",
}

export enum BasicType {
    ADDITIONAL = "additional",
    SECOND = "second",
    CARD_COLORS_INTERACTIVE = "card-colors-interactive",
    BASIC = "basic",
}

export enum BasicTypeSize {
    FONT_SIZE_12 = "12px",
    FONT_SIZE_14 = "14px",
    FONT_SIZE_16 = "16px",
    FONT_SIZE_20 = "20px",
}

export interface BasicModel extends PropsWithChildren {
    basicTypeSize?: BasicTypeSize
    typeUI: BasicType
}
export interface HeadingModel extends PropsWithChildren {
    typeUI: HeadingType
}

export type HeadingProps = HeadingModel & HTMLAttributes<HTMLDivElement>
export type BasicProps = BasicModel & HTMLAttributes<HTMLDivElement>
