import { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { GeoSvg, SizeSvg } from "shared/assets/svg"
import { formatCoordinates } from "../../../../lib"
import { StyledFooterBtn } from "./styles"
import { type ContactCardFooterButtonProps } from "./types"

export const ContactCardFooterButton: FC<ContactCardFooterButtonProps> = (props) => {
    const { isModal, coordinates, onBackClick = () => {}, isSmallModal, id } = props
    const navigate = useNavigate()
    const { fromStoM } = useMediaSizeButton()
    return (
        <>
            {!isModal ? (
                <Button
                    size={fromStoM}
                    onClick={() => {
                        navigate(`/contacts/map/${id}/${formatCoordinates(coordinates)}`)
                    }}
                    leftIcon={() => <GeoSvg size={SizeSvg.M} />}
                    typeUI={ButtonType.GHOST}
                >
                    На карте
                </Button>
            ) : (
                <StyledFooterBtn>
                    <Button size={fromStoM} onClick={onBackClick} typeUI={ButtonType.GHOST}>
                        {isSmallModal ? "Назад" : "Отмена"}
                    </Button>
                </StyledFooterBtn>
            )}
        </>
    )
}
