import { type UseFormProps } from "react-hook-form"
import * as yup from "yup"
import { type ObjectSchema, type ObjectShape } from "yup"
import { type IAuthDataByEmail, type IAuthDataByPhone } from "features/user"
import { ChoiceInputAuthType, choiceInputSchemaObject } from "entities/user"

export const AuthFormConfig: UseFormProps<IAuthDataByEmail | IAuthDataByPhone> = {
    defaultValues: {
        email: "",
        password: "",
    },
    mode: "onChange",
    criteriaMode: "all",
}

export const schemaAuthForm = (choice: ChoiceInputAuthType = ChoiceInputAuthType.EMAIL) => {
    const schemaAuthFormObject = yup
        .object({
            ...choiceInputSchemaObject(choice),
            password: yup.string().required("Поле обязательно для заполнения"),
        } as unknown as ObjectShape)
        .required()
    return schemaAuthFormObject as ObjectSchema<IAuthDataByEmail | IAuthDataByPhone>
}
