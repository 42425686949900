import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type LightSvgProps } from "./types"

export const LightSvg: FC<LightSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="20" viewBox="0 0 14 20" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.76393 19H8V19.5C8 19.7761 7.77614 20 7.5 20H6.5C6.22386 20 6 19.7761 6 19.5V19H5.23607C5.08082 19 4.92771 18.9639 4.78885 18.8944L4.55279 18.7764C4.214 18.607 4 18.2607 4 17.882V14.5905C4 13.8184 3.53977 13.1351 2.91327 12.6838C1.14878 11.4129 0 9.34061 0 7C0 3.13401 3.13401 0 7 0C10.866 0 14 3.13401 14 7C14 9.34061 12.8512 11.4129 11.0867 12.6838C10.4602 13.1351 10 13.8184 10 14.5905V17.882C10 18.2607 9.786 18.607 9.44721 18.7764L9.21115 18.8944C9.07229 18.9639 8.91918 19 8.76393 19ZM8.76394 18L9 17.882V17H5L4.99999 17.882L5.23606 18H8.76394ZM5 16H9V15H5L5 16ZM4.94094 14C4.75636 13.0882 4.16278 12.3514 3.49773 11.8724C1.98293 10.7813 1 9.00548 1 7C1 3.68629 3.68629 1 7 1C10.3137 1 13 3.68629 13 7C13 9.00548 12.0171 10.7813 10.5023 11.8724C9.83722 12.3514 9.24364 13.0882 9.05906 14H4.94094Z"
                fill="black"
            />
        </svg>
    )
}
