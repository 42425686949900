import React, { type FC } from "react"
import { useFormContext } from "react-hook-form"
import { CountrySelector } from "shared/ui"
import { StyledInput } from "shared/styles"
import { type InputNumberProps } from "./types"
import { StyledInputNumber } from "./styles"

export const InputNumber: FC<InputNumberProps> = (props) => {
    const { labelText, name, required = true, ...other } = props
    const { register } = useFormContext()
    return (
        <StyledInputNumber
            className={props.className}
            name={name}
            required={required}
            isSuccess={false}
            labelText={labelText}
            render={(focus) => (
                <>
                    <CountrySelector />
                    <StyledInput type={"tel"} {...other} {...register(name)} onFocus={focus} onBlur={focus} />
                </>
            )}
        />
    )
}
