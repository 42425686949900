import styled from "styled-components"
import { getMediaVariable } from "shared/styles"
const StyledSlider = styled.div`
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 640px;
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        width: 100%;
    }
`
const StyledSliderContent = styled.div`
    display: flex;
    width: 100%;
    height: var(--assets-opportunity-photos-height, 360px);
    background: var(--root-colors-accent-crema, #f4efe9);
    &,
    & > img,
    & > iframe {
        border-radius: var(--assets-opportunity-photos-radius, 24px);
    }
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
`
const StyledSliderImages = styled.div`
    //display: grid;
    //grid-template-columns: repeat(auto-fit, minmax(auto, 118px));
    display: flex;
    overflow: scroll;
    gap: 12px;
    width: 100%;
    height: 118px;
    &::-webkit-scrollbar {
        display: none;
    }
    & img {
        object-fit: cover;
    }
    & > div {
        flex-shrink: 0;
        width: 118px;

        overflow-x: unset;
    }
`

export { StyledSlider, StyledSliderContent, StyledSliderImages }
