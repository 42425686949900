import { type FC } from "react"
import { InputCounter } from "shared/ui"

export const FieldVolunteers: FC = () => {
    return (
        <>
            <InputCounter labelText={"Волонтеров"} required={true} name={"volunteers"} />
        </>
    )
}
