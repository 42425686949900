import React, { type FC, type HTMLAttributes } from "react"
import { useGetPresentMonthQuery, useGetProfileDataQuery } from "features/viewer"
import { BasicType, ProgressBar, Typography } from "shared/ui"
import { StyledPoints, StyledProgress } from "./styles"

interface ProgressProps extends HTMLAttributes<HTMLDivElement> {}

export const Progress: FC<ProgressProps> = (props) => {
    const { data: ProfileData } = useGetProfileDataQuery("")
    const { data: PresentData } = useGetPresentMonthQuery("")

    if (ProfileData && PresentData) {
        return (
            <StyledProgress {...props}>
                <StyledPoints>
                    <Typography.Basic typeUI={BasicType.ADDITIONAL}>{ProfileData.points}</Typography.Basic>
                    <span>{PresentData.pointsRequired}</span>
                </StyledPoints>
                <ProgressBar percentThumb={[ProfileData.points, PresentData.pointsRequired]} />
                <Typography.Basic typeUI={BasicType.CARD_COLORS_INTERACTIVE}>Подарок месяца</Typography.Basic>
            </StyledProgress>
        )
    }
}
