import styled, { css } from "styled-components"
import { conditions } from "shared/lib"
import { getMediaVariable } from "shared/styles"
import { RatingPlateSize, RatingPlateType } from "./types"

const StyledRatingPlate = styled.div<{
    $type?: RatingPlateType
    $size: RatingPlateSize
}>`
    display: flex;
    align-items: center;
    gap: var(--assets-card-custom-goal-sizes-s-gap, 16px);
    border-radius: var(--assets-card-leaderboard-sizes-s-radius, 16px);
    background: var(--root-colors-main-white-light, rgba(0, 0, 0, 0.02));
    color: var(--root-colors-main-black-basic, #000);

    ${(props) =>
        conditions<RatingPlateSize>(props.$size, [
            [
                RatingPlateSize.L,
                css`
                    width: 396px;
                    padding: var(--assets-card-leaderboard-sizes-l-padding, 12px);
                    justify-content: center;
                `,
            ],
            [
                RatingPlateSize.S,
                css`
                    width: 100%;
                    min-width: 326px;
                    padding: var(--assets-card-leaderboard-sizes-s-padding, 8px);
                    justify-content: flex-start;
                    max-width: 396px;
                `,
            ]
        ])};
    ${(props) =>
        props.$type &&
        conditions<RatingPlateType>(props.$type, [
            [
                RatingPlateType.GET_PRESENT_USERS,
                css`
                    background: var(--root-colors-accent-crema, #f4efe9);
                    color: var(--root-colors-main-black-basic, #000);
                `,
            ],
            [
                RatingPlateType.USER,
                css`
                    background: var(--root-colors-main-blue-basic, #3e3aed);
                    color: var(--root-colors-main-white-basic, #fff);
                `,
            ],
            [
                RatingPlateType.LEADER,
                css`
                    background: var(--root-colors-main-orange-basic, #ff8100);
                    color: var(--root-colors-main-white-basic, #fff);
                `,
            ]
        ])};
`

const StyledRank = styled.div<{
    $size: RatingPlateSize
}>`
    display: flex;
    width: ${({ $size }) => `var(--assets-card-leaderboard-sizes-${$size}-width-num)`};
    justify-content: center;
    align-items: center;
    gap: var(--assets-card-leaderboard-sizes-s-gap-inner, 4px);
    flex-shrink: 0;
    font-size: 16px;
    font-weight: 500;

    @media (min-width: ${getMediaVariable("tablet")}) {
        font-size: 24px;
    }
`

const StyledRankDescription = styled.div<{
    $type?: RatingPlateType
}>`
    display: flex;
    flex-direction: column;
    gap: var(--assets-card-leaderboard-sizes-s-gap-inner, 4px);
    line-height: 120%;
    flex: 1 0 0;
    & :nth-child(2) {
        color: ${(props) =>
            props.$type === RatingPlateType.USER || props.$type === RatingPlateType.LEADER
                ? "var(--root-colors-main-white-basic, #fff)"
                : "var(--root-colors-main-black-light, rgba(0, 0, 0, 0.72))"};
        font-size: 12px;
        @media (min-width: ${getMediaVariable("tablet")}) {
            font-size: 14px;
        }
    }
`

export { StyledRatingPlate, StyledRank, StyledRankDescription }
