import styled from "styled-components"

const StyledNotification = styled.div`
    & > div:last-child {
        position: absolute;
        right: 4px;
        bottom: 4px;
    }
`

export { StyledNotification }
