import { mainApi, BASE_URL } from "features/api"
import { type RequestConfig } from "shared/api"

import { transformUrlImages } from "shared/lib"
import { type ProfileI } from "./types"

const profileApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getProfileData: builder.query<ProfileI, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/profile",
                method: "GET",
            }),
            providesTags: ["Profile"],
            transformResponse: (data: ProfileI, ...rest) => {
                const avatar = data?.avatar?.url ? transformUrlImages(data.avatar.url) : null
                if (avatar && data?.avatar) {
                    return {
                        ...data,
                        avatar: {
                            ...data.avatar,
                            url: avatar,
                        },
                    } satisfies ProfileI
                }

                return data
            },
        }),
        changeProfileData: builder.mutation<ProfileI, unknown>({
            query: (body: ProfileI): RequestConfig<ProfileI, unknown> => ({
                url: BASE_URL.USER + "/profile",
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["Profile"],
        }),
        deleteAvatar: builder.mutation<ProfileI, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/profile/avatar",
                method: "DELETE",
            }),
            invalidatesTags: ["Profile"],
        }),
    }),
})

export const {
    useGetProfileDataQuery,
    useDeleteAvatarMutation,
    useLazyGetProfileDataQuery,
    useChangeProfileDataMutation,
} = profileApi
