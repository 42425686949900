import { mainApi, BASE_URL } from "features/api"
import { type RequestConfig } from "shared/api"
import { type INotification } from "./types"

const notificationsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getNotifications: builder.query<INotification[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/notifications",
                method: "GET",
            }),
            providesTags: ["Notifications", "Profile"],
        }),
        readNotification: builder.mutation<INotification, unknown>({
            query: (id: string): RequestConfig<string, unknown> => ({
                url: BASE_URL.USER + `/notifications/${id}`,
                method: "PATCH",
            }),
            invalidatesTags: ["Notifications"],
        }),
    }),
})

export const { useGetNotificationsQuery, useReadNotificationMutation } = notificationsApi
