import { type HTMLAttributes } from "react"

export interface document {
    id: string | null
    filename: string | null
    mimetype: string | null
    size: number
    url: string | null
    placeId: string | null
    opportunityId: string | null
    awardId: string | null
    reportId: string | null
    createdAt: string | null
    updatedAt: string | null
}
export enum FileType {
    PICTURE = "picture",
    PICTURE_LIST = "picture-list",
    DOCUMENT = "document",
    DOCUMENT_LIST = "document-list",
    MULTIPLE = "multiple",
}
export enum FileAccept {
    MULTIPLE = ".pdf, .png, .jpg",
    PICTURE = ".png, .jpg",
    DOCUMENT = ".pdf, .doc, .docx",
}
export enum FileLoadSize {
    L = "l",
    S = "s",
}
export interface FileLoadUploadProps {
    name?: string | null
    url?: string | null
    mimetype?: string
    size?: number
    requestDelete: () => Promise<void> | void
    modal?: boolean
    modalTitle?: string
    textModalDelete?: string
    textModalReplace?: string
}
export interface FileLoadProps extends HTMLAttributes<HTMLInputElement> {
    name: string
    size?: FileLoadSize
    title: string
    uploadFile?: FileLoadUploadProps | null
    example?: string
    maxFiles?: number
    FLType?: FileType
    accept?: FileAccept
}
