import { type FC } from "react"
import { StyledContactsCardItem, StyledContactsCardLabel, StyledContactsCardText, StyledPhoneNumbers } from "./styles"
import { type AddressProps, type EmailProps, type PhonesProps, type WebsiteProps } from "./types"

const Address: FC<AddressProps> = ({ address }) => {
    return (
        <>
            <StyledContactsCardItem>
                <StyledContactsCardText>{address}</StyledContactsCardText>
                <StyledContactsCardLabel>Адрес</StyledContactsCardLabel>
            </StyledContactsCardItem>
        </>
    )
}

const Phones: FC<PhonesProps> = ({ fax, isModal, phones, isSmallModal }) => {
    return (
        <StyledPhoneNumbers $isBigArr={phones.length > 1} $isModal={isModal} $isSmallModal={isSmallModal}>
            {phones.map((phone) => (
                <StyledContactsCardItem key={`${phone}phone`}>
                    <StyledContactsCardText href={`tel:${phone}`}>{phone}</StyledContactsCardText>
                    <StyledContactsCardLabel>Телефон</StyledContactsCardLabel>
                </StyledContactsCardItem>
            ))}
            {fax && (
                <StyledContactsCardItem>
                    <StyledContactsCardText>{fax}</StyledContactsCardText>
                    <StyledContactsCardLabel>Факс</StyledContactsCardLabel>
                </StyledContactsCardItem>
            )}
        </StyledPhoneNumbers>
    )
}

const Email: FC<EmailProps> = ({ email }) => {
    return (
        <>
            {email && (
                <StyledContactsCardItem>
                    <StyledContactsCardText>{email}</StyledContactsCardText>
                    <StyledContactsCardLabel>Email</StyledContactsCardLabel>
                </StyledContactsCardItem>
            )}
        </>
    )
}
const WebsiteUrl: FC<WebsiteProps> = ({ websiteUrl }) => {
    return (
        <>
            {websiteUrl && (
                <StyledContactsCardItem>
                    <StyledContactsCardText href={websiteUrl}>{websiteUrl}</StyledContactsCardText>
                    <StyledContactsCardLabel>Сайт</StyledContactsCardLabel>
                </StyledContactsCardItem>
            )}
        </>
    )
}

export const ContactCardItems = {
    Address,
    Phones,
    Email,
    WebsiteUrl,
}
