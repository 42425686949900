import styled from "styled-components"

const NameSecondNameArea = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--layout-mobile-row-gap, 8px);
    align-self: stretch;

    & > *:first-child {
        width: 180px;
    }
    & > *:nth-child(2) {
        flex: 1;
    }
`

export { NameSecondNameArea }
