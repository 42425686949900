import { type FC, type SyntheticEvent } from "react"
import { useFormContext } from "react-hook-form"
import { Retry } from "shared/assets/svg"
import {
    StyledFrameInputCode,
    StyledInputCode,
    StyledInputCodeWrapper,
    StyledInputParent,
    StyledRepeatText,
    StyledRowInputCode,
} from "./styles"
import { type InputCodeItemProps, type InputCodeProps } from "./types"
import { useArrayRefs } from "./config"

const InputCodeItem: FC<InputCodeItemProps> = (props) => {
    const { register } = useFormContext()
    const { refInputItem, handleInputChange, handleInputKeyDown, name, index } = props
    return (
        <StyledInputParent>
            <StyledInputCodeWrapper>
                <StyledInputCode
                    {...register(name, {
                        maxLength: 1,
                    })}
                    ref={refInputItem}
                    maxLength={1}
                    onChange={(event) => handleInputChange(index, event)}
                    onKeyDown={(event) => handleInputKeyDown(index, event)}
                />
            </StyledInputCodeWrapper>
        </StyledInputParent>
    )
}

export const InputCode: FC<InputCodeProps> = (props) => {
    const { fieldsNames } = props
    const inputRefs = useArrayRefs()

    const handleInputChange = (index: number, event: SyntheticEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget
        if (value && index < inputRefs.length - 1) {
            inputRefs[index + 1].current?.focus()
        }
    }

    const handleInputKeyDown = (index: number, event: any) => {
        switch (event.key) {
            case "Backspace": {
                if (index > 0) {
                    event.preventDefault()
                    const currentInput = inputRefs[index].current
                    inputRefs[index - 1].current?.focus()
                    if (currentInput !== null) {
                        currentInput.value = ""
                    }
                }
                break
            }
            default: {
                break
            }
        }
    }
    return (
        <StyledFrameInputCode>
            <StyledRowInputCode>
                {fieldsNames.map((name, i) => (
                    <InputCodeItem
                        key={name}
                        name={name}
                        refInputItem={inputRefs[i]}
                        handleInputChange={handleInputChange}
                        handleInputKeyDown={handleInputKeyDown}
                        index={i}
                    />
                ))}
            </StyledRowInputCode>
            <StyledRepeatText>
                <Retry />
                Отправить повторно
            </StyledRepeatText>
        </StyledFrameInputCode>
    )
}
