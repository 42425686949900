import { type FC } from "react"
import { StyledAboutList } from "widgets/user/About/AboutList/styles"
import { useGetAboutUsDataQuery } from "features/user"
import { InfoEventCard, useMediaInfoEvent } from "entities/user"
import { StyledSection } from "shared/styles"
import { HeadingType, Typography } from "shared/ui"
import { formatNumber } from "shared/lib"

export const AboutList: FC = () => {
    const mediaInfoEventCard = useMediaInfoEvent()
    const { data = { sections: [] }, } = useGetAboutUsDataQuery({})
    return (
        <>
            {data.sections.map(({ heading, cards }, i) => (
                <StyledSection key={`about-sections-${i}`}>
                    <div>
                        <Typography.Heading typeUI={HeadingType.H2}>{heading}</Typography.Heading>
                    </div>
                    <StyledAboutList>
                        {cards.map((text, i) => (
                            <InfoEventCard
                                number={formatNumber(i)}
                                cardText={text}
                                key={`about-events-cards-${i}`}
                                size={mediaInfoEventCard.fromStoL}
                            />
                        ))}
                    </StyledAboutList>
                </StyledSection>
            ))}
        </>
    )
}
