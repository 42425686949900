import styled from "styled-components"
import { type AvatarSize } from "./types"

const StyledAvatar = styled.div<{
    $size: AvatarSize
}>`
    display: flex;

    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: var(--assets-user-avatar-colors-background);
    border-radius: var(--assets-user-avatar-radius-${(props) => props.$size});
    color: var(--assets-user-avatar-colors-text);
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    & > img,
    & {
        width: var(--assets-user-avatar-size-${(props) => props.$size});
        height: var(--assets-user-avatar-size-${(props) => props.$size});
        border-radius: var(--assets-user-avatar-radius-${(props) => props.$size});
    }
`

const StyledAvatarParent = styled.div`
    display: flex;
    align-items: end;
    gap: 8px;
`

export { StyledAvatar, StyledAvatarParent }
