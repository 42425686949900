import { InvisibleSmartCaptcha } from "@yandex/smart-captcha"
import React, { useRef, useState } from "react"
import { useFormContext } from "react-hook-form"

export const CAPTCHA_INPUT_NAME = "smart-token"

type Props = {
    visible: boolean
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
    onSuccess: (token: string) => any
}

/**
 * Компонент обёртка InvisibleSmartCaptcha
 *
 * используется вместе с хуком {@link useYandexCaptcha} и использует контекст react-hook-form
 * @see https://cloud.yandex.ru/ru/docs/smartcaptcha/concepts/react
 * @see https://react-hook-form.com/docs/useformcontext
 */
export function YandexCaptchaInput({ visible, setVisible, onSuccess }: Props) {
    const { resetField } = useFormContext()

    return (
        <InvisibleSmartCaptcha
            sitekey={String(process.env.REACT_APP_YANDEX_CAPTCHA_CLIENT_KEY)}
            visible={visible}
            onSuccess={onSuccess}
            onChallengeHidden={() => setVisible(false)}
            onTokenExpired={() => resetField(CAPTCHA_INPUT_NAME)}
            language="ru"
        />
    )
}

/**
 * Хук для использования вместе с {@link YandexCaptchaInput}, регулирует видимость капчи, выставляет значение токена на успешную капчу и вызывает `successCallback` после успешной капчи;
 *
 * использует `trigger` из контекста формы react-hook-form, чтобы провалидировать форму, в контексте которой находится капча, прежде чем проверять капчу.
 * @param successCallback - эта функция позовётся, когда капча успешна
 * @see https://react-hook-form.com/docs/useform/trigger
 */
export function useYandexCaptcha() {
    const [captchaVisible, setCaptchaVisible] = useState(false)
    const { setValue, trigger } = useFormContext()
    const callbackRef = useRef<() => void>()
    const onCaptchaSuccess = (token: string) => {
        setValue(CAPTCHA_INPUT_NAME, token)
        callbackRef.current && callbackRef.current()
    }

    return {
        captchaVisible,
        setCaptchaVisible,
        onCaptchaSuccess,
        validateCaptcha: async (successCallback: () => void) => {
            const validForm = await trigger()
            if (!validForm) return
            setCaptchaVisible(true)
            callbackRef.current = successCallback
        },
    }
}
