export const mapArrayToHash = <T>(array: T) => {
    const hash = new Map()
    if (Array.isArray(array)) {
        array.forEach((item: object) => {
            const [key, value] = Object.values(item)
            hash.set(key, value)
        })
    }
    return hash
}
