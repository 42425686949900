import { type SelectData } from "shared/ui"

export const sortSelect: SelectData[] = [
    {
        name: "Сначала новые",
        id: "asc",
    },
    {
        name: "Сначала старые",
        id: "desc",
    },
]
