import { type FC } from "react"
import { useFormContext } from "react-hook-form"
import dayjs from "dayjs"
import { Retry } from "shared/assets/svg"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { type CurrentButtonProps } from "./types"

export const CurrentButton: FC<CurrentButtonProps> = (props) => {
    const { setWasClear } = props
    const { reset } = useFormContext()
    const { fromStoM } = useMediaSizeButton()
    return (
        <Button
            leftIcon={() => <Retry />}
            onClick={async () => {
                await reset({
                    month: dayjs().month(),
                    year: dayjs().year(),
                })
                await setWasClear(true)
            }}
            typeUI={ButtonType.GHOST}
            size={fromStoM}
        >
            Текущий месяц
        </Button>
    )
}
