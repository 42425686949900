import React, { type FC } from "react"
import { Avatar, useGetProfileDataQuery } from "features/viewer"
import { ProfileData, ProfileDataSize, ProfileDataType } from "entities/viewer"
import { BasicType, HeadingType, Typography } from "shared/ui"
import { EmailSvg, GeoSvg, PhoneSvg, SizeSvg } from "shared/assets/svg"
import { conditions } from "shared/lib"
import { type ProfileCardProps, ProfileCardSize } from "./types"
import { StyledProfileCard, StyledTitleProfileCard } from "./styles"

export const ProfileCard: FC<ProfileCardProps> = (props) => {
    const { cut, coordinatorName, size = ProfileCardSize.L } = props
    const { data } = useGetProfileDataQuery("")
    const sizeSvgCondition = conditions<ProfileCardSize>(size, [
        [ProfileCardSize.L, SizeSvg.L],
        [ProfileCardSize.S, SizeSvg.M],
    ])
    const dataSizeCondition = size === ProfileCardSize.L ? ProfileDataSize.L : ProfileDataSize.S
    return (
        <StyledProfileCard>
            {!cut ? (
                <Avatar />
            ) : (
                <>
                    <StyledTitleProfileCard>
                        <Typography.Basic typeUI={BasicType.ADDITIONAL}>Получить у координатора</Typography.Basic>
                        <Typography.Heading typeUI={HeadingType.H4}>{coordinatorName}</Typography.Heading>
                    </StyledTitleProfileCard>
                </>
            )}
            <ProfileData caption={"Населенный пункт"} size={dataSizeCondition}>
                <GeoSvg size={sizeSvgCondition} />
                {data?.locality}
            </ProfileData>
            <ProfileData
                type={cut ? ProfileDataType.LINK : ProfileDataType.TEXT}
                caption={"Номер телефона"}
                size={dataSizeCondition}
            >
                <PhoneSvg size={sizeSvgCondition} />
                {data?.phone}
            </ProfileData>
            {!cut && (
                <ProfileData caption={"Адрес электронной почты"} size={dataSizeCondition}>
                    <EmailSvg size={sizeSvgCondition} />
                    {data?.email}
                </ProfileData>
            )}
        </StyledProfileCard>
    )
}
