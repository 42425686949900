import styled from "styled-components"
import { patternSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "shared/styles"

const StyledNewText = styled.div`
    & p:not(:nth-last-of-type(1)) {
        margin-bottom: 10px;
        line-height: 120%;
    }
`

const StyledNextPrev = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height: 100%;
`

const StyledNewsBackground = styled.div`
    width: 90vw;
    height: 1520px;
    position: absolute;
    left: 0;
    background-image: url(${() => `data:image/svg+xml,${patternSvgStatic}#news`});
    background-repeat: no-repeat;
    background-position: left -1196px bottom -440px;
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        background: unset;
    }
`
const StyledNewsMore = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;

    @media all and (min-width: ${getMediaVariable("tablet")}) {
        flex-direction: row;
    }
`
const StyledHeading = styled.div`
    & > span {
        @media all and (min-width: ${getMediaVariable("laptop")}) {
            font-size: 35px;
        }
    }
`
export { StyledNextPrev, StyledNewText, StyledNewsBackground, StyledNewsMore, StyledHeading }
