import styled, { css } from "styled-components"
import { ButtonSize, ButtonType } from "./types"

const StyledButton = styled.button<{
    $type?: ButtonType
    $size?: ButtonSize
    $hover?: boolean
    $onlyIcon: boolean
    $svgColor: string | null
}>`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background-color: var(--assets-button-${(props) => props.$type}-fill);
    color: var(--assets-button-${(props) => props.$type}-content);
    width: ${({ $type, $size }) =>
        $type === ButtonType.FREE ? `var(--assets-button-common-${$size}-height)` : "100%"};
    height: var(--assets-button-common-${({ $size }) => $size}-height);
    padding: var(--assets-button-common-${(props) => props.$size}-padding-vert)
        ${({ $onlyIcon, $size }) => !$onlyIcon && `var(--assets-button-common-${$size}-padding-hor)`};
    border-radius: var(--assets-button-common-${(props) => props.$size}-radius);
    border: ${(props) => {
        if (props.$type === ButtonType.AUTH) {
            return "1px solid var(--assets-o-auth-colors-stroke, rgba(0, 0, 0, 0.06))"
        }
        return props.$type !== ButtonType.SECONDARY ? "transparent" : "1px solid var(--assets-button-secondary-stroke)"
    }};
    gap: var(--assets-button-common-${(props) => props.$size}-gap);
    font-size: ${(props) => (props.$size !== ButtonSize.L ? 14 : 16)}px;
    user-select: none;

    ${(props) => {
        switch (props.$hover) {
            case true:
                return css`
                    &:not(:disabled):hover {
                        background-color: var(--assets-button-${props.$type}-fill-hover);
                        border-width: ${props.$type === ButtonType.SECONDARY ? "2px" : "1px"};
                    }
                `
            default:
                return css``
        }
    }}
    & > svg path {
        fill: ${({ $svgColor, $type }) => ($svgColor ? $svgColor : `var(--assets-button-${$type}-content)`)};
    }
`

export { StyledButton }
