import { type HTMLAttributes, type ReactNode } from "react"
import { type SizeSvg } from "shared/assets/svg"

export enum RequirementStatus {
    SUCCESS = "success",
    ERROR = "error",
    EMPTY = "empty",
}

export interface RequirementProps extends HTMLAttributes<HTMLDivElement> {
    status?: RequirementStatus
    size?: SizeSvg.M | SizeSvg.S
    children?: ReactNode
}
