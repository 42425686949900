import { type UseFormProps } from "react-hook-form"
import { type ObjectSchema, type ObjectShape, string, boolean, object, ref } from "yup"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import { ChoiceInputAuthType, choiceInputSchemaObject } from "entities/user"
import { passwordValidation } from "shared/lib"
import { CAPTCHA_INPUT_NAME } from "../../YandexCaptchaInput"
import { type IRegistrationForm } from "./types"
dayjs.extend(customParseFormat)

export const ModelRegistrationForm: UseFormProps<IRegistrationForm> = {
    defaultValues: {
        email: "",
        lastname: "",
        firstname: "",
        midname: "",
        locality: "",
        birthday: "",
        password: "",
        passwordCheck: "",
        isAccept: false,
        isAcceptNews: false,
    },
}

const simpleDataValidation = () => string().matches(/^[a-z|а-ё]+$/i, { message: "Введите корректные данные" })

export const schemaRegistrationForm = (choice: ChoiceInputAuthType = ChoiceInputAuthType.EMAIL) => {
    const schemaAuthFormObject = object({
        ...choiceInputSchemaObject(choice),
        lastname: simpleDataValidation().required("Поле обязательно для заполнения"),
        firstname: simpleDataValidation().required("Поле обязательно для заполнения"),
        locality: simpleDataValidation(),
        birthday: string()
            .required("Поле обязательно для заполнения")
            .test("right-format", "Введите корректное значение", (value) => {
                return dayjs(value, "YYYY-MM-DD", true).isValid()
            }),
        password: passwordValidation,
        passwordCheck: string()
            .required("Поле обязательно для заполнения")
            .test("is-equal", "Пароли не совпадают", function (value) {
                const password = this.resolve(ref("password")) as string
                return password === value
            }),
        isAccept: boolean().test("is-required", "Поле обязательно для заполнения", function (value) {
            return value
        }),
        isAcceptNews: boolean(),
        [CAPTCHA_INPUT_NAME]: string(),
    } as unknown as ObjectShape)
    return schemaAuthFormObject as ObjectSchema<IRegistrationForm>
}
