import styled, { css } from "styled-components"

const StyledDeleteButton = styled.div<{
    $show: boolean
}>`
    ${({ $show }) =>
        $show
            ? css``
            : css`
                  display: none;
              `}
`

const StyledAddButton = styled.div<{
    $show: boolean
}>`
    ${({ $show }) =>
        $show
            ? css``
            : css`
                  display: none;
              `}
    margin-left: auto;
    margin-right: 0;
`

const StyledButtons = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    align-self: stretch;
    & > div:last-child {
        justify-self: end;
    }
`

export { StyledAddButton, StyledDeleteButton, StyledButtons }
