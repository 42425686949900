import React, { type FC } from "react"

export const Retry: FC = () => {
    return (
        <svg width="18" height="21" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                id="vector"
                d="M10.1893 3.75L8.46967 2.03033L9.53033 0.969666L13.0607 4.5L9.53033 8.03033L8.46967 6.96967L10.1893 5.25H9C7.66498 5.25 6.35994 5.64588 5.2499 6.38758C4.13987 7.12928 3.27471 8.18348 2.76382 9.41688C2.25293 10.6503 2.11925 12.0075 2.3797 13.3169C2.64015 14.6262 3.28303 15.829 4.22703 16.773C5.17104 17.717 6.37377 18.3598 7.68314 18.6203C8.99252 18.8807 10.3497 18.7471 11.5831 18.2362C12.8165 17.7253 13.8707 16.8601 14.6124 15.7501C15.3541 14.6401 15.75 13.335 15.75 12H17.25C17.25 13.6317 16.7662 15.2267 15.8596 16.5835C14.9531 17.9402 13.6646 18.9976 12.1571 19.622C10.6497 20.2464 8.99085 20.4098 7.39051 20.0915C5.79017 19.7731 4.32016 18.9874 3.16637 17.8336C2.01259 16.6798 1.22685 15.2098 0.908525 13.6095C0.590197 12.0091 0.753575 10.3503 1.378 8.84286C2.00242 7.33537 3.05984 6.04689 4.41655 5.14037C5.77325 4.23385 7.36831 3.75 9 3.75H10.1893Z"
                fill="#3E3AED"
            />
        </svg>
    )
}
