import { useEffect, useState } from "react"

export type Devices = "isPhone" | "isTablet" | "isLaptop" | "isLaptopL" | "isDesktop"

type UseMediaQueryType = () => Record<Devices, boolean>

const mediaPoints: Record<string, string> = {
    phone: "(max-width: 425px)",
    tablet: "(max-width: 768px)",
    laptop: "(max-width: 1024px)",
    "laptop-l": "(max-width: 1440px)",
    desktop: "(min-width: 1920px)",
}

//!
//! refactor
//!

export const useMediaQuery: UseMediaQueryType = () => {
    const [state, setState] = useState<Record<Devices, boolean>>({
        isPhone: false,
        isTablet: false,
        isLaptop: false,
        isLaptopL: false,
        isDesktop: false,
    })

    useEffect(() => {
        const mediaQueryList = Object.keys(mediaPoints).map((key) => window.matchMedia(mediaPoints[key]))

        const changeMediaHandler = (): void => {
            const newState: Record<Devices, boolean> = {
                isPhone: mediaQueryList[0].matches,
                isTablet: mediaQueryList[1].matches || mediaQueryList[0].matches,
                isLaptop: mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
                isLaptopL:
                    mediaQueryList[3].matches ||
                    mediaQueryList[2].matches ||
                    mediaQueryList[1].matches ||
                    mediaQueryList[0].matches,
                isDesktop:
                    mediaQueryList[4].matches ||
                    mediaQueryList[3].matches ||
                    mediaQueryList[2].matches ||
                    mediaQueryList[1].matches ||
                    mediaQueryList[0].matches,
            }

            setState(newState)
        }

        mediaQueryList.forEach((mediaQuery) => {
            mediaQuery.addEventListener("change", changeMediaHandler)
        })

        // Initial check for the current media query
        const initialState: Record<Devices, boolean> = {
            isPhone: mediaQueryList[0].matches,
            isTablet: mediaQueryList[1].matches || mediaQueryList[0].matches,
            isLaptop: mediaQueryList[2].matches || mediaQueryList[1].matches || mediaQueryList[0].matches,
            isLaptopL:
                mediaQueryList[3].matches ||
                mediaQueryList[2].matches ||
                mediaQueryList[1].matches ||
                mediaQueryList[0].matches,
            isDesktop:
                mediaQueryList[4].matches ||
                mediaQueryList[3].matches ||
                mediaQueryList[2].matches ||
                mediaQueryList[1].matches ||
                mediaQueryList[0].matches,
        }

        setState(initialState)

        return () => {
            mediaQueryList.forEach((mediaQuery) => {
                mediaQuery.removeEventListener("change", changeMediaHandler)
            })
        }
    }, [])

    return state
}
