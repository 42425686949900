import styled from "styled-components"

const StyledProgressBar = styled.div`
    display: flex;
    padding: var(--assets-progress-bar-sizes-padding, 2px);
    align-items: center;
    align-self: stretch;

    border-radius: var(--assets-progress-bar-sizes-radius, 4px);
    background: var(--assets-progress-bar-colors-background, rgba(0, 0, 0, 0.06));
`

const StyledProgressThumb = styled.div<{
    $width: number
}>`
    height: var(--assets-progress-bar-sizes-height, 4px);
    background: var(--assets-progress-bar-colors-fill, #3e3aed);
    border-radius: 4px;
    width: ${({ $width }) => $width}%;
`

export { StyledProgressThumb, StyledProgressBar }
