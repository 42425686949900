import React, { type FC } from "react"
import { useGetCategoriesOpportunitiesQuery, useGetCategoriesTagsQuery } from "features/user"
import { Input, InputTextArea, Select } from "shared/ui"
import { StyledSection } from "shared/styles"

export const AboutFields: FC = () => {
    const { data: InterestsList } = useGetCategoriesOpportunitiesQuery("")
    const { data: tagsList } = useGetCategoriesTagsQuery("")

    return (
        <StyledSection>
            <Input name={"name"} labelText={"Название"} placeholder={"Новая возможность"} />
            <Select
                data={InterestsList}
                initialValue={"Выберите категорию возможности"}
                labelText={"Категория"}
                name={"categories"}
                multiple={false}
                clearable={true}
            />

            <Input name={"address"} labelText={"География"} placeholder={"Оренбург"} />
            <InputTextArea
                labelText={"Описание"}
                placeholder={"Подробнее о возможности"}
                name={"description"}
                limit={300}
            />
            <Select
                data={tagsList}
                name={"tags"}
                required={false}
                initialValue={"Дополнительные теги"}
                labelText={"Теги"}
                multiple={true}
                clearable={true}
            />
        </StyledSection>
    )
}
