import React, { type FC } from "react"
import { useGetPresentMonthQuery, useGetProfileDataQuery } from "features/viewer"
import { GetGiftButton, PointsState } from "features/dashboard"
import { Card } from "shared/ui"
import { StyledBottom, StyledGiftCard, StyledGiftCardContent, StyledImagePresent, StyledTop } from "./styles"

export const GiftCard: FC = () => {
    const { data: PresentData, isLoading } = useGetPresentMonthQuery("")
    const { data: ProfileData } = useGetProfileDataQuery("")
    const isDataNotUndefined = ProfileData && PresentData
    const isProgress = isDataNotUndefined && ProfileData.points < PresentData.pointsRequired
    const isGetPresent = !isProgress && !PresentData?.userReceivedAward
    const presentState = () => {
        if (isProgress) {
            return PointsState.NOT_ENOUGH
        }
        if (isGetPresent) {
            return PointsState.ENOUGH
        }
        return PointsState.ALREADY_GOT_GIFT
    }
    if (isDataNotUndefined) {
        return (
            <Card withHover>
                <StyledGiftCard>
                    <StyledImagePresent>
                        <img src={PresentData.images.url} alt="" />
                    </StyledImagePresent>
                    <StyledGiftCardContent>
                        <StyledTop>{PresentData.title}</StyledTop>
                        <StyledBottom>
                            <GetGiftButton presentState={presentState()} />
                        </StyledBottom>
                    </StyledGiftCardContent>
                </StyledGiftCard>
            </Card>
        )
    }
}
