import { mainApi, BASE_URL } from "features/api"
import { type RequestConfig } from "shared/api"
import { transformUrlImages } from "shared/lib"
import { type getCoordinatorData, type getOperatorsRating, type getRatingMonth, type Rating } from "./types"

const ratingApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getPresentMonth: builder.query<getRatingMonth, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/operator/rating/award",
                method: "GET",
            }),
            transformResponse: (data: getRatingMonth, ...rest) => {
                const image = data?.images?.url ? transformUrlImages(data.images.url) : null
                if (image && data?.images.url) {
                    return {
                        ...data,
                        images: {
                            ...data.images,
                            url: image,
                        },
                    } satisfies getRatingMonth
                }
                return data
            },
        }),
        getOperatorsRating: builder.query<getOperatorsRating[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/operator/rating/operators",
                method: "GET",
            }),
            transformResponse: (data: getOperatorsRating[], ...rest) => {
                return data.sort((a, b) => a.points + b.points)
            },
        }),
        getRating: builder.query<Rating, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/operator/rating/rating",
                method: "GET",
            }),
        }),
        getCoordinatorData: builder.query<getCoordinatorData, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/operator/rating/award/coordinator",
                method: "GET",
            }),
        }),
    }),
})

export const { useGetPresentMonthQuery, useGetCoordinatorDataQuery, useGetOperatorsRatingQuery, useGetRatingQuery } =
    ratingApi
