import { mainApi, BASE_URL } from "features/api"
import { type RequestConfig } from "shared/api"
import { type Ideas, type IIdeaForm } from "./types"

const ideaApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        createIdea: builder.mutation<IIdeaForm, unknown>({
            query: (body: IIdeaForm): RequestConfig<IIdeaForm, unknown> => ({
                url: BASE_URL.USER + "/ideas",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Ideas"],
        }),
        getIdeas: builder.query<Ideas[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/ideas",
                method: "GET",
            }),
            providesTags: ["Ideas"],
        }),
        getIdeaId: builder.query<Ideas, string>({
            query: (id): RequestConfig<unknown, string> => ({
                url: BASE_URL.USER + `/ideas/${id}`,
                method: "GET",
            }),
        }),
    }),
})

export const { useCreateIdeaMutation, useLazyGetIdeaIdQuery, useGetIdeasQuery } = ideaApi
