import React, { type FC } from "react"
import { useParams } from "react-router-dom"
import { ShowIdeaContentCard } from "widgets/viewer/ui/ShowIdeaContent/ui"
import { type Ideas } from "features/viewer"

export const ShowIdeaContent: FC<Ideas> = (props) => {
    const { problem, solution, successReason, results } = props
    const { id } = useParams()

    return (
        <>
            <ShowIdeaContentCard title={"Какую проблему решает"}>{problem}</ShowIdeaContentCard>
            <ShowIdeaContentCard title={"Как можно решить проблему"}>{solution}</ShowIdeaContentCard>
            <ShowIdeaContentCard title={"Почему идея сработает?"}>{successReason}</ShowIdeaContentCard>
            <ShowIdeaContentCard title={"Результаты реализации"}>{results}</ShowIdeaContentCard>
        </>
    )
}
