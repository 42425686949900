import { type FC } from "react"
import { StyledContent, StyledInfoEventCard, StyledNumber, StyledBackgroundPicture, StyledText } from "./styles"
import { type InfoEventCardProps, InfoEventCardSize } from "./types"

export const InfoEventCard: FC<InfoEventCardProps> = (props) => {
    const { size = InfoEventCardSize.S, cardText, number } = props
    return (
        <StyledInfoEventCard $size={size}>
            <StyledContent>
                <StyledNumber>{number}</StyledNumber>
                <StyledText>{cardText}</StyledText>
            </StyledContent>
            <StyledBackgroundPicture />
        </StyledInfoEventCard>
    )
}
