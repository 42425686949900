import styled from "styled-components"
import { FormFieldState } from "shared/hook/useFormField"

const StyledTextAreaParent = styled.div`
    display: flex;
    height: var(--assets-input-textarea-sizes-height);
    width: 100%;
    min-height: var(--assets-input-textarea-sizes-height);
    padding: var(--assets-input-textarea-sizes-padding-top) 0px var(--assets-input-textarea-sizes-padding-bottom) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--assets-input-textarea-sizes-gap);
    align-self: stretch;
`

const StyledTextArea = styled.textarea`
    color: var(--assets-input-colors-text);
    width: 100%;
    row-span: 10;
    resize: none;
    height: 100%;
    font-size: 14px;
    line-height: 18px;
    &::placeholder {
        color: var(--assets-input-colors-placeholder, rgba(0, 0, 0, 0.36));
    }
`

const StyledTextAreaCount = styled.div<{
    $state: FormFieldState
}>`
    color: var(
        ${({ $state }) =>
            $state === FormFieldState.INVALID
                ? "--assets-input-textarea-colors-counter-error"
                : "--assets-input-textarea-colors-counter"}
    );
    font-size: 12px;
`

const StyledTextAreaBottom = styled.div`
    display: flex;
    justify-content: space-between;
    height: var(--assets-input-textarea-sizes-bottom-height);
    align-items: center;
    gap: var(--assets-input-textarea-sizes-gap);
    flex-shrink: 0;
    align-self: stretch;
`

export { StyledTextAreaParent, StyledTextArea, StyledTextAreaCount, StyledTextAreaBottom }
