import { type FC } from "react"
import { BasicType, Typography } from "shared/ui"
import { DividerSvg } from "shared/assets/svg"
import { StyledBreak, StyledDivider, StyledMain, StyledOutputSchedule, StyledScheduleRow } from "./styles"
import { type ScheduleListProps } from "./types"

export const ScheduleList: FC<ScheduleListProps> = (props) => {
    const { scheduleData } = props
    return (
        <>
            {Object.entries(scheduleData).map(([key, value], i, array) => (
                <StyledOutputSchedule key={`${i}output`}>
                    <StyledScheduleRow>
                        <StyledMain>
                            <Typography.Basic typeUI={BasicType.SECOND}>{key}</Typography.Basic>
                            <div>{value.hours}</div>
                        </StyledMain>
                        {value.break && (
                            <StyledBreak>
                                <Typography.Basic typeUI={BasicType.ADDITIONAL}>Перерыв</Typography.Basic>
                                <Typography.Basic typeUI={BasicType.SECOND}>{value.break}</Typography.Basic>
                            </StyledBreak>
                        )}
                    </StyledScheduleRow>
                    <StyledDivider>
                        <DividerSvg strokeOpacity={0.06} />
                    </StyledDivider>
                </StyledOutputSchedule>
            ))}
        </>
    )
}
