import styled from "styled-components"
import { TagSize } from "./types"

const StyledTag = styled.div<{
    $size: TagSize
    $bgColor?: string
}>`
    display: inline-flex;
    width: fit-content;
    align-items: center;
    min-height: var(--assets-tag-sizes-${(props) => props.$size}-height);
    padding: 0px var(--assets-tag-sizes-${(props) => props.$size}-padding-hor);
    gap: var(--assets-tag-sizes-${(props) => props.$size}-gap);
    border-radius: var(--assets-tag-sizes-${(props) => props.$size}-radius);
    background-color: ${({ $bgColor }) => ($bgColor ? $bgColor : "var(--assets-tag-colors-fill, #3E3AED)")};
    font-size: ${({ $size }) => ($size === TagSize.M ? "14px" : "12px")};
    flex-shrink: 0;
    color: var(--assets-tag-colors-content, #fff);
    white-space: pre-wrap;
`

export { StyledTag }
