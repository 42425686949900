import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledIdeaCardsGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    gap: 8px;
    margin-top: 32px;
    @media all and (min-width: ${getMediaVariable("tablet")}) {
        grid-template-columns: repeat(2, 1fr);
    }
`

export { StyledIdeaCardsGrid }
