import styled from "styled-components"

const OptionStyle = {
    background: (multiple: boolean) => {
        if (multiple) {
            return "var(--root-colors-main-blue-ghost)"
        }
        return "var(--assets-select-colors-fill-active)"
    },
    color: (multiple: boolean, selected: boolean = true) => {
        if (selected) {
            return multiple ? "var(--root-colors-main-blue-basic)" : "var(--assets-select-colors-content-active)"
        }
        return "var(--assets-select-colors-content)"
    },
    fill: (multiple: boolean, selected: boolean = true) => {
        if (selected) {
            return multiple ? "var(--root-colors-main-blue-basic)" : "white"
        }
        return "black"
    },
}
const StyledSelectOption = styled.li<{
    $isSelected: boolean
    $isMultiple: boolean
}>`
    display: flex;
    min-height: var(--assets-select-sizes-height);
    padding: var(--assets-select-sizes-padding-vert) var(--assets-select-sizes-padding-hor);
    align-items: center;
    gap: var(--assets-input-phone-country-selector-gap);
    align-self: stretch;
    cursor: pointer;
    color: ${({ $isSelected, $isMultiple }) => OptionStyle.color($isMultiple, $isSelected)};
    background: ${({ $isSelected, $isMultiple }) => $isSelected && OptionStyle.background($isMultiple)};
    & > svg path {
        fill: ${({ $isSelected, $isMultiple }) => OptionStyle.fill($isMultiple, $isSelected)};
    }
    &:hover {
        background: ${({ $isMultiple }) => OptionStyle.background($isMultiple)};
        color: ${({ $isMultiple }) => OptionStyle.color($isMultiple)};
        & > svg path {
            fill: ${({ $isMultiple }) => OptionStyle.fill($isMultiple)};
        }
    }
`
const StyledSelectOptionContent = styled.div`
    font-size: 14px;
    line-height: 130%;
`

export { StyledSelectOption, StyledSelectOptionContent }
