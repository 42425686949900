export enum FillSvg {
    WHITE = "white",
    BLACK = "#292929",
    CURRENT_COLOR = "CurrentColor",
}

export enum SizeSvg {
    L = "32",
    M = "24",
    S = "20",
    XS = "16",
    XXS = "10",
}

export enum RuleSvg {
    NONZERO = "nonzero",
    EVENODD = "evenodd",
}
