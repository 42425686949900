import React, { type FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { Navigate, useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import { ref, string } from "yup"
import { useLazyPostForgotPasswordQuery } from "features/user"
import {
    Button,
    ButtonType,
    FormLayout,
    InputPassword,
    Modal,
    ModalDefault,
    StyledError,
    useMediaSizeButton,
} from "shared/ui"
import { useModal } from "shared/hook"
import { SectionGapSize, StyledSection } from "shared/styles"
import { passwordValidation } from "shared/lib"

const schemaForgotPasswordForm = yup.object().shape({
    password: passwordValidation.required("Поле обязательно для заполнения"),
    passwordCheck: string()
        .required("Поле обязательно для заполнения")
        .test("is-equal", "Пароли не совпадают", function (value) {
            const password = this.resolve(ref("password")) as string
            return password === value
        }),
})

interface IForgotPasswordForm {
    password: string
    passwordCheck: string
}

export const ForgotPasswordForm: FC = () => {
    const methods = useForm<IForgotPasswordForm>({
        defaultValues: {
            passwordCheck: "",
            password: "",
        },
        mode: "all",
        criteriaMode: "all",
        resolver: yupResolver<IForgotPasswordForm>(schemaForgotPasswordForm),
    })

    const navigate = useNavigate()
    const { fromMtoL, fromStoM } = useMediaSizeButton()
    const modal = useModal()
    const payload = new URLSearchParams(window.location.search)
    const token = payload.get("token")
    const [fetch, { error, isError, isSuccess }] = useLazyPostForgotPasswordQuery()
    useEffect(() => {
        if (error && isError) {
            const err = "error" in error ? JSON.parse(JSON.stringify(error?.error?.response?.data)) : null
            const mes =
                err && err.statusCode === 403 ? "Вы не можете повторно воспользоваться ссылкой для сброса пароля" : ""
            methods.setError("root", { type: "error-token", message: mes })
            methods.setError("password", { type: "error-token", message: " " })
            methods.setError("passwordCheck", { type: "error-token", message: " " })
        }
        if (isSuccess) {
            modal.toggleActivity()
        }
    }, [error, isSuccess, isError])

    const handlerSubmit = methods.handleSubmit((data) => {
        if (token) {
            fetch({ password: data.password, token })
        }
    })
    if (!token) {
        return <Navigate to={"/auth"} />
    }
    return (
        <>
            <FormProvider {...methods}>
                <FormLayout
                    headerText={"Изменение пароля"}
                    main={() => (
                        <>
                            <StyledSection>
                                <InputPassword
                                    validWindow={true}
                                    labelText={"Пароль"}
                                    placeholder={"Пароль"}
                                    name={"password"}
                                />
                                <StyledSection $gapSize={SectionGapSize.S}>
                                    <InputPassword
                                        labelText={"Подтверждение пароля"}
                                        placeholder={"Пароль"}
                                        name={"passwordCheck"}
                                    />
                                    <StyledError>{methods.formState.errors.root?.message}</StyledError>
                                </StyledSection>
                            </StyledSection>
                        </>
                    )}
                    footer={() => (
                        <>
                            <Button
                                onClick={handlerSubmit}
                                size={fromMtoL}
                                typeUI={methods.formState.isValid ? ButtonType.PRIMARY : ButtonType.DISABLED}
                                svgColor={"#0077FF"}
                            >
                                Изменить пароль
                            </Button>
                        </>
                    )}
                />
            </FormProvider>
            <Modal isActive={modal.isActive} toggleActivity={() => {}}>
                <ModalDefault
                    backButton={false}
                    footerBtn={() => (
                        <Button
                            size={fromMtoL}
                            onClick={() => {
                                navigate("/auth")
                            }}
                        >
                            Хорошо
                        </Button>
                    )}
                    title={"Изменение пароля"}
                    body={"Вы успешно сменили пароль"}
                />
            </Modal>
        </>
    )
}
