import { Link, useNavigate } from "react-router-dom"
import { type FC } from "react"
import { useDispatch } from "react-redux"
import { Avatar, AvatarSize, ProfileRole, useGetProfileDataQuery } from "features/viewer"
import { mainApi } from "features/api"
import { Button, ButtonType } from "shared/ui"
import { LogoutSvg } from "shared/assets/svg"
import { StyledUserControl } from "./styles"
import { type PersonalPersonalRedirectControlProps } from "./types"

export const PersonalRedirectControl: FC<PersonalPersonalRedirectControlProps> = (props) => {
    const { updateToken } = props
    const { data } = useGetProfileDataQuery("")
    const dispatch = useDispatch()

    const navigate = useNavigate()
    return (
        <>
            <Avatar size={AvatarSize.S} visibleName={false} />
            <StyledUserControl>
                <div>{`${data?.lastname} ${data?.firstname}`}</div>
                <Link to={"/personal-account"}>Личный кабинет</Link>
            </StyledUserControl>
            <div style={{ justifySelf: "end" }}>
                <Button
                    typeUI={ButtonType.GHOST}
                    leftIcon={() => <LogoutSvg />}
                    onClick={() => {
                        navigate("/")
                        localStorage.removeItem("access_token")
                        localStorage.setItem("role", ProfileRole.PUBLIC)
                        dispatch(mainApi.util.resetApiState())
                        updateToken?.()
                    }}
                />
            </div>
        </>
    )
}
