import React, { type FC } from "react"
import { OpportunitySection } from "entities/user"
import { Row } from "shared/ui"

interface OpportunityContactsProps {
    websiteUrl?: string
    vkSocialUrl?: string
}

export const OpportunityContacts: FC<OpportunityContactsProps> = ({ websiteUrl, vkSocialUrl }) => {
    return (
        <Row>
            <OpportunitySection.LinkSection websiteUrl={websiteUrl} vkSocialUrl={vkSocialUrl} />
        </Row>
    )
}
