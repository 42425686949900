import React, { type FC } from "react"
import { useForm } from "react-hook-form"
import { EditProfileChoice } from "widgets/viewer/EditProfile/ui"
import { HeadingType, Typography } from "shared/ui"
import { type EditProfileProps } from "./types"
import { StyledEditProfile, StyledEditProfileTop } from "./styles"

export const EditProfile: FC<EditProfileProps> = (props) => {
    const { children } = props
    const methods = useForm()
    return (
        <>
            <StyledEditProfileTop>
                <Typography.Heading typeUI={HeadingType.H1}>Редактирование данных</Typography.Heading>
            </StyledEditProfileTop>

            <StyledEditProfile>
                <EditProfileChoice />
                {children}
            </StyledEditProfile>
        </>
    )
}
