import styled from "styled-components"

const StyledProfileCard = styled.div`
    display: flex;
    width: 100%;
    padding: var(--assets-user-profile-card-sizes-padding, 8px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-user-profile-card-sizes-gap, 8px);

    border-radius: var(--assets-user-profile-card-sizes-radius, 24px);
    border: 1px solid var(--assets-user-profile-card-colors-stroke, rgba(0, 0, 0, 0.06));
`

const StyledTitleProfileCard = styled.div`
    display: flex;
    padding: 0 var(--assets-user-profile-card-sizes-padding-title, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-user-profile-card-sizes-gap-title, 4px);
    align-self: stretch;
`

const StyledPhoneText = styled.div<{
    colorBlue?: boolean
}>``

export { StyledProfileCard, StyledTitleProfileCard, StyledPhoneText }
