import React, { type FC } from "react"
import { CardStatusType, Informer, InformerType } from "shared/ui"
import { type OrgStatusInformerProps } from "./types"

export const OrgStatusInformer: FC<OrgStatusInformerProps> = (props) => {
    const { type } = props
    const StatusCondition = {
        accept: type === CardStatusType.ACCEPTED,
        declined: type === CardStatusType.DECLINED,
        waiting: type === CardStatusType.WAITING,
    }
    return (
        <>
            {StatusCondition.waiting && (
                <Informer type={InformerType.WARNING} title={"Организация проверяется"}>
                    Прежде, чем предоставить вам доступ к функционалу “Оператора возможностей”, мы должны убедиться в
                    том, что вы являетесь ее официально уполномоченным представителем.
                </Informer>
            )}

            {StatusCondition.accept && (
                <Informer type={InformerType.SUCCESS} title={"Организация подтверждена"}>
                    Мы проверили представленную информацию и предоставили вам доступ к функционалу “Оператора
                    возможностей”
                </Informer>
            )}
            {StatusCondition.declined && (
                <Informer type={InformerType.ERROR} title={"Проверка не пройдена"}>
                    Прежде, чем предоставить вам доступ к функционалу “Оператора возможностей”, мы должны убедиться в
                    том, что вы являетесь ее официально уполномоченным представителем.
                </Informer>
            )}
        </>
    )
}
