import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledGiftCard = styled.div`
    display: flex;
    width: 100%;
    min-width: 326px;
    padding: var(--assets-card-custom-gift-s-padding, 8px) var(--assets-card-custom-gift-s-padding-right, 24px)
        var(--assets-card-custom-gift-s-padding, 8px) var(--assets-card-custom-gift-s-padding, 8px);
    align-items: flex-start;
    gap: var(--assets-card-custom-gift-s-gap, 16px);
    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
`

const StyledGiftCardContent = styled.div`
    display: flex;
    padding: var(--assets-card-custom-gift-s-padding-content, 4px) 0px;
    flex-direction: column;
    align-items: center;
    gap: var(--assets-card-custom-gift-s-gap-content, 8px);
    flex: 1 0 0;
    align-self: stretch;
`

const StyledTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--assets-card-custom-gift-s-gap-inner, 4px);
    flex: 1;
    align-self: stretch;
    border-bottom: 1px solid var(--layout-divider, rgba(0, 0, 0, 0.06));
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    @media (min-width: ${getMediaVariable("tablet")}) {
        font-size: 20px !important;
    }
`
const StyledImagePresent = styled.div`
    display: flex;
    width: var(--assets-card-custom-gift-s-pic-size, 128px);
    height: var(--assets-card-custom-gift-s-pic-size, 128px);
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border-radius: var(--assets-card-custom-gift-s-pic-radius, 16px);
    overflow: hidden;
    background: #fff;
    & > img {
        width: 100%;
        object-fit: cover;
    }
`
const StyledBottom = styled.div`
    display: flex;
    align-items: flex-end;
    gap: 16px;
    width: 100%;
    @media (max-width: ${getMediaVariable("laptop")}) {
        flex: 1;
    }
`

export { StyledGiftCard, StyledTop, StyledGiftCardContent, StyledBottom, StyledImagePresent }
