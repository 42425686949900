import React, { type FC } from "react"
import { BasicType, Card } from "shared/ui"
import { Dash } from "shared/assets/svg"
import { GoalTag } from "../GoalTag"
import {
    StyledGoalCard,
    StyledProjectName,
    StyledTaskCardDate,
    StyledTaskCardFooter,
    StyledDate,
    StyledDash,
    StyledTitle,
} from "./styles"
import { type GoalCardProps } from "./types"

export const GoalCard: FC<GoalCardProps> = (props) => {
    const { statusType, onModalClick, title, startTime, endTime, projectName } = props
    return (
        <Card withHover onClick={onModalClick}>
            <StyledGoalCard>
                <GoalTag typeCard={statusType} />
                <StyledTitle>{title}</StyledTitle>
                <StyledTaskCardFooter>
                    <StyledProjectName>{projectName}</StyledProjectName>
                    <StyledTaskCardDate>
                        <StyledDate typeUI={BasicType.ADDITIONAL}>{startTime}</StyledDate>
                        <StyledDash>
                            <Dash />
                        </StyledDash>
                        <StyledDate typeUI={BasicType.ADDITIONAL}>{endTime}</StyledDate>
                    </StyledTaskCardDate>
                </StyledTaskCardFooter>
            </StyledGoalCard>
        </Card>
    )
}
