import React, { type FC, type SyntheticEvent, useEffect, useRef } from "react"
import { useFormContext } from "react-hook-form"
import { type SelectData } from "shared/ui"
import { Option } from "shared/ui"
import { StyledSelectList } from "./styles"
import { type SelectListProps } from "./types"

export const SelectList: FC<SelectListProps> = (props) => {
    const { isOpen, data, multiple, name, mapData, setSelected, setSelectData, handleClickOpenItem, ...others } = props
    const { getValues, setValue } = useFormContext()
    const ListRef = useRef<HTMLUListElement | null>(null)
    useEffect(() => {
        if (isOpen && ListRef.current) {
            ListRef.current?.scrollIntoView({ block: "end", behavior: "smooth" })
        }
    }, [isOpen])
    const isSelected = (id: number | string) => {
        return multiple ? Boolean(mapData?.has(id)) : getValues(name) === id
    }
    const handleClickOption = (item: SelectData, event: SyntheticEvent) => {
        if (multiple && mapData && setSelectData) {
            mapData.has(item.id) ? mapData.delete(item.id) : mapData.set(item.id, item.name)
            setSelectData(mapData)
            setValue(name, Array.from(mapData.keys()), {
                shouldValidate: true,
                shouldDirty: true,
            })
            setSelected("Выбрано " + getValues(name).length)
            event.stopPropagation()
            return
        }
        setValue(name, item.id, { shouldValidate: true, shouldDirty: true })
        setSelected(item.name)
    }
    return (
        <StyledSelectList $isOpen={isOpen} {...others} ref={ListRef}>
            {data &&
                data.map((item: SelectData, index) => {
                    return (
                        <Option
                            key={index}
                            tabIndex={0}
                            text={item.name}
                            selected={isSelected(item.id)}
                            multiple={multiple}
                            icon={() => item.icon}
                            onClick={(event) => {
                                handleClickOption(item, event)
                                handleClickOpenItem?.()
                            }}
                        />
                    )
                })}
        </StyledSelectList>
    )
}
