import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { type IRestorePasswordForm } from "./types"

export const restorePasswordApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        postRestorePassword: builder.query<IRestorePasswordForm, unknown>({
            query: (body): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL.USER}/auth/forgot-password`,
                method: "POST",
                body,
            }),
        }),
    }),
})

export const { useLazyPostRestorePasswordQuery } = restorePasswordApi
