type MediaVariablesType = "mobile-s" | "mobile-m" | "mobile-l" | "tablet" | "laptop" | "laptop-l" | "desktop"

const mediaVariables: Record<MediaVariablesType, number> = {
    "mobile-s": 320,
    "mobile-m": 375,
    "mobile-l": 425,
    tablet: 768,
    laptop: 1024,
    "laptop-l": 1440,
    desktop: 1920,
}

export const getMediaVariable = (key: MediaVariablesType) => {
    if (mediaVariables[key] === undefined) {
        throw new Error(`Media variable ${key} not found`)
    }

    return `${mediaVariables[key]}px`
}
