import { type FC, useEffect, useState } from "react"
import { StyledProgressBar, StyledProgressThumb } from "./styles"
import { type ProgressBarProps } from "./types"

export const ProgressBar: FC<ProgressBarProps> = (props) => {
    const { percentThumb } = props
    const [oneNum, totalNum] = percentThumb

    const [width, setWidth] = useState<number>((oneNum / totalNum) * 100)

    useEffect(() => {
        if (width >= 100) {
            setWidth(100)
        }
    }, [width])

    return (
        <StyledProgressBar>
            <StyledProgressThumb $width={width}></StyledProgressThumb>
        </StyledProgressBar>
    )
}
