import styled, { css } from "styled-components"
import { ToggleType } from "./types"

const StyledToggle = styled.div<{
    $state: boolean
    $type: ToggleType
}>`
    display: flex;
    align-items: center;
    ${(props) => {
        switch (props.$type) {
            case ToggleType.CHECKBOX:
                return css`
                    width: var(--assets-checkbox-sizes-width);
                    height: var(--assets-checkbox-sizes-width);
                    border-radius: var(--assets-checkbox-sizes-radius);
                    border: ${!props.$state && "1px solid var(--assets-checkbox-colors-stroke)"};
                    background: ${props.$state ? "var(--assets-checkbox-checked-fill)" : "none"};
                    flex-direction: column;
                    justify-content: center;
                    flex-shrink: 0;
                `
            case ToggleType.SWITCH:
                return css`
                    width: var(--assets-switch-sizes-width);
                    height: var(--assets-switch-sizes-height);
                    padding: var(--assets-switch-sizes-padding);
                    border-radius: var(--assets-switch-sizes-radius);
                    border: ${!props.$state && "1px solid var(--assets-switch-colors-stroke)"};
                    background: ${props.$state ? "var(--assets-switch-checked-fill)" : "none"};
                    flex-shrink: 0;
                    transition: ease-in-out 0.2s;
                    &:after {
                        content: "";
                        width: var(--assets-switch-sizes-grip);
                        height: var(--assets-switch-sizes-grip);
                        border-radius: var(--assets-switch-sizes-radius);
                        background: var(
                            ${props.$state ? "--assets-switch-checked-grip" : "--assets-switch-colors-grip"}
                        );
                        transition: ease-in-out 0.2s;
                        transform: translateX(${props.$state ? "100%" : "0%"});
                        flex-shrink: 0;
                    }
                `
        }
    }}

    &:hover {
        background: ${(props) =>
            props.$state
                ? "var(--assets-checkbox-checked-fill-hover)"
                : "var(--assets-checkbox-colors-fill-hover, rgba(0, 0, 0, 0.04))"};
        cursor: pointer;
    }
`

const StyledInputToggle = styled.div`
    display: none;
`

export { StyledToggle, StyledInputToggle }
