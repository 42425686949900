import { type FC } from "react"
import { useParams } from "react-router-dom"
import { useGetNewDataQuery } from "features/user"
import { NewsCard, OpportunitySection } from "entities/user"
import { BasicType, HeadingType, Typography, WrapperColumn } from "shared/ui"
import { StyledPatternPageBackground, StyledSection } from "shared/styles"
import { useResize } from "shared/hook"
import { transformUrlImages } from "shared/lib"
import { getDate } from "../../lib"
import { StyledHeading, StyledNewsMore, StyledNewText, StyledNextPrev } from "./styles"

export const New: FC = () => {
    const {
        mediaScreenSize: { width, isTablet, isLaptopSm },
    } = useResize()
    const { id = "0" } = useParams()
    const { data } = useGetNewDataQuery(parseInt(id))
    if (!data) {
        return null
    }
    const media = width < isLaptopSm
    const mediaTitle = media ? data?.title : undefined
    return (
        <>
            <StyledPatternPageBackground $color={"news"} />
            <WrapperColumn.Wrapper isMobile={media} title={mediaTitle}>
                <WrapperColumn.Item maxWidth={350}>
                    {media || (
                        <StyledHeading>
                            <Typography.Heading typeUI={HeadingType.H1}>{data?.title}</Typography.Heading>
                        </StyledHeading>
                    )}
                </WrapperColumn.Item>
                <WrapperColumn.Item maxWidth={675}>
                    <OpportunitySection.PhotoSection
                        isSliderMode={width < isTablet}
                        videoUrl={data?.externalMedia}
                        images={data?.images || []}
                    />
                    <StyledNewText dangerouslySetInnerHTML={{ __html: data?.text || "<div></div>" }} />
                    <StyledNewsMore>
                        {data?.previous && (
                            <StyledSection>
                                <StyledNextPrev>
                                    <Typography.Basic typeUI={BasicType.SECOND}>Предыдущая новость</Typography.Basic>
                                </StyledNextPrev>
                                <NewsCard
                                    id={data?.previous?.id}
                                    imgSrc={transformUrlImages(data?.previous?.images?.[0]?.url ?? "")}
                                    date={getDate("previous", data)?.date ?? ""}
                                    time={getDate("previous", data)?.time ?? ""}
                                    title={data?.previous?.title ?? ""}
                                    body={data?.previous?.previewText ?? ""}
                                />
                            </StyledSection>
                        )}
                        {data?.next && (
                            <StyledSection>
                                <StyledNextPrev>
                                    <Typography.Basic typeUI={BasicType.SECOND}>Следующая новость</Typography.Basic>
                                </StyledNextPrev>
                                <NewsCard
                                    id={data?.next?.id}
                                    imgSrc={transformUrlImages(data?.next?.images?.[0]?.url ?? "")}
                                    date={getDate("next", data)?.date ?? ""}
                                    time={getDate("next", data)?.time ?? ""}
                                    title={data?.next?.title ?? ""}
                                    body={data?.next?.previewText ?? ""}
                                />
                            </StyledSection>
                        )}
                    </StyledNewsMore>
                </WrapperColumn.Item>
            </WrapperColumn.Wrapper>
        </>
    )
}
