import { type FC } from "react"
import { useResize } from "shared/hook"
import { type OpportunityPageLayoutProps } from "./types"
import { StyledContent, StyledContainer, StyledFooterButton } from "./styles"

export const OpportunityPageLayout: FC<OpportunityPageLayoutProps> = (props) => {
    const { children, footerButton } = props
    const {
        mediaScreenSize: { width, isLaptopSm },
    } = useResize()
    return (
        <StyledContainer>
            <StyledContent>{children}</StyledContent>
            {width < isLaptopSm && <StyledFooterButton>{footerButton?.()}</StyledFooterButton>}
        </StyledContainer>
    )
}
