import React, { type FC } from "react"
import { IdeaFormType, type Ideas } from "features/viewer"
import { ProfileData } from "entities/viewer"
import { CardStatus, CardType, Group, GroupDirection, HeadingType, Typography } from "shared/ui"
import { CalendarSvg, GeoSvg } from "shared/assets/svg"
import { dateConverter } from "shared/api"
import { conditions } from "shared/lib"
import { useResize } from "shared/hook"

export const ShowIdeaCard: FC<Ideas> = (props) => {
    const { status, title, locality, ideaType, createdAt } = props
    const {
        mediaScreenSize: { width, isLaptopSm },
    } = useResize()
    const media = width <= isLaptopSm
    const IdeaTypeCondition = (type: IdeaFormType) => {
        return conditions<IdeaFormType>(type, [
            [IdeaFormType.TEAM, "Командная"],
            [IdeaFormType.INDIVIDUAL, "Индивидуальная"],
        ])
    }

    return (
        <>
            <Group>
                <CardStatus statusType={status} cardType={CardType.APPLICATION} />
                <Typography.Heading typeUI={HeadingType.H1}>{title}</Typography.Heading>
            </Group>

            <Group>
                <ProfileData icon={(size) => <GeoSvg size={size} />} caption={"Территория"}>
                    {locality}
                </ProfileData>
                <Group direction={media ? GroupDirection.COLUMN : GroupDirection.ROW}>
                    <ProfileData icon={(size) => <CalendarSvg size={size} />} caption={"Дата подачи"}>
                        {dateConverter(createdAt).ddd_mmm_yyy(".")}
                    </ProfileData>

                    <ProfileData icon={(size) => <CalendarSvg size={size} />} caption={"Тип"}>
                        {IdeaTypeCondition(ideaType)}
                    </ProfileData>
                </Group>
            </Group>
        </>
    )
}
