import { type FC, type PropsWithChildren } from "react"
import { ContactsHeader, ContactsSection } from "widgets/user"

export const ContactsPage: FC<PropsWithChildren> = () => {
    return (
        <>
            <ContactsHeader />
            <ContactsSection />
        </>
    )
}
