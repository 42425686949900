import React, { type FC } from "react"
import { useGetProfileDataQuery } from "features/viewer"
import { HeadingType, Typography } from "shared/ui"
import { getFirstLetters } from "./lib/getFitstLetters"
import { type AvatarProps, AvatarSize } from "./types"
import { StyledAvatar, StyledAvatarParent } from "./styles"

export const Avatar: FC<AvatarProps> = (props) => {
    const { size = AvatarSize.L, visibleName = true, ...other } = props
    const { data } = useGetProfileDataQuery("")
    const name = data ? `${data.lastname} ${data.firstname} ${data?.midname ?? ""}` : "TA tf"
    const shortName = data ? `${data.lastname} ${data.firstname}` : "TA tf"
    const avatar = data?.avatar?.url ?? ""
    return (
        <StyledAvatarParent>
            <StyledAvatar $size={size}>
                {avatar ? <img src={avatar} alt={"avatar"} {...other} /> : <span> {getFirstLetters(shortName)} </span>}
            </StyledAvatar>
            {visibleName && <Typography.Heading typeUI={HeadingType.H2}>{name}</Typography.Heading>}
        </StyledAvatarParent>
    )
}
