import { type FC } from "react"
import { Card } from "shared/ui"
import { LinkSvg, SizeSvg } from "shared/assets/svg"
import { EventCardSize, type EventCardProps } from "./types"
import { StyledEventCardContainer, StyledEventCardHeader, StyledEventCardText, StyledEventCardTime } from "./styles"

export const EventCard: FC<EventCardProps> = (props) => {
    const { link, text, startDate, endDate, size = EventCardSize.M, withLink = true, ...rest } = props

    return (
        <Card withHover={false} {...rest}>
            <StyledEventCardContainer $size={size}>
                <StyledEventCardHeader>
                    <StyledEventCardTime $size={size}>
                        {startDate} - {endDate}
                    </StyledEventCardTime>
                    {withLink && <LinkSvg size={SizeSvg.XS} />}
                </StyledEventCardHeader>
                <StyledEventCardText $size={size}>{text}</StyledEventCardText>
            </StyledEventCardContainer>
        </Card>
    )
}
