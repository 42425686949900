import styled, { css } from "styled-components"
import { getMediaVariable } from "shared/styles"
import { type ModalPosition, ModalSize } from "./types"

const position = (position: ModalPosition) => {
    switch (position) {
        case "center":
            return css`
                transform: translate(-50%, -50%);
                left: 50%;
            `
        case "left":
            return css`
                transform: translate(0%, -50%);
                left: 32px;
            `
        case "right":
            return css`
                transform: translate(0%, -50%);
                right: 32px;
            `
    }
}
const StyledModalContainer = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 99;

    & * {
        scrollbar-width: none;
    }
`

const StyledModalBackground = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: var(--root-colors-main-black-paranja, rgba(0, 0, 0, 0.64));
    overflow: hidden;
`

const StyledModal = styled.div<{
    $size: ModalSize
    $position: ModalPosition
    $bigHeight: boolean
}>`
    display: block;
    //flex-direction: column;
    //align-items: stretch;
    //gap: var(--assets-modals-informer-gap, 16px);
    border-radius: var(--assets-modals-informer-radius, 24px);
    background: #fff;
    position: absolute;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 64px);
    top: 50%;
    ${({ $position }) => position($position)};
    @media (max-width: ${getMediaVariable("laptop")}) {
        transform: translate(-50%, 0%);
        left: 50%;
        top: unset;
        bottom: 64px;
    }
    ${({ $size, $bigHeight }) => {
        switch ($size) {
            case ModalSize.L: {
                const height = $bigHeight ? "100vh" : ""
                return css`
                    height: ${height};
                    min-width: 420px;
                    max-width: 464px;
                `
            }
            case ModalSize.S: {
                return css`
                    max-height: calc(100vh - 128px);
                    height: 100vh;
                    width: 326px;
                `
            }
            default: {
                return
            }
        }
    }}
    &::-webkit-scrollbar {
        display: none;
    }
    & > * {
        padding: var(--assets-modals-informer-padding, 24px);
    }
`

export { StyledModal, StyledModalBackground, StyledModalContainer }
