import React, { type FC } from "react"
import { Link } from "react-router-dom"
import { StyledProfileSection } from "widgets/viewer/PersonalAccount/styles"
import { type IdeaProps } from "widgets/viewer/PersonalAccount/Idea/types"
import { StyledIdeaCardsGrid } from "widgets/viewer/PersonalAccount/Idea/styles"
import { useGetIdeasQuery } from "features/viewer"
import { IdeaCard } from "entities/viewer"
import {
    Button,
    ButtonType,
    CardType,
    Group,
    Informer,
    InformerType,
    useMediaGroup,
    useMediaSizeButton,
} from "shared/ui"
import { PlusSvg } from "shared/assets/svg"

export const Idea: FC<IdeaProps> = (props) => {
    const { type = false } = props
    const { data: IdeasList, isLoading } = useGetIdeasQuery("")
    const mediaButton = useMediaSizeButton()
    const mediaGroup = useMediaGroup()
    const isIdeas = IdeasList && Boolean(IdeasList.length)
    if (!isLoading) {
        return (
            <StyledProfileSection>
                <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowReversToColumn}>
                    <Link to={"/idea-form"}>
                        <Button size={mediaButton.fromMtoL} typeUI={isIdeas ? ButtonType.GHOST : ButtonType.PRIMARY}>
                            <PlusSvg />
                            Предложить идею
                        </Button>
                    </Link>
                    {isIdeas || (
                        <Informer type={InformerType.BASIC} title={"Предложение идей"}>
                            Чтобы иметь доступ ко всем возможностям сайта, пожалуйста, заполните небольшую анкету о
                            себе.
                        </Informer>
                    )}
                </Group>
                <StyledIdeaCardsGrid>
                    {IdeasList &&
                        IdeasList.map((item, index) => (
                            <IdeaCard
                                key={index}
                                statusType={item.status}
                                cardType={CardType.APPLICATION}
                                title={item.title}
                                date={item.createdAt}
                                location={item.locality}
                                to={"/idea/" + item.id}
                            >
                                Карточка идеи
                            </IdeaCard>
                        ))}
                </StyledIdeaCardsGrid>
            </StyledProfileSection>
        )
    }
}
