import { styled } from "styled-components"

export const StyledLinkSection = styled.div`
    display: flex;
    padding: var(--assets-input-links-sizes-padding, 12px);
    flex-direction: column;
    gap: var(--layout-mobile-section-gap, 12px);

    border-radius: var(--assets-input-links-sizes-radius, 16px);
    border: 1px solid var(--assets-input-links-colors-stroke, rgba(0, 0, 0, 0.06));
`
