import { styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledContactsList = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--layout-mobile-section-gap, 12px);
    min-height: 450px;
    @media all and (min-width: ${getMediaVariable("desktop")}) {
        min-height: unset;
    }
`
export const StyledContactsCardsContainer = styled.div`
    gap: var(--layout-mobile-section-gap, 12px);

    display: flex;
    flex-wrap: wrap;
`

export const StyledTitle = styled.div`
    z-index: 2;
`
