import styled from "styled-components"
import { SizeSvg } from "shared/assets/svg"
import { RequirementStatus } from "./types"

const StyledRequirement = styled.div<{
    $status: RequirementStatus
    $size: SizeSvg
}>`
    display: flex;
    align-items: center;
    gap: var(--assets-status-sizes-gap);
    color: var(
        --assets-status-colors-${(props) => props.$status}${(props) =>
                props.$status === RequirementStatus.EMPTY && "-text"}
    );
    line-height: 18px;
    font-size: ${(props) => (props.$size === SizeSvg.M ? 14 : 12)}px;
`

export { StyledRequirement }
