import { type FC, Suspense } from "react"
import { OpportunityCard } from "entities/user"
import { CardStatusType, ScrollGroup } from "shared/ui"
import { useResize } from "shared/hook"
import { type OpportunityListProps } from "./types"
import { StyledOpportunityList, StyledRowOpp } from "./styles"

export const OpportunityList: FC<OpportunityListProps> = (props) => {
    const { listType, cards } = props
    const {
        mediaScreenSize: { isLaptopL, width },
    } = useResize()
    return (
        <Suspense>
            <StyledOpportunityList>
                <ScrollGroup isScrollMode={width < isLaptopL}>
                    <StyledRowOpp className={props.className}>
                        {cards.map((el) => {
                            return (
                                <OpportunityCard
                                    operator={true}
                                    id={el.id}
                                    statusType={el.status ? CardStatusType[el.status] : undefined}
                                    title={el.name}
                                    key={el.id}
                                    typeCard={listType}
                                    imgSrc={el.images?.[0]?.url}
                                >
                                    {el.description}
                                </OpportunityCard>
                            )
                        })}
                    </StyledRowOpp>
                </ScrollGroup>
            </StyledOpportunityList>
        </Suspense>
    )
}
