import { CardStatusType, CardType } from "../types"

export const CardStatusText: Record<CardStatusType, Record<CardType, string>> = {
    [CardStatusType.WAITING]: {
        [CardType.APPLICATION]: "На рассмотрении",
        [CardType.STATUS]: "Статус проверяется",
        [CardType.OPPORTUNITY_STATUS]: "На проверке",
    },
    [CardStatusType.ACCEPTED]: {
        [CardType.APPLICATION]: "Одобрена",
        [CardType.STATUS]: "Статус подтвержден",
        [CardType.OPPORTUNITY_STATUS]: "Опубликовано",
    },
    [CardStatusType.DECLINED]: {
        [CardType.APPLICATION]: "Отклонена",
        [CardType.STATUS]: "Проверка не пройдена",
        [CardType.OPPORTUNITY_STATUS]: "Отклонено",
    },
    [CardStatusType.ARCHIVE]: {
        [CardType.APPLICATION]: "",
        [CardType.STATUS]: "",
        [CardType.OPPORTUNITY_STATUS]: "В архиве",
    },
    [CardStatusType.REGISTRATION]: {
        [CardType.APPLICATION]: "",
        [CardType.STATUS]: "",
        [CardType.OPPORTUNITY_STATUS]: "Открыта регистрация",
    },
    [CardStatusType.PUBLIC]: {
        [CardType.APPLICATION]: "",
        [CardType.STATUS]: "",
        [CardType.OPPORTUNITY_STATUS]: "Опубликовано",
    },
    [CardStatusType.NONE]: {
        [CardType.APPLICATION]: "",
        [CardType.STATUS]: "",
        [CardType.OPPORTUNITY_STATUS]: "",
    },
}
