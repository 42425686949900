import { type FC, type PropsWithChildren } from "react"
import { HeadingType, Typography, Wrapper } from "shared/ui"
import { StyledTitle } from "./styles"
import { DashboardHeaderChoice } from "./ui"

export const DashboardHeader: FC<PropsWithChildren> = (props) => {
    const { children } = props

    return (
        <Wrapper width={100}>
            <StyledTitle>
                <Typography.Heading typeUI={HeadingType.H1}>Дашборд</Typography.Heading>
            </StyledTitle>
            <DashboardHeaderChoice />
            {children}
        </Wrapper>
    )
}
