import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type RequirementProps, RequirementStatus } from "./types"
import { StyledRequirement } from "./styles"

export const Requirement: FC<RequirementProps> = (props) => {
    const { status = RequirementStatus.EMPTY, size = SizeSvg.L, children } = props

    if (status === RequirementStatus.SUCCESS) {
        return (
            <StyledRequirement $size={size} $status={status}>
                <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                        fill="#5FDD4A"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.2357 8.23569L9.99999 16.4714L5.09763 11.569L5.56903 11.0976L9.99999 15.5286L17.7643 7.76428L18.2357 8.23569Z"
                        fill="white"
                    />
                </svg>
                {children}
            </StyledRequirement>
        )
    }

    if (status === RequirementStatus.ERROR) {
        return (
            <StyledRequirement $size={size} $status={status}>
                <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
                    <path
                        d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                        fill="#E21414"
                    />
                    <path
                        d="M12 12.4714L16.431 16.9023L16.9024 16.4309L12.4714 12L16.9024 7.569L16.431 7.0976L12 11.5286L7.56903 7.0976L7.09763 7.569L11.5286 12L7.09763 16.4309L7.56903 16.9023L12 12.4714Z"
                        fill="white"
                    />
                </svg>
                {children}
            </StyledRequirement>
        )
    }

    return (
        <StyledRequirement $size={size} $status={status}>
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
                <path
                    d="M0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12Z"
                    fill="#FAFAFA"
                />
            </svg>
            {children}
        </StyledRequirement>
    )
}
