import { NavLink } from "react-router-dom"
import { type FC } from "react"
import { formatNumber } from "shared/lib"
import { StyledMarker, StyledOption, StyledSelect } from "./styles"
import { type HeaderNavLinkProps } from "./types"

export const HeaderNavLink: FC<HeaderNavLinkProps> = (props) => {
    const { navList, onLinkClick } = props

    return (
        <>
            {navList.map(({ name, path, role }, index) => {
                if (!role || role === localStorage.getItem("role")) {
                    return (
                        <NavLink key={name} to={path} onClick={onLinkClick}>
                            {({ isActive }) => (
                                <StyledOption>
                                    <StyledMarker $isSelect={isActive}>{formatNumber(index)}</StyledMarker>
                                    <StyledSelect $isSelect={isActive}>{name}</StyledSelect>
                                </StyledOption>
                            )}
                        </NavLink>
                    )
                }
            })}
        </>
    )
}
