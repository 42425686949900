import React, { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, ModalDefault, useMediaSizeButton } from "shared/ui"

export const RestoreSentModal: FC = () => {
    const navigate = useNavigate()
    const { fromMtoL } = useMediaSizeButton()
    return (
        <ModalDefault
            backButton={false}
            footerBtn={() => (
                <Button
                    size={fromMtoL}
                    onClick={() => {
                        navigate("/auth")
                    }}
                >
                    Хорошо
                </Button>
            )}
            title={"Инструкции отправлены!"}
            body={
                "На указанный вами адрес электронной почты были отправлены инструкции по завершению восстановления пароля!"
            }
        />
    )
}
