import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type DashBoardSvgProps } from "./types"

export const DashBoardSvg: FC<DashBoardSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_1131_13581)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.5 6.5C2.5 4.01472 4.51472 2 7 2C9.48528 2 11.5 4.01472 11.5 6.5C11.5 8.98528 9.48528 11 7 11C4.51472 11 2.5 8.98528 2.5 6.5ZM7 3C5.067 3 3.5 4.567 3.5 6.5C3.5 8.433 5.067 10 7 10C8.933 10 10.5 8.433 10.5 6.5C10.5 4.567 8.933 3 7 3Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M13.5 3.5C13.5 2.67157 14.1716 2 15 2H21C21.8284 2 22.5 2.67157 22.5 3.5V20.5C22.5 21.3284 21.8284 22 21 22H15C14.1716 22 13.5 21.3284 13.5 20.5V3.5ZM15 3C14.7239 3 14.5 3.22386 14.5 3.5V20.5C14.5 20.7761 14.7239 21 15 21H21C21.2761 21 21.5 20.7761 21.5 20.5V3.5C21.5 3.22386 21.2761 3 21 3H15Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M4 13C3.17157 13 2.5 13.6716 2.5 14.5V20.5C2.5 21.3284 3.17157 22 4 22H10C10.8284 22 11.5 21.3284 11.5 20.5V14.5C11.5 13.6716 10.8284 13 10 13H4ZM3.5 14.5C3.5 14.2239 3.72386 14 4 14H10C10.2761 14 10.5 14.2239 10.5 14.5V20.5C10.5 20.7761 10.2761 21 10 21H4C3.72386 21 3.5 20.7761 3.5 20.5V14.5Z"
                    fill="white"
                />
            </g>
            <defs>
                <clipPath id="clip0_1131_13581">
                    <rect width="24" height="24" fill="white" transform="translate(0.5)" />
                </clipPath>
            </defs>
        </svg>
    )
}
