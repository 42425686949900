import { type FC } from "react"
import { BasicType, Card, HeadingType, Typography } from "shared/ui"
import { MarkerSvg } from "shared/assets/svg"
import { StyledLink, StyledNotificationCard, StyledTitle, StyledDescription, StyledMarker } from "./styles"
import { type NotificationCardProps } from "./types"

export const NotificationCard: FC<NotificationCardProps> = (props) => {
    const { id, read, message, title, handlerItemClick } = props
    return (
        <Card withHover>
            <StyledNotificationCard>
                <StyledTitle>
                    <div>
                        <Typography.Heading typeUI={HeadingType.H4}>
                            {!read && (
                                <StyledMarker>
                                    <MarkerSvg />
                                </StyledMarker>
                            )}
                            {title}
                        </Typography.Heading>
                    </div>
                </StyledTitle>
                <StyledDescription>
                    <Typography.Basic typeUI={BasicType.SECOND}>{message}</Typography.Basic>
                </StyledDescription>
                {!read && <StyledLink onClick={handlerItemClick(id)}>Просмотреть</StyledLink>}
            </StyledNotificationCard>
        </Card>
    )
}
