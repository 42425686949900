import React, { type FC } from "react"
import { Partners } from "widgets/viewer"
import { Wrapper } from "shared/ui"

export const PartnersPage: FC = () => {
    return (
        <>
            <Wrapper width={100}>
                <Partners />
            </Wrapper>
        </>
    )
}
