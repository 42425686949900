import React, { type FC } from "react"
import { OpportunityPageLayout } from "entities/user"
import { useResize } from "shared/hook"
import { WrapperColumn } from "shared/ui"
import { type OppContainerProps } from "./types"

export const OppContainer: FC<OppContainerProps> = (props) => {
    const { descriptionText, button, header, description, photo, regInformer, conditionText, contacts, conditions } =
        props
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return (
        <>
            {width < isTablet ? (
                <OpportunityPageLayout footerButton={button}>
                    {header()}
                    {photo()}
                    {regInformer()}
                    {descriptionText()}
                    {description()}
                    {conditionText()}
                    {conditions()}
                    {contacts()}
                </OpportunityPageLayout>
            ) : (
                <WrapperColumn.Wrapper>
                    <WrapperColumn.Item maxWidth={396}>
                        {header()}
                        {regInformer()}
                        {button()}
                        {description()}
                        {conditions()}
                        {contacts()}
                    </WrapperColumn.Item>
                    <WrapperColumn.Item maxWidth={640}>
                        {photo()}
                        {descriptionText()}
                        {conditionText()}
                    </WrapperColumn.Item>
                </WrapperColumn.Wrapper>
            )}
        </>
    )
}
