import styled from "styled-components"
import { type RowSize } from "./types"

const StyledRow = styled.div<{
    $height?: number
    $size: RowSize
}>`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-${({ $size }) => $size}-grid-gap);
    align-self: stretch;
    align-content: center;
    justify-content: center;
`

export { StyledRow }
