import React, { type FC } from "react"
import { GiftImage, GiftModal, Progress, useMediaGetImage } from "features/viewer"
import { Modal } from "shared/ui"
import { useModal } from "shared/hook"
import { type GetGiftProps, PointsState } from "./types"
import { getGiftConditions } from "./lib"

export const GetGiftButton: FC<GetGiftProps> = (props) => {
    const modal = useModal()
    const { presentState = PointsState.ENOUGH } = props

    const getComponents = getGiftConditions(presentState, modal.toggleActivity)
    const { fromStoL } = useMediaGetImage()

    return (
        <>
            {getComponents.button()}
            <Modal bigHeight position={"right"} {...modal}>
                <GiftModal backClick={modal.toggleActivity} imageComponent={() => <GiftImage size={fromStoL} />}>
                    <Progress />
                    {getComponents.modalBottom()}
                </GiftModal>
            </Modal>
        </>
    )
}
