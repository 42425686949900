import { type ErrorI } from "shared/api"

type apiReturn = {
    message: string[]
    statusCode: number
}
export const useApiError = () => {
    const handlerError = (error: ErrorI, isError: boolean) => {
        const errorData = error?.error?.response?.data
        const Message = errorData?.message
        const Status = errorData?.statusCode
        if (Message && Status) {
            const errMsg = new Array(Message)
            return {
                message: errMsg,
                statusCode: Status,
            } satisfies apiReturn
        }
    }

    return { handlerError }
}
