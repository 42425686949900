import { type FC } from "react"
import { useOutletContext } from "react-router-dom"
import { AuthRedirectControl, PersonalRedirectControl, useGetHomePageDataQuery } from "features/user"
import {
    AdditionalText,
    CardContainerMain,
    CardMain,
    DescriptionText,
    ImageMain,
    MainText,
    StyledRedirectPanel,
    StyledRedirectProfile,
    VideoContainer,
} from "./styles"
import { MainContainer } from "./ui"

export const Main: FC = () => {
    const { wasLogin, updateToken } = useOutletContext<{ updateToken: () => void; wasLogin: boolean }>()
    const { data } = useGetHomePageDataQuery("")
    return (
        <>
            {/*<StyledPatternMain />*/}
            <MainContainer
                column1={() => (
                    <>
                        <VideoContainer
                            src={data?.videoUrl}
                            title="Video player"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        ></VideoContainer>
                        <CardContainerMain>
                            <CardMain $visibleBackground $type={"middle-dark-blue"}>
                                <MainText>«Оренбуржье - регион для молодых»</MainText>
                                <DescriptionText>платформа твоих возможностей в Оренбургской области </DescriptionText>
                            </CardMain>
                        </CardContainerMain>
                    </>
                )}
                column2={() => (
                    <>
                        <DescriptionText>
                            <CardContainerMain>
                                <CardMain $visibleBackground $type={"main-red"}>
                                    <AdditionalText>Присоединяйся!</AdditionalText>
                                    <DescriptionText>
                                        Здесь ты узнаешь, как реализовать свои идеи, прокачать навыки и применить знания
                                        для развития родного региона и страны.
                                    </DescriptionText>
                                </CardMain>
                            </CardContainerMain>
                        </DescriptionText>
                        <ImageMain $myUrl={data?.imageUrl ?? ""} />
                    </>
                )}
                btnControl={() => (
                    <>
                        {wasLogin ? (
                            <>
                                <StyledRedirectProfile>
                                    <PersonalRedirectControl updateToken={updateToken} />
                                </StyledRedirectProfile>
                            </>
                        ) : (
                            <>
                                <StyledRedirectPanel>
                                    <AuthRedirectControl onButtonClick={() => {}} />
                                </StyledRedirectPanel>
                            </>
                        )}
                    </>
                )}
            ></MainContainer>
        </>
    )
}
