import React, { type FC } from "react"
import { Link } from "react-router-dom"
import { StyledProfileSection } from "widgets/viewer/PersonalAccount/styles"
import { OrgStatus } from "widgets/viewer/PersonalAccount/Potential/ui"
import { useGetOrgUserQuery, useGetProfileDataQuery } from "features/viewer"

import { Button, ButtonSize, ButtonType, CardStatusType, Group, Informer, InformerType, useMediaGroup } from "shared/ui"
import { DashBoardSvg, PlusSvg } from "shared/assets/svg"
import { type PotentialProps } from "./types"

export const Potential: FC<PotentialProps> = (props) => {
    const { type = true } = props
    const { data, isLoading } = useGetProfileDataQuery("")
    const { data: OrgUser, isLoading: isLoadingOrg } = useGetOrgUserQuery("")
    console.log(OrgUser)
    const mediaGroup = useMediaGroup()

    if (!isLoading && OrgUser && "examplePoaUrl" in OrgUser) {
        return (
            <StyledProfileSection>
                <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowReversToColumn}>
                    <Link to={"/profile-edit/organization"}>
                        <Button size={ButtonSize.M} typeUI={ButtonType.PRIMARY}>
                            <PlusSvg />
                            Добавить организацию
                        </Button>
                    </Link>
                    <Informer type={InformerType.BASIC} title={"Стать оператором возможностей"}>
                        Добавьте организацию, которую вы можете представлять, чтобы получить доступ к функционалу
                        “Оператора возможностей”.
                    </Informer>
                </Group>
            </StyledProfileSection>
        )
    }
    if (!isLoadingOrg && OrgUser && "title" in OrgUser) {
        return (
            <>
                <StyledProfileSection>
                    <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowToColumn}>
                        <OrgStatus {...OrgUser} />
                    </Group>
                </StyledProfileSection>
                {OrgUser.user.orgStatus === CardStatusType.ACCEPTED && (
                    <Link to={"/dashboard"}>
                        <Button>
                            <DashBoardSvg />
                            Дашборд оператора
                        </Button>
                    </Link>
                )}
            </>
        )
    }
}
