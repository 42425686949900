import React, { type FC } from "react"
import { useFormContext } from "react-hook-form"
import { useGetCategoriesUsersQuery } from "features/user"
import { type IOpportunityForm } from "features/viewer"
import { InputCounter, Row, Select, StyledError } from "shared/ui"
import { SectionGapSize, StyledSection } from "shared/styles"

export const ForWhomFields: FC = () => {
    const { data: nameWhom } = useGetCategoriesUsersQuery("")
    const { getFieldState } = useFormContext<IOpportunityForm>()
    const { error } = getFieldState("userCategories")
    return (
        <StyledSection>
            <StyledSection $gapSize={SectionGapSize.NONE}>
                <Select
                    data={nameWhom}
                    name={"userCategories"}
                    initialValue={"Выберите все подходящие"}
                    labelText={"Для кого"}
                    multiple={true}
                    clearable={true}
                />
                {(error as unknown as Array<{ message: string }>)?.[0]?.message && (
                    <StyledError>{(error as unknown as Array<{ message: string }>)?.[0]?.message}</StyledError>
                )}
            </StyledSection>
            <Row>
                <InputCounter name={"ageRange.from"} labelText={"Возраст, от"} />
                <InputCounter name={"ageRange.to"} labelText={"Возраст, до"} />
            </Row>
        </StyledSection>
    )
}
