import {
    AgeSection,
    CategorySection,
    ForWhomSection,
    GeographySection,
    LinkSection,
    PhoneSection,
    PhotoSection,
    InclusivitySection,
} from "./ui"

export const OpportunitySection = {
    CategorySection,
    GeographySection,
    AgeSection,
    ForWhomSection,
    LinkSection,
    PhotoSection,
    PhoneSection,
    InclusivitySection,
}
