import React, { type FC } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { Header } from "widgets"
import { useMediaQuery } from "shared/hook"
import { useAuth } from "../../lib"
import { type PageLayoutProps } from "./types"
import { StyledPageLayout } from "./style"

export const PageLayout: FC<PageLayoutProps> = (props) => {
    const { updateToken, wasLogin } = useAuth()
    const screen = useMediaQuery()
    const location = useLocation()
    return (
        <>
            <Header wasLogin={wasLogin} updateToken={updateToken} />
            <StyledPageLayout $screen={screen} $isMain={location.pathname === "/"}>
                <Outlet context={{ updateToken, wasLogin } satisfies { updateToken: () => void; wasLogin: boolean }} />
            </StyledPageLayout>
        </>
    )
}
