import { css, styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledWrapper = styled.div<{
    $isMobile: boolean | undefined
}>`
    display: flex;
    position: relative;
    flex-direction: ${({ $isMobile }) => ($isMobile ? "column" : "row")};
    ${({ $isMobile }) => {
        if (!$isMobile) {
            return css`
                & > *:not(:last-child):not(:first-child) {
                    padding: 0 32px;
                }

                & > *:not(:last-child) {
                    border-right: 1px solid var(--layout-divider, rgba(0, 0, 0, 0.06));
                }
                & > *:first-child {
                    padding-right: 32px;
                }
                & > *:last-child {
                    padding-left: 32px;
                }
            `
        }
        return css`
            gap: 32px;
        `
    }}
`

export const StyledTitle = styled.div`
    display: flex;
    justify-items: start;
    width: 100%;
`

export const StyledColumn = styled.div<{
    $maxWidth: number | undefined
    $minWidth?: number
}>`
    display: flex;
    //width: 100%;
    max-width: ${({ $maxWidth }) => $maxWidth && $maxWidth}px;
    min-width: ${({ $minWidth }) => $minWidth && $minWidth}px;
    flex-direction: column;
    align-items: flex-start;
    gap: 32px;

    @media all and (max-width: ${getMediaVariable("laptop")}) {
        gap: 24px;
    }
`
