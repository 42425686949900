import { type PropsWithChildren } from "react"

export enum ModalSize {
    S = "s",
    L = "l",
}
export type ModalPosition = "left" | "right" | "center"

export interface ModalProps extends PropsWithChildren {
    isActive: boolean
    toggleActivity: () => void
    size?: ModalSize
    position?: ModalPosition
    bigHeight?: boolean
}
