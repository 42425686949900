import { type FC } from "react"
import { OpportunityTag, OpportunityType, SizeOpportunityTag } from "entities/user"
import { HeadingType, Typography } from "shared/ui"
import { SectionGapSize, StyledSection } from "shared/styles"

interface PlaceHeaderProps {
    name: string
}

export const PlaceHeader: FC<PlaceHeaderProps> = ({ name }) => {
    return (
        <>
            <StyledSection $gapSize={SectionGapSize.S}>
                <div>
                    <OpportunityTag typeCard={OpportunityType.PLACE} size={SizeOpportunityTag.BIG} />
                </div>
                <Typography.Heading typeUI={HeadingType.H1}>{name}</Typography.Heading>
            </StyledSection>
        </>
    )
}
