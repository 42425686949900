import React, { type FC } from "react"
import { LinkSvg } from "shared/assets/svg"
import { useMediaQuery } from "shared/hook"
import { type PartnersCardProps, PartnersCardSize } from "./types"
import { StyledPartnerCard, StyledPartnerCardName, StyledPartnerCardPicture } from "./styles"

export const PartnersCard: FC<PartnersCardProps> = (props) => {
    const { isDesktop } = useMediaQuery()
    const {
        hover = true,
        size = isDesktop ? PartnersCardSize.L : PartnersCardSize.S,
        children,
        img,
        href,
        ...other
    } = props

    return (
        <a href={href} target={"_blank"} rel="noreferrer">
            <StyledPartnerCard $size={size} $hover={hover}>
                <LinkSvg />
                <StyledPartnerCardPicture $size={size}>
                    <img src={img} />
                </StyledPartnerCardPicture>
                <StyledPartnerCardName $size={size}>{children}</StyledPartnerCardName>
            </StyledPartnerCard>
        </a>
    )
}
