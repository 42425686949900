import { type FillSvg, type SizeSvg } from "shared/assets/svg"

export enum VectorType {
    CHECK = "check",
    CROSS = "cross",
}
export interface VectorProps {
    fill?: FillSvg
    size?: SizeSvg
    type?: VectorType
}
