import React, { type FC } from "react"
import { ModalDefault } from "shared/ui"
import { type GiftModalProps } from "./types"
import { StyledTitle, StyledHeading } from "./styles"

export const GiftModal: FC<GiftModalProps> = (props) => {
    const { imageComponent, children, backClick } = props
    return (
        <ModalDefault back={backClick} title={"Подарок месяца"}>
            <StyledTitle>
                {imageComponent()}
                <div>
                    <StyledHeading>Супер значок великого умника</StyledHeading>
                </div>
            </StyledTitle>
            {children}
        </ModalDefault>
    )
}
