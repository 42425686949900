import { type FC } from "react"
import { Place } from "widgets/user"

export const PlacePage: FC = () => {
    return (
        <>
            <Place />
        </>
    )
}
