import * as yup from "yup"
import { type ObjectSchema } from "yup"
import { type IEditProfileData } from "features/viewer"

export const EditPersonalDataSchema: ObjectSchema<IEditProfileData> = yup
    .object({
        firstname: yup.string().required("Поле обязательно для заполнения"),
        lastname: yup.string().required("Поле обязательно для заполнения"),
        midname: yup.string().required("Поле обязательно для заполнения"),
        birthday: yup.string().required("Поле обязательно для заполнения"),
        locality: yup.string().required("Поле обязательно для заполнения"),
        avatar: yup
            .mixed<FileList>()
            .optional()
            .test("Avatar", "Превышен лимит размера файла", (value) => {
                console.log(value)
                return !value?.length || !(value && value[0].size > 5000000)
            })
            .nullable(),
    })
    .required()
