import React, { type FC, useEffect, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { NavLink, Outlet, useLocation } from "react-router-dom"
import { type ContactsListsProps } from "widgets/user"
import { useGetContactsQuery } from "features/user"
import { CalendarSvg, RuleSvg, SizeSvg, StarSvg } from "shared/assets/svg"
import { Choice, ChoiceGroup, ChoiceGroupSize, ChoiceType, InputSearch, useMediaSizeChoice } from "shared/ui"
import { type ContactsSectionForm } from "./types"
import { StyledContactsSection, StyledContactsMenu } from "./styles"

export const ContactsSection: FC = () => {
    const methods = useForm<ContactsSectionForm>()
    const mediaChoice = useMediaSizeChoice()
    const { data } = useGetContactsQuery({})
    const [searchLocationData, setSearchLocationData] = useState<string>("")
    const filteredLocations = data?.filters.filter((location, _, array) =>
        searchLocationData ? location.toLowerCase().includes(searchLocationData?.toLowerCase() as string) : array
    )
    const [propsList, setPropsList] = useState<ContactsListsProps>({
        searchLocationData: searchLocationData,
        filteredLocations: filteredLocations,
    })
    const route = useLocation()
    useEffect(() => {
        setPropsList({
            searchLocationData: searchLocationData,
            filteredLocations: filteredLocations,
        })
    }, [searchLocationData])

    return (
        <StyledContactsSection>
            <StyledContactsMenu>
                <ChoiceGroup size={ChoiceGroupSize.S} scroll={false}>
                    <NavLink to={"/contacts"} end={true}>
                        {({ isActive }) => (
                            <Choice type={ChoiceType.NAVLINK} selected={isActive} size={mediaChoice.fromStoL}>
                                <StarSvg size={SizeSvg.M} rule={RuleSvg.EVENODD} />
                                Список
                            </Choice>
                        )}
                    </NavLink>
                    <NavLink to={"/contacts/map"}>
                        {({ isActive }) => (
                            <Choice type={ChoiceType.NAVLINK} selected={isActive} size={mediaChoice.fromStoL}>
                                <CalendarSvg />
                                Карта
                            </Choice>
                        )}
                    </NavLink>
                </ChoiceGroup>
                {route.pathname === "/contacts" && (
                    <FormProvider {...methods}>
                        <InputSearch
                            filteredValues={filteredLocations ?? data?.filters ?? []}
                            setSearchData={setSearchLocationData}
                            placeholder={"Поиск по населенному пункту"}
                            name={"locationSearch"}
                        />
                    </FormProvider>
                )}
            </StyledContactsMenu>
            <Outlet context={propsList} />
        </StyledContactsSection>
    )
}
