import styled, { css } from "styled-components"
import { getMediaVariable } from "shared/styles"
import { BasicType, type BasicTypeSize, HeadingType } from "./types"

const mixinHeadingFont = css`
    font-weight: 500;
    color: var(--layout-text-colors-header, #000);
`

const StyledTypography = styled.span<{
    $type: HeadingType | BasicType
    $basicTypeSize?: BasicTypeSize | never
}>`
    line-height: 100%;
`

export const StyledHeading = styled(StyledTypography)<{
    $type?: HeadingType | BasicType
}>`
    color: var(--layout-text-colors-header, #000);
    ${({ $type }) => {
        switch ($type) {
            case HeadingType.H1:
                return css`
                    ${mixinHeadingFont};
                    font-size: clamp(2rem, 1.8214rem + 0.8929vw, 2.625rem);
                `
            case HeadingType.H2:
                return css`
                    ${mixinHeadingFont};
                    font-size: 24px;
                    line-height: 120%;

                    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
                        font-size: 36px;
                    }
                `
            case HeadingType.H3:
                return css`
                    font-weight: 500;
                    font-size: 20px;
                `
            case HeadingType.H4:
                return css`
                    line-height: 130%;
                    font-weight: 500;
                    font-size: 16px;

                    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
                        font-size: 20px;
                    }
                `

            default:
                return css``
        }
    }}
`

export const StyledBasic = styled(StyledTypography)<{
    $type?: HeadingType | BasicType
    $basicTypeSize?: BasicTypeSize
}>`
    font-size: ${({ $basicTypeSize }) => ($basicTypeSize ? $basicTypeSize : "14px")};
    ${({ $type }) => {
        switch ($type) {
            case BasicType.ADDITIONAL:
                return css`
                    color: var(--layout-text-colors-additional, rgba(0, 0, 0, 0.48));
                `
            case BasicType.SECOND:
                return css`
                    color: var(--layout-text-colors-secondary, rgba(0, 0, 0, 0.72));
                `
            case BasicType.CARD_COLORS_INTERACTIVE: {
                return css`
                    color: var(--assets-card-common-colors-interactive, #3e3aed);
                    font-size: 12px;
                `
            }
            default:
                return css``
        }
    }}
`

export { StyledTypography }
