import { type HTMLAttributes, type ReactNode } from "react"

export enum RowSize {
    L = "l",
    S = "s",
}
export interface RowProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    size?: RowSize
}
