import { type FC } from "react"
import { Input, AddDeleteButtons } from "shared/ui"
import { StyledLinkSection } from "./styles"
import { type InputLinkFormProps } from "./types"

export const LinksSection: FC<InputLinkFormProps> = (props) => {
    const {
        linkName,
        title,
        hasButtons = false,
        handlerClickDelete = () => {},
        handlerClickAdd = () => {},
        showDeleteButton = false,
        showAddButton = false,
        children,
    } = props
    return (
        <StyledLinkSection>
            <p>{title}</p>
            {children || (linkName && <Input name={linkName} placeholder={"Ссылка..."} />)}

            {hasButtons && (
                <AddDeleteButtons
                    handlerClickDelete={handlerClickDelete}
                    handlerClickAdd={handlerClickAdd}
                    showDeleteButton={showDeleteButton}
                    showAddButton={showAddButton}
                />
            )}
        </StyledLinkSection>
    )
}
