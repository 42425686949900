import React, { type FC } from "react"
import { Counter, CounterSize } from "shared/ui"
import { NotificationSvg } from "shared/assets/svg"
import { type NotificationCounterProps } from "./types"
import { StyledNotification } from "./styles"

export const NotificationCounter: FC<NotificationCounterProps> = (props) => {
    const { count, ...rest } = props
    return (
        <StyledNotification {...rest}>
            <NotificationSvg />
            <Counter count={count} size={CounterSize.S} />
        </StyledNotification>
    )
}
