import styled, { css } from "styled-components"
import { ChoiceSize } from "./types"

const StyledChoice = styled.button<{
    $size?: ChoiceSize
    $selected: boolean
}>`
    display: flex;
    justify-content: center;
    align-items: center;
    height: var(--assets-button-common-${(props) => props.$size}-height);
    padding: var(--assets-choice-group-sizes-${(props) => props.$size}-padding-vert)
        var(--assets-choice-group-sizes-${(props) => props.$size}-padding-hor);
    border-radius: var(--assets-choice-group-sizes-${(props) => props.$size}-radius);
    gap: var(--assets-choice-group-sizes-${(props) => props.$size}-gap);
    background: var(--assets-choice-group-${(props) => (props.$selected ? "active" : "colors")}-fill);
    color: var(--assets-choice-group-${(props) => (props.$selected ? "active" : "colors")}-content);
    font-size: ${(props) => (props.$size !== ChoiceSize.L ? 14 : 16)}px;
    & > svg {
        & path {
            fill: ${({ $selected }) => ($selected ? "white" : "black")};
        }
    }
    ${(props) => {
        if (!props.$selected) {
            return css`
                &:hover {
                    background: var(--assets-choice-group-colors-fill-hover);
                }
            `
        }
    }}
`

export { StyledChoice }
