import { type HTMLAttributes, type ReactNode } from "react"

export enum PartnersCardSize {
    S = "s",
    L = "l",
}
export interface PartnersCardProps extends HTMLAttributes<HTMLAnchorElement> {
    hover?: boolean
    size?: PartnersCardSize
    children: ReactNode
    href: string
    img: string
}
