import React, { type FC } from "react"
import { Toggle, ToggleType } from "../Toggle"
import { StyledInputCheck, StyledInputCheckBody } from "./styles"
import { type InputCheckProps } from "./types"

export const InputCheck: FC<InputCheckProps> = (props) => {
    const { body, isTurnOn, handlerToggle, isError = false } = props
    return (
        <StyledInputCheck $isError={isError}>
            <div>
                <Toggle isTurnOn={isTurnOn} handlerToggle={handlerToggle} type={ToggleType.CHECKBOX} />
            </div>
            <StyledInputCheckBody>{body()}</StyledInputCheckBody>
        </StyledInputCheck>
    )
}
