import React, { type FC } from "react"
import { FillSvg, SizeSvg } from "../../types"
import { type InfoSvgProps } from "./types"

export const InfoSvg: FC<InfoSvgProps> = (props) => {
    const { size = SizeSvg.M, fill = FillSvg.WHITE } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                d="M12.4167 7.00004C12.8769 7.00004 13.25 6.62694 13.25 6.16671C13.25 5.70647 12.8769 5.33337 12.4167 5.33337C11.9564 5.33337 11.5833 5.70647 11.5833 6.16671C11.5833 6.62694 11.9564 7.00004 12.4167 7.00004Z"
                fill={fill}
            />
            <path d="M9.5 9.50004H12V18.6667H12.8333V8.66671H9.5V9.50004Z" fill={fill} />
        </svg>
    )
}
