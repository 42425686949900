import { useResize } from "shared/hook"
import { ChoiceSize } from "./types"

export const useMediaSizeChoice = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromStoL: width >= isTablet ? ChoiceSize.L : ChoiceSize.S,
        fromXStoS: width >= isTablet ? ChoiceSize.S : ChoiceSize.XS,
    }
}
