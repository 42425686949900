import { type FC } from "react"
import { conditions } from "shared/lib"
import { Tag, TagSize } from "shared/ui"
import { GeoSvg, OpportunitySvg, SizeSvg } from "shared/assets/svg"
import { OpportunityType } from "../../types"
import { type OpportunityTagProps, SizeOpportunityTag } from "./types"

export const OpportunityTag: FC<OpportunityTagProps> = (props) => {
    const { typeCard, size = SizeOpportunityTag.SMALL } = props
    const TagSizeProps = size === SizeOpportunityTag.BIG ? TagSize.M : TagSize.S
    const SvgSizeProps = size === SizeOpportunityTag.BIG ? SizeSvg.S : SizeSvg.XS
    const tagCard = conditions<OpportunityType>(typeCard, [
        [
            OpportunityType.PLACE,
            () => (
                <Tag
                    size={TagSizeProps}
                    bgColor={"var(--assets-opportunity-cards-colors-accent-place, #3CB7FF)"}
                    icon={() => <GeoSvg size={SvgSizeProps} />}
                >
                    Место
                </Tag>
            ),
        ],
        [
            OpportunityType.OPPORTUNITY,
            () => (
                <Tag
                    size={TagSizeProps}
                    bgColor={"var(--assets-opportunity-cards-colors-accent-basic, #8E00EA)"}
                    icon={() => <OpportunitySvg size={SvgSizeProps} />}
                >
                    Возможность
                </Tag>
            ),
        ]
    ])
    return <>{tagCard()}</>
}
