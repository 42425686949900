import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledEditChoices = styled.div`
    @media all and (min-width: ${getMediaVariable("tablet")}) {
        & > div {
            flex-direction: column;
            height: 100%;
            button {
                width: 100%;
                justify-content: start;
            }
        }
    }
`

export { StyledEditChoices }
