import { type typeObjectWorkingHours } from "../types"

export const isJsonString = (str: typeObjectWorkingHours | string | undefined): typeObjectWorkingHours | null => {
    if (str === undefined) return null
    try {
        return typeof str === "string" ? JSON.parse(JSON.parse(str)) : str
    } catch (e) {
        return null
    }
}
