import styled from "styled-components"

const StyledBurgerButton = styled.div<{
    $isOpen: boolean
}>`
    position: relative;
    width: 24px;
    padding: 0 2px;
    height: 24px;

    & > span {
        transition: ease 0.2s;
    }
    & > span:after {
        content: "";
        position: absolute;
        transition: ease 0.2s;
        height: 1px;
        width: calc(100% - 4px);
        top: ${(props) => (props.$isOpen ? "11.5px" : "8.5px")};
        animation: ${(props) => props.$isOpen && "AfterRotate"} 0.3s forwards 0.2s;
        background: #292929;
    }
    & > span:before {
        content: " ";
        position: absolute;
        transition: ease 0.2s;
        display: block;
        width: calc(100% - 4px);
        height: 1px;
        background: #292929;
        bottom: ${(props) => (props.$isOpen ? "11.5px" : "8.5px")};
        animation: ${(props) => props.$isOpen && "BeforeRotate"} 0.3s forwards 0.2s;
    }

    @keyframes AfterRotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(-45deg);
        }
    }

    @keyframes BeforeRotate {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(45deg);
        }
    }
`

export { StyledBurgerButton }
