import React, { type FC } from "react"
import { Toggle } from "shared/ui/Toggle"
import { useToggle } from "shared/hook"
import { type OptionProps } from "./types"
import { StyledSelectOption, StyledSelectOptionContent } from "./styles"

export const Option: FC<OptionProps> = (props) => {
    const { selected, text, multiple = false, icon, ...other } = props
    const toggle = useToggle(false)
    return (
        <StyledSelectOption $isSelected={selected} $isMultiple={multiple} {...other}>
            {multiple && <Toggle {...toggle} isTurnOn={selected} />}
            {icon?.()}
            <StyledSelectOptionContent>{text}</StyledSelectOptionContent>
        </StyledSelectOption>
    )
}
