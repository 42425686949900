import { type HTMLAttributes } from "react"

export enum AvatarSize {
    XL = "xl",
    L = "l",
    M = "m",
    S = "s",
}
export interface AvatarProps extends HTMLAttributes<HTMLImageElement> {
    visibleName?: boolean
    size?: AvatarSize
}
