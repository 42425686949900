import React, { type FC, useEffect, useState } from "react"
import { GoalStatusType } from "entities/viewer"
import { Button, Modal, ModalDefault, useMediaSizeButton } from "shared/ui"
import { useModal, useToggle } from "shared/hook"
import { ModalGoal } from "../ModalGoal"
import { ModalGoalReport } from "../ModalGoalReport"
import { StyledGoalList } from "./styles"
import { GoalListItem } from "./ui"

export const GoalsList: FC = () => {
    const modal = useModal()
    const [categoryCard, setCategoryCard] = useState<GoalStatusType>()
    const [idCard, setIdCard] = useState<string | null>(null)
    const { fromMtoL } = useMediaSizeButton()
    const { isTurnOn, handlerToggle } = useToggle(false)
    useEffect(() => {
        if (isTurnOn) {
            document.body.style.overflow = "hidden"
            return () => {
                document.body.style.overflow = "unset"
            }
        }
    }, [isTurnOn])
    const [isErrorReport, setIsErrorReport] = useState<boolean>(false)
    return (
        <>
            <StyledGoalList>
                <GoalListItem
                    setIdCard={setIdCard}
                    itemType={GoalStatusType.NEW}
                    modalToggleActivity={modal.toggleActivity}
                    setCategoryCard={setCategoryCard}
                />
                <GoalListItem
                    setIdCard={setIdCard}
                    itemType={GoalStatusType.IN_PROGRESS}
                    modalToggleActivity={modal.toggleActivity}
                    setCategoryCard={setCategoryCard}
                />
                <GoalListItem
                    setCategoryCard={setCategoryCard}
                    itemType={GoalStatusType.IN_REVIEW}
                    setIdCard={setIdCard}
                />
                <GoalListItem
                    setIdCard={setIdCard}
                    itemType={GoalStatusType.COMPLETED}
                    setCategoryCard={setCategoryCard}
                />
            </StyledGoalList>
            <Modal position={"right"} bigHeight {...modal}>
                <ModalGoal
                    toggleActivity={modal.toggleActivity}
                    toggleGoodLackModal={handlerToggle}
                    id={idCard}
                    type={categoryCard}
                />
            </Modal>

            {categoryCard === GoalStatusType.NEW ? (
                <Modal position={"center"} isActive={isTurnOn} toggleActivity={handlerToggle}>
                    <ModalDefault
                        backButton={false}
                        title={"Удачи"}
                        body={"Задача принята в работу!"}
                        footerBtn={() => (
                            <Button size={fromMtoL} onClick={handlerToggle}>
                                Хорошо
                            </Button>
                        )}
                    />
                </Modal>
            ) : categoryCard === GoalStatusType.IN_PROGRESS ? (
                <ModalGoalReport
                    errorHandler={() => {
                        setIsErrorReport(true)
                    }}
                    isActive={isTurnOn}
                    id={idCard}
                    toggleActivity={handlerToggle}
                />
            ) : (
                <></>
            )}
            {isErrorReport && (
                <Modal
                    position={"center"}
                    isActive={isErrorReport}
                    toggleActivity={() => {
                        setIsErrorReport(false)
                    }}
                >
                    <ModalDefault
                        back={() => {
                            setIsErrorReport(false)
                        }}
                        title={"Ошибка загрузки отчета"}
                        body={"Отчет был отправлен ранее"}
                    ></ModalDefault>
                </Modal>
            )}
        </>
    )
}
