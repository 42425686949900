import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { Vector } from "shared/ui"
import { type ToggleProps, ToggleType } from "./types"
import { StyledInputToggle, StyledToggle } from "./styles"

export const Toggle: FC<ToggleProps> = (props) => {
    const { type = ToggleType.CHECKBOX, isTurnOn, handlerToggle, ...other } = props

    const isCheckBox = isTurnOn && type === ToggleType.CHECKBOX

    return (
        <StyledToggle onClick={handlerToggle} $state={!!isTurnOn} $type={type}>
            {isCheckBox && <Vector size={SizeSvg.XS} />}
            <StyledInputToggle {...other} />
        </StyledToggle>
    )
}
