import { type FC, type PropsWithChildren } from "react"
import { RubricatorHeader } from "widgets/user"

export const RubricatorPage: FC<PropsWithChildren> = () => {
    return (
        <>
            <RubricatorHeader />
        </>
    )
}
