import { type UseFormProps } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { type IEditWorkSheetForm } from "features/viewer"
import { EditWorkSheetSchema } from "./EditWorkSheetSchema"

export const EditWorkSheetConfig = (defaultValues: any): UseFormProps<IEditWorkSheetForm> => ({
    defaultValues: {
        userVkId: null,
        interests: null,
        userCategories: null,
        withDisability: false,
        disabilityGroup: null,
    },
    values: defaultValues,
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(EditWorkSheetSchema),
})
