import React, { type FC } from "react"
import { ScheduleList, ScheduleWrapper } from "entities/viewer"
import { type typeObjectWorkingHours } from "../../types"
import { isJsonString } from "../../lib"

interface workingHours {
    workingHours?: typeObjectWorkingHours | string
}

export const PlaceSchedule: FC<workingHours> = ({ workingHours }) => {
    const formatedWorkingHours = isJsonString(workingHours)

    if (!formatedWorkingHours) {
        return null
    }

    return (
        <ScheduleWrapper>
            <ScheduleList scheduleData={formatedWorkingHours} />
        </ScheduleWrapper>
    )
}
