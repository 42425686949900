import styled from "styled-components"
import { ModalDefault, StyledDefaultModalBody } from "shared/ui"
import { getMediaVariable } from "shared/styles"

export const StyledModalDefaultFilters = styled(ModalDefault)<{}>`
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        ${StyledDefaultModalBody} {
            overflow-y: visible !important;
        }
    }
`
