import { type FC } from "react"
import { BurgerButton } from "shared/ui"
import { type HeaderBurgerProps } from "./types"

export const HeaderBurger: FC<HeaderBurgerProps> = (props) => {
    const { isShowMenu = false, handleClickBurger } = props

    return (
        <div onClick={handleClickBurger}>
            <BurgerButton isOpen={isShowMenu} />
            <div>Меню</div>
        </div>
    )
}
