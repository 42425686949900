import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type PlusSvgProps } from "./types"

export const PlusSvg: FC<PlusSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 23" fill="none">
            <path d="M13 11.5V4H12V11.5H4.5V12.5H12V20H13V12.5H20.5V11.5H13Z" fill="currentColor" />
        </svg>
    )
}
