import * as yup from "yup"
import { number, string } from "yup"

export const validationSchema = yup.object().shape({
    name: string().required("Поле обязательно для заполнения"),
    categories: string().required("Поле обязательно для заполнения"),
    address: string().required("Поле обязательно для заполнения"),
    description: string().required("Поле обязательно для заполнения"),
    tags: yup.mixed<Array<string>>().optional(),
    userCategories: yup
        .array()
        .of(string().required("Выберите категории"))
        .required("Выберите категории")
        .typeError("Выберите категории"),
    startDate: string()
        .required("Заполните поле")
        .test("is-right-format", "Некорректный формат", (value) => {
            const date = new Date(value)
            if (date.getFullYear() > new Date().getFullYear() + 30 || date.getFullYear() < new Date().getFullYear()) {
                return false
            }
            return true
        }),
    endDate: string()
        .required("Заполните поле")
        .test("is-right-format", "Некорректный формат", (value) => {
            const date = new Date(value)
            if (date.getFullYear() > new Date().getFullYear() + 30 || date.getFullYear() < new Date().getFullYear()) {
                return false
            }
            return true
        }),
    ageRange: yup.object({
        from: number()
            .required()
            .test("is-enough", " ", function (value) {
                return !!value
            })
            .typeError(" "),
        to: number()
            .required(" ")
            .nonNullable()
            .test("is-enough", " ", function (value) {
                return !!value
            })
            .typeError(" "),
    }),
    condition: string().required("Поле обязательно для заполнения"),
    images: yup
        .mixed<FileList>()
        .optional()
        .test((value) => {
            return !value?.length || !(value && value[0].size > 5000000)
        }),
    ctaUrl: string().url("Введите корректную ссылку").required("Поле обязательно для заполнения"),
    videoUrl: string()
        .url("Введите корректную ссылку")
        .test("is-valid", "Предоставь ссылку с RuTube/YouTube/Vk", (value) => {
            return value === "" || value?.includes("vk") || value?.includes("rutube") || value?.includes("youtu")
        })
        .optional(),
    websiteUrl: string().url("Введите корректную ссылку").optional(),
    vkSocialUrl: string().url("Введите корректную ссылку").optional(),
})
