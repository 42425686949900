import { StrictMode } from "react"
import ReactDOM from "react-dom/client"
import { YMInitializer } from "@appigram/react-yandex-metrika"
import App from "app"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
    <StrictMode>
        <App />
        <YMInitializer accounts={[Number(process.env.REACT_APP_YM_ID)]} />
    </StrictMode>
)
