import { useResize } from "shared/hook"
import { SizeSvg } from "../types"

export const useMediaSizeSvg = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromMtoL: width >= isTablet ? SizeSvg.L : SizeSvg.M,
    }
}
