import styled, { keyframes } from "styled-components"
const collapse = keyframes`
  0%{
    grid-template-rows: 0fr;
    overflow: hidden;
    margin-top: 0px;
  }
  25%{
    margin-top: 8px;
  }
  50%{
    margin-top: 8px;
    grid-template-rows: 1fr;
  }
  75%{
    margin-top: 8px;
    grid-template-rows: 1fr;
  }
  100%{
    grid-template-rows: 1fr;
    overflow: unset;
    margin-top: 8px;
  }
`
const StyledLabeledSwitch = styled.div<{
    $isTurnOn?: boolean
}>`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: var(--assets-switch-sizes-invalid-padding, 12px);
    align-items: flex-start;
    justify-content: center;
    border-radius: var(--assets-switch-sizes-invalid-radius, 16px);
    border: 1px solid
        ${({ $isTurnOn }) =>
            $isTurnOn
                ? "var(--assets-switch-colors-invalid-stroke-checked)"
                : "var(--assets-switch-colors-invalid-stroke)"};
`

const StyledLabeledSwitchContent = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--assets-switch-sizes-invalid-label-gap, 8px);
    font-size: 14px;
    line-height: 18px;
`

const StyledLabelSwitchCollapse = styled.div<{
    $isToggle: boolean
}>`
    width: 100%;
    display: grid;
    grid-template-rows: 0fr;
    overflow: hidden;
    animation: ${({ $isToggle }) => $isToggle && collapse} 0.3s forwards;

    & > div {
        min-height: 0;
    }
`

export { StyledLabeledSwitch, StyledLabeledSwitchContent, StyledLabelSwitchCollapse }
