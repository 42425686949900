import { type FC } from "react"
import { type CounterProps, CounterSize, CounterColor } from "./types"
import { StyledCounter } from "./styles"

export const Counter: FC<CounterProps> = (props) => {
    const { size = CounterSize.L, count = 0, color = CounterColor.WEEKEND, ...rest } = props

    const plus = count > 9 ? "9+" : count

    if (!count) return null

    return (
        <StyledCounter $size={size} $color={color} {...rest}>
            {plus}
        </StyledCounter>
    )
}
