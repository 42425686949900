import styled, { css } from "styled-components"
import { Map } from "@pbe/react-yandex-maps"
import { getMediaVariable } from "shared/styles"

export const StyledMap = styled(Map)`
    width: 100%;
    height: calc(100vh - 308px);
    max-height: 640px;
    .ymaps-2-1-79-balloon-overlay {
        float: right;
        position: static;
        right: 0px !important;
        top: 0 !important;
        left: 0px !important;
        width: 300px !important;
    }

    .ymaps-2-1-79-balloon {
        top: 0 !important;
        left: 0px !important;
        right: 0px !important;
    }
`
export const StyledModalContainer = styled.div`
    position: fixed;
    width: 320px;
    height: 100%;
    top: 0;
    right: 0;
    z-index: 99;
`

export const StyledBackground = styled.div`
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    overflow: hidden;
`

export const StyledModal = styled.div<{
    $isBodySelector: boolean
}>`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: var(--assets-modals-informer-gap, 16px);
    border-radius: var(--assets-modals-informer-radius, 24px);
    background: #fff;
    position: absolute;
    bottom: 32px;
    overflow-x: hidden;
    overflow-y: auto;

    max-height: calc(100vh - 64px);
    top: 50%;
    transform: translate(0%, -50%);
    right: 32px;
    height: calc(100% - 44px);
    width: 320px;
    &::-webkit-scrollbar {
        display: none;
    }
    & > * {
        padding: var(--assets-modals-informer-padding, 24px);
    }

    ${({ $isBodySelector }) => {
        if ($isBodySelector) {
            return css`
                height: calc(100vh - 308px - 32px);
                max-height: 596px;
                right: 124px;
                top: 340px;
                @media all and (min-width: ${getMediaVariable("laptop-l")}) {
                    top: 460px;
                    right: 164px;

                    height: calc(100vh - 308px - 44px);
                }
            `
        }
    }}
`
