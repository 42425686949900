import React, { type FC } from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { ScheduleList, ScheduleWrapper } from "entities/viewer"
import { OpportunitySection } from "entities/user"
import { Button, Modal, ModalDefault, useMediaSizeButton } from "shared/ui"
import { LinkSvg } from "shared/assets/svg"
import { isJsonString } from "../../lib"
import { type ModalPlaceProps } from "./types"

const StyledButtonPlaceModal = styled.div`
    width: 100%;
    & > a {
        width: 100%;
    }
`

export const PlaceModal: FC<ModalPlaceProps> = (props) => {
    const { modal, address, workingHours, coords } = props
    const arrCoords = coords?.split(" ")
    const { fromMtoL } = useMediaSizeButton()
    const formatedWorkingHours = isJsonString(workingHours)
    return (
        <Modal bigHeight position={"right"} {...modal}>
            <ModalDefault
                footerBtn={() => (
                    <StyledButtonPlaceModal>
                        <Link
                            target="_blank"
                            rel="noopener noreferrer"
                            to={`https://yandex.ru/maps/?pt=${arrCoords[1]},${arrCoords[0].slice(0, -1)}&z=18&l=map`}
                        >
                            <Button size={fromMtoL}>
                                Показать на карте <LinkSvg />
                            </Button>
                        </Link>
                    </StyledButtonPlaceModal>
                )}
                back={modal.toggleActivity}
                title={"Посещение места"}
            >
                <OpportunitySection.GeographySection address={address} isAddress={true} />
                {formatedWorkingHours && (
                    <ScheduleWrapper>
                        <ScheduleList scheduleData={formatedWorkingHours} />
                    </ScheduleWrapper>
                )}
                <OpportunitySection.PhoneSection />
            </ModalDefault>
        </Modal>
    )
}
