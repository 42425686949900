import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledTitle = styled.div`
    align-self: start;
`

export const StyledSection = styled.div`
    display: flex;
    align-self: start;
    gap: 16px;
    justify-content: space-between;
    width: 100%;

    @media all and (max-width: ${getMediaVariable("laptop")}) {
        flex-direction: column;
        align-self: center;
    }
`
