import React, { type FC } from "react"
import { SizeSvg, FillSvg } from "shared/assets/svg"
import { VectorType, type VectorProps } from "./types"

export const Vector: FC<VectorProps> = (props) => {
    const { fill = FillSvg.WHITE, size = SizeSvg.L, type = VectorType.CHECK, ...other } = props

    if (type === VectorType.CROSS) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
                <path
                    d="M12 12.7072L18.6465 19.3536L19.3536 18.6465L12.7072 12L19.3536 5.35359L18.6465 4.64648L12 11.2929L5.35359 4.64648L4.64648 5.35359L11.2929 12L4.64648 18.6465L5.35359 19.3536L12 12.7072Z"
                    fill={fill}
                />
            </svg>
        )
    }

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox={"0 0 32 32"} fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.4714 8.47137L12 24.9428L2.19525 15.138L3.13806 14.1952L12 23.0572L27.5286 7.52856L28.4714 8.47137Z"
                fill={fill}
            />
        </svg>
    )
}
