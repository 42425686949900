import styled from "styled-components"

const StyledModalGoal = styled.div`
    display: flex;
    width: 326px;
    padding: var(--assets-modals-informer-padding, 24px);
    flex-direction: column;
    gap: var(--assets-modals-informer-gap, 16px);

    border-radius: var(--assets-modals-informer-radius, 24px);
    background: #fff;
`

const StyledModalGoalCardText = styled.div`
    display: flex;
    gap: var(--assets-user-profile-data-sizes-s-gap-tag, 8px);
`

const StyledModalGoalCountersForms = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--layout-mobile-row-gap, 8px);
    align-self: stretch;
    & > div {
        width: 50%;
    }
`

const StyledFieldsContainer = styled.div`
    height: 318px;
    position: relative;
`

const StyledFields = styled.div`
    height: 318px;
    width: 326px;
    padding: 0 26px;
    overflow: auto;

    display: flex;
    flex-direction: column;
    gap: var(--layout-mobile-form-gap, 24px);
    flex: 1 0 0;
    align-self: stretch;

    position: absolute;
    left: -23px;
`

const StyledFileDocument = styled.div`
    display: flex;
    padding: var(--assets-file-custom-card-sizes-s-padding, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-file-custom-card-sizes-s-gap, 12px);
    align-self: stretch;

    border-radius: var(--assets-file-custom-card-sizes-s-radius, 16px);
    border: 1px solid var(--assets-file-custom-card-colors-stroke, rgba(0, 0, 0, 0.06));
`

const StyledModalGoalReportControl = styled.div`
    display: flex;
    padding: var(--assets-modals-informer-gap, 16px) var(--assets-file-custom-card-sizes-s-padding, 12px) 0;
    flex-direction: column;
    gap: var(--layout-mobile-controls-gap, 8px);
    background: #fff;
    z-index: 99;
`
export {
    StyledModalGoal,
    StyledModalGoalCardText,
    StyledModalGoalCountersForms,
    StyledFields,
    StyledFieldsContainer,
    StyledFileDocument,
    StyledModalGoalReportControl,
}
