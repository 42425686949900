import { Provider as Store } from "react-redux"
import Router from "./router"
import Map from "./map"
// import Store from "./store"

const Provider = {
    Router,
    Store,
    Map,
}

export default Provider
