import { type FC } from "react"
import dayjs from "dayjs"
import { formatNumber } from "shared/lib"
import { dateConverter } from "shared/api"
import { EventsCalendarItem } from "../EventsCalendarItem"
import { type DayListProps } from "./types"

export const EventDaysList: FC<DayListProps> = (props) => {
    const {
        month,
        onItemClick,
        datesEvent,
        startDayOfWeek,
        isAnotherMonth = false,
        arrayLength,
        isPrevious = false,
        formattedData,
    } = props
    return (
        <>
            {Array.from({ length: arrayLength }, (_, i) => {
                const day = isPrevious && startDayOfWeek ? month.daysInMonth() - startDayOfWeek + i : i
                const dayOfWeek = dayjs(`${month.year()}-${formatNumber(month.month())}-${formatNumber(day)}`).day()
                    ? dayjs(`${month.year()}-${formatNumber(month.month())}-${formatNumber(day)}`).day() - 1
                    : 6
                const hasEvent = datesEvent?.some(
                    ({ startDate }) =>
                        dateConverter(startDate).yyy_mmm_ddd("-") ===
                        `${month.year()}-${formatNumber(month.month())}-${formatNumber(day)}`
                )
                return (
                    <EventsCalendarItem.Day
                        formattedData={formattedData}
                        datesEvent={datesEvent}
                        day={`${month.year()}-${formatNumber(month.month())}-${formatNumber(day)}`}
                        onClick={
                            hasEvent
                                ? onItemClick(`${month.year()}-${formatNumber(month.month())}-${formatNumber(day)}`)
                                : () => {}
                        }
                        count={6}
                        key={
                            isPrevious
                                ? `previous-month-day-${day + 1}`
                                : isAnotherMonth
                                ? `next-month-day-${day + 1}`
                                : `current-month-day-${day + 1}`
                        }
                        isWeekend={dayOfWeek === 6 || dayOfWeek === 5}
                        isAnotherMonth={isAnotherMonth}
                        hasEvent={hasEvent}
                    >
                        {day + 1}
                    </EventsCalendarItem.Day>
                )
            })}
        </>
    )
}
