import { ProfileRole } from "features/viewer"

interface navListI {
    name: string
    path: string
    role?: ProfileRole
}
export const navList: navListI[] = [
    {
        name: "Главная",
        path: "/",
    },
    {
        name: "Рубрикатор возможностей",
        path: "/rubricator",
    },
    {
        name: "Новости",
        path: "/news",
    },
    {
        name: "Партнеры",
        path: "/partners",
    },
    {
        name: "О нас",
        path: "/about",
    },
    {
        name: "Контакты",
        path: "/contacts",
    },
    {
        name: "Кабинет оператора возможностей",
        path: "/dashboard",
        role: ProfileRole.OPERATOR_NO_ORG,
    },
    // {
    //     name: "test_ui",
    //     path: "/TestUI",
    // },
    // {
    //     name: "restore",
    //     path: "/restore",
    // },
    // {
    //     name: "registration",
    //     path: "/registration",
    // },
    // {
    //     name: "auth",
    //     path: "/auth",
    // },
    // {
    //     name: "personal-account",
    //     path: "/personal-account",
    // },
]
