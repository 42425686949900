import { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import {
    BasicType,
    Button,
    HeadingType,
    InputCode,
    Modal,
    ModalDefault,
    Typography,
    useMediaSizeButton,
} from "shared/ui"
import { ModalPhoneText } from "./styles"
import { type IModalPhoneForm, type ModalPhoneProps } from "./types"

export const ModalPhone: FC<ModalPhoneProps> = ({ isActive, toggleActivity }) => {
    const { fromMtoL } = useMediaSizeButton()
    const methods = useForm<IModalPhoneForm>({})

    return (
        <Modal bigHeight position={"right"} isActive={isActive} toggleActivity={toggleActivity}>
            <ModalDefault
                footerBtn={() => <Button size={fromMtoL}>Завершить</Button>}
                title={"Подтверждение регистрации"}
            >
                <ModalPhoneText>
                    <Typography.Basic typeUI={BasicType.SECOND}>На указанный вами телефон:</Typography.Basic>
                    <br />
                    <Typography.Heading typeUI={HeadingType.H4}>+7 912 345-67-89</Typography.Heading>
                    <br />
                    <Typography.Basic typeUI={BasicType.SECOND}>
                        отправлено SMS-сообщение.
                        <br />
                        Введите код из него в поле ниже для подтверждения регистрации.
                    </Typography.Basic>
                </ModalPhoneText>
                <FormProvider {...methods}>
                    <InputCode
                        fieldsNames={[
                            "confirmationPhone1",
                            "confirmationPhone2",
                            "confirmationPhone3",
                            "confirmationPhone4",
                        ]}
                    />
                </FormProvider>
            </ModalDefault>
        </Modal>
    )
}
