import { type HTMLAttributes } from "react"

export enum PointsState {
    ENOUGH = "enough",
    NOT_ENOUGH = "not_enough",
    ALREADY_GOT_GIFT = "alreadyGotGift",
}

export interface GetGiftProps extends HTMLAttributes<HTMLButtonElement> {
    presentState?: PointsState
}
