import styled from "styled-components"

const StyledInputSection = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-section-gap, 12px);
    & > div {
        width: 100%;
    }
`

export { StyledInputSection }
