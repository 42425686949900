import styled from "styled-components"

export const StyledContainer = styled.div`
    max-height: calc(100vh - 113px);
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: calc(100vw - 64px);
`
export const StyledContent = styled.div`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    gap: var(--layout-mobile-gap, 24px);
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`

export const StyledFooterButton = styled.div`
    padding-top: var(--assets-header-common-mobile-padding-vert, 16px);
`
