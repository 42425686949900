import { type FC, useState } from "react"
import { FormProvider, useForm } from "react-hook-form"
import dayjs from "dayjs"
import { useGetCalendarEventsQuery } from "features/viewer"
import { useModal, useResize } from "shared/hook"
import { dateConverter } from "shared/api"
import { type IFormattedCalendarData } from "../../../types"
import { EventsCalendarBody } from "./EventsCalendarBody"
import { StyledCalendarWrapper, StyledParentControls } from "./styles"
import { EventsCalendarModal } from "./EventsCalendarModal"
import { type IEventsCalendarForm } from "./types"
import { CurrentButton } from "./CurrentMonthButton"
import { EventsControlGroup } from "./EventCotrolsGroup"

export const EventsCalendar: FC = () => {
    const modal = useModal()
    const methods = useForm<IEventsCalendarForm>({
        defaultValues: {
            month: dayjs().month(),
            year: dayjs().year(),
        },
    })
    const [wasClear, setWasClear] = useState<boolean>(false)
    const {
        mediaScreenSize: { width, isLaptopSm },
    } = useResize()

    const { data } = useGetCalendarEventsQuery({})
    const [day, setDay] = useState<string>("")

    const mapsEvents = data?.reduce(
        (acc, { endDate, startDate, id, title, url }) => {
            const date = dateConverter(startDate).yyy_mmm_ddd("-")
            if (date in acc) {
                acc[date].push({ id, title, url, startDate, endDate })
                return acc
            }
            acc[date] = [{ id, title, url, startDate, endDate }]
            return acc
        },
        {} as unknown as IFormattedCalendarData
    )
    return (
        <FormProvider {...methods}>
            <StyledCalendarWrapper>
                <EventsCalendarModal formattedData={mapsEvents} day={day} {...modal} />
                {width >= isLaptopSm ? (
                    <StyledParentControls>
                        <EventsControlGroup
                            wasClear={wasClear}
                            handlerSetWasClear={() => {
                                setWasClear(false)
                            }}
                        />
                        <CurrentButton setWasClear={setWasClear} />
                    </StyledParentControls>
                ) : (
                    <EventsControlGroup
                        wasClear={wasClear}
                        handlerSetWasClear={() => {
                            setWasClear(false)
                        }}
                    />
                )}
                <EventsCalendarBody
                    formattedData={mapsEvents}
                    datesEvent={data}
                    yearNumber={methods.getValues("year")}
                    monthNumber={methods.getValues("month")}
                    onItemClick={(day: string) => () => {
                        modal.toggleActivity()
                        setDay(day)
                    }}
                />
                {width <= isLaptopSm && <CurrentButton setWasClear={setWasClear} />}
            </StyledCalendarWrapper>
        </FormProvider>
    )
}
