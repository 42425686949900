import React, { type FC } from "react"
import { type OrgStatusProps } from "widgets/viewer/PersonalAccount/Potential/ui/OrgStatus/types"
import { UserMetric } from "features/viewer"
import { OrganizationCard } from "entities/viewer"
import { CardStatusType, CardType, Informer, InformerType } from "shared/ui"

export const OrgStatus: FC<OrgStatusProps> = (props) => {
    const data = props
    const TypeCondition = {
        notVerify: data.user.orgStatus === CardStatusType.DECLINED,
        verification: data.user.orgStatus === CardStatusType.ACCEPTED,
    }

    return (
        <>
            {data.user.orgStatus !== CardStatusType.NONE && (
                <>
                    <OrganizationCard
                        statusType={data.user.orgStatus}
                        cardType={CardType.STATUS}
                        name={data?.title}
                        inn={data?.taxId}
                    />
                    {TypeCondition.notVerify && (
                        <Informer type={InformerType.ERROR} title={"Проверка не пройдена"}>
                            Предоставленная вами информация не прошла проверку модерацией. Пожалуйста, проверьте
                            корректность ввода данных и приложите корректную скан-копию доверенности.
                        </Informer>
                    )}
                    {TypeCondition.verification && <UserMetric />}
                </>
            )}
        </>
    )
}
