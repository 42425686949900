import React, { type FC } from "react"
import { DropDownChevron, RussianFlagSvg } from "shared/assets/svg"
import { type CountrySelectorProps } from "./types"
import { StyledSelected } from "./styles"

export const CountrySelector: FC<CountrySelectorProps> = (props) => {
    return (
        <StyledSelected>
            <RussianFlagSvg />
            <div>+7</div>
            <DropDownChevron />
        </StyledSelected>
    )
}
