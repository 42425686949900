import React, { type FC } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { AddDeleteButtons, BackButtonText, Button, ButtonType, ModalDefault, useMediaSizeButton } from "shared/ui"
import { ArrayFieldHandlers } from "shared/lib"
import { type IModalWorkFormValues, type Times } from "../../models"
import { type ModalWorkProps } from "./types"
import { ModalScheduleSection, WorkDays } from "./ui"
import { StyledForm } from "./styles"

export const ModalWork: FC<ModalWorkProps> = (props) => {
    const {
        state,
        getTurnOn,
        toggleHandler,
        dispatch,
        toggleDayAction,
        setSubmitFormData,
        selectedDaysCount,
        toggleActivity,
    } = props

    const { control, getValues, formState, getFieldState, handleSubmit } = useFormContext<IModalWorkFormValues>()
    const { fields, append, remove } = useFieldArray({
        name: "times",
        control: control,
    })
    const { handlerClickDelete, handlerClickAdd } = new ArrayFieldHandlers<IModalWorkFormValues, Times[], "times">(
        getValues("times"),
        append,
        remove,
        fields,
        {
            defaultTimes: { startTime: "", finishTime: "" },
        },
        !!(fields[0].defaultTimes.startTime && fields[0].defaultTimes.finishTime)
    )
    const fieldState = getFieldState("times", formState)
    const { fromMtoL } = useMediaSizeButton()
    return (
        <ModalDefault
            title={"График работы"}
            back={toggleActivity}
            backButtonText={BackButtonText.CANCEL}
            footerBtn={() => (
                <Button
                    size={fromMtoL}
                    form="modalWorkForm"
                    typeUI={
                        !fieldState.isDirty || fieldState.invalid || !selectedDaysCount
                            ? ButtonType.DISABLED
                            : ButtonType.PRIMARY
                    }
                    type={"submit"}
                    id={"modalWorkForm"}
                >
                    Сохранить
                </Button>
            )}
        >
            <StyledForm
                id="modalWorkForm"
                onSubmit={handleSubmit((data) => {
                    setSubmitFormData(data.times)
                    toggleActivity()
                })}
            >
                {fields.map((item, j) => {
                    return (
                        <ModalScheduleSection
                            isTurnOn={getTurnOn(j)}
                            handlerToggle={toggleHandler(j)}
                            index={j}
                            key={item.id}
                            inputNameFinish={`times.${j}.defaultTimes.finishTime`}
                            inputNameStart={`times.${j}.defaultTimes.startTime`}
                        >
                            <WorkDays
                                toggleDayAction={toggleDayAction}
                                arrayNumber={j}
                                state={state}
                                key={`${j} + workdays`}
                                dispatch={dispatch}
                            />
                        </ModalScheduleSection>
                    )
                })}
                <AddDeleteButtons
                    showAddButton={fields.length < 7}
                    handlerClickDelete={handlerClickDelete}
                    showDeleteButton={fields.length !== 1}
                    handlerClickAdd={handlerClickAdd}
                />
            </StyledForm>
        </ModalDefault>
    )
}
