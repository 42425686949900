import React from "react"
import { useParams } from "react-router-dom"
import { useGetRubricatorOpportunityQuery } from "features/user"
import { useGetDashboardOpportunityQuery } from "features/viewer"
import { OpportunitySection } from "entities/user"
import { Button, TextSection, useMediaSizeButton } from "shared/ui"
import { LinkSvg } from "shared/assets/svg"
import { useResize } from "shared/hook"
import { dateConverter } from "shared/api"
import {
    OppContainer,
    OpportunityConditions,
    OpportunityContacts,
    OpportunityDescription,
    OpportunityHeader,
    OpportunityRegInformer,
} from "./ui"

export const Opportunity = () => {
    const { fromMtoL } = useMediaSizeButton()
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()

    const { operator, id } = useParams()
    const { currentData: data } = useGetRubricatorOpportunityQuery(id!, {
        skip: !id || operator === "true",
    })
    const { currentData: dataPrivate } = useGetDashboardOpportunityQuery(id, { skip: !id || operator === "false" })
    if (!dataPrivate && !data) {
        return null
    }

    return (
        <>
            <OppContainer
                button={() => (
                    <>
                        {data?.openForRegistration && (
                            <Button
                                onClick={() => {
                                    window.open(data?.ctaUrl)
                                }}
                                size={fromMtoL}
                            >
                                Воспользоваться <LinkSvg />
                            </Button>
                        )}
                    </>
                )}
                photo={() => (
                    <OpportunitySection.PhotoSection
                        videoUrl={data?.videoUrl}
                        isSliderMode={width < isTablet}
                        images={(dataPrivate || data)?.images ?? []}
                    />
                )}
                contacts={() => (
                    <OpportunityContacts
                        websiteUrl={(dataPrivate || data)?.websiteUrl}
                        vkSocialUrl={(dataPrivate || data)?.vkSocialUrl}
                    />
                )}
                conditions={() => (
                    <OpportunityConditions
                        userCategories={(dataPrivate || data)?.userCategories ?? []}
                        endDate={dateConverter((dataPrivate || data)?.endDate ?? "").ddd_mmm_yyy(".")}
                        startDate={dateConverter((dataPrivate || data)?.startDate ?? "").ddd_mmm_yyy(".")}
                        ageRange={(dataPrivate || data)?.ageRange}
                    />
                )}
                conditionText={() => (
                    <TextSection title={"Условия получения"}>{(dataPrivate || data)?.condition}</TextSection>
                )}
                descriptionText={() => (
                    <TextSection title={"О возможности"}>{(dataPrivate || data)?.description}</TextSection>
                )}
                description={() => (
                    <OpportunityDescription
                        tags={(dataPrivate || data)?.tags ?? []}
                        categories={(dataPrivate || data)?.categories ?? []}
                        address={(dataPrivate || data)!.address}
                    />
                )}
                header={() => <OpportunityHeader name={(dataPrivate || data)!.name} />}
                regInformer={() => <> {!data?.openForRegistration && <OpportunityRegInformer />}</>}
            />
        </>
    )
}
