import { vkVideoStatic } from "shared/assets/svg"

export const transformUrlVideo = (videoURL: string) => {
    try {
        const url = new URL(videoURL)
        const searchParams = new URLSearchParams(url.search)
        const hostname = url.hostname
        const pathname = url.pathname
        if (hostname.includes("youtube")) {
            return `https://www.youtube.com/embed/${searchParams.get("v")}`
        }
        if (hostname.includes("youtu.be")) {
            return `https://www.youtube.com/embed/${pathname.slice(1, pathname.length)}`
        }

        if (hostname.includes("rutube")) {
            return `https://rutube.ru/play/embed/${pathname.split("/video/")[1].slice(0, -1)}/`
        }
        if (hostname.includes("vk")) {
            const re = /(video)(.+)(\/)/i
            const found = searchParams.get("z")?.match(re) ?? `${pathname.slice(1, pathname.length)}/`.match(re)
            const paramsFormatted = found?.[2].split("_")
            if (paramsFormatted && paramsFormatted?.[0] && paramsFormatted?.[1]) {
                return `https://vk.com/video_ext.php?oid=${paramsFormatted[0]}&id=${paramsFormatted[1]}&hd=2 `
            }
        }
        return null
    } catch (e) {
        return null
    }
}

export const transformUrlVideoWithPreview = (videoURL: string) => {
    try {
        const url = new URL(videoURL)
        const searchParams = new URLSearchParams(url.search)
        const hostname = url.hostname
        const pathname = url.pathname
        const id = searchParams.get("v")
        if (hostname.includes("youtube")) {
            return { url: `https://www.youtube.com/embed/${id}`, preview: `//img.youtube.com/vi/${id}/default.jpg` }
        }
        if (hostname.includes("youtu.be")) {
            const id = pathname.slice(1, pathname.length)
            return { url: `https://www.youtube.com/embed/${id}`, preview: `//img.youtube.com/vi/${id}/default.jpg` }
        }

        if (hostname.includes("rutube")) {
            const id = pathname.split("/video/")[1].slice(0, -1)
            return {
                url: `https://rutube.ru/play/embed/${id}/`,
                preview: `https://rutube.ru/api/video/${id}/thumbnail/?redirect=1`,
            }
        }
        if (hostname.includes("vk")) {
            const re = /(video)(.+)(\/)/i
            const found = searchParams.get("z")?.match(re) ?? `${pathname.slice(1, pathname.length)}/`.match(re)
            const paramsFormatted = found?.[2].split("_")
            if (paramsFormatted && paramsFormatted?.[0] && paramsFormatted?.[1]) {
                return {
                    url: `https://vk.com/video_ext.php?oid=${paramsFormatted[0]}&id=${paramsFormatted[1]}&hd=2 `,
                    preview: `data:image/svg+xml,${vkVideoStatic}`,
                }
            }
        }
        return null
    } catch (e) {
        return null
    }
}
