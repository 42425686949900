import { type IRubricatorFilters, useGetRubricatorFeedQuery, useGetRubricatorForMeQuery } from "features/user"
import { useForMeContext } from "shared/context"

type UseRubricatorType = {
    takePart: number
    type: 0 | 1 | 2
    filters: IRubricatorFilters | undefined
    countPage: number
}

export const useRubricator = ({ filters, type, takePart, countPage }: UseRubricatorType) => {
    const { isOn } = useForMeContext()

    if (!isOn) {
        const { data, isFetching, isUninitialized } = useGetRubricatorFeedQuery({
            opCats: filters?.opCats,
            nameCats: filters?.nameCats,
            sort: filters?.sort,
            placeCats: filters?.placeCats,
            userCats: filters?.userCats,
            onlyOpen: filters?.onlyOpen,
            type: type as 0 | 1 | 2,
            skip: type === undefined ? 0 : countPage * takePart,
            take: takePart,
        })

        return { data, isFetching, isUninitialized }
    }

    const { data, isFetching, isUninitialized } = useGetRubricatorForMeQuery("")

    return { data, isFetching, isUninitialized }
}
