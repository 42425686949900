import styled from "styled-components"
import { patternSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "../mediaVariables"

export const StyledPatternPageBackground = styled.div<{
    $color: string
    $position?: string
    $height?: string
}>`
    width: 100%;
    height: ${({ $height }) => $height ?? "1520px"};
    position: absolute;
    left: 0;
    background-image: url(${({ $color }) => `data:image/svg+xml,${patternSvgStatic}#${$color}`});
    background-repeat: no-repeat;
    background-position: ${({ $position }) => $position ?? "left -1196px bottom -440px"};
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        background: unset;
    }
`
