import { styled } from "styled-components"
import { Typography } from "shared/ui"
import { getMediaVariable } from "shared/styles"

export const StyledDefaultModalContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    //grid-template-rows: min-content auto min-content;
    row-gap: 16px;
    overflow: hidden;
    max-height: 100%;
`

export const StyledDefaultModalBody = styled.div`
    overflow-y: auto;
    height: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    gap: var(--assets-modals-informer-gap, 16px);
    justify-items: start;

    &::-webkit-scrollbar {
        display: none;
    }

    @media all and (max-width: ${getMediaVariable("laptop-l")}) {
        gap: var(--layout-desktop-form-gap, 24px);
    }
`

export const StyledControls = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-controls-gap, 8px);
    align-self: stretch;
`
export const StyledModalHeader = styled.div`
    display: grid;
    gap: var(--layout-mobile-title-gap, 8px);
`

export const StyledTitle = styled(Typography.Heading)`
    line-height: 100%;
`

export const StyledBody = styled(Typography.Basic)`
    line-height: 130%;
    font-size: clamp(1rem, 0.959rem + 0.179vw, 1.143rem) !important;
`
