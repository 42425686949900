import { type CardStatusType } from "shared/ui"

export enum OpportunityType {
    PLACE = "place",
    OPPORTUNITY = "opportunity",
}

export interface IOpportunityCard {
    titleCard: string
    descriptionCard: string
    cardType: OpportunityType
    cardStatus: CardStatusType
    imgSrc?: string
    id: number
}
