import { type UseFormProps } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { type IEditProfileData } from "features/viewer"
import { EditPersonalDataSchema } from "./EditPersonalDataSchema"
export const EditPersonalDataConfig = (defaultValues: IEditProfileData): UseFormProps<IEditProfileData> => {
    return {
        values: defaultValues,
        mode: "all",
        criteriaMode: "all",
        resolver: yupResolver(EditPersonalDataSchema),
    }
}
