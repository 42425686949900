import styled from "styled-components"

const StyledDocumentCard = styled.a`
    display: flex;
    padding: var(--assets-file-card-sizes-padding, 4px);
    border-radius: var(--assets-file-card-sizes-radius, 12px);
    background: var(--root-colors-main-white-light, rgba(0, 0, 0, 0.02));
`
const StyledDocumentCardIcon = styled.div`
    display: flex;
    width: var(--assets-file-card-sizes-icon-bg, 42px);
    height: var(--assets-file-card-sizes-icon-bg, 42px);
    justify-content: center;
    align-items: center;
    border-radius: var(--assets-file-card-sizes-icon-bg-radius, 8px);
    background: var(--assets-file-card-colors-fill-bg, #fff);
    & path {
        fill: #3e3aed;
    }
`
const StyledDocumentCardName = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--assets-file-card-sizes-gap-content, 4px);
    flex: 1 0 0;
    align-self: stretch;
`
const StyledDocumentCardSize = styled.div`
    color: var(--assets-file-card-colors-additional, rgba(0, 0, 0, 0.48));
    font-size: 12px;
    font-weight: 400;
    line-height: 100%; /* 12px */
`
export { StyledDocumentCard, StyledDocumentCardName, StyledDocumentCardIcon, StyledDocumentCardSize }
