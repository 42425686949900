import React, { type FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { type AxiosError } from "axios"
import { type IRestorePasswordForm, useLazyPostRestorePasswordQuery } from "features/user"
import { ChoiceInputAuth, useChoiceInputAuth } from "entities/user"
import { Button, ButtonType, FormLayout, Modal, useMediaSizeButton } from "shared/ui"
import { useModal } from "shared/hook"
import { StyledInputs } from "../AuthForm/styles"
import { RestoreSentModal } from "./ui"
import { schemaRestoreForm } from "./lib"

export const RestorePasswordForm: FC = () => {
    const { choice, setChoiceHandler } = useChoiceInputAuth()
    const methods = useForm<IRestorePasswordForm>({
        defaultValues: {
            email: "",
        },
        mode: "all",
        criteriaMode: "all",
        resolver: yupResolver(schemaRestoreForm(choice)),
    })

    const navigate = useNavigate()
    const { fromMtoL, fromStoM } = useMediaSizeButton()
    const modal = useModal()
    const [fetch, { error, isError, isSuccess }] = useLazyPostRestorePasswordQuery()
    useEffect(() => {
        if (error && isError) {
            const err = "error" in error ? (error.error as AxiosError)?.response?.data : null
            const mes =
                err && typeof err === "object" && "message" in err && typeof err.message === "string"
                    ? err.message
                    : "Некорректный email"
            methods.setError("email", { type: "is-correct", message: mes })
        }
        if (isSuccess) {
            modal.toggleActivity()
        }
    }, [error, isSuccess, isError])

    const handlerSubmit = methods.handleSubmit((data) => {
        fetch({ ...data })
    })
    return (
        <>
            <FormProvider {...methods}>
                <FormLayout
                    headerText={"Восстановление пароля"}
                    secondHeaderText={
                        "Чтобы сбросить пароль - укажите электронную почту или телефон, связанные с аккаунтом"
                    }
                    main={() => (
                        <>
                            <StyledInputs>
                                <ChoiceInputAuth choice={choice} setChoiceHandler={setChoiceHandler} />
                                {/*<YandexCaptcha />*/}
                            </StyledInputs>
                        </>
                    )}
                    footer={() => (
                        <>
                            <Button
                                onClick={handlerSubmit}
                                size={fromMtoL}
                                typeUI={methods.formState.isValid ? ButtonType.PRIMARY : ButtonType.DISABLED}
                                svgColor={"#0077FF"}
                            >
                                Сбросить пароль
                            </Button>
                            <Button
                                size={fromStoM}
                                typeUI={ButtonType.GHOST}
                                onClick={() => {
                                    navigate(-1)
                                }}
                            >
                                Назад
                            </Button>
                        </>
                    )}
                />
            </FormProvider>
            <Modal {...modal}>
                <RestoreSentModal />
            </Modal>
        </>
    )
}
