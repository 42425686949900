import styled, { css } from "styled-components"

const IdeaFormMixin = css`
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    align-self: stretch;
`

const StyledIdeaFormParent = styled.div`
    ${IdeaFormMixin};
    gap: var(--layout-mobile-form-gap, 24px);
    & > div {
        width: 100%;
    }
`

const StyledIdeaFormInputSection = styled.div`
    ${IdeaFormMixin};
    gap: var(--layout-mobile-section-gap, 12px);
    & > div {
        width: 100%;
    }
    & > div:first-child > div:first-child {
        padding: 0;
    }
`

export { StyledIdeaFormParent, StyledIdeaFormInputSection }
