import { type FC, useEffect, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Button, ButtonSize, ButtonType, FormField, SelectList, TypeInput, Vector, VectorType } from "shared/ui"
import { FillSvg, SearchSvg, SizeSvg } from "shared/assets/svg"
import { StyledInput, StyledInputWrapper } from "shared/styles"
import { type InputSearchProps } from "./types"
import { StyledInputSearch } from "./styles"

export const InputSearch: FC<InputSearchProps> = (props) => {
    const { name, placeholder, setSearchData, filteredValues } = props
    const { register, resetField } = useFormContext()
    const InputValueResults = useWatch({ name })
    const [, setSelected] = useState<string>("")
    const { watch } = useFormContext()
    const watchSearchInput = watch(name)
    useEffect(() => {
        setSearchData(watchSearchInput)
    }, [watchSearchInput])
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const handleClickOpen = () => {
        setIsOpen(!isOpen)
    }
    const [isShowClearButton, setShowClearButton] = useState<boolean>(false)
    return (
        <StyledInputSearch
            onBlur={(event) => {
                if (!event.currentTarget.contains(event.relatedTarget) && isOpen) {
                    setIsOpen(false)
                }
            }}
        >
            <FormField
                isSuccess={false}
                isInvalid={false}
                name={name}
                placeholder={placeholder}
                render={(focus) => (
                    <StyledInputWrapper>
                        <SearchSvg />
                        <StyledInput
                            autoComplete="off"
                            placeholder={placeholder}
                            {...register(name)}
                            type={TypeInput.TEXT}
                            onClick={() => {
                                setIsOpen(true)
                            }}
                            onFocus={focus}
                            onBlur={focus}
                            onInput={() => {
                                setIsOpen(true)
                            }}
                            onKeyUp={(e) => {
                                setShowClearButton(e.key === "Enter")
                                if (e.key === "Enter") {
                                    setIsOpen(false)
                                }
                            }}
                        />
                        {!!InputValueResults && isShowClearButton && (
                            <Button
                                typeUI={ButtonType.FREE}
                                hover={false}
                                onlyIcon={true}
                                size={ButtonSize.S}
                                onClick={() => resetField(name)}
                            >
                                <Vector size={SizeSvg.M} type={VectorType.CROSS} fill={FillSvg.BLACK} />
                            </Button>
                        )}
                    </StyledInputWrapper>
                )}
            />
            {isOpen && (
                <SelectList
                    handleClickOpenItem={handleClickOpen}
                    data={filteredValues.map((el) => ({ id: el, name: el }))}
                    isOpen={isOpen}
                    multiple={false}
                    name={name}
                    setSelected={setSelected}
                />
            )}
        </StyledInputSearch>
    )
}
