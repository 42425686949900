import React, { type FC } from "react"
import { NavLink } from "react-router-dom"
import { Choice, ChoiceGroup, ChoiceType, useMediaSizeChoice, useMediaSizeChoiceGroup } from "shared/ui"
import { GeoSvg, OpportunitySvg, useMediaSizeSvg } from "shared/assets/svg"
import { type ChoiceRubricatorProps } from "./types"
import { StyledChoice } from "./styles"

export const ChoiceRubricator: FC<ChoiceRubricatorProps> = (props) => {
    const { isModal = false } = props
    const { fromStoL } = useMediaSizeChoiceGroup()
    const { fromStoL: fromStoLChoice } = useMediaSizeChoice()
    const { fromMtoL: fromMtoLSvg } = useMediaSizeSvg()
    return (
        <StyledChoice>
            <ChoiceGroup size={fromStoL} isModal={isModal}>
                <NavLink to={""} end={true}>
                    {({ isActive }) => (
                        <Choice type={ChoiceType.NAVLINK} selected={isActive} size={fromStoLChoice}>
                            Все
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"opportunities"} end={true}>
                    {({ isActive }) => (
                        <Choice
                            type={ChoiceType.NAVLINK}
                            selected={isActive}
                            size={fromStoLChoice}
                            leftIcon={() => <OpportunitySvg size={fromMtoLSvg} />}
                        >
                            Возможности
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"place"} end={true}>
                    {({ isActive }) => (
                        <Choice
                            type={ChoiceType.NAVLINK}
                            selected={isActive}
                            size={fromStoLChoice}
                            leftIcon={() => <GeoSvg size={fromMtoLSvg} />}
                        >
                            Места
                        </Choice>
                    )}
                </NavLink>
            </ChoiceGroup>
        </StyledChoice>
    )
}
