import { type FC } from "react"
import { HeaderNavLink } from "features/HeaderNavlink"
import { StyledMenu, StyledMenuContainer } from "./styles"
import { type HeaderMenuProps } from "./types"
import { navList } from "./config"

export const Navbar: FC<HeaderMenuProps> = ({ onNavLinkClick }) => {
    return (
        <StyledMenuContainer>
            <StyledMenu>
                <HeaderNavLink onLinkClick={onNavLinkClick} navList={navList} />
            </StyledMenu>
        </StyledMenuContainer>
    )
}
