import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { getImagesArrWithTransformUrls, transformUrlImages, transformUrlVideo } from "shared/lib"
import { type NewItem, type NewsParams } from "./types"

const base_url_news = `${BASE_URL.PUBLIC}/news`
export const newsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getNewsData: builder.query<Array<NewItem>, NewsParams>({
            query: (params): RequestConfig<unknown, NewsParams> => ({
                url: base_url_news,
                method: "GET",
                params,
            }),
            merge: (currentCache, newItems) => {
                return [...currentCache, ...newItems]
            },
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            forceRefetch({ currentArg, previousArg, state, endpointState }) {
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },
            transformResponse: (data: NewItem[]) => {
                return [
                    ...getImagesArrWithTransformUrls(data, "images").map((el) => ({
                        ...el,
                    })),
                ]
            },
        }),
        getNewData: builder.query<NewItem, number>({
            query: (id: number): RequestConfig<unknown, unknown> => ({
                url: `${base_url_news}/${id}`,
                method: "GET",
            }),
            transformResponse: (data: NewItem) => {
                return {
                    ...data,
                    videoUrl: transformUrlVideo(data.videoUrl ?? "") ?? "",
                    images: data.images.map((el) => ({
                        ...el,
                        url: transformUrlImages(el.url),
                    })),
                }
            },
        }),
    }),
})

export const { useGetNewsDataQuery, useGetNewDataQuery } = newsApi
