import React, { type FC } from "react"
import { InputTextArea } from "shared/ui"

export const DescriptionField: FC = () => {
    return (
        <InputTextArea
            labelText={"Описание"}
            placeholder={"Что молодой посетитель может здесь получить?"}
            name={"description"}
        />
    )
}
