import { type FC } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { type IModalGoalReportForm } from "features/viewer"
import { AddDeleteButtons, Input, LinksSection } from "shared/ui"
import { ArrayFieldHandlers } from "shared/lib"

export const CloudLinksField: FC = () => {
    const { getValues, control } = useFormContext<IModalGoalReportForm>()

    const { fields, append, remove } = useFieldArray({
        name: "cloudLinks",
        control: control,
    })

    const { handlerClickDelete, handlerClickAdd } = new ArrayFieldHandlers<
        IModalGoalReportForm,
        { cloudLink?: string }[],
        "cloudLinks"
    >(
        getValues("cloudLinks"),
        append,
        remove,
        fields,
        {
            cloudLink: "",
        },
        true
    )
    return (
        <>
            <LinksSection title={"Ссылки на облако с фото"}>
                {fields.map((item, j) => {
                    return <Input name={`cloudLinks.${j}.cloudLink`} key={item.id} placeholder={"Ссылка..."} />
                })}
                <AddDeleteButtons
                    handlerClickDelete={handlerClickDelete}
                    handlerClickAdd={handlerClickAdd}
                    showDeleteButton={fields.length > 1}
                    showAddButton={fields.length < 7}
                />
            </LinksSection>
        </>
    )
}
