import React, { type FC, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate, useOutletContext } from "react-router-dom"
import { FiltersBar } from "features/user"
import { type IRubricatorFilters } from "features/user"
import { HeadingType, Typography } from "shared/ui"
import { ChoiceRubricator } from "../ChoiceRubricator"
import { type ContextType } from "../../types"
import { StyledTitle, StyledSection } from "./styles"

export const RubricatorHeader: FC = () => {
    const [filters, setFilters] = useState<IRubricatorFilters>()
    const { wasLogin } = useOutletContext<{ updateToken: () => void; wasLogin: boolean }>()
    const navigate = useNavigate()
    const location = useLocation()
    useEffect(() => {
        if (location.search) {
            navigate("/rubricator")
        }
    }, [])
    return (
        <>
            <StyledTitle>
                <Typography.Heading typeUI={HeadingType.H1}>Рубрикатор</Typography.Heading>
            </StyledTitle>
            <StyledSection>
                <ChoiceRubricator />
                <FiltersBar wasLogin={wasLogin} setFilters={setFilters}>
                    <ChoiceRubricator isModal={true} />
                </FiltersBar>
            </StyledSection>
            <Outlet context={{ filters } satisfies ContextType} />
        </>
    )
}
