import React, { type FC, useEffect } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { type IModalGoalReportForm, type IModalGoalReportFormRequest, useCompleteGoalMutation } from "features/viewer"
import { ProfileData, useMediaProfileData } from "entities/viewer"
import {
    BackButtonText,
    Button,
    ButtonType,
    FileAccept,
    FileLoad,
    FileType,
    HeadingType,
    Modal,
    ModalDefault,
    Typography,
    useMediaSizeButton,
} from "shared/ui"
import { RuleSvg, SizeSvg, StarSvg } from "shared/assets/svg"
import { formDataHandler } from "shared/api"
import { defaultValuesReportForm } from "../ModalGoal/config"
import { StyledModalGoalCardText, StyledModalGoalCountersForms } from "./styles"
import { CloudLinksField, FieldParticipants, FieldVolunteers, PostLinksField } from "./ui"
import { type ModalGoalReportProps } from "./types"
import { fieldsModalGoalReportSchema } from "./lib"

export const ModalGoalReport: FC<ModalGoalReportProps> = (props) => {
    const { toggleActivity, id, isActive, errorHandler } = props
    const formMethods = useForm<IModalGoalReportForm>({
        defaultValues: defaultValuesReportForm,
        mode: "all",
        criteriaMode: "all",
        resolver: yupResolver<IModalGoalReportForm>(fieldsModalGoalReportSchema),
    })
    const { fromMtoL } = useMediaSizeButton()
    const { SizeStoL } = useMediaProfileData()
    const [completeGoalRequest, { error }] = useCompleteGoalMutation()
    useEffect(() => {
        if (error && "status" in error && error.status === 400) {
            errorHandler()
        }
    }, [error])
    const handlerClickFooterButton = () => {
        toggleActivity()
        formMethods.handleSubmit(({ volunteers, attendees, ...data }) => {
            const cloudLinks = data.cloudLinks?.map((el) => el.cloudLink).filter((el) => !!el) as string[]
            const postLinks = data.postLinks?.map((el) => el.postLink).filter((el) => !!el) as string[]
            const result = {} as { cloudLinks?: string[]; postLinks?: string[] }
            if (cloudLinks.length > 0) {
                result["cloudLinks"] = cloudLinks
            }
            if (postLinks.length > 0) {
                result["postLinks"] = postLinks
            }
            if (id) {
                completeGoalRequest([
                    id,
                    formDataHandler(
                        { volunteers, attendees, files: data.files, ...result },
                        "files"
                    ) as IModalGoalReportFormRequest,
                ])
            }
        })()
    }
    return (
        <Modal position={"right"} bigHeight isActive={isActive} toggleActivity={toggleActivity}>
            <ModalDefault
                footerBtn={() => (
                    <Button
                        typeUI={
                            !formMethods.formState.isValid || formMethods.formState.isValidating
                                ? ButtonType.DISABLED
                                : ButtonType.PRIMARY
                        }
                        onClick={handlerClickFooterButton}
                        size={fromMtoL}
                    >
                        Отправить
                    </Button>
                )}
                backButtonText={BackButtonText.CANCEL}
            >
                <Typography.Heading typeUI={HeadingType.H2}>Отчет по задаче</Typography.Heading>
                <ProfileData size={SizeStoL} caption={"Задача"}>
                    <StyledModalGoalCardText>
                        <div>
                            <StarSvg size={SizeSvg.M} rule={RuleSvg.EVENODD} />
                        </div>
                        <div>Какая-то задачка c длинным названием например</div>
                    </StyledModalGoalCardText>
                </ProfileData>
                <FormProvider {...formMethods}>
                    <StyledModalGoalCountersForms>
                        <FieldVolunteers />
                        <FieldParticipants />
                    </StyledModalGoalCountersForms>
                    <PostLinksField />
                    <CloudLinksField />
                    <FileLoad
                        name={"files"}
                        title={"Дополнительный документ"}
                        accept={FileAccept.DOCUMENT}
                        FLType={FileType.DOCUMENT_LIST}
                    />
                </FormProvider>
            </ModalDefault>
        </Modal>
    )
}
