import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type DateSvgProps } from "./types"

export const DateSvg: FC<DateSvgProps> = (props) => {
    const { size = SizeSvg.XS } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path
                d="M4.66659 6C4.66659 6.36819 4.36811 6.66667 3.99992 6.66667C3.63173 6.66667 3.33325 6.36819 3.33325 6C3.33325 5.63181 3.63173 5.33333 3.99992 5.33333C4.36811 5.33333 4.66659 5.63181 4.66659 6Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M3.99992 9.33333C4.36811 9.33333 4.66659 9.03486 4.66659 8.66667C4.66659 8.29848 4.36811 8 3.99992 8C3.63173 8 3.33325 8.29848 3.33325 8.66667C3.33325 9.03486 3.63173 9.33333 3.99992 9.33333Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M4.66659 11.3333C4.66659 11.7015 4.36811 12 3.99992 12C3.63173 12 3.33325 11.7015 3.33325 11.3333C3.33325 10.9651 3.63173 10.6667 3.99992 10.6667C4.36811 10.6667 4.66659 10.9651 4.66659 11.3333Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M6.66659 6.66667C7.03478 6.66667 7.33325 6.36819 7.33325 6C7.33325 5.63181 7.03478 5.33333 6.66659 5.33333C6.2984 5.33333 5.99992 5.63181 5.99992 6C5.99992 6.36819 6.2984 6.66667 6.66659 6.66667Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M7.33325 8.66667C7.33325 9.03486 7.03478 9.33333 6.66659 9.33333C6.2984 9.33333 5.99992 9.03486 5.99992 8.66667C5.99992 8.29848 6.2984 8 6.66659 8C7.03478 8 7.33325 8.29848 7.33325 8.66667Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M6.66659 12C7.03478 12 7.33325 11.7015 7.33325 11.3333C7.33325 10.9651 7.03478 10.6667 6.66659 10.6667C6.2984 10.6667 5.99992 10.9651 5.99992 11.3333C5.99992 11.7015 6.2984 12 6.66659 12Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M9.99992 6C9.99992 6.36819 9.70144 6.66667 9.33325 6.66667C8.96506 6.66667 8.66659 6.36819 8.66659 6C8.66659 5.63181 8.96506 5.33333 9.33325 5.33333C9.70144 5.33333 9.99992 5.63181 9.99992 6Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M9.33325 9.33333C9.70144 9.33333 9.99992 9.03486 9.99992 8.66667C9.99992 8.29848 9.70144 8 9.33325 8C8.96506 8 8.66659 8.29848 8.66659 8.66667C8.66659 9.03486 8.96506 9.33333 9.33325 9.33333Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M9.99992 11.3333C9.99992 11.7015 9.70144 12 9.33325 12C8.96506 12 8.66659 11.7015 8.66659 11.3333C8.66659 10.9651 8.96506 10.6667 9.33325 10.6667C9.70144 10.6667 9.99992 10.9651 9.99992 11.3333Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M11.9999 6.66667C12.3681 6.66667 12.6666 6.36819 12.6666 6C12.6666 5.63181 12.3681 5.33333 11.9999 5.33333C11.6317 5.33333 11.3333 5.63181 11.3333 6C11.3333 6.36819 11.6317 6.66667 11.9999 6.66667Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                d="M12.6666 8.66667C12.6666 9.03486 12.3681 9.33333 11.9999 9.33333C11.6317 9.33333 11.3333 9.03486 11.3333 8.66667C11.3333 8.29848 11.6317 8 11.9999 8C12.3681 8 12.6666 8.29848 12.6666 8.66667Z"
                fill="black"
                fillOpacity="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.33325 1L3.66659 1V2H2.66659C1.93021 2 1.33325 2.59695 1.33325 3.33333V12.6667C1.33325 13.403 1.93021 14 2.66659 14H13.3333C14.0696 14 14.6666 13.403 14.6666 12.6667V3.33333C14.6666 2.59695 14.0696 2 13.3333 2H12.3333V1H11.6666V2H8.33325V1H7.66659V2H4.33325V1ZM7.66659 2.66667H4.33325V3.66667C4.14916 3.66667 3.66659 3.66667 3.66659 3.66667V2.66667H2.66659C2.2984 2.66667 1.99992 2.96514 1.99992 3.33333V12.6667C1.99992 13.0349 2.2984 13.3333 2.66659 13.3333H13.3333C13.7014 13.3333 13.9999 13.0349 13.9999 12.6667V3.33333C13.9999 2.96514 13.7014 2.66667 13.3333 2.66667H12.3333V3.66667H11.6666V2.66667H8.33325V3.66667H7.66659V2.66667Z"
                fill="black"
                fillOpacity="currentColor"
            />
        </svg>
    )
}
