import * as process from "process"
import { type FC } from "react"
import { FormProvider, useForm } from "react-hook-form"
import { object, type ObjectSchema, mixed } from "yup"

import { yupResolver } from "@hookform/resolvers/yup"
import { Button, ButtonType, FileAccept, FileLoad, FileLoadSize, FileType, type SelectData } from "shared/ui"
import { SizeSvg, TimeSvg } from "shared/assets/svg"
import { api_test } from "./api"

interface TestUIForm {
    images: FileList
}

const schemaTest: ObjectSchema<TestUIForm> = object({
    images: mixed<FileList>().required(),
}).required()

export const TestUI: FC = () => {
    const name: SelectData[] = [
        {
            name: "Выбор 1",
            id: 1,
            icon: <TimeSvg size={SizeSvg.M} />,
        },
        {
            name: "Выбор 2",
            id: 2,
            icon: <TimeSvg size={SizeSvg.M} />,
        },
        {
            name: "Выбор 3",
            id: 3,
            icon: <TimeSvg size={SizeSvg.M} />,
        },
    ]
    const methods = useForm<TestUIForm>({
        defaultValues: {},
        mode: "onChange",
        criteriaMode: "all",
        resolver: yupResolver(schemaTest),
    })
    const handleSubmit = async (data: any) => {
        await api_test(data)
    }
    console.log(process.env.REACT_APP_API_URL)
    return (
        <div>
            {/*<ChoiceInputAuth />*/}

            <FormProvider {...methods}>
                {/*<InputPassword validWindow={false} name={"password"} />*/}
                {/*<InputCounter name={"count"} labelText={"Введите число"} required={true} />*/}
                {/*<InputTime name={"time"} />*/}
                {/*/!*<Select data={name} name={"name"} clearable={true} />*!/*/}
                {/*<Select data={name} name={"textarea"} multiple={true} clearable={true} />*/}
                {/*<InputTextArea name={"name"} limit={300} labelText={"Test"} placeholder={"Test"} />*/}
                <FileLoad
                    accept={FileAccept.PICTURE}
                    FLType={FileType.PICTURE}
                    name={"images"}
                    title={"Фотографии"}
                    size={FileLoadSize.S}
                    // example={"dsds"}
                />
            </FormProvider>
            <Button typeUI={ButtonType.PRIMARY} onClick={methods.handleSubmit(handleSubmit)}>
                Отправить
            </Button>
        </div>
    )
}
