import { type FC, type PropsWithChildren } from "react"
import { EventCard, EventCardSize } from "entities/viewer"
import { useResize } from "shared/hook"
import { dateConverter } from "shared/api"
import { type EventsCalendarDayItemProps } from "./types"
import { StyledEventsCalendarDayItem, StyledEventsCalendarHeaderItem, StyledEventsCardList } from "./styles"
import { EventsCounter } from "./ui"

const EventsCalendarDayItem: FC<EventsCalendarDayItemProps> = (props) => {
    const {
        children,
        day,
        isWeekend = false,
        hasEvent = false,
        formattedData,
        isAnotherMonth = false,
        count,
        onClick,
    } = props
    const {
        mediaScreenSize: { isLaptopSm, isLaptopL, width },
    } = useResize()
    return (
        <StyledEventsCalendarDayItem
            onClick={onClick}
            $isAnotherMonth={isAnotherMonth}
            $count={count}
            $isWeekend={isWeekend}
            $hasEvent={hasEvent}
        >
            <p>{children}</p>
            {width > isLaptopSm + 1 && hasEvent && (
                <StyledEventsCardList>
                    {formattedData?.[day][0] && (
                        <EventCard
                            key={formattedData?.[day][0].id}
                            onClick={() => {}}
                            withLink={false}
                            size={EventCardSize.S}
                            link={formattedData?.[day][0].url}
                            startDate={dateConverter(formattedData?.[day][0].startDate).hh_mm()}
                            endDate={dateConverter(formattedData?.[day][0].endDate).hh_mm()}
                            text={formattedData?.[day][0].title}
                        />
                    )}
                    {width >= isLaptopL && formattedData?.[day][1] && (
                        <EventCard
                            onClick={() => {}}
                            withLink={false}
                            size={EventCardSize.S}
                            link={formattedData?.[day][1].url}
                            startDate={dateConverter(formattedData?.[day][1].startDate).hh_mm()}
                            endDate={dateConverter(formattedData?.[day][1].startDate).hh_mm()}
                            text={formattedData?.[day][1].title}
                        />
                    )}
                </StyledEventsCardList>
            )}
            <EventsCounter
                count={formattedData?.[day]?.length}
                hasEvent={hasEvent}
                isAnotherMonth={isAnotherMonth}
                isWeekend={isWeekend}
                isLaptop={width <= isLaptopSm + 1}
            />
        </StyledEventsCalendarDayItem>
    )
}

const EventsCalendarHeaderItem: FC<PropsWithChildren> = ({ children }) => {
    return <StyledEventsCalendarHeaderItem>{children}</StyledEventsCalendarHeaderItem>
}

export const EventsCalendarItem = {
    Day: EventsCalendarDayItem,
    Header: EventsCalendarHeaderItem,
}
