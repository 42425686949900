import React, { type FC } from "react"
import {
    BackButtonText,
    BasicType,
    Button,
    ButtonType,
    HeadingType,
    type ModalDefaultProps,
    useMediaSizeButton,
} from "shared/ui"
import {
    StyledBody,
    StyledControls,
    StyledDefaultModalBody,
    StyledDefaultModalContainer,
    StyledModalHeader,
    StyledTitle,
} from "./styles"

export const ModalDefault: FC<ModalDefaultProps> = (props) => {
    const {
        title,
        body,
        footerBtn,
        additionalHeadComponent,
        backButtonText = BackButtonText.BACK,
        backButton = true,
        children,
        back,
        beforeAdditionalHeadComponent,
    } = props
    const { fromStoM } = useMediaSizeButton()

    return (
        <StyledDefaultModalContainer className={props.className}>
            <StyledModalHeader>
                {beforeAdditionalHeadComponent?.()}
                <StyledTitle typeUI={HeadingType.H2} className={props.className}>
                    {title}
                </StyledTitle>
                {additionalHeadComponent?.()}
            </StyledModalHeader>
            <StyledDefaultModalBody>
                {body && (
                    <StyledBody className={props.className} typeUI={BasicType.SECOND}>
                        {body}
                    </StyledBody>
                )}
                {children}
            </StyledDefaultModalBody>
            <StyledControls>
                {footerBtn?.()}
                {backButton && (
                    <Button onClick={back} size={fromStoM} typeUI={ButtonType.GHOST}>
                        {backButtonText}
                    </Button>
                )}
            </StyledControls>
        </StyledDefaultModalContainer>
    )
}
