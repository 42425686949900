import dayjs from "dayjs"
import localeData from "dayjs/plugin/localeData"
import ru from "dayjs/locale/ru"
import updateLocale from "dayjs/plugin/updateLocale"

dayjs.extend(localeData)
dayjs.extend(updateLocale)
dayjs.locale(ru)

dayjs.updateLocale("ru", {
    weekdaysShort: ["Пн", "Вт", "Ср", "Чт", "Пт", "Сб", "Вс"],
    months: [
        "Январь",
        "Февраль",
        "Март",
        "Апрель",
        "Май",
        "Июнь",
        "Июль",
        "Август",
        "Сентябрь",
        "Октябрь",
        "Ноябрь",
        "Декабрь",
    ],
})

export const daysOfWeek = dayjs.weekdaysShort()

export const months = dayjs.months()
