import { type HTMLAttributes, type ReactNode } from "react"

export enum InformerType {
    BASIC = "basic",
    SUCCESS = "success",
    WARNING = "warning",
    ERROR = "error",
}
export interface InformerProps extends HTMLAttributes<HTMLDivElement> {
    type?: InformerType
    title?: string
    children?: ReactNode
    isWithSvg?: boolean
    icon?: () => ReactNode
    isNotIcon?: boolean
}
