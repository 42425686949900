import styled from "styled-components"
import { type FormFieldState } from "shared/hook/useFormField"

const StyledLabelForm = styled.label<{
    $state?: FormFieldState
}>`
    display: inline-flex;
    gap: var(--assets-label-required-gap, 2px);
    color: var(--assets-label-colors-${(props) => props.$state});

    font-size: 12px;
    line-height: 16px;

    padding: 0px var(--assets-label-padding-hor, 12px) var(--assets-label-gap, 2px)
        var(--assets-label-padding-hor, 12px);

    & > span {
        color: var(--assets-checkbox-checked-red-fill);
    }
`

export { StyledLabelForm }
