import styled from "styled-components"

export const StyledOpportunityPhoto = styled.div`
    display: flex;
    width: 326px;
    height: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: var(--root-colors-accent-crema, #f4efe9);
`

export const StyledImage = styled.img`
    width: inherit;
    height: 360px;
    object-fit: cover;
    border-radius: inherit;
`

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-section-gap, 12px);
    align-self: stretch;
    height: 360px;
    & > * {
        padding: 0;
    }
`

export const StyledVideoContainer = styled.iframe`
    display: flex;
    width: 326px;
    height: 360px;
    max-height: 360px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 24px;
    background-color: var(--root-colors-accent-crema, #f4efe9);
`
