import React, { type FC } from "react"
import { Link } from "react-router-dom"
import { useGetProfileDataQuery } from "features/viewer"
import { ProfileData, ProfileDataType, useMediaProfileData } from "entities/viewer"
import {
    Button,
    ButtonType,
    Group,
    Informer,
    InformerType,
    type SelectData,
    Tag,
    useMediaGroup,
    useMediaSizeButton,
    useMediaTag,
} from "shared/ui"
import { EditSvg, InvalidSvg, OpportunitySvg, SizeSvg, VkIconSvg } from "shared/assets/svg"
import { StyledProfileDataPattern } from "shared/styles"
import { StyledProfileSection } from "../styles"

export const WorkSheet: FC = () => {
    const { data, isLoading } = useGetProfileDataQuery("")
    const type = data?.userVkId && data?.interests && data?.userCategories
    const mediaTag = useMediaTag()
    const mediaProfileData = useMediaProfileData()
    const mediaButtonSize = useMediaSizeButton()
    const mediaGroup = useMediaGroup()

    if (type) {
        return (
            <StyledProfileSection>
                <Group>
                    <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowReversToColumn}>
                        <Link to={"/rubricator"}>
                            <Button typeUI={ButtonType.PRIMARY} size={mediaButtonSize.fromMtoL}>
                                <OpportunitySvg size={SizeSvg.S} />
                                Узнать возможности
                            </Button>
                        </Link>
                        <Link to={"/profile-edit/work-sheet"}>
                            <Button id={"change-top"} typeUI={ButtonType.GHOST} size={mediaButtonSize.fromMtoL}>
                                <EditSvg size={SizeSvg.S} />
                                Изменить
                            </Button>
                        </Link>
                    </Group>
                    <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowToColumn}>
                        <ProfileData
                            caption={"Вконтакте"}
                            type={ProfileDataType.LINK}
                            size={mediaProfileData.SizeStoL}
                            icon={(size) => <VkIconSvg size={size} />}
                        >
                            {data?.userVkId}
                        </ProfileData>
                        <ProfileData
                            caption={"Инвалидность"}
                            size={mediaProfileData.SizeStoL}
                            icon={(size) => <InvalidSvg size={size} />}
                        >
                            {data?.disabilityGroup || "Не имеет"}
                        </ProfileData>
                    </Group>
                    <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowToColumn}>
                        <StyledProfileDataPattern $type={"interests"}>
                            <ProfileData
                                caption={"Мне интересно"}
                                type={ProfileDataType.TAG}
                                size={mediaProfileData.SizeStoL}
                            >
                                {data?.interests.map((item: SelectData, index) => (
                                    <Tag key={index} size={mediaTag.TagSizeStoM}>
                                        {item.name}
                                    </Tag>
                                ))}
                            </ProfileData>
                        </StyledProfileDataPattern>
                        <StyledProfileDataPattern $type={"status"}>
                            <ProfileData
                                caption={"Мой статус"}
                                type={ProfileDataType.TAG}
                                size={mediaProfileData.SizeStoL}
                            >
                                {data?.userCategories.map((item: SelectData, index) => (
                                    <Tag key={index} size={mediaTag.TagSizeStoM}>
                                        {item.name}
                                    </Tag>
                                ))}
                            </ProfileData>
                        </StyledProfileDataPattern>
                    </Group>
                    <Link to={"/profile-edit/work-sheet"}>
                        <Button id={"change-bottom"} typeUI={ButtonType.GHOST} size={mediaButtonSize.fromMtoL}>
                            <EditSvg size={SizeSvg.S} />
                            Изменить
                        </Button>
                    </Link>
                </Group>
            </StyledProfileSection>
        )
    }
    return (
        <Group size={mediaGroup.size.sizeLtoS} direction={mediaGroup.direction.RowReversToColumn}>
            <Link to={"/profile-edit/work-sheet"}>
                <Button typeUI={ButtonType.PRIMARY} size={mediaButtonSize.fromMtoL}>
                    <EditSvg size={SizeSvg.S} />
                    Заполнить анкету
                </Button>
            </Link>
            <Informer type={InformerType.BASIC} title={"Заполнение анкеты"}>
                Чтобы иметь доступ ко всем возможностям сайта, пожалуйста, заполните небольшую анкету о себе.
            </Informer>
        </Group>
    )
}
