import * as yup from "yup"
import { number } from "yup"

export const fieldsModalGoalReportSchema = yup.object().shape({
    cloudLinks: yup
        .array(
            yup.object({
                cloudLink: yup.string().url("Введите корректную ссылку").optional(),
            })
        )
        .required(""),
    volunteers: number()
        .required()
        .test("is-enough", "Введите корректное число", function (value) {
            return !!value
        }),
    attendees: number()
        .required()
        .test("is-enough", "Введите корректное число", function (value) {
            return !!value
        }),
    postLinks: yup
        .array(
            yup.object({
                postLink: yup.string().url("Введите корректную ссылку").optional(),
            })
        )
        .required(""),
    files: yup
        .mixed<FileList>()
        .optional()
        .test((value) => {
            return !value?.length || !(value && value[0].size > 5000000)
        }),
})
