import { BASE_URL, mainApi } from "features/api"
import { getImagesArrWithTransformUrls, transformUrlImages } from "shared/lib"
import { type RequestConfig } from "shared/api"
import { type OpportunityItem, type RubricatorParams, type RubricatorResponse } from "./types"

const BASE_URL_RUBRICATOR = `${BASE_URL.PUBLIC}/rubricator`

let variable: boolean
export const rubricatorApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getRubricatorFeed: builder.query<RubricatorResponse, RubricatorParams>({
            query: (credentials: RubricatorParams): RequestConfig<unknown, RubricatorParams> => ({
                url: `${BASE_URL.PUBLIC}/rubricator/feed`,
                method: "GET",
                params: credentials,
            }),
            providesTags: ["Rubricator"],
            serializeQueryArgs: ({ endpointName }) => {
                return endpointName
            },
            merge: (currentCache, newItems, baseQueryMeta) => {
                if (!variable) {
                    return {
                        opportunities: [...(currentCache?.opportunities ?? []), ...(newItems?.opportunities ?? [])],
                        places: [...(currentCache?.places ?? []), ...(newItems?.places ?? [])],
                    }
                }
                return {
                    opportunities: [...(newItems?.opportunities ?? [])],
                    places: [...(newItems?.places ?? [])],
                }
            },
            forceRefetch({ currentArg, previousArg, state, endpointState }) {
                variable = currentArg?.skip === 0 || currentArg?.skip === previousArg?.skip
                return JSON.stringify(currentArg) !== JSON.stringify(previousArg)
            },

            transformResponse: (data: RubricatorResponse, ...rest) => {
                const opportunities = getImagesArrWithTransformUrls<OpportunityItem>(data.opportunities ?? [], "images")
                const places = getImagesArrWithTransformUrls<OpportunityItem>(data.places ?? [], "images")
                return {
                    opportunities,
                    places,
                }
            },
        }),
        getRubricatorPlace: builder.query<OpportunityItem, string>({
            query: (id: string): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL_RUBRICATOR}/places/${id}`,
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem, ...rest) => {
                return {
                    ...data,
                    images: data.images?.map((el) => ({
                        url: transformUrlImages(el.url),
                    })),
                }
            },
        }),
        getRubricatorOpportunity: builder.query<OpportunityItem, string>({
            query: (id: string): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL_RUBRICATOR}/opportunities/${id}`,
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem, ...rest) => {
                return {
                    ...data,
                    images: data.images?.map((el) => ({
                        url: transformUrlImages(el.url),
                    })),
                }
            },
        }),
        getRubricatorForMe: builder.query<RubricatorResponse, string>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL.USER}/rubricator/for-me`,
                method: "GET",
            }),
            transformResponse: (data: RubricatorResponse, ...rest) => {
                const opportunities = getImagesArrWithTransformUrls<OpportunityItem>(data.opportunities ?? [], "images")
                const places = getImagesArrWithTransformUrls<OpportunityItem>(data.places ?? [], "images")
                return {
                    opportunities,
                    places,
                }
            },
        }),
    }),
})

export const {
    useGetRubricatorFeedQuery,
    useLazyGetRubricatorFeedQuery,
    useGetRubricatorForMeQuery,
    useGetRubricatorPlaceQuery,
    useGetRubricatorOpportunityQuery,
} = rubricatorApi
