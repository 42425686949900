import React, { type FC } from "react"

import { Link } from "react-router-dom"
import { Avatar, AvatarSize, useGetProfileDataQuery } from "features/viewer"
import { ProfileData, ProfileDataSize } from "entities/viewer"
import { Button, ButtonSize, ButtonType, HeadingType, Typography } from "shared/ui"
import { DateSvg, EditSvg, GeoSvg, SizeSvg } from "shared/assets/svg"
import { useResize } from "shared/hook"
import { StyledAvatar, StyledLinkUpdate, StyledProfileTop, StyledProfileTopData } from "./styles"

export const ProfileTop: FC = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    const { data, isLoading } = useGetProfileDataQuery("")
    const name = `${data?.lastname} ${data?.firstname} ${data?.midname}`
    const birthDay = data?.birthday.split("T")[0].split("-").reverse().join(".")
    const mediaProfile = width > isTablet ? ProfileDataSize.L : ProfileDataSize.S
    const mediaAvatar = width > isTablet ? AvatarSize.XL : AvatarSize.L
    console.log(data)
    if (!isLoading && data) {
        return (
            <StyledProfileTop>
                <StyledAvatar>
                    <Avatar visibleName={false} size={mediaAvatar} />
                    <div>
                        <Typography.Heading typeUI={HeadingType.H2}>{name}</Typography.Heading>
                        <Link to={"/profile-edit"}>
                            <Button size={ButtonSize.XS} typeUI={ButtonType.SECONDARY}>
                                <EditSvg size={SizeSvg.S} />
                                Редактировать
                            </Button>
                        </Link>
                    </div>
                </StyledAvatar>
                <StyledLinkUpdate>
                    <Link to={"/profile-edit"}>
                        <Button size={ButtonSize.XS} typeUI={ButtonType.SECONDARY}>
                            <EditSvg size={SizeSvg.S} />
                            Редактировать
                        </Button>
                    </Link>
                </StyledLinkUpdate>
                <StyledProfileTopData>
                    <ProfileData caption={"Населенный пункт"} size={mediaProfile}>
                        <GeoSvg size={SizeSvg.M} />
                        {data?.locality}
                    </ProfileData>
                    <ProfileData caption={"Дата рождения"} size={mediaProfile}>
                        <DateSvg size={SizeSvg.M} />
                        {birthDay}
                    </ProfileData>
                </StyledProfileTopData>
            </StyledProfileTop>
        )
    }
}
