import React, { type FC } from "react"
import { conditions } from "shared/lib"
import { FileType } from "../../types"
import { DocumentCard } from "../DocumentCard"
import { ImagesCard } from "../ImagesCard"
import { type UploadFileProps } from "./types"

export const UploadFile: FC<UploadFileProps> = (props) => {
    const { url = "", name = "", type, accept, size, mimetype } = props
    const isDocument = conditions<FileType>(type, [
        [FileType.DOCUMENT, true],
        [FileType.DOCUMENT_LIST, true],
    ])
    const isPicture = conditions<FileType>(type, [
        [FileType.PICTURE, true],
        [FileType.PICTURE_LIST, true],
    ])
    const isMultiple = ""
    const regImage = new RegExp("image")
    const regDoc = new RegExp("/document|application/")
    const isMultipleImg = mimetype && mimetype.match(regImage)
    const isMultipleDoc = mimetype && mimetype.match(regDoc) && url

    return (
        <>
            {isDocument && url && (
                <a href={url} download={true}>
                    <DocumentCard size={size} name={name as string} />
                </a>
            )}
            {isPicture && <ImagesCard img={url as string} />}
            {isMultipleImg && <ImagesCard img={url as string} />}
            {isMultipleDoc && (
                <a href={url} download={true}>
                    <DocumentCard size={size} name={name as string} />
                </a>
            )}
        </>
    )
}
