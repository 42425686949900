import axios from "axios"

export const axiosInstanceViewer = axios.create({
    baseURL: "https://youth-api.dev-vt2b.ru",
    headers: {
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        "Content-Type": "application/json",
    },
})

export const axiosInstanceUser = axios.create({
    headers: {
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
        // "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
    },
})
