import React, { type FC } from "react"
import { OpportunityPageLayout } from "entities/user"
import { useResize } from "shared/hook"
import { WrapperColumn } from "shared/ui"
import { type PlaceContainerProps } from "./types"

export const PlaceContainer: FC<PlaceContainerProps> = (props) => {
    const { footerButton, contacts, aboutText, about, header, schedule, photo, description } = props
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return (
        <>
            {width < isTablet ? (
                <>
                    <OpportunityPageLayout footerButton={footerButton}>
                        {header()}
                        {photo()}
                        {about()}
                        {aboutText()}
                        {description()}
                        {schedule()}
                        {contacts()}
                    </OpportunityPageLayout>
                </>
            ) : (
                <WrapperColumn.Wrapper>
                    <WrapperColumn.Item maxWidth={396}>
                        {header()}
                        {footerButton?.()}
                        {about()}
                        {description()}
                        {schedule()}
                        {contacts()}
                    </WrapperColumn.Item>
                    <WrapperColumn.Item maxWidth={640}>
                        {photo()}
                        {aboutText()}
                    </WrapperColumn.Item>
                </WrapperColumn.Wrapper>
            )}
        </>
    )
}
