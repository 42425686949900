import React, { type FC } from "react"
import { type TagProps, TagSize } from "./types"
import { StyledTag } from "./styles"

export const Tag: FC<TagProps> = (props) => {
    const { size = TagSize.M, icon, children, bgColor } = props
    return (
        <StyledTag $bgColor={bgColor} $size={size}>
            {typeof icon === "function" && icon()}
            {children}
        </StyledTag>
    )
}
