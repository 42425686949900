import styled from "styled-components"
import { FileLoadSize } from "shared/ui"
import { getMediaVariable } from "shared/styles"

const StyledFileLoad = styled.div`
    position: relative;
    display: flex;
    padding: var(--assets-file-custom-card-sizes-s-padding, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-file-custom-card-sizes-s-gap, 12px);
    border-radius: var(--assets-file-custom-card-sizes-s-radius, 16px);
    border: 1px solid var(--assets-file-custom-card-colors-stroke, rgba(0, 0, 0, 0.06));
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        min-width: var(--assets-opportunity-cards-sizes-s-width, 326px);
    }
`
const StyledFileLoadTitle = styled.div<{
    $size: FileLoadSize
}>`
    color: var(--layout-text-colors-primary, #000);
    font-size: ${({ $size }) => ($size === FileLoadSize.S ? "14px" : "16px")};
    font-weight: 400;
    line-height: 100%;
`
const StyledFileLoadFiles = styled.div`
    display: flex;
    gap: 12px;
    overflow-x: scroll;
    &::-webkit-scrollbar {
        display: none;
    }
    &,
    & > div {
        width: 100%;
    }

    max-height: 200px;
    & > div > div {
        justify-content: flex-start;
    }
`
const StyledFileLoadMain = styled.div<{
    $visible: boolean
}>`
    display: ${({ $visible }) => ($visible ? "flex" : "none")};
    padding: var(--assets-file-load-sizes-s-padding, 24px);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: var(--assets-file-load-sizes-s-gap, 8px);
    align-self: stretch;

    border-radius: var(--assets-file-load-sizes-s-radius, 12px);
    border: 1px dashed var(--assets-file-load-colors-stroke, #3e3aed);
`

const StyledFileLoadDescription = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: 2fr 2fr;
    align-self: stretch;
`

const StyledDescriptionSection = styled.div`
    color: var(--assets-file-load-colors-content, #3e3aed);
    font-size: 12px;
    line-height: 16px;
    &:first-of-type {
        border-right: 1px solid var(--assets-file-load-colors-divider, #3e3aed);
    }
    &:nth-last-of-type(1) {
        text-align: right;
    }
`

const StyledFileUpload = styled.div`
    display: block;
`
const StyledFileUnUpload = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--assets-file-load-sizes-s-gap, 8px);
`

const StyledFileLoadDropZone = styled.div<{
    $active: boolean
}>`
    position: absolute;
    left: 0;
    top: 0;
    z-index: 99;
    width: 100%;
    height: 100%;
    display: ${({ $active }) => ($active ? "block" : "none")};
    border-radius: var(--assets-file-load-sizes-s-radius, 12px);
    border: 1px solid var(--assets-file-load-colors-stroke, #3e3aed);
    background-color: white;

    align-self: stretch;
    & > div {
        width: 100%;
        height: 100%;
        display: flex;
        border-radius: var(--assets-file-load-sizes-s-radius, 12px);
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: var(--assets-file-load-colors-fill, rgba(62, 58, 237, 0.04));
    }
    color: var(--assets-file-load-colors-content, #3e3aed);
    text-align: center;
    font-size: 14px;
    line-height: 16px; /* 114.286% */
`

export {
    StyledFileLoadTitle,
    StyledFileLoad,
    StyledFileLoadMain,
    StyledFileLoadDescription,
    StyledDescriptionSection,
    StyledFileUpload,
    StyledFileUnUpload,
    StyledFileLoadFiles,
    StyledFileLoadDropZone,
}
