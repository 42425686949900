import { type FC } from "react"
import { Informer, InformerType } from "shared/ui"
import { OpportunityType } from "../../types"
import { type OpportunityInformerProps } from "./types"

export const OpportunityInformer: FC<OpportunityInformerProps> = (props) => {
    const { informerType } = props
    return (
        <>
            {informerType === OpportunityType.OPPORTUNITY ? (
                <Informer type={InformerType.BASIC} title={"Предложение возможностей"}>
                    Вы можете предложить возможность для молодых людей - после проверки администрацией она может попасть
                    в официальный рубрикатор портала!
                </Informer>
            ) : (
                <Informer type={InformerType.BASIC} title={"Предложение мест"}>
                    Вы можете предложить место, которое было бы интересно или полезно посетить молодым людям - после
                    проверки администрацией оно может попасть в официальный рубрикатор портала!
                </Informer>
            )}
        </>
    )
}
