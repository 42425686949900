import React, { type FC } from "react"
import { useGetAboutUsDataQuery } from "features/user"
import { OpportunitySection } from "entities/user"
import { HeadingType, Typography, WrapperColumn } from "shared/ui"
import { useResize } from "shared/hook"
import { StyledPatternPageBackground } from "shared/styles"
import { StyledAboutMain, StyledText, StyledTitle } from "./styles"

export const AboutMain: FC = () => {
    const {
        mediaScreenSize: { width, isLaptopSm, isTablet },
    } = useResize()
    const media = width > isLaptopSm
    const mediaSlider = width < isTablet
    const { data } = useGetAboutUsDataQuery({})
    return (
        <>
            <StyledPatternPageBackground
                $height={"1279px"}
                $position={"left -1364px bottom -440px"}
                $color={"mainRedFill"}
            />
            <StyledAboutMain>
                <WrapperColumn.Wrapper isMobile={!media}>
                    <WrapperColumn.Item maxWidth={800} minWidth={300}>
                        <StyledTitle>
                            <Typography.Heading typeUI={HeadingType.H1}>{data?.heading}</Typography.Heading>
                        </StyledTitle>
                        {media && (
                            <StyledText dangerouslySetInnerHTML={{ __html: data?.["main-content"] || "<div></div>" }} />
                        )}
                    </WrapperColumn.Item>
                    <WrapperColumn.Item maxWidth={900}>
                        <OpportunitySection.PhotoSection isSliderMode={mediaSlider} images={data?.images ?? []} />
                        {media || (
                            <StyledText dangerouslySetInnerHTML={{ __html: data?.["main-content"] || "<div></div>" }} />
                        )}
                    </WrapperColumn.Item>
                </WrapperColumn.Wrapper>
            </StyledAboutMain>
        </>
    )
}
