import styled from "styled-components"
import { conditions } from "shared/lib"
import { CardStatusType } from "./types"

const CardColor = (type: CardStatusType) => {
    return conditions<CardStatusType>(type, [
        [CardStatusType.WAITING, "orange"],
        [CardStatusType.ACCEPTED, "green"],
        [CardStatusType.DECLINED, "error"],
        [CardStatusType.ARCHIVE, "red"],
        [CardStatusType.REGISTRATION, "green"],
        [CardStatusType.PUBLIC, "green"],
    ])
}
const StyledCardStatus = styled.div<{
    $type: CardStatusType
}>`
    display: inline-flex;
    height: var(--assets-tag-sizes-s-height);
    padding: 0px var(--assets-tag-sizes-s-padding-hor);
    align-items: center;
    background: var(--assets-tag-colors-${({ $type }) => CardColor($type)}-fill);
    gap: var(--assets-tag-sizes-s-gap);
    border-radius: var(--assets-tag-sizes-s-radius);
    color: var(--assets-tag-colors-content);
    font-size: 12px;
    & > svg path {
        fill: white;
    }
`

export { StyledCardStatus }
