import React from "react"
import { GiftCoordinatorCard, Progress, useMediaCoordinatorCard } from "features/viewer"
import { conditions } from "shared/lib"
import { Button, ButtonSize, Informer, InformerType } from "shared/ui"
import { PointsState } from "../types"

export const getGiftConditions = (presentState: PointsState, toggleActivity: () => void) =>
    conditions<PointsState>(presentState, [
        [
            PointsState.ENOUGH,
            {
                button: () => {
                    return (
                        <Button onClick={toggleActivity} size={ButtonSize.M}>
                            Получить
                        </Button>
                    )
                },
                modalBottom: () => {
                    const { fromLtoS } = useMediaCoordinatorCard()
                    return <GiftCoordinatorCard size={fromLtoS} />
                },
            },
        ],
        [
            PointsState.NOT_ENOUGH,
            {
                button: () => <Progress onClick={toggleActivity} />,
                modalBottom: () => (
                    <Informer
                        isWithSvg={true}
                        type={InformerType.WARNING}
                        title={"Недостаточно баллов для получения подарка"}
                    >
                        Выполняйте задания, чтобы набрать нужное количество баллов и подняться в рейтинге.
                    </Informer>
                ),
            },
        ],
        [
            PointsState.ALREADY_GOT_GIFT,
            {
                button: () => (
                    <Informer onClick={toggleActivity} type={InformerType.SUCCESS} isWithSvg={true}>
                        Получен!
                    </Informer>
                ),

                modalBottom: () => (
                    <Informer isWithSvg={true} type={InformerType.SUCCESS} title={"Подарок получен!"}>
                        Новый подарок будет объявлен в следующем месяце - следите за обновлениями!
                    </Informer>
                ),
            },
        ]
    ])
