import { type HTMLAttributes, type ReactNode } from "react"

export enum ChoiceGroupSize {
    L = "l",
    M = "m",
    S = "s",
    XS = "xs",
}

export interface ChoiceGroupProps extends HTMLAttributes<HTMLDivElement> {
    size?: ChoiceGroupSize
    scroll?: boolean
    children: ReactNode
    heightAuto?: boolean
    isModal?: boolean
}
