import { type HTMLAttributes } from "react"

export enum CardStatusType {
    WAITING = "WAITING",
    ACCEPTED = "ACCEPTED",
    DECLINED = "DECLINED",
    NONE = "NONE",
    ARCHIVE = "ARCHIVE",
    REGISTRATION = "REGISTRATION",
    PUBLIC = "PUBLIC",
}

export enum CardType {
    APPLICATION = "application",
    STATUS = "status",
    OPPORTUNITY_STATUS = "opportunity-status",
}

export interface CardStatusProps extends HTMLAttributes<HTMLDivElement> {
    statusType: CardStatusType
    cardType: CardType
    isIcon?: boolean
}
