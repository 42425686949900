import { styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledControlsGroup = styled.div`
    display: grid;
    gap: 8px;
    @media all and (min-width: ${getMediaVariable("laptop")}) {
        grid-template-columns: 112px min-content;
    }
    @media all and (max-width: ${getMediaVariable("laptop")}) {
        grid-template-columns: 1fr min-content;
    }
`
