import styled from "styled-components"

const StyledTextSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-self: stretch;
    line-height: 18px;
    width: 100%;
    word-break: break-word;
`

const StyledTitle = styled.div`
    line-height: 12px;
`

export { StyledTextSection, StyledTitle }
