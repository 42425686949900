import { type FC } from "react"
import { useResize } from "shared/hook"
import { ScrollGroup } from "shared/ui"
import { MainColumn, ScrollGroupContainer, StyledMainContainer, StyledScrollColumn } from "../../styles"
import { type MainContainerProps } from "./types"

export const MainContainer: FC<MainContainerProps> = ({ column1, column2, btnControl }) => {
    const {
        mediaScreenSize: { width, isLaptopL },
    } = useResize()

    return (
        <>
            {width > isLaptopL ? (
                <StyledMainContainer>
                    <MainColumn>
                        {column1()}
                        {btnControl()}
                    </MainColumn>
                    <MainColumn>{column2()}</MainColumn>
                </StyledMainContainer>
            ) : (
                <StyledMainContainer>
                    <ScrollGroupContainer>
                        <ScrollGroup>
                            <StyledScrollColumn>{column1()}</StyledScrollColumn>
                            <StyledScrollColumn>{column2()}</StyledScrollColumn>
                        </ScrollGroup>
                    </ScrollGroupContainer>
                    {btnControl()}
                </StyledMainContainer>
            )}
        </>
    )
}
