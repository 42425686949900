import { type FC } from "react"
import { type CardProps } from "./types"
import { StyledCard } from "./styles"

export const Card: FC<CardProps> = (props) => {
    const { children, withHover = false, ...rest } = props

    return (
        <StyledCard $withHover={withHover} {...rest}>
            {children}
        </StyledCard>
    )
}
