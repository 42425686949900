import React, { type FC } from "react"
import { useGetGoalsQuery } from "features/viewer"
import { GoalCard, GoalStatusType } from "entities/viewer"
import { StyledCategoryTitle, StyledGoalScrollGroup, StyledGoalsListRow } from "./styles"
import { type GoalListItemProps } from "./types"

export const GoalListItem: FC<GoalListItemProps> = (props) => {
    const { modalToggleActivity, setCategoryCard, itemType, setIdCard } = props
    const { data } = useGetGoalsQuery("")
    const filteredData = data?.flatMap((el) => (GoalStatusType[el?.status] === itemType ? el : []))
    return (
        <>
            {filteredData && (
                <StyledGoalsListRow>
                    <StyledCategoryTitle>
                        {itemType} {filteredData.length}
                    </StyledCategoryTitle>
                    <StyledGoalScrollGroup className={props.className}>
                        {filteredData.map((el) => (
                            <GoalCard
                                projectName={el.project.name}
                                id={el.id}
                                title={el.title}
                                startTime={el.startTime}
                                endTime={el.endTime}
                                description={el.description}
                                key={el.id}
                                onModalClick={() => {
                                    modalToggleActivity?.()
                                    setCategoryCard(itemType)
                                    setIdCard(el.id)
                                }}
                                statusType={itemType}
                            />
                        ))}
                    </StyledGoalScrollGroup>
                </StyledGoalsListRow>
            )}
        </>
    )
}
