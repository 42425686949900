import { css, styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledEventsCalendarDefaultItem = styled.div<{
    $hasEvent?: boolean
    $isWeekend?: boolean
    $count?: number
    $isAnotherMonth?: boolean
}>`
    border-radius: 12%;
    font-size: 14px;
    position: relative;
    user-select: none;
    color: var(
        ${({ $hasEvent, $isWeekend, $isAnotherMonth }) => {
            switch (true) {
                case $isWeekend && $hasEvent && !$isAnotherMonth:
                    return "--assets-calendar-colors-events-weekend"
                case $isWeekend && $isAnotherMonth:
                    return "--assets-calendar-colors-another-month-weekend"
                case $isWeekend:
                    return "--assets-calendar-colors-day-weekend"
                case $hasEvent && !$isAnotherMonth:
                    return "--assets-calendar-colors-events"
                case $isAnotherMonth:
                    return "--assets-calendar-colors-another-month"
                default:
                    return "--assets-calendar-colors-day"
            }
        }}
    );

    ${({ $isWeekend, $isAnotherMonth, $hasEvent }) => {
        switch (true) {
            case $isWeekend && !$isAnotherMonth && $hasEvent:
                return css`
                    &:hover {
                        background: var(
                            --assets-calendar-colors-hover-event-weekend,
                            rgba(236, 0, 140, 0.04)
                        ) !important;
                    }
                `
            case $hasEvent && !$isAnotherMonth:
                return css`
                    &:hover {
                        background: var(--assets-calendar-colors-hover-event, rgba(62, 58, 237, 0.04));
                    }
                `
            default:
                return css``
        }
    }};

    @media all and (max-width: ${getMediaVariable("laptop")}) {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    @media (min-width: ${getMediaVariable("tablet")}) {
        padding: var(--assets-calendar-sizes-l-padding, 16px);
    }
`

const StyledEventsCalendarDayItem = styled(StyledEventsCalendarDefaultItem)`
    aspect-ratio: 1;

    @media all and (min-width: 1026px) {
        font-size: 32px;
        display: grid;
        grid-template-rows: min-content 1fr;
        font-weight: 500;
    }

    &:hover {
        background-color: var(--assets-calendar-colors-hover-event, rgba(62, 58, 237, 0.04));
    }

    @media all and (max-width: ${getMediaVariable("laptop")}) {
        min-width: var(--assets-calendar-sizes-s-width, 46px);
        min-height: var(--assets-calendar-sizes-s-width, 46px);
        aspect-ratio: 1;
    }

    @media (min-width: ${getMediaVariable("desktop")}) {
        margin: var(--assets-calendar-sizes-l-padding, 16px);
        min-width: var(--assets-calendar-sizes-l-width, 160px);
        min-height: var(--assets-calendar-sizes-l-width, 160px);
    }
`

const StyledEventsCalendarHeaderItem = styled(StyledEventsCalendarDefaultItem)`
    color: var(--assets-calendar-colors-title, rgba(0, 0, 0, 0.36));

    &:nth-child(n + 6) {
        color: var(--assets-calendar-colors-title-weekend, rgba(0, 0, 0, 0.12));
    }

    @media all and (min-width: ${getMediaVariable("laptop")}) {
        height: unset;
        font-size: 16px;
    }
`

const StyledEventsCardList = styled.div`
    display: flex;
    flex-direction: column-reverse;
    gap: 4px;
`

export { StyledEventsCardList, StyledEventsCalendarDayItem, StyledEventsCalendarHeaderItem }
