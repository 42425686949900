import React from "react"
import { Input } from "shared/ui"
import { VkIconSvg } from "shared/assets/svg"
import { StyledSection } from "shared/styles"

export const PersonalLinkFields = () => {
    return (
        <StyledSection>
            <Input
                name={"ctaUrl"}
                required={true}
                placeholder={"https://place.ru"}
                labelText={"Ссылка на страницу регистрации"}
            />
            <Input
                name={"websiteUrl"}
                required={false}
                placeholder={"https://place.ru"}
                labelText={"Официальный сайт"}
            />
            <Input
                name={"vkSocialUrl"}
                required={false}
                labelText={"Сообщество ВК"}
                hasIcon={() => <VkIconSvg />}
                placeholder={"https://vk.com/community"}
            />
        </StyledSection>
    )
}
