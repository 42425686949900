import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { transformUrlImages } from "shared/lib"
import { type IEditOrganization, type IGetOrganizationUser } from "./types"

const profileApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        changePoaOrganization: builder.mutation<IEditOrganization, unknown>({
            query: ({ id, body }): RequestConfig<IEditOrganization, string> => {
                return {
                    url: BASE_URL.USER + "/organization/document",
                    method: "PATCH",
                    body,
                    params: id,
                }
            },
            invalidatesTags: ["Organization"],
        }),
        createOrganization: builder.mutation<IEditOrganization, unknown>({
            query: (body: IEditOrganization): RequestConfig<IEditOrganization, unknown> => ({
                url: BASE_URL.USER + "/organization",
                method: "POST",
                body,
            }),
            invalidatesTags: ["Organization"],
        }),
        getOrgUser: builder.query<IGetOrganizationUser | { examplePoaUrl: string }, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/organization",
                method: "GET",
            }),
            transformResponse: (data: IGetOrganizationUser, ...rest) => {
                if (data?.user?.orgDocument?.url) {
                    const url = transformUrlImages(data?.user?.orgDocument?.url)
                    return {
                        ...data,
                        user: {
                            ...data?.user,
                            orgDocument: {
                                ...data?.user?.orgDocument,
                                url: url,
                            },
                        },
                    }
                }
                return data
            },
            providesTags: ["Organization"],
        }),
        deleteOrgDocument: builder.mutation<IGetOrganizationUser, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL.USER + "/organization",
                method: "DELETE",
            }),
            invalidatesTags: ["Organization"],
        }),
    }),
})

export const {
    useChangePoaOrganizationMutation,
    useGetOrgUserQuery,
    useLazyGetOrgUserQuery,
    useCreateOrganizationMutation,
    useDeleteOrgDocumentMutation,
} = profileApi
