import { FormProvider, useForm } from "react-hook-form"
import React, { type FC, useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { yupResolver } from "@hookform/resolvers/yup"
import { WorkScheduleForm } from "features/dashboard"
import { type IOfferPlaceForm, useCreateDashboardPlaceMutation } from "features/viewer"
import { Button, ButtonType, FileLoad, FileType, FormLayout } from "shared/ui"
import { formDataHandler } from "shared/api"
import { AboutFields, PlaceFormContactsFields, DescriptionField, WithDisabilitiesFields } from "./ui"
import { validationSchema } from "./lib"

export const OfferPlaceForm: FC = () => {
    const navigate = useNavigate()
    const methods = useForm<IOfferPlaceForm>({
        defaultValues: {
            description: "",
        },
        mode: "onChange",
        criteriaMode: "all",
        resolver: yupResolver<IOfferPlaceForm>(validationSchema),
    })
    const [createPlace, { data }] = useCreateDashboardPlaceMutation()
    const customHandlerSubmit = methods.handleSubmit((data) => {
        const result = { ...data }
        if ("vkSocialUrl" in data && result.vkSocialUrl === "") {
            delete result.vkSocialUrl
        }
        if ("websiteUrl" in data && result.websiteUrl === "") {
            delete result.websiteUrl
        }
        createPlace(
            formDataHandler<IOfferPlaceForm>(
                {
                    ...result,
                    phone: `+7 ${data.phone}`,
                } as IOfferPlaceForm,
                "images",
                "workingHours"
            )
        )
        methods.reset()
    })

    useEffect(() => {
        if (data) {
            navigate(`/place/true/${data.id}`)
        }
    }, [data])
    return (
        <FormProvider {...methods}>
            <FormLayout
                headerText={"Предложение места"}
                main={() => (
                    <>
                        <AboutFields />
                        <PlaceFormContactsFields />
                        <FileLoad name={"images"} title={"Фотографии"} FLType={FileType.PICTURE_LIST} />
                        <DescriptionField />
                        <WorkScheduleForm />
                        <WithDisabilitiesFields />
                    </>
                )}
                footer={() => (
                    <>
                        <Button onClick={customHandlerSubmit}>Предложить</Button>
                        <Button
                            typeUI={ButtonType.GHOST}
                            onClick={() => {
                                navigate("/dashboard/opportunities")
                            }}
                        >
                            Отмена
                        </Button>
                    </>
                )}
            />
        </FormProvider>
    )
}
