import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledContactsCardLabel = styled.div`
    color: var(--layout-text-colors-additional, rgba(0, 0, 0, 0.48));
    font-size: 14px;
    font-weight: 400;
    line-height: 12px;
    @media all and (min-width: ${getMediaVariable("laptop")}) {
        font-size: 14px;
    }
`

const StyledContactsCardText = styled.a`
    color: var(--layout-text-colors-link, #3e3aed);
    font-weight: 400;
    line-height: 20px;
`

const StyledContactsCardItem = styled.div`
    display: flex;
    flex-direction: column;
    gap: 4px;
`

const StyledPhoneNumbers = styled.div<{
    $isModal: boolean
    $isSmallModal: boolean
    $isBigArr: boolean
}>`
    display: ${({ $isModal, $isSmallModal, $isBigArr }) =>
        $isBigArr ? "flex" : $isSmallModal ? "flex" : $isModal ? "grid" : "flex"};
    gap: 16px;
    flex-wrap: wrap;
`

export { StyledPhoneNumbers, StyledContactsCardItem, StyledContactsCardText, StyledContactsCardLabel }
