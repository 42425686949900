import { type FC } from "react"
import { useFieldArray, useFormContext } from "react-hook-form"
import { type IModalGoalReportForm } from "features/viewer"
import { ArrayFieldHandlers } from "shared/lib"
import { AddDeleteButtons, Input, LinksSection } from "shared/ui"

export const PostLinksField: FC = () => {
    const { getValues, control } = useFormContext<IModalGoalReportForm>()
    const {
        fields: fieldsPosts,
        append,
        remove,
    } = useFieldArray({
        name: "postLinks",
        control: control,
    })

    const { handlerClickDelete: handlerClickDeletePost, handlerClickAdd: handlerClickAddPost } = new ArrayFieldHandlers<
        IModalGoalReportForm,
        { postLink?: string }[],
        "postLinks"
    >(
        getValues("postLinks"),
        append,
        remove,
        fieldsPosts,
        {
            postLink: "",
        },
        true
    )
    return (
        <>
            <LinksSection title={"Ссылки на посты"}>
                {fieldsPosts.map((item, j) => {
                    return <Input name={`postLinks.${j}.postLink`} key={item.id} placeholder={"Ссылка..."} />
                })}
                <AddDeleteButtons
                    handlerClickDelete={handlerClickDeletePost}
                    handlerClickAdd={handlerClickAddPost}
                    showDeleteButton={fieldsPosts.length > 1}
                    showAddButton={fieldsPosts.length < 7}
                />
            </LinksSection>
        </>
    )
}
