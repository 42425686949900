import React, { type FC } from "react"
import { ProfileData, ProfileDataType } from "entities/viewer"
import { Tag, useMediaTag } from "shared/ui"
import { StyledProfileDataPattern } from "shared/styles"

interface InclusivitySectionProps {
    accessible?: boolean
    lowMobilityFriendly?: boolean
}

export const InclusivitySection: FC<InclusivitySectionProps> = (props) => {
    const { accessible, lowMobilityFriendly } = props
    const { TagSizeStoM } = useMediaTag()
    if (!accessible && !lowMobilityFriendly) {
        return null
    }
    return (
        <StyledProfileDataPattern $type={"middle-light-blue"}>
            <ProfileData type={ProfileDataType.TAG} caption={"Инклюзивность"}>
                {accessible && <Tag size={TagSizeStoM}>Для людей с ОВЗ</Tag>}
                {lowMobilityFriendly && <Tag size={TagSizeStoM}>Для маломобильных граждан</Tag>}
            </ProfileData>
        </StyledProfileDataPattern>
    )
}
