import process from "process"
import { type TResponseImages } from "features/api"
export const transformUrlImages = (url: string): string => {
    if (!url.includes("https://") || !url.includes("http://")) {
        return new URL(url, process.env.REACT_APP_API_URL as string).toString()
    }
    return url
}

export const getImagesArrWithTransformUrls = <T extends object>(arr: Array<T>, key: keyof T) => {
    return arr.map((item) =>
        "images" in item
            ? {
                  ...item,
                  images: (item.images as TResponseImages)?.map(({ url }: { url: string }) => ({
                      url: transformUrlImages(url),
                  })),
              }
            : { ...item }
    )
}
