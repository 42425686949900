import React, { type FC } from "react"

export const VkVideoSvg: FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="118"
            height="118"
            fill="#0000007a"
            style={{ backgroundColor: "rgba(217,212,217, 0.5)" }}
            viewBox="0 0 64 64"
        >
            <g transform="translate(20 20)" clipPath="url(#logo_vk_video_color_with_text_24h__b)">
                <path
                    fill="#07F"
                    d="M0 8.77C0 1.55 1.55 0 8.77 0h6.46C22.45 0 24 1.55 24 8.77v6.46c0 7.22-1.55 8.77-8.77 8.77H8.77C1.55 24 0 22.45 0 15.23V8.77Z"
                ></path>
                <path
                    fill="#FF2B42"
                    d="M12.9 24h2.28c7.1 0 8.74-1.48 8.82-8.28V8.24C23.92 1.47 22.26 0 15.18 0H12.9C5.64 0 4.08 1.55 4.08 8.77v6.46c0 7.22 1.56 8.77 8.82 8.77Z"
                ></path>
                <path
                    fill="#fff"
                    d="M16.93 9.92c1.22.7 1.82 1.05 2.03 1.51.18.4.18.86 0 1.25-.2.46-.81.81-2.03 1.51l-3.32 1.92c-1.21.7-1.82 1.05-2.32 1a1.54 1.54 0 0 1-1.08-.63c-.3-.4-.3-1.1-.3-2.5v-3.84c0-1.4 0-2.1.3-2.5A1.54 1.54 0 0 1 11.29 7c.5-.06 1.1.3 2.32 1l3.32 1.91Z"
                ></path>
            </g>
        </svg>
    )
}
