import { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type PhoneSvgProps } from "./types"

export const PhoneSvg: FC<PhoneSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 0C18.1046 0 19 0.895431 19 2V22C19 23.1046 18.1046 24 17 24H7C5.89543 24 5 23.1046 5 22V2C5 0.89543 5.89543 0 7 0H17ZM16 1H17C17.5523 1 18 1.44772 18 2V22C18 22.5523 17.5523 23 17 23H7C6.44772 23 6 22.5523 6 22V2C6 1.44772 6.44772 1 7 1H8C8 2.10457 8.89543 3 10 3H14C15.1046 3 16 2.10457 16 1ZM15 1L9 1C9 1.55228 9.44772 2 10 2H14C14.5523 2 15 1.55228 15 1Z"
                fill="currentColor"
            />
        </svg>
    )
}
