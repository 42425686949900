import { type FC } from "react"
import { Opportunity } from "widgets/user"

export const OpportunityPage: FC = () => {
    return (
        <>
            <Opportunity />
        </>
    )
}
