import { type HTMLAttributes, type ReactNode } from "react"

export enum ButtonType {
    PRIMARY = "primary",
    SECONDARY = "secondary",
    GHOST = "ghost",
    FREE = "free",
    DISABLED = "disabled",
    BLUE = "blue",
    AUTH = "AUTH",
    GHOST_RED = "ghost-red",
}

export enum ButtonSize {
    L = "l",
    M = "m",
    S = "s",
    XS = "xs",
}

export interface ButtonProps extends HTMLAttributes<HTMLButtonElement> {
    typeUI?: ButtonType
    size?: ButtonSize
    hover?: boolean
    onlyIcon?: boolean
    leftIcon?: () => ReactNode
    rightIcon?: () => ReactNode
    children?: ReactNode
    svgColor?: string | null
    type?: "button" | "submit" | "reset" | undefined
    form?: string
}
