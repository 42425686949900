import React, { type FC } from "react"
import { useGetCategoriesPlacesQuery, useGetCategoriesUsersQuery } from "features/user"
import { Input, Select } from "shared/ui"
import { StyledSection } from "shared/styles"

export const AboutFields: FC = () => {
    const { data } = useGetCategoriesPlacesQuery("")
    const { data: userCat } = useGetCategoriesUsersQuery("")
    return (
        <StyledSection>
            <Input name={"name"} labelText={"Название"} placeholder={"Идея на миллион"} />
            <Input name={"address"} labelText={"Адрес"} placeholder={"Оренбург, ул. Советская, д. 1"} />
            <Select
                data={data}
                initialValue={"Выберите категорию места"}
                labelText={"Категория"}
                name={"categories"}
                multiple={false}
                clearable={true}
            />

            <Select
                data={userCat}
                name={"userCategories"}
                initialValue={"Выберите все подходящие"}
                labelText={"Для кого"}
                multiple={true}
                clearable={true}
            />
        </StyledSection>
    )
}
