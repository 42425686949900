import { InfoEventCardSize } from "entities/user"
import { useResize } from "shared/hook"

export const useMediaInfoEvent = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()

    return {
        fromStoL: width >= isTablet ? InfoEventCardSize.L : InfoEventCardSize.S,
    }
}
