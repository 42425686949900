import { string } from "yup"

export const passwordValidation = string()
    .test("NumberAndLetter", " ", (value) => {
        if (/[А-Яа-я]/.test(value as string)) {
            return false
        }

        return /[A-Za-z]/.test(value as string)
    })
    .matches(/[0-9]+/, {
        name: "IsNumber",
        message: " ",
        excludeEmptyString: true,
    })
    .matches(/[A-Z]+/, {
        name: "UpperCase",
        message: " ",
        excludeEmptyString: true,
    })
    .matches(/[a-z]+/, {
        name: "LowerCase",
        message: " ",
        excludeEmptyString: true,
    })
    .min(8, "")
