import { type FC } from "react"
import { SizeSvg } from "../../types"
import { type ArrowRightProps } from "./types"

export const ArrowRight: FC<ArrowRightProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M20.7929 12.5H2V11.5H20.7929L15.6464 6.35359L16.3536 5.64648L22.7071 12L16.3536 18.3536L15.6464 17.6465L20.7929 12.5Z"
                fill="white"
            />
        </svg>
    )
}
