import dayjs from "dayjs"
import { type SelectData } from "shared/ui"
import { months } from "shared/lib"

export const MonthsSelectData: SelectData[] = months.map((name, id) => ({
    name: name[0].toUpperCase() + name.slice(1),
    id,
}))

export const YearsSelectData: SelectData[] = Array.from({ length: dayjs().year() + 1 - 2023 + 1 }, (_, index) => {
    const year = dayjs().year() + 1 - index
    return {
        name: year.toString(),
        id: year,
    }
})
