import React, { type FC } from "react"
import { InputPassword } from "shared/ui"
import { StyledSection } from "shared/styles"

export const NewPasswordFields: FC = () => {
    return (
        <StyledSection>
            <InputPassword validWindow={true} labelText={"Пароль"} placeholder={"Пароль"} name={"password"} />
            <InputPassword labelText={"Подтверждение пароля"} placeholder={"Пароль"} name={"passwordCheck"} />
        </StyledSection>
    )
}
