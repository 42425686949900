import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type GeoSvgProps } from "./types"

export const GeoSvg: FC<GeoSvgProps> = (props) => {
    const { size = SizeSvg.XS } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 7.33341C9.47276 7.33341 10.6667 6.13951 10.6667 4.66675C10.6667 3.19399 9.47276 2.00008 8 2.00008C6.52724 2.00008 5.33333 3.19399 5.33333 4.66675C5.33333 6.13951 6.52724 7.33341 8 7.33341ZM8 6.66675C9.10457 6.66675 10 5.77132 10 4.66675C10 3.56218 9.10457 2.66675 8 2.66675C6.89543 2.66675 6 3.56218 6 4.66675C6 5.77132 6.89543 6.66675 8 6.66675Z"
                fill="currentColor"
                fillOpacity="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.37958 13.3088C9.3778 11.4661 12 6.4554 12 4.66675C12 2.45761 10.2091 0.666748 8 0.666748C5.79086 0.666748 4 2.45761 4 4.66675C4 6.4554 6.6222 11.4661 7.62042 13.3088C7.85508 13.742 8 14.0001 8 14.0001C8 14.0001 8.14492 13.742 8.37958 13.3088ZM10.7525 6.8185C11.1298 5.83401 11.3333 5.08085 11.3333 4.66675C11.3333 2.8258 9.84095 1.33341 8 1.33341C6.15905 1.33341 4.66667 2.8258 4.66667 4.66675C4.66667 5.08085 4.87025 5.83401 5.24752 6.8185C5.61271 7.77146 6.10343 8.84971 6.59958 9.87039C7.09479 10.8891 7.59066 11.8408 7.9631 12.5384L8 12.6075L8.0369 12.5384C8.40934 11.8408 8.90521 10.8891 9.40042 9.87039C9.89657 8.84971 10.3873 7.77146 10.7525 6.8185Z"
                fill="currentColor"
                fillOpacity="currentColor"
            />
            <path
                d="M9.14843 13.4859C9.58887 13.613 9.84401 13.7868 9.95029 13.9151C9.99635 13.9707 9.99966 13.9973 9.99979 13.9983C9.9999 13.9992 10 14.0002 9.99939 14.0031C9.99861 14.0067 9.99611 14.0155 9.98819 14.0303C9.94379 14.1131 9.7792 14.284 9.38456 14.4355C9.00724 14.5802 8.51954 14.6667 8 14.6667C7.48046 14.6667 6.99276 14.5802 6.61544 14.4355C6.2208 14.284 6.05621 14.1131 6.01181 14.0303C6.00389 14.0155 6.00139 14.0067 6.00061 14.0031C5.99997 14.0002 6.00009 13.9992 6.00021 13.9983C6.00034 13.9973 6.00365 13.9707 6.04971 13.9151C6.15599 13.7868 6.41112 13.613 6.85157 13.4859L6.66667 12.8454C6.15822 12.9922 5.76093 13.2187 5.53632 13.4898C5.43387 13.6135 5.3592 13.7546 5.33889 13.9141C5.31817 14.0767 5.3585 14.2226 5.4242 14.3452C5.57605 14.6285 5.91068 14.8791 6.37664 15.0579C6.84235 15.2366 7.41297 15.3334 8 15.3334C8.58703 15.3334 9.15765 15.2366 9.62336 15.0579C10.0893 14.8791 10.4239 14.6285 10.5758 14.3452C10.6415 14.2226 10.6818 14.0767 10.6611 13.9141C10.6408 13.7546 10.5661 13.6135 10.4637 13.4898C10.2391 13.2187 9.84178 12.9922 9.33333 12.8454L9.14843 13.4859Z"
                fill="currentColor"
                fillOpacity="currentColor"
            />
        </svg>
    )
}
