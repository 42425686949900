import React, { type FC } from "react"
import { PasswordShowSvg, ProfileSvg, SizeSvg, TimeSvg } from "shared/assets/svg"
import { Vector, VectorType } from "../Vector"
import { type CardStatusProps, CardStatusType } from "./types"
import { StyledCardStatus } from "./styles"
import { CardStatusText } from "./lib/CardStatusText"

export const CardStatus: FC<CardStatusProps> = (props) => {
    const { statusType, cardType, isIcon = true } = props

    const setIcon = (type: CardStatusType) => {
        switch (type) {
            case CardStatusType.WAITING:
                return <TimeSvg size={SizeSvg.XS} />
            case CardStatusType.ACCEPTED:
                return <Vector type={VectorType.CHECK} size={SizeSvg.XS} />
            case CardStatusType.PUBLIC:
                return <Vector type={VectorType.CHECK} size={SizeSvg.XS} />
            case CardStatusType.DECLINED:
                return <Vector type={VectorType.CROSS} size={SizeSvg.XS} />
            case CardStatusType.ARCHIVE:
                return <PasswordShowSvg />
            case CardStatusType.REGISTRATION:
                return <ProfileSvg size={SizeSvg.XS} />
        }
    }
    return (
        <StyledCardStatus $type={statusType}>
            {isIcon && setIcon(statusType)}
            {CardStatusText[statusType][cardType]}
        </StyledCardStatus>
    )
}
