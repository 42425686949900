import React, { type FC } from "react"
import { HeadingType, Typography } from "../Typography"
import { StyledColumn, StyledTitle, StyledWrapper } from "./styles"
import { type WrapperColumnProps, type WrapperItemProps } from "./types"

const WrapperColumnParent: FC<WrapperColumnProps> = (props) => {
    const { title, isMobile, children } = props
    return (
        <>
            {title && (
                <StyledTitle>
                    <Typography.Heading typeUI={HeadingType.H1}>{title}</Typography.Heading>
                </StyledTitle>
            )}
            <StyledWrapper $isMobile={isMobile}>{children}</StyledWrapper>
        </>
    )
}

const Column: FC<WrapperItemProps> = (props) => {
    const { maxWidth, minWidth, children } = props
    return (
        <StyledColumn $minWidth={minWidth} $maxWidth={maxWidth}>
            {children}
        </StyledColumn>
    )
}

export const WrapperColumn = {
    Wrapper: WrapperColumnParent,
    Item: Column,
}
