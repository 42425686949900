import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledWrapper = styled.div<{
    $maxWidth: number | undefined
    $minWidth: number | undefined
    $width: number | undefined
}>`
    display: flex;
    min-width: ${({ $minWidth }) => `${$minWidth}px` || "none"};
    max-width: ${({ $maxWidth }) => `${$maxWidth}px` || "none"};
    width: ${({ $width }) => `${$width}%` || "none"};
    flex-direction: column;
    align-items: center;
    gap: 24px;
    @media all and (max-width: ${getMediaVariable("tablet")}) {
        min-width: 100%;
        max-width: 100%;
    }
`

export { StyledWrapper }
