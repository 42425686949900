import React, { type FC } from "react"
import { Link } from "react-router-dom"
import { DateSvg, GeoSvg } from "shared/assets/svg"
import { CardStatus, HeadingType, Typography } from "shared/ui"
import { dateConverter } from "shared/api"
import mySvg from "./Idea.svg"
import { type IdeaCardProps } from "./types"
import {
    StyledIdeaCard,
    StyledIdeaCardBottom,
    StyledIdeaCardChildren,
    StyledIdeaCardContent,
    StyledIdeaCardData,
} from "./styles"

export const IdeaCard: FC<IdeaCardProps> = (props) => {
    const { to, title, statusType, cardType, date, location, children } = props
    return (
        <Link to={to}>
            <StyledIdeaCard $bg={mySvg}>
                <StyledIdeaCardContent>
                    <CardStatus statusType={statusType} cardType={cardType} />
                    <Typography.Heading typeUI={HeadingType.H4}>{title}</Typography.Heading>
                    <StyledIdeaCardChildren>{children}</StyledIdeaCardChildren>
                </StyledIdeaCardContent>
                <StyledIdeaCardBottom>
                    <StyledIdeaCardData>
                        <GeoSvg />
                        {location}
                    </StyledIdeaCardData>
                    <StyledIdeaCardData>
                        <DateSvg />
                        {dateConverter(date).ddd_mmm_yyy(".")}
                    </StyledIdeaCardData>
                </StyledIdeaCardBottom>
                {/*<LightSvg />*/}
            </StyledIdeaCard>
        </Link>
    )
}
