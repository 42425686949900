import axios from "axios"
interface mockDataI {
    name: string
    address: string
    description: string
    startDate: string
    endDate: string
    ageRange: string[]
    condition: string
}
const mockData: mockDataI = {
    name: "Test",
    address: "Test street",
    description: "Test Desc",
    startDate: new Date().toISOString(),
    endDate: "2023-09-30T10:39:58.649Z",
    ageRange: ["18", "34"],
    condition: "test",
}
export const api_test = async (data: any) => {
    const test = {
        ...mockData,
    }
    const req_data = new FormData({ ...mockData, ...data })
    data?.images.forEach((item: any) => {
        req_data.append("images", item)
    })
    for (let key in test) {
        // @ts-ignore
        req_data.append(key, test[key])
    }
    console.log(req_data)
    await axios.post("https://youth-api.dev-vt2b.ru/user/operator/opportunity", req_data, {
        headers: {
            Authorization:
                "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiOWU0ZmMwOTgtMjgxYy00ZjA3LWIwMTEtNThjMzZhOTBhMjQ2IiwiaWF0IjoxNjk2MzA4NzI3LCJleHAiOjE3ODI3MDg3Mjd9.IDmdrfesKxzkWQQhi0xSSmkpS1VokCBImWcdTSz2DRw",
            // "Content-type": "multipart/form-data",
        },
    })
}
