import React, { type FC } from "react"

export const RussianFlagSvg: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clipPath="url(#clip0_206_3509)">
                <path fillRule="evenodd" clipRule="evenodd" d="M0 3H24V21H0V3Z" fill="white" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 9H24V21H0V9Z" fill="#0039A6" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 15H24V21H0V15Z" fill="#D52B1E" />
            </g>
            <rect x="0.5" y="3.5" width="23" height="17" rx="3.5" stroke="black" strokeOpacity="0.02" />
            <defs>
                <clipPath id="clip0_206_3509">
                    <rect y="3" width="24" height="18" rx="4" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}
