export enum InfoEventCardSize {
    L = "l",
    S = "s",
}

export interface InfoEventCardProps {
    size?: InfoEventCardSize
    cardText: string
    number: string
}
