import React, { type FC } from "react"
import { NavLink } from "react-router-dom"
import { Choice, ChoiceGroup, ChoiceGroupSize, ChoiceSize, ChoiceType } from "shared/ui"
import { LightSvg, OpportunitySvg, ProfileSvg } from "shared/assets/svg"
import { useResize } from "shared/hook"
import { type BottomSectionsProps } from "./types"
import { StyledProfileSection } from "./styles"

export const BottomSections: FC<BottomSectionsProps> = (props) => {
    const { children } = props
    const {
        mediaScreenSize: { width, isMobileL, isTablet, isLaptopSm },
    } = useResize()

    const mediaChoiceGroup = width > isLaptopSm ? ChoiceGroupSize.L : ChoiceGroupSize.M
    const mediaChoice = width > isTablet ? ChoiceSize.L : ChoiceSize.S
    const scrollChoiceGroup = width <= isMobileL
    return (
        <StyledProfileSection>
            <ChoiceGroup size={mediaChoiceGroup} scroll={scrollChoiceGroup}>
                <NavLink to={"/personal-account"} end={true}>
                    {({ isActive }) => (
                        <Choice type={ChoiceType.NAVLINK} selected={isActive} size={mediaChoice}>
                            <ProfileSvg />
                            Анкета
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"/personal-account/idea"}>
                    {({ isActive }) => (
                        <Choice type={ChoiceType.NAVLINK} selected={isActive} size={mediaChoice}>
                            <LightSvg />
                            Идея
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"/personal-account/potential"}>
                    {({ isActive }) => (
                        <Choice type={ChoiceType.NAVLINK} selected={isActive} size={mediaChoice}>
                            <OpportunitySvg />
                            Возможности
                        </Choice>
                    )}
                </NavLink>
            </ChoiceGroup>
            {children}
        </StyledProfileSection>
    )
}
