import { useNavigate } from "react-router-dom"
import { useEffect } from "react"

export const useAccessTokenRedirect = (updateToken: () => void, data?: object) => {
    const navigate = useNavigate()
    useEffect(() => {
        if (data && "access_token" in data) {
            updateToken()
            navigate("/")
        }
    }, [data])
}
