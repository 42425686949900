import { type HTMLAttributes, type ReactNode } from "react"

export enum GroupSize {
    L = "l",
    S = "s",
}
export enum GroupDirection {
    COLUMN = "column",
    ROW = "row",
    ROW_REVERSE = "row-reverse",
    COLUMN_REVERSE = "column-reverse",
}
export enum ContentDirection {
    LEFT = "flex-start",
    RIGHT = "flex-end",
    CENTER = "center",
}
export interface GroupProps extends HTMLAttributes<HTMLDivElement> {
    children: ReactNode
    contentDirection?: ContentDirection
    size?: GroupSize
    direction?: GroupDirection
    wrap?: boolean
}
