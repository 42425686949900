import { type UseFormProps } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { IdeaFormType, type IIdeaForm } from "features/viewer"
import { IdeaFormSchema } from "./IdeaFormSchema"

export const IdeaFormConfig = (): UseFormProps<IIdeaForm> => ({
    defaultValues: {
        title: "",
        problem: "",
        solution: "",
        successReason: "",
        locality: "",
        ideaType: IdeaFormType.TEAM,
        results: "",
    },
    mode: "all",
    criteriaMode: "all",
    resolver: yupResolver(IdeaFormSchema),
})
