import React, { type FC } from "react"
import { type DashSvgProps } from "./types"

export const Dash: FC<DashSvgProps> = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" style={{ width: "100%" }} height="2" viewBox="0 0 25 1" fill="none">
            <path
                d="M0.333008 1H24.333"
                stroke="var(--assets-card-common-colors-additional, rgba(0, 0, 0, 0.48))"
                strokeWidth={1}
            />
        </svg>
    )
}
