import React, { type FC } from "react"
import { OpportunitySection } from "entities/user"
import { ProfileData } from "entities/viewer"
import { Row } from "shared/ui"
import { SectionGapSize, StyledSection } from "shared/styles"
import { CalendarSvg } from "shared/assets/svg"

interface OpportunityConditionsProps {
    ageRange?: [number, number]
    startDate: string
    endDate: string
    userCategories: Array<{ id: string; name: string }>
}
export const OpportunityConditions: FC<OpportunityConditionsProps> = ({
    ageRange,
    userCategories,
    startDate,
    endDate,
}) => {
    return (
        <>
            <StyledSection $gapSize={SectionGapSize.S}>
                <Row>
                    {startDate && (
                        <ProfileData caption={"Начало"}>
                            <CalendarSvg />
                            {startDate}
                        </ProfileData>
                    )}
                    {endDate && (
                        <ProfileData caption={"Завершение"}>
                            <CalendarSvg />
                            {endDate}
                        </ProfileData>
                    )}
                </Row>
                <OpportunitySection.AgeSection ageRange={ageRange} />
                <OpportunitySection.ForWhomSection userCategories={userCategories} />
            </StyledSection>
        </>
    )
}
