import styled from "styled-components"

const StyledYandexCaptcha = styled.div`
    display: flex;
    padding: var(--assets-smart-captcha-sizes-padding, 12px);
    align-items: flex-start;
    gap: var(--assets-smart-captcha-sizes-gap, 12px);
    align-self: stretch;
    border-radius: var(--assets-smart-captcha-sizes-radius, 12px);
    border: 1px solid var(--assets-smart-captcha-colors-stroke, rgba(0, 0, 0, 0.06));
`

const StyledYandexCaptchaBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-smart-captcha-sizes-gap, 12px);
    flex: 1 0 0;
`

const StyledYandexCaptchaBodySubtitle = styled.div`
    color: var(--assets-smart-captcha-colors-subtitle, rgba(0, 0, 0, 0.72));
    font-size: 12px;
`

const StyledYandexCaptchaBodyBottom = styled.div`
    color: var(--assets-smart-captcha-colors-bottom, rgba(0, 0, 0, 0.48));
    font-size: 10px;
`

const StyledYandexCaptchaBodyTitle = styled.div`
    display: flex;
    justify-content: space-between;
    gap: var(--assets-smart-captcha-sizes-gap-inner, 8px);
    align-self: stretch;
`

export {
    StyledYandexCaptcha,
    StyledYandexCaptchaBody,
    StyledYandexCaptchaBodySubtitle,
    StyledYandexCaptchaBodyBottom,
    StyledYandexCaptchaBodyTitle,
}
