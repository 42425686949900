import type { BaseQueryFn } from "@reduxjs/toolkit/query"
import type { AxiosRequestConfig, AxiosError } from "axios"
import Qs from "qs"
import { axiosInstanceUser } from "shared/api/config"
import { type ErrorI } from "shared/api/types"
export const axiosBaseQuery =
    (
        { baseUrl }: { baseUrl: string } = { baseUrl: "" }
    ): BaseQueryFn<
        {
            url: string
            method: AxiosRequestConfig["method"]
            body?: AxiosRequestConfig["data"]
            params?: AxiosRequestConfig["params"]
        },
        unknown,
        ErrorI
    > =>
    async ({ url, method, body, params }) => {
        try {
            const result = await axiosInstanceUser({
                url: baseUrl + url,
                method,
                data: body,
                params,
                paramsSerializer: function (params) {
                    return Qs.stringify(params, { arrayFormat: "comma" })
                },
            })
            return { data: result.data }
        } catch (axiosError) {
            let err = axiosError as AxiosError<{
                message: string
                statusCode: number
            }>
            return {
                error: {
                    status: err?.response?.status,
                    error: err,
                },
            }
        }
    }
