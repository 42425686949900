import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { getImagesArrWithTransformUrls, transformUrlImages } from "shared/lib"
import { type IOfferPlaceForm, type IOpportunityFormRequest, type OpportunityItem } from "./types"

const BASE_URL_OPPORTUNITIES = `${BASE_URL.USER}/operator`

const dashboardOpportunitiesApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getDashboardPlaces: builder.query<OpportunityItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + "/place",
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem[]) => {
                return getImagesArrWithTransformUrls<OpportunityItem>(data, "images")
            },
        }),
        getDashboardPlace: builder.query<OpportunityItem, unknown>({
            query: (id): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + `/place/${id}`,
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem) => {
                return {
                    ...data,
                    images: data.images?.map((el) => ({
                        url: transformUrlImages(el.url),
                    })),
                }
            },
        }),
        getDashboardOpportunities: builder.query<OpportunityItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + "/opportunity",
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem[]) => {
                return getImagesArrWithTransformUrls<OpportunityItem>(data, "images")
            },
            providesTags: ["DashboardOpportunities"],
        }),
        getDashboardOpportunity: builder.query<OpportunityItem, unknown>({
            query: (id): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + `/opportunity/${id}`,
                method: "GET",
            }),
            transformResponse: (data: OpportunityItem) => {
                return {
                    ...data,
                    images: data.images?.map((el) => ({
                        url: transformUrlImages(el.url),
                    })),
                }
            },
            providesTags: ["DashboardPlaces"],
        }),
        createDashboardOpportunity: builder.mutation<OpportunityItem, unknown>({
            query: (body): RequestConfig<IOpportunityFormRequest, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + "/opportunity",
                method: "POST",
                body,
            }),
            invalidatesTags: ["DashboardOpportunities"],
        }),
        createDashboardPlace: builder.mutation<OpportunityItem, unknown>({
            query: (body): RequestConfig<IOfferPlaceForm, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + "/place",
                method: "POST",
                body,
            }),
            invalidatesTags: ["DashboardPlaces"],
        }),
    }),
})

export const {
    useGetDashboardPlacesQuery,
    useGetDashboardPlaceQuery,
    useGetDashboardOpportunityQuery,
    useGetDashboardOpportunitiesQuery,
    useCreateDashboardOpportunityMutation,
    useCreateDashboardPlaceMutation,
} = dashboardOpportunitiesApi
