import styled from "styled-components"
import { conditions } from "shared/lib"
import { ChoiceGroupSize } from "./types"

const heightCondition = (size: ChoiceGroupSize) => {
    return conditions<ChoiceGroupSize>(size, [
        [ChoiceGroupSize.L, 56],
        [ChoiceGroupSize.M, 48],
        [ChoiceGroupSize.S, 42],
        [ChoiceGroupSize.XS, 32],
    ])
}
const StyledChoiceGroup = styled.div<{
    $size: ChoiceGroupSize
    $scroll: boolean
    $heightAuto?: boolean
}>`
    display: flex;
    width: ${({ $scroll }) => ($scroll ? "fit-content" : "100%")};
    height: ${({ $size, $heightAuto }) => ($heightAuto ? "auto" : heightCondition($size) + "px")};
    gap: var(--assets-choice-group-sizes-${({ $size }) => $size}-row-gap);
`

export { StyledChoiceGroup }
