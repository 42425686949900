import styled, { keyframes } from "styled-components"

const listVisible = keyframes`
  0% {
    height: 0px;
  }
 
  100% {
   height: 168px;
  }
`

const listHidden = keyframes`
  0% {
    height: 168px;
  }
 
  100% {
   height: 0px;
  }
`
const StyledSelectList = styled.ul<{
    $isOpen: boolean
}>`
    position: absolute;
    overflow: hidden;
    top: calc(100% + 4px);
    max-height: ${({ $isOpen }) => ($isOpen ? "168px" : "0px")};
    left: 0;
    display: ${({ $isOpen }) => ($isOpen ? "flex" : "none")};
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    z-index: 999999999;
    border-radius: var(--assets-select-sizes-radius);
    background: #fff;
    transition: linear 0.2s;
    box-shadow: inset 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
    // animation: ${({ $isOpen }) => ($isOpen ? listVisible : listHidden)} 0.2s linear forwards;
    overflow-y: auto;
    &::-webkit-scrollbar {
        display: none;
    }
`
export { StyledSelectList }
