import { type FC } from "react"

export const Pattern: FC = () => {
    const cssStyles = `
    .coin-color {display: none;}
    .coin-color:target {display: block;}
  `
    return (
        <svg
            width="1520px"
            height="1520px"
            viewBox="0 0 1520 1520"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <style dangerouslySetInnerHTML={{ __html: cssStyles }} />
                <g id="myCircle">
                    <circle cx="760" cy="760" r="754" strokeWidth="12" />
                    <circle cx="760" cy="760" r="730" strokeWidth="12" />
                    <circle cx="760" cy="760" r="706" strokeWidth="12" />
                    <circle cx="760" cy="760" r="682" strokeWidth="12" />
                    <circle cx="760" cy="760" r="658" strokeWidth="12" />
                    <circle cx="760" cy="760" r="634" strokeWidth="12" />
                    <circle cx="760" cy="760" r="610" strokeWidth="12" />
                    <circle cx="760" cy="760" r="586" strokeWidth="12" />
                    <circle cx="760" cy="760" r="562" strokeWidth="12" />
                    <circle cx="760" cy="760" r="538" strokeWidth="12" />
                    <circle cx="760" cy="760" r="514" strokeWidth="12" />
                    <circle cx="760" cy="760" r="490" strokeWidth="12" />
                    <circle cx="760" cy="760" r="466" strokeWidth="12" />
                    <circle cx="760" cy="760" r="442" strokeWidth="12" />
                    <circle cx="760" cy="760" r="418" strokeWidth="12" />
                    <circle cx="760" cy="760" r="394" strokeWidth="12" />
                    <circle cx="760" cy="760" r="370" strokeWidth="12" />
                    <circle cx="760" cy="760" r="346" strokeWidth="12" />
                    <circle cx="760" cy="760" r="322" strokeWidth="12" />
                    <circle cx="760" cy="760" r="298" strokeWidth="12" />
                    <circle cx="760" cy="760" r="274" strokeWidth="12" />
                    <circle cx="760" cy="760" r="250" strokeWidth="12" />
                    <circle cx="760" cy="760" r="226" strokeWidth="12" />
                    <circle cx="760" cy="760" r="202" strokeWidth="12" />
                    <circle cx="760" cy="760" r="178" strokeWidth="12" />
                    <circle cx="760" cy="760" r="154" strokeWidth="12" />
                    <circle cx="760" cy="760" r="130" strokeWidth="12" />
                    <circle cx="760" cy="760" r="106" strokeWidth="12" />
                    <circle cx="760" cy="760" r="82" strokeWidth="12" />
                    <circle cx="760" cy="760" r="58" strokeWidth="12" />
                    <circle cx="760" cy="760" r="34" strokeWidth="12" />
                    <circle cx="760" cy="760" r="10" strokeWidth="12" />
                </g>
            </defs>
            <g className="coin-color" id="opportunity">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#8E00EA" />
            </g>
            <g className="coin-color" id="opportunityFill">
                <use x="0" y="0" xlinkHref="#myCircle" fill="#EC008C" stroke="#8E00EA" />
            </g>
            <g className="coin-color" id="place">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#3CB7FF" />
            </g>
            <g className="coin-color" id="news">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#3BE2DE" />
            </g>
            <g className="coin-color" id="newsFill">
                <use x="0" y="0" xlinkHref="#myCircle" fill="#3CB7FF" stroke="#3BE2DE" />
            </g>
            <g className="coin-color" id="idea-wait">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#FF8100" />
            </g>
            <g className="coin-color" id="idea-accept">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#5FDD4A" />
            </g>
            <g className="coin-color" id="greenFill">
                <use x="0" y="0" xlinkHref="#myCircle" fill="#8E00EA" stroke="#5FDD4A" />
            </g>
            <g className="coin-color" id="yellowFill">
                <use x="0" y="0" xlinkHref="#myCircle" fill="#5FDD4A" stroke="#FFF200" />
            </g>
            <g className="coin-color" id="mainRed">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#EC008C" />
            </g>
            <g className="coin-color" id="mainRedFill">
                <use x="0" y="0" xlinkHref="#myCircle" fill="#3BE2DE" stroke="#EC008C" />
            </g>
        </svg>
    )
}

export const PatternSmall = () => {
    const cssStyles = `
    .coin-color {display: none;}
    .coin-color:target {display: block;}
  `
    return (
        <svg
            width="1520px"
            height="1520px"
            viewBox="0 0 1520 1520"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <defs>
                <style dangerouslySetInnerHTML={{ __html: cssStyles }} />
                <g id="myCircle">
                    <circle cx="760" cy="760" r="106" strokeWidth="12" />
                    <circle cx="760" cy="760" r="82" strokeWidth="12" />
                    <circle cx="760" cy="760" r="58" strokeWidth="12" />
                    <circle cx="760" cy="760" r="34" strokeWidth="12" />
                    <circle cx="760" cy="760" r="10" strokeWidth="12" />
                </g>
            </defs>
            <g className="coin-color" id="opportunity">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#8E00EA" />
            </g>
            <g className="coin-color" id="place">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#3CB7FF" />
            </g>
            <g className="coin-color" id="news">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#3BE2DE" />
            </g>
            <g className="coin-color" id="info">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#3E3AED" />
            </g>
            <g className="coin-color" id="mainRed">
                <use x="0" y="0" xlinkHref="#myCircle" fill="none" stroke="#EC008C" />
            </g>
        </svg>
    )
}
