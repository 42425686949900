import { useNavigate } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { useEffect } from "react"
import { type IOpportunityFormRequest, useCreateDashboardOpportunityMutation } from "features/viewer"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { StyledSection } from "shared/styles"
import { formDataHandler } from "shared/api"

export const OpportunityFormButtonsControl = () => {
    const navigate = useNavigate()
    const { fromStoM, fromMtoL } = useMediaSizeButton()
    const { handleSubmit, reset } = useFormContext()
    const [createOpp, { data }] = useCreateDashboardOpportunityMutation()
    useEffect(() => {
        if (data) {
            navigate(`/opportunity/true/${data.id}`)
        }
    }, [data])
    const customHandleSubmit = handleSubmit((data) => {
        const result = { ...data }
        if ("vkSocialUrl" in data && result.vkSocialUrl === "") {
            delete result.vkSocialUrl
        }
        if ("websiteUrl" in data && result.websiteUrl === "") {
            delete result.websiteUrl
        }
        if ("videoUrl" in data && result.videoUrl === "") {
            delete result.videoUrl
        }
        if ("tags" in data && !result.tags.length) {
            delete result.tags
        }
        createOpp(
            formDataHandler<IOpportunityFormRequest>(
                {
                    ...result,
                    ageRange: [Number(data.ageRange.from), Number(data.ageRange.to)],
                } as IOpportunityFormRequest,
                "images"
            )
        )
        reset()
    })
    return (
        <StyledSection>
            <Button size={fromMtoL} onClick={customHandleSubmit} typeUI={ButtonType.PRIMARY}>
                Предложить
            </Button>
            <Button
                size={fromStoM}
                typeUI={ButtonType.GHOST}
                onClick={() => {
                    navigate("/dashboard/opportunities")
                }}
            >
                Отмена
            </Button>
        </StyledSection>
    )
}
