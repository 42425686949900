import React, { type FC } from "react"
import { type RowProps, RowSize } from "./types"
import { StyledRow } from "./styles"

export const Row: FC<RowProps> = (props) => {
    const { children, size = RowSize.S, ...other } = props
    return (
        <StyledRow $size={size} {...other}>
            {children}
        </StyledRow>
    )
}
