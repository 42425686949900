import styled from "styled-components"
import { StyledParentInput } from "../FormField/styles"
import { StyledButton } from "../Button/styles"
import { StyledInput } from "../../styles"
export const StyledPeriod = styled.div<{
    $time: boolean
}>`
    display: grid;
    grid-template-columns: ${({ $time }) => ($time ? "114px 1fr 114px" : "1fr 1fr")};
    align-items: center;
    gap: var(--assets-input-schedule-form-sizes-s-gap-period, 8px);
    align-self: stretch;
    color: var(--root-colors-main-black-basic, #000);
    fill-opacity: 0.72;

    & ${StyledParentInput} {
        padding: var(--assets-input-sizes-padding-vert, 2px) var(--assets-input-sizes-padding-button, 3px)
            var(--assets-input-sizes-padding-vert, 2px) var(--assets-input-sizes-padding-hor, 12px);
        max-width: ${({ $time }) => ($time ? "169px" : "auto")};
    }

    & ${StyledButton} {
        padding-right: 10px;
    }

    & ${StyledInput} {
        & * {
            letter-spacing: 2em;
        }
    }
`

export const StyledDash = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid var(--root-colors-main-black-basic, #000);
`
