import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledDays = styled.div`
    display: flex;
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px var(--assets-choice-group-sizes-xs-row-gap, 4px);
    align-self: stretch;
    flex-wrap: wrap;
    width: 80%;
    @media (min-width: ${getMediaVariable("tablet")}) {
        width: 100%;
    }
`
export { StyledDays }
