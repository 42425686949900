import styled from "styled-components"
import { Button } from "shared/ui"

export const StyledCardsContainer = styled.div`
    display: grid;
    gap: var(--layout-mobile-section-gap, 12px);
    grid-template-columns: repeat(auto-fit, minmax(288px, 371px));
    justify-content: center;
    align-self: stretch;
    flex-wrap: wrap;
    @keyframes ani {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    opacity: 0;
    animation: ani 2.5s forwards;
`

export const StyledCardContainer = styled.div`
    background-color: #fff;
    border-radius: var(--assets-opportunity-cards-sizes-s-radius, 24px);
`

export const StyledInformerNotResultContainer = styled.div`
    width: 386px;
    align-self: start;
`

export const StyledTextInformer = styled.div`
    color: var(--assets-informer-colors-text, #000);
    font-size: 14px;
    line-height: 18px;
    border-radius: var(--assets-opportunity-cards-sizes-s-radius, 24px);
`

export const StyledButtonRubricatorPagination = styled(Button)`
    z-index: 2;
`
