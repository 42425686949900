import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledFormLayout = styled.div`
    display: flex;
    flex-direction: column;
    overflow: hidden;

    @media all and (max-width: ${getMediaVariable("tablet")}) {
        height: 100vh;
        max-height: calc(100vh - 113px);
        width: calc(100vw - 64px);
        max-width: var(--layout-desktop-form-col-width, 420px);
        row-gap: var(--layout-mobile-form-gap, 24px);
    }

    @media all and (min-width: ${getMediaVariable("tablet")}) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: var(--layout-desktop-gap, 32px);
        width: var(--layout-desktop-form-col-width, 420px);
    }
`
const StyledHeaderForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--layout-mobile-title-gap, 8px);
    z-index: 2;
    background-color: #fff;
    padding-top: 5px;
    padding-right: 32px;
`
const StyledMainForm = styled.div<{}>`
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    width: 100%;
    gap: var(--layout-mobile-gap, 24px);
    &::-webkit-scrollbar {
        display: none;
    }
    overflow-x: scroll;
`

const StyledErrorList = styled.div`
    display: flex;
    flex-direction: column;
    gap: 5px;
    & > * {
        color: var(--assets-label-colors-error, #e21414);
        font-size: 12px;
        line-height: 16px;
    }
`
const StyledFooterForm = styled.div`
    display: flex;
    flex-direction: column;
    gap: var(--layout-mobile-controls-gap, 8px);
    margin-top: auto;
    margin-bottom: -1px;
    height: fit-content;
    width: 100%;

    //padding-right: 32px;
`
export { StyledHeaderForm, StyledErrorList, StyledFooterForm, StyledMainForm, StyledFormLayout }
