import { type IScheduleData } from "entities/viewer"
import { type Times } from "../../models"
import { getDaysString } from "../getDaysString"

export const getScheduleData = (submitData: Times[]) => (numbersDay: (string | number)[][]) => {
    return submitData.reduce((acc, el, i, array): IScheduleData => {
        const key = getDaysString(i, numbersDay, array)
        let result = {}
        if (!key) {
            return acc
        }
        if (el.defaultTimes?.startTime && el.defaultTimes?.finishTime) {
            result = { hours: `${el.defaultTimes.startTime} - ${el.defaultTimes.finishTime}` }
        }
        if (
            el.defaultTimes?.startTime &&
            el.defaultTimes?.finishTime &&
            el.breakTimes?.startTime &&
            el.breakTimes?.finishTime
        ) {
            result = { ...result, break: `${el.breakTimes.startTime} - ${el.breakTimes.finishTime}` }
        }
        return {
            ...acc,
            [key]: result as { hours: string; break?: string },
        }
    }, {} as IScheduleData)
}
