import * as process from "process"
import React, { type FC, useEffect } from "react"
import { Link } from "react-router-dom"
import { useFormContext } from "react-hook-form"
import { InputCheck } from "shared/ui"
import { useToggle } from "shared/hook"
import { SectionGapSize, StyledSection } from "shared/styles"
import { StyledError } from "./styles"

export const AcceptCheck: FC = () => {
    const toggle = useToggle(false)
    const { getFieldState, setValue } = useFormContext()
    useEffect(() => {
        setValue("isAccept", toggle.isTurnOn)
    }, [toggle.isTurnOn])
    const { error, invalid } = getFieldState("isAccept")
    return (
        <StyledSection $gapSize={SectionGapSize.S}>
            <InputCheck
                isError={invalid}
                {...toggle}
                body={() => {
                    return (
                        <>
                            <div>Я ознакомился и принимаю условия следующих документов:</div>
                            <Link to={`${process.env.REACT_APP_API_URL}public/policies/user-agreement`}>
                                Пользовательское соглашение
                            </Link>
                            <Link to={`${process.env.REACT_APP_API_URL}public/policies/personal-data`}>
                                Политика обработки персональных данных
                            </Link>
                        </>
                    )
                }}
            />
            <StyledError>{invalid && error?.message}</StyledError>
        </StyledSection>
    )
}
