import React, { type FC, useEffect } from "react"
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { type IEditWorkSheetForm, useChangeWorkSheetDataMutation, useGetProfileDataQuery } from "features/viewer"
import { useGetCategoriesUsersQuery, useGetCategoriesOpportunitiesQuery } from "features/user"
import { Button, ButtonSize, ButtonType, FormLayout, Input, LabeledSwitch, Select } from "shared/ui"
import { SizeSvg, VkIconSvg } from "shared/assets/svg"
import { useToggle } from "shared/hook"
import { EditWorkSheetNames } from "./types"
import { EditWorkSheetConfig } from "./lib/EditWorkSheetConfig"
import { disabilityGroup } from "./lib/disabilityGroup"

export const EditWorkSheet: FC = () => {
    const navigate = useNavigate()
    const { data: InterestsList } = useGetCategoriesOpportunitiesQuery("")
    const { data: CategoriesLists } = useGetCategoriesUsersQuery("")
    const { data: ProfileData, isLoading } = useGetProfileDataQuery("")
    const [fetch, requestState] = useChangeWorkSheetDataMutation()
    const methods = useForm<IEditWorkSheetForm>(
        EditWorkSheetConfig({
            userVkId: ProfileData?.userVkId,
            interests: ProfileData?.interests.map((item) => item.id),
            userCategories: ProfileData?.userCategories.map((item) => item.id),
            withDisability: ProfileData?.withDisability,
            disabilityGroup: ProfileData?.disabilityGroup,
        })
    )
    const toggleInvalid = useToggle(Boolean(ProfileData?.withDisability), true)
    useEffect(() => {
        toggleInvalid.setTurnOn!(Boolean(ProfileData?.withDisability))
    }, [ProfileData])
    const handleSubmit: SubmitHandler<IEditWorkSheetForm> = async (data) => {
        await fetch(data)
        navigate("/personal-account")
    }
    if (!isLoading) {
        return (
            <FormProvider {...methods}>
                <FormLayout
                    main={() => (
                        <>
                            <Input
                                name={EditWorkSheetNames.VKid}
                                hasIcon={() => <VkIconSvg size={SizeSvg.M} />}
                                labelText={"Профиль ВК"}
                                placeholder={"username"}
                                clearable={false}
                            />
                            <Select
                                start={ProfileData?.interests}
                                data={InterestsList}
                                name={EditWorkSheetNames.INTERESTS}
                                labelText={"Мне интересно"}
                                required={true}
                                multiple={true}
                                clearable={true}
                            />
                            <Select
                                start={ProfileData?.userCategories}
                                data={CategoriesLists}
                                name={EditWorkSheetNames.CATEGORIES}
                                labelText={"Мой статус"}
                                required={true}
                                multiple={true}
                                clearable={true}
                            />
                            <LabeledSwitch {...toggleInvalid} name={EditWorkSheetNames.IsDISABILITY} title={"Инвалид"}>
                                <Select
                                    data={disabilityGroup}
                                    name={EditWorkSheetNames.DisabilityGroup}
                                    required={true}
                                    clearable={true}
                                />
                            </LabeledSwitch>
                        </>
                    )}
                    footer={() => (
                        <>
                            <Button
                                typeUI={ButtonType.PRIMARY}
                                onClick={methods.handleSubmit(handleSubmit)}
                                size={ButtonSize.M}
                            >
                                Сохранить
                            </Button>
                            <Button
                                typeUI={ButtonType.GHOST}
                                size={ButtonSize.M}
                                onClick={() => navigate("/personal-account")}
                            >
                                Отменить
                            </Button>
                        </>
                    )}
                />
            </FormProvider>
        )
    }
}
