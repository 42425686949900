import React, { type FC } from "react"
import { Input, InputDate } from "shared/ui"
import { StyledSection } from "shared/styles"

export const CityDateData: FC = () => {
    return (
        <StyledSection>
            <Input placeholder={"Москва"} labelText={"Город проживания"} name={"locality"} />
            <InputDate name={"birthday"} placeholder={"Введите дату"} labelText={"Дата рождения"} />
        </StyledSection>
    )
}
