import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledProfileTop = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 8px;
`
const StyledProfileTopData = styled.div`
    display: flex;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap, 8px);
    align-self: stretch;
`
const StyledAvatar = styled.div`
    display: flex;

    gap: var(--assets-user-profile-data-sizes-l-gap-title, 16px);
    & > div {
        display: flex;
        max-width: 350px;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        gap: var(--assets-user-profile-data-sizes-l-grid-gap, 12px);
    }

    @media all and (max-width: ${getMediaVariable("mobile-l")}) {
        & > div a {
            display: none;
        }
    }
`
const StyledLinkUpdate = styled.div`
    @media all and (min-width: ${getMediaVariable("mobile-l")}) {
        display: none;
    }
`
export { StyledProfileTop, StyledProfileTopData, StyledAvatar, StyledLinkUpdate }
