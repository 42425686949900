import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledEditProfileTop = styled.div`
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 32px;
    width: 100%;
`

const StyledEditProfile = styled.div`
    display: flex;
    width: 100%;
    gap: var(--layout-desktop-gap, 32px);
    @media all and (max-width: ${getMediaVariable("tablet")}) {
        align-items: flex-start;
        flex-direction: column;
    }
`

export { StyledEditProfileTop, StyledEditProfile }
