import { type ReactNode } from "react"
import type React from "react"

export enum ChoiceSize {
    L = "l",
    M = "m",
    S = "s",
    XS = "xs",
}
export enum ChoiceType {
    BASIC = "basic",
    NAVLINK = "NavLink",
}

export interface ChoiceProps extends React.HTMLAttributes<HTMLButtonElement> {
    size?: ChoiceSize
    type?: ChoiceType
    id?: string
    counter?: number
    selected?: any
    leftIcon?: () => ReactNode
    children?: React.ReactNode
}
