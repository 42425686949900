import styled from "styled-components"

export const StyledError = styled.div`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
    color: var(--assets-label-colors-error);
    padding: var(--assets-label-gap) var(--assets-label-padding-hor) 0px var(--assets-label-padding-hor);
    line-height: 16px;
    font-size: 12px;
`
