import React, { type FC } from "react"
import { OpportunitySection } from "entities/user"
import { ProfileData, ProfileDataType } from "entities/viewer"
import { SectionGapSize, StyledSection } from "shared/styles"
import { EmailSvg, useMediaSizeSvg } from "shared/assets/svg"
import { Row } from "shared/ui"

interface PlaceContactsProps {
    websiteUrl?: string
    vkSocialUrl?: string
    email?: string
    phone?: string | null
}
export const PlaceContacts: FC<PlaceContactsProps> = ({ websiteUrl, vkSocialUrl, email, phone }) => {
    const { fromMtoL } = useMediaSizeSvg()
    return (
        <StyledSection $gapSize={SectionGapSize.S}>
            {email && (
                <ProfileData type={ProfileDataType.LINK} caption={"Электронная почта"}>
                    <EmailSvg size={fromMtoL} />
                    {email}
                </ProfileData>
            )}
            <OpportunitySection.PhoneSection phone={phone} />
            <Row>
                <OpportunitySection.LinkSection vkSocialUrl={vkSocialUrl} websiteUrl={websiteUrl} />
            </Row>
        </StyledSection>
    )
}
