import { type FC } from "react"
import { BasicType, BasicTypeSize, ScrollGroup, Typography } from "shared/ui"
import { StyledSection } from "shared/styles"
import { useResize } from "shared/hook"
import { type ContactListProps } from "./types"
import { StyledContactsCardsContainer, StyledContactsList, StyledTitle } from "./styles"

export const ContactsLocationsList: FC<ContactListProps> = (props) => {
    const { children, title } = props
    const {
        mediaScreenSize: { width, isMobileMd },
    } = useResize()
    return (
        <StyledSection>
            <StyledTitle>
                <Typography.Basic typeUI={BasicType.ADDITIONAL} basicTypeSize={BasicTypeSize.FONT_SIZE_20}>
                    {title}
                </Typography.Basic>
            </StyledTitle>
            <StyledContactsList>
                {width > isMobileMd ? (
                    <StyledContactsCardsContainer>{children}</StyledContactsCardsContainer>
                ) : (
                    <ScrollGroup>{children}</ScrollGroup>
                )}
            </StyledContactsList>
        </StyledSection>
    )
}
