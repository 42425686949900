import { type FC } from "react"
import { StyledContactsCardBody, StyledContactsCardContainer, StyledContactsCardHeader } from "./styles"
import { type ContactsCardProps } from "./types"
import { ContactCardFooterButton, ContactCardItems } from "./ui"

export const ContactsCard: FC<ContactsCardProps> = (props) => {
    const {
        isModal = false,
        fax,
        address,
        name,
        phones,
        websiteUrl,
        email,
        isSmallModal = false,
        ...buttonProps
    } = props
    return (
        <>
            <StyledContactsCardContainer $isModal={isModal}>
                <StyledContactsCardBody>
                    <StyledContactsCardHeader $isModal={isModal}>{name}</StyledContactsCardHeader>
                    <ContactCardItems.Address address={address} />
                    <ContactCardItems.Phones phones={phones} isModal={isModal} isSmallModal={isSmallModal} fax={fax} />
                    <ContactCardItems.Email email={email} />
                    <ContactCardItems.WebsiteUrl websiteUrl={websiteUrl} />
                </StyledContactsCardBody>
                <ContactCardFooterButton isSmallModal={isSmallModal} isModal={isModal} {...buttonProps} />
            </StyledContactsCardContainer>
        </>
    )
}
