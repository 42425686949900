import React, { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"

export const AuthRedirectButton: FC = () => {
    const { fromStoM } = useMediaSizeButton()
    const navigate = useNavigate()
    return (
        <>
            <Button
                onClick={() => {
                    navigate("/registration")
                }}
                size={fromStoM}
                typeUI={ButtonType.GHOST}
            >
                Зарегистрироваться
            </Button>
        </>
    )
}
