import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type PasswordSvgProps } from "./types"

export const PasswordSvg: FC<PasswordSvgProps> = (props) => {
    const { size = SizeSvg.M } = props

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 15C14 16.1046 13.1046 17 12 17C10.8954 17 10 16.1046 10 15C10 13.8954 10.8954 13 12 13C13.1046 13 14 13.8954 14 15ZM13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15C11 14.4477 11.4477 14 12 14C12.5523 14 13 14.4477 13 15Z"
                fill="#3E3AED"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17 10V8C17 6.67392 16.4732 5.40215 15.5355 4.46447C14.5979 3.52678 13.3261 3 12 3C10.6739 3 9.40215 3.52678 8.46447 4.46447C7.52678 5.40215 7 6.67392 7 8V10H5V20H19V10H17ZM12 4C10.9391 4 9.92172 4.42143 9.17157 5.17157C8.42143 5.92172 8 6.93913 8 8V10H16V8C16 6.93913 15.5786 5.92172 14.8284 5.17157C14.0783 4.42143 13.0609 4 12 4ZM18 11H6V19H18V11Z"
                fill="#3E3AED"
            />
        </svg>
    )
}
