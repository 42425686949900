import { type FC } from "react"
import { OfferPlaceForm } from "widgets/viewer"

export const OfferPlaceFormPage: FC = () => {
    return (
        <>
            <OfferPlaceForm />
        </>
    )
}
