import { type FC } from "react"
import { InfoSvg, RuleSvg, SizeSvg, StarSvg } from "shared/assets/svg"
import { conditions } from "shared/lib"
import { Vector, VectorType } from "shared/ui/Vector"
import { type InformerProps, InformerType } from "./types"
import { StyledInformer, StyledInformerContent, StyledSvgFrame } from "./styles"

export const Informer: FC<InformerProps> = (props) => {
    const { type = InformerType.BASIC, title = "", isWithSvg = false, icon, children } = props
    const getSvg = (type: InformerType) => {
        return conditions<InformerType>(type, [
            [InformerType.WARNING, () => <StarSvg size={SizeSvg.S} rule={RuleSvg.EVENODD} />],
            [InformerType.SUCCESS, () => <Vector size={SizeSvg.S} type={VectorType.CHECK} />],
            [InformerType.ERROR, () => <Vector size={SizeSvg.S} type={VectorType.CROSS} />],
        ])
    }
    return (
        <StyledInformer $type={type}>
            <StyledSvgFrame $type={type}>{isWithSvg ? getSvg(type)() : <InfoSvg size={SizeSvg.M} />}</StyledSvgFrame>
            <StyledInformerContent>
                {title && <h3>{title}</h3>}
                {children}
            </StyledInformerContent>
        </StyledInformer>
    )
}
