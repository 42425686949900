import styled, { css } from "styled-components"

const flexMixin = css`
    display: flex;
    align-items: flex-start;
    align-self: stretch;
`
const StyledEditPersonal = styled.div`
    ${flexMixin};
    flex-direction: column;
    gap: var(--layout-mobile-form-gap, 24px);
`
const StyledRowInput = styled.div`
    ${flexMixin};
    gap: var(--layout-mobile-row-gap, 8px);
`

const StyledInputSection = styled.div`
    ${flexMixin};
    flex-direction: column;
    gap: var(--layout-mobile-section-gap, 12px);
    & > div {
        width: 100%;
    }
`

export { StyledInputSection, StyledRowInput, StyledEditPersonal }
