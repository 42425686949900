import styled from "styled-components"

const StyledRow = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap);
    align-self: stretch;
`

export { StyledRow }
