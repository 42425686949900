import React, { type FC } from "react"
import { ScrollGroup } from "shared/ui"
import { type ChoiceGroupProps, ChoiceGroupSize } from "./types"
import { StyledChoiceGroup } from "./styles"

export const ChoiceGroup: FC<ChoiceGroupProps> = (props) => {
    const { size = ChoiceGroupSize.M, scroll = true, children, heightAuto, isModal = false } = props
    return (
        <StyledChoiceGroup $size={size} $heightAuto={heightAuto} $scroll={scroll}>
            {scroll ? <ScrollGroup isModal={isModal}>{children}</ScrollGroup> : children}
        </StyledChoiceGroup>
    )
}
