import React, { type FC, useEffect, useState } from "react"
import { useFormContext } from "react-hook-form"
import { FormField } from "shared/ui/FormField"
import { StyledInput } from "shared/styles"
import { Button, ButtonSize, ButtonType } from "shared/ui/Button"
import { PasswordShowSvg } from "shared/assets/svg"
import { WindowValidation } from "shared/ui/WindowValidation"
import { type InputPasswordProps, InputPasswordType } from "./types"

export const InputPassword: FC<InputPasswordProps> = (props) => {
    const { labelText, required = true, name, validWindow = false, ...other } = props
    const [passwordShow, setPasswordShow] = useState<boolean>(false)
    const [type, setType] = useState<InputPasswordType>(InputPasswordType.PASSWORD)
    const { register } = useFormContext()

    const handlePasswordShow = () => {
        setPasswordShow(!passwordShow)
    }

    useEffect(() => {
        passwordShow ? setType(InputPasswordType.TEXT) : setType(InputPasswordType.PASSWORD)
    }, [passwordShow])

    return (
        <>
            <FormField
                name={name}
                labelText={labelText}
                required={required}
                render={(focus, state) => (
                    <>
                        <StyledInput {...other} {...register(name)} type={type} onFocus={focus} onBlur={focus} />
                        <Button
                            typeUI={ButtonType.FREE}
                            hover={false}
                            onClick={handlePasswordShow}
                            onlyIcon={true}
                            size={ButtonSize.S}
                        >
                            <PasswordShowSvg isShow={passwordShow} />
                        </Button>
                    </>
                )}
            />

            {validWindow && <WindowValidation name={name} />}
        </>
    )
}
