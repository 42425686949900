import { useState } from "react"
import * as yup from "yup"
import { ChoiceInputAuthType } from "./types"

export const useChoiceInputAuth = () => {
    const [choice, setChoice] = useState<ChoiceInputAuthType>(ChoiceInputAuthType.EMAIL)
    return {
        choice,
        setChoiceHandler: (userChoice: typeof choice) => {
            setChoice(userChoice)
        },
    }
}

export const choiceInputSchemaObject = (choice: ChoiceInputAuthType = ChoiceInputAuthType.EMAIL) =>
    choice === ChoiceInputAuthType.EMAIL
        ? {
              email: yup.string().email("Поле заполнено неверно").required("Поле обязательно для заполнения"),
          }
        : {
              phone: yup
                  .string()
                  .required("Поле обязательно для заполнения")
                  //eslint-disable-next-line
                  .matches(/^(\d{3})(\s)(\d{3})(\-)(\d{2})(\-)(\d{2})$/, "Введите корректный номер телефона"),
          }
