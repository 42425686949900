import { useEffect, useState } from "react"

export const useToggleBreak = (
    resetAddHandler: (index: number) => void,
    resetDeleteHandler: (index: number) => void
) => {
    const [turnOnArray, setTurnOnArray] = useState<number[]>([])
    const [index, setIndex] = useState<number | null>(null)
    useEffect(() => {
        if (index) {
            turnOnArray.includes(index) ? resetAddHandler(index) : resetDeleteHandler(index)
        }
    }, [index, turnOnArray])

    return {
        getTurnOn: (j: number) => turnOnArray.includes(j),
        toggleHandler: (j: number) => () => {
            setTurnOnArray((prevState) =>
                prevState.includes(j) ? prevState.filter((el) => el !== j) : [...prevState, j]
            )
            setIndex(j)
        },
    }
}
