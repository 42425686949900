import React, { type FC } from "react"
import { useGetCoordinatorDataQuery } from "features/viewer"
import { ProfileData, ProfileDataSize, ProfileDataType } from "entities/viewer"
import { GeoSvg, PhoneSvg } from "shared/assets/svg"
import { StyledCoordinatorCard } from "./styles"
import { type CoordinatorCardProps, CoordinatorCardSize } from "./types"
export const GiftCoordinatorCard: FC<CoordinatorCardProps> = (props) => {
    const { size = CoordinatorCardSize.S } = props
    const { data, isLoading } = useGetCoordinatorDataQuery("")
    const dataSizeCondition = size === CoordinatorCardSize.L ? ProfileDataSize.L : ProfileDataSize.S
    if (!isLoading && data) {
        return (
            <StyledCoordinatorCard>
                <ProfileData
                    caption={"Населенный пункт"}
                    size={dataSizeCondition}
                    icon={(size) => <GeoSvg size={size} />}
                >
                    {data.locality}
                </ProfileData>
                <ProfileData
                    type={ProfileDataType.TEXT}
                    caption={"Номер телефона"}
                    size={dataSizeCondition}
                    icon={(size) => <PhoneSvg size={size} />}
                >
                    {data.phone}
                </ProfileData>
            </StyledCoordinatorCard>
        )
    }
}
