import React, { type FC } from "react"
import { conditions } from "shared/lib"
import { SizeSvg } from "shared/assets/svg"
import { type ProfileDataProps, ProfileDataSize, ProfileDataType } from "./types"
import { StyledCaption, StyledProfileData, StyledProfileDataContent } from "./styles"
import { useMediaProfileData } from "./lib"

export const ProfileData: FC<ProfileDataProps> = (props) => {
    const { SizeStoL } = useMediaProfileData()
    const { size = SizeStoL, href = "", type = ProfileDataType.TEXT, icon, caption = "", children, ...rest } = props
    const sizeSvgCondition = conditions<ProfileDataSize>(size, [
        [ProfileDataSize.L, SizeSvg.L],
        [ProfileDataSize.S, SizeSvg.M],
    ])
    return (
        <StyledProfileData {...rest} $size={size} $type={type} href={href}>
            <StyledProfileDataContent $size={size} $type={type}>
                {icon?.(sizeSvgCondition)}
                {children}
            </StyledProfileDataContent>
            <StyledCaption $size={size}>{caption}</StyledCaption>
        </StyledProfileData>
    )
}
