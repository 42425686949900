import styled from "styled-components"

const StyledInputCheck = styled.div<{
    $isError: boolean
}>`
    display: flex;
    padding: var(--assets-smart-captcha-sizes-padding, 12px);
    align-items: flex-start;
    gap: var(--assets-smart-captcha-sizes-gap, 12px);
    align-self: stretch;
    border-radius: var(--assets-smart-captcha-sizes-radius, 12px);
    border: 1px solid
        ${({ $isError }) =>
            $isError
                ? "var(--assets-input-colors-stroke-error, #E21414)"
                : "var(--assets-smart-captcha-colors-stroke, rgba(0, 0, 0, 0.06))"};
`

const StyledInputCheckBody = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-smart-captcha-sizes-gap, 12px);
    flex: 1 0 0;
    line-height: 18px;
`

export { StyledInputCheck, StyledInputCheckBody }
