import React, { type FC } from "react"
import { type ImagesCardProps } from "./types"
import { StyledImagesCard, StyledImagesCardImg } from "./styles"

export const ImagesCard: FC<ImagesCardProps> = (props) => {
    const { img, ...other } = props
    return (
        <StyledImagesCard {...other}>
            <StyledImagesCardImg>
                <img src={img} alt="" />
            </StyledImagesCardImg>
        </StyledImagesCard>
    )
}
