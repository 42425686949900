import * as yup from "yup"
import { type ObjectSchema } from "yup"
import { type IModalWorkFormValues } from "../../models"

const fieldsTimes = {
    startTime: yup
        .string()
        .optional()
        .test("is-required", " ", function (value) {
            const finishTime = this.resolve(yup.ref("finishTime")) as string
            return !(finishTime && finishTime.trim() !== "" && !value)
        }),
    finishTime: yup
        .string()
        .optional()
        .test("is-required", " ", function (value) {
            const startTime = this.resolve(yup.ref("startTime")) as string
            return !(startTime && startTime.trim() !== "" && !value)
        }),
}

export const fieldsSchema: ObjectSchema<IModalWorkFormValues> = yup.object().shape({
    times: yup
        .array()
        .of(
            yup.object().shape({
                defaultTimes: yup.object().shape(fieldsTimes).required(" "),
                breakTimes: yup.object().shape(fieldsTimes).optional(),
            })
        )
        .required("Times are required"),
})
