import styled from "styled-components"

export const StyledWorkSchedule = styled.div`
    width: 100%;
    display: flex;
    padding: var(--assets-input-schedule-sizes-s-padding, 12px);
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: var(--assets-input-schedule-sizes-s-gap, 8px);

    border-radius: var(--assets-input-schedule-sizes-s-radius, 16px);
    border: 1px solid var(--root-colors-main-white-dark, rgba(0, 0, 0, 0.06));
`
export const StyledLabeledWrap = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-input-schedule-sizes-s-gap-title, 8px);
    align-self: stretch;
    fill-opacity: 0.72;
`
