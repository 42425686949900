import React, { type FC } from "react"
import { BasicType, BasicTypeSize, Typography } from "../Typography"
import { type TextSectionProps } from "./types"
import { StyledTextSection, StyledTitle } from "./styles"

export const TextSection: FC<TextSectionProps> = (props) => {
    const { children, title } = props
    return (
        <StyledTextSection>
            <StyledTitle>
                <Typography.Basic basicTypeSize={BasicTypeSize.FONT_SIZE_12} typeUI={BasicType.ADDITIONAL}>
                    {title}
                </Typography.Basic>
            </StyledTitle>
            <div>{children}</div>
        </StyledTextSection>
    )
}
