import React, { type FC } from "react"
import { Group } from "shared/ui"
import { StylesIdeaContentText, StylesTitleIdeaContentCard } from "./styles"

import { type ShowIdeaContentCardProps } from "./types"

export const ShowIdeaContentCard: FC<ShowIdeaContentCardProps> = (props) => {
    const { title, children } = props
    return (
        <Group>
            <StylesTitleIdeaContentCard>{title}</StylesTitleIdeaContentCard>
            <StylesIdeaContentText>{children}</StylesIdeaContentText>
        </Group>
    )
}
