import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type OpportunitySvgProps } from "./types"

export const OpportunitySvg: FC<OpportunitySvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M20.2658 4.41697C20.5207 3.97419 20.0257 3.47925 19.583 3.73417L12.2494 7.95637C12.095 8.04528 11.9049 8.04528 11.7505 7.95637L4.4169 3.73417C3.97413 3.47926 3.47919 3.97419 3.73411 4.41696L7.95631 11.7505C8.04522 11.905 8.04522 12.095 7.95631 12.2495L3.73411 19.583C3.47919 20.0258 3.97413 20.5207 4.4169 20.2658L11.7505 16.0436C11.9049 15.9547 12.095 15.9547 12.2494 16.0436L19.583 20.2658C20.0257 20.5207 20.5207 20.0258 20.2658 19.583L16.0436 12.2495C15.9547 12.095 15.9547 11.905 16.0436 11.7505L20.2658 4.41697ZM18.4726 5.52737L12.7484 8.823C12.2851 9.08974 11.7148 9.08974 11.2515 8.823L5.52731 5.52737L8.82294 11.2516C9.08968 11.7149 9.08968 12.2851 8.82294 12.7484L5.52731 18.4726L11.2515 15.177C11.7148 14.9103 12.2851 14.9103 12.7484 15.177L18.4726 18.4726L15.1769 12.7484C14.9102 12.2851 14.9102 11.7149 15.1769 11.2516L18.4726 5.52737Z"
                fill="currentColor"
            />
            <path
                d="M20.9999 12C20.9999 10.4202 20.5841 8.86817 19.7942 7.5L20.6602 7C21.5379 8.52019 21.9999 10.2446 21.9999 12C21.9999 13.7554 21.5379 15.4798 20.6602 17L19.7942 16.5C20.5841 15.1318 20.9999 13.5798 20.9999 12Z"
                fill="currentColor"
            />
            <path
                d="M16.4999 19.7942C15.1318 20.5841 13.5798 21 11.9999 21C10.4201 21 8.86811 20.5841 7.49994 19.7942L6.99994 20.6603C8.52013 21.5379 10.2446 22 11.9999 22C13.7553 22 15.4797 21.5379 16.9999 20.6603L16.4999 19.7942Z"
                fill="currentColor"
            />
            <path
                d="M2.99994 12C2.99994 13.5798 3.41579 15.1318 4.20571 16.5L3.33968 17C2.462 15.4798 1.99994 13.7554 1.99994 12C1.99994 10.2446 2.462 8.52019 3.33969 7L4.20571 7.5C3.4158 8.86817 2.99994 10.4202 2.99994 12Z"
                fill="currentColor"
            />
            <path
                d="M7.49994 4.20577C8.86811 3.41586 10.4201 3 11.9999 3C13.5798 3 15.1318 3.41586 16.4999 4.20577L16.9999 3.33975C15.4797 2.46206 13.7553 2 11.9999 2C10.2446 2 8.52013 2.46206 6.99994 3.33975L7.49994 4.20577Z"
                fill="currentColor"
            />
        </svg>
    )
}
