import styled from "styled-components"

const StyledText = styled.div`
    color: var(--layout-text-colors-primary, #000);
    line-height: 18px;
`
const StyledAboutMain = styled.div`
    width: 100%;
`

const StyledTitle = styled.div`
    z-index: 2;
`

export { StyledText, StyledAboutMain, StyledTitle }
