import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { transformUrlImages } from "shared/lib"
import { type Partners } from "./types"

const base_url_news = `${BASE_URL.PUBLIC}/partners`
export const partnersApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getPartnersData: builder.query<Partners[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: base_url_news,
                method: "GET",
            }),
            transformResponse: (data: Partners[], ...rest) => {
                const newData = data.map((item) => ({ ...item, logo: transformUrlImages(item.logo) }))
                return newData
            },
        }),
    }),
})

export const { useGetPartnersDataQuery } = partnersApi
