import { type FC } from "react"
import { useGetDashboardPlacesQuery, useGetDashboardOpportunitiesQuery } from "features/viewer"
import { OpportunityType } from "entities/user"
import { StyledSection } from "shared/styles"
import { useResize } from "shared/hook"
import { Group, GroupDirection } from "shared/ui"
import { OpportunityList } from "./OpportunityList/OpportunityList"
import { OfferOpportunityButton } from "./OfferOpportunityButton"
import { OfferPlaceButton } from "./OfferPlaceButton"

export const OpportunityDashboard: FC = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    const { data: dataPlace } = useGetDashboardPlacesQuery("")
    const { data: dataOpp } = useGetDashboardOpportunitiesQuery("")
    return (
        <>
            <Group direction={width > isTablet ? GroupDirection.ROW : GroupDirection.COLUMN}>
                <OfferOpportunityButton isShowInformer={!dataOpp} />
                {width > isTablet && <OfferPlaceButton isShowInformer={!dataOpp} />}
                {width < isTablet && dataOpp && (
                    <OpportunityList cards={dataOpp} listType={OpportunityType.OPPORTUNITY} />
                )}
            </Group>
            {width > isTablet && dataOpp && <OpportunityList cards={dataOpp} listType={OpportunityType.OPPORTUNITY} />}
            <StyledSection>
                {width < isTablet && <OfferPlaceButton isShowInformer={!dataPlace} />}
                {dataPlace && <OpportunityList cards={dataPlace} listType={OpportunityType.PLACE} />}
            </StyledSection>
        </>
    )
}
