import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledTitle = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-title-gap, 8px);
    align-self: stretch;
`

export const StyledHeading = styled.div`
    font-size: 20px;
    @media (min-width: ${getMediaVariable("laptop")}) {
        font-size: 24px;
    }
    font-weight: 500;
    line-height: 120%;
`
