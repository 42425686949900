import { type FC } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { Button, ButtonSize, ButtonType, Vector, VectorType } from "shared/ui"
import { FillSvg, SizeSvg } from "shared/assets/svg"
import { StyledInput, StyledInputWrapper } from "shared/styles"
import { FormField } from "../FormField"
import { type InputProps, TypeInput } from "./types"

export const Input: FC<InputProps> = (props) => {
    const {
        hasIcon,
        hasStatus = false,
        name,
        type = TypeInput.TEXT,
        clearable = true,
        required = true,
        labelText,
        iconSvg,
        isSuccess = true,
        isInvalid = true,
        labelIcon,
        ...other
    } = props
    const { register, resetField } = useFormContext()
    const InputValueResults = useWatch({ name })
    return (
        <FormField
            isSuccess={isSuccess}
            isInvalid={isInvalid}
            hasStatus={hasStatus}
            name={name}
            required={required}
            labelIcon={labelIcon}
            labelText={labelText}
            render={(focus) => (
                <StyledInputWrapper>
                    {hasIcon?.()}
                    <StyledInput {...other} {...register(name)} type={type} onFocus={focus} onBlur={focus} />
                    {clearable && !!InputValueResults && (
                        <Button
                            typeUI={ButtonType.FREE}
                            hover={false}
                            onlyIcon={true}
                            size={ButtonSize.S}
                            onClick={() => resetField(name)}
                        >
                            <Vector size={SizeSvg.M} type={VectorType.CROSS} fill={FillSvg.BLACK} />
                        </Button>
                    )}
                </StyledInputWrapper>
            )}
        />
    )
}
