import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type ProfileSvgProps } from "./types"

export const ProfileSvg: FC<ProfileSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg width={size} height={size} viewBox="0 0 18 23" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15 6C15 9.31371 12.3137 12 9 12C5.68629 12 3 9.31371 3 6C3 2.68629 5.68629 0 9 0C12.3137 0 15 2.68629 15 6ZM14 6C14 8.76142 11.7614 11 9 11C6.23858 11 4 8.76142 4 6C4 3.23858 6.23858 1 9 1C11.7614 1 14 3.23858 14 6Z"
                fill="currentColor"
            />
            <path
                d="M6.5 13.5C2.91015 13.5 0 16.4101 0 20V23H1V20C1 16.9624 3.46243 14.5 6.5 14.5H11.5C14.5376 14.5 17 16.9624 17 20V23H18V20C18 16.4101 15.0899 13.5 11.5 13.5H6.5Z"
                fill="currentColor"
            />
        </svg>
    )
}
