import dayjs from "dayjs"
import { type FC } from "react"
import { Select } from "shared/ui"
import { StyledControlsGroup } from "./styles"
import { MonthsSelectData, YearsSelectData } from "./config"
import { type EventsControlGroupProps } from "./types"

export const EventsControlGroup: FC<EventsControlGroupProps> = (props) => {
    const { handlerSetWasClear, wasClear } = props
    return (
        <StyledControlsGroup>
            <Select
                setWasClear={handlerSetWasClear}
                wasClear={wasClear}
                initialValue={MonthsSelectData[dayjs().month()].name}
                data={MonthsSelectData}
                name={"month"}
            />
            <Select
                setWasClear={handlerSetWasClear}
                wasClear={wasClear}
                initialValue={dayjs().year().toString()}
                data={YearsSelectData}
                name={"year"}
            />
        </StyledControlsGroup>
    )
}
