import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledScrollGroup = styled.div<{
    $isModal: boolean
}>`
    display: flex;
    align-items: flex-start;
    overflow-x: auto;

    &::-webkit-scrollbar {
        display: none;
    }
    position: relative;
    max-width: ${({ $isModal }) => ($isModal ? "288px" : "calc(100vw - 32px)")};
    padding-left: 0;
    padding-right: 0;
    z-index: 60;
    left: 1px;
    height: fit-content;
    gap: inherit;

    @media all and (min-width: ${getMediaVariable("laptop")}) {
        max-width: ${({ $isModal }) => ($isModal ? "416px" : "calc(100vw - 34px)")};
        position: relative;
        padding-right: 0;
        padding-left: 0;
    }
    @media all and (max-width: ${getMediaVariable("mobile-m")}) {
        max-width: ${({ $isModal }) => ($isModal ? "288px" : "calc(100vw - 34px)")};
    }
`

export { StyledScrollGroup }
