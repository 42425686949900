import React, { type FC } from "react"
import { Outlet, useLocation } from "react-router-dom"
import { EditProfile } from "widgets/viewer"
import { Wrapper } from "shared/ui"

export const EditProfilePage: FC = () => {
    const route = useLocation()
    // console.log(route.pathname)
    return (
        <Wrapper maxWidth={644} width={100}>
            <EditProfile>
                <Outlet />
            </EditProfile>
        </Wrapper>
    )
}
