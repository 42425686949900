import React, { type FC } from "react"
import { type BurgerButtonProps } from "./types"
import { StyledBurgerButton } from "./styles"

export const BurgerButton: FC<BurgerButtonProps> = (props) => {
    const { isOpen = false, ...other } = props
    return (
        <StyledBurgerButton $isOpen={isOpen} {...other}>
            <span></span>
        </StyledBurgerButton>
    )
}
