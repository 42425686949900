import styled from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledHeaderContainer = styled.div`
    height: 81px;
`

const StyledHeader = styled.div<{
    $isActive: boolean
    $isMain: boolean
}>`
    position: fixed;
    display: flex;
    width: 100vw;
    padding: var(--assets-header-common-mobile-padding-vert) var(--assets-header-common-mobile-padding-hor);
    background: ${({ $isMain }) =>
        $isMain ? "var(--root-colors-main-timur-grey-basic)" : "var(--root-colors-main-white-basic)"};
    justify-content: space-between;
    align-items: flex-start;
    font-weight: 300;
    border-bottom: 1px solid
        ${(props) => (props.$isActive ? "var(--assets-header-menu-line-colors-divider)" : "transparent")};
    z-index: 99;
    @media (min-width: ${getMediaVariable("laptop-l")}) {
        padding: var(--assets-header-common-mobile-padding-vert, 16px) 128px;
    }
`
const StyledHeaderControls = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 16px;

    & > * {
        position: relative;
        cursor: pointer;
        width: var(--assets-header-menu-button-mobile-common-width);
        height: var(--assets-header-menu-button-mobile-common-height);
        padding: var(--assets-header-menu-button-mobile-common-padding-vert)
            var(--assets-header-menu-button-mobile-main-padding-hor);
        justify-content: center;
        align-items: center;
        gap: var(--assets-header-menu-button-mobile-common-gap);
        border-radius: var(--assets-header-menu-button-mobile-common-radius);
        display: flex;
        &:hover {
            background: var(--root-colors-main-black-ghost-alt, rgba(0, 0, 0, 0.06));
        }
    }
`

const StyledDropdown = styled.div<{
    $isShow: boolean
}>`
    position: ${(props) => (props.$isShow ? "fixed" : "static")};

    top: 81px;

    width: 100vw;
    max-width: var(--assets-header-menu-common-desktop-width, 360px);
    padding: var(--assets-header-menu-common-mobile-padding-hor, 32px);

    display: ${(props) => (props.$isShow ? "flex" : "none")};
    flex-direction: column;
    height: calc(100vh - 81px);
    background: var(--root-colors-main-white-basic);
    z-index: 97;

    align-items: flex-start;
    flex-shrink: 0;
    right: 0;

    @media (min-width: ${getMediaVariable("laptop-l")}) {
        right: 32px;
    }
`

const StyledVector = styled.div<{
    $isShow: boolean
}>`
    position: absolute;
    top: 0;
    left: 63px;

    width: 1px;
    height: 100%;

    background-color: ${(props) => (props.$isShow ? "var(--assets-header-menu-line-colors-divider)" : "transparent")};
`

const StyledRedirectBtnPanel = styled.div`
    display: flex;
    padding: var(--assets-header-menu-personal-sizes-padding, 12px);
    justify-content: space-around;
    align-items: center;
    gap: var(--assets-header-menu-personal-sizes-gap, 12px);

    margin: auto auto -1px;

    width: 312px;
    max-height: 72px;

    border-radius: var(--assets-header-menu-personal-sizes-radius, 24px);
    background: #fafafa;

    flex-grow: 1;
    z-index: 99;
`

const StyledLogoContainer = styled.div`
    cursor: pointer;
`

export {
    StyledLogoContainer,
    StyledDropdown,
    StyledVector,
    StyledRedirectBtnPanel,
    StyledHeader,
    StyledHeaderControls,
    StyledHeaderContainer,
}
