import React, { type FC } from "react"
import { BasicType, HeadingType, Typography } from "shared/ui"
import { StyledFooterForm, StyledHeaderForm, StyledMainForm, StyledFormLayout, StyledErrorList } from "./styles"
import { type FormLayoutProps } from "./types"
export const FormLayout: FC<FormLayoutProps> = (props) => {
    const { headerText, main, footer, error, secondHeaderText = null } = props
    return (
        <StyledFormLayout>
            {(headerText || secondHeaderText) && (
                <StyledHeaderForm>
                    {headerText && <Typography.Heading typeUI={HeadingType.H1}>{headerText}</Typography.Heading>}
                    {secondHeaderText && (
                        <div>
                            <Typography.Basic typeUI={BasicType.ADDITIONAL}>{secondHeaderText}</Typography.Basic>
                        </div>
                    )}
                </StyledHeaderForm>
            )}
            <StyledMainForm>
                {main()}
                <StyledErrorList>{error && error.map((item, index) => <div key={index}>{item}</div>)}</StyledErrorList>
            </StyledMainForm>
            <StyledFooterForm>{footer()}</StyledFooterForm>
        </StyledFormLayout>
    )
}
