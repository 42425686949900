import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { type IEditWorkSheetForm } from "./types"

export const WorkSheetApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        changeWorkSheetData: builder.mutation<IEditWorkSheetForm, IEditWorkSheetForm>({
            query: (body: IEditWorkSheetForm): RequestConfig<IEditWorkSheetForm, unknown> => ({
                url: BASE_URL.USER + "/survey",
                method: "PATCH",
                body,
            }),
            invalidatesTags: ["Profile"],
        }),
    }),
})

export const { useChangeWorkSheetDataMutation } = WorkSheetApi
