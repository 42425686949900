import { styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledContactsSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
    width: 100%;
`
const StyledContactsLists = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--layout-mobile-gap, 24px);
    align-self: stretch;
    & > div {
        width: unset;
    }
    @media all and( min-width: ${getMediaVariable("tablet")}) {
        gap: var(--layout-desktop-gap, 32px);
        flex-direction: column;
    }
`

const StyledContactsMenu = styled.div`
    z-index: 2;
    display: flex;
    flex-direction: column;
    gap: var(--layout-mobile-section-gap, 12px);
    & > *:last-child {
        width: 100%;
    }
    & > *:first-child {
        width: unset;
    }

    @media all and (min-width: ${getMediaVariable("tablet")}) {
        flex-direction: row;
        height: fit-content;
        gap: var(--layout-desktop-gap, 32px);
        align-items: center;
        & > *:first-child {
            width: unset;
            height: fit-content;
        }
    }
`
export { StyledContactsSection, StyledContactsLists, StyledContactsMenu }
