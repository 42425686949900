import styled from "styled-components"
import { patternSmallSvgStatic } from "shared/assets/svg"
import { InfoEventCardSize } from "./types"

export const StyledInfoEventCard = styled.div<{
    $size: InfoEventCardSize
}>`
    z-index: 2;
    display: flex;
    width: ${({ $size }) =>
        $size === InfoEventCardSize.S ? "var(--assets-opportunity-cards-sizes-s-width, 326px)" : "386.667px"};
    min-width: var(--assets-card-info-event-${({ $size }) => $size}-width-min, 288px);
    align-items: flex-start;
    background-color: #fff;

    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
    border: 1px solid var(--assets-card-common-colors-stroke, rgba(0, 0, 0, 0.04));
`

export const StyledContent = styled.div`
    display: flex;
    padding: var(--assets-card-info-event-s-indent, 16px) 0px var(--assets-card-info-event-s-indent, 16px)
        var(--assets-card-info-event-s-indent, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-card-info-event-s-indent, 16px);
    flex: 1 0 0;
    align-self: stretch;
`

export const StyledNumber = styled.div`
    color: var(--assets-card-common-colors-interactive, #3e3aed);
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
`

export const StyledBackgroundPicture = styled.div`
  border-radius: inherit;
  width: 128px;
  flex-shrink: 0;
  align-self: stretch;
  background-image: url(${() => `data:image/svg+xml,${patternSmallSvgStatic}#info`});
  background-position: right  -776px bottom -615px;

  background-size: 1520px;
  background-repeat: no-repeat
}
`

export const StyledText = styled.div`
    line-height: 18px;
`
