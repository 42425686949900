import { styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

export const StyledCalendarWrapper = styled.div`
    display: grid;
    gap: 12px;
    & ul {
        max-height: 50vh;
    }

    @media (min-width: ${getMediaVariable("desktop")}) {
        align-self: stretch;
    }
`

export const StyledParentControls = styled.div`
    display: grid;
    z-index: 2;
    grid-template-columns: 1fr 170px;
    justify-content: space-around;
    transform: translateY(-16px);
`
