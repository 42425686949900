import React, { type FC, useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { type INotification, useGetNotificationsQuery, useReadNotificationMutation } from "features/viewer"
import { NotificationCard } from "entities/viewer"
import {
    Button,
    ButtonSize,
    ButtonType,
    Counter,
    HeadingType,
    Modal,
    ModalDefault,
    NotificationCounter,
    Typography,
} from "shared/ui"
import { useModal } from "shared/hook"
import { StyledTitle } from "./styles"

export const HeaderNotification: FC = () => {
    const modal = useModal()
    const { data, refetch } = useGetNotificationsQuery("")
    const location = useLocation()
    const [notReadingArr, setNotReadingArr] = useState<INotification[] | undefined>()
    const [notReadingCount, setNotReadingCount] = useState<number | undefined>()
    useEffect(() => {
        refetch()
    }, [location.pathname])

    useEffect(() => {
        setNotReadingArr(data?.filter((n) => !n.read))
    }, [data])
    useEffect(() => {
        const notCount = notReadingArr?.length
        setNotReadingCount(notCount || undefined)
    }, [notReadingArr])

    const [fetchReadNotifications] = useReadNotificationMutation()
    const handlerItemClick = (id: string) => () => {
        fetchReadNotifications(id)
    }

    const handlerReaderAllClick = () => {
        if (notReadingArr?.length)
            notReadingArr.forEach((el) => {
                fetchReadNotifications(el)
            })
    }

    return (
        <>
            <>
                <NotificationCounter count={notReadingCount} onClick={modal.toggleActivity} />
            </>
            <Modal {...modal} bigHeight>
                <ModalDefault
                    back={modal.toggleActivity}
                    footerBtn={() => (
                        <Button
                            size={ButtonSize.M}
                            typeUI={notReadingArr?.length ? ButtonType.PRIMARY : ButtonType.DISABLED}
                            onClick={handlerReaderAllClick}
                        >
                            Прочитать все
                        </Button>
                    )}
                    beforeAdditionalHeadComponent={() => (
                        <StyledTitle>
                            <Typography.Heading typeUI={HeadingType.H2}>Уведомления</Typography.Heading>
                            <div>
                                <Counter count={notReadingCount} />
                            </div>
                        </StyledTitle>
                    )}
                >
                    {data?.map(({ id, message, read, title }) => (
                        <NotificationCard
                            handlerItemClick={handlerItemClick}
                            id={id}
                            message={message}
                            read={read}
                            title={title}
                            key={`notification-${id}`}
                        />
                    ))}
                </ModalDefault>
            </Modal>
        </>
    )
}
