import React, { type FC } from "react"
import { RuleSvg, SizeSvg } from "shared/assets/svg"
import { type StarSvgProps } from "./types"

export const StarSvg: FC<StarSvgProps> = (props) => {
    const { size = SizeSvg.L, rule = RuleSvg.NONZERO } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                fillRule={rule}
                clipRule={rule}
                d="M14.4675 7.05723L12 2.20606L9.53254 7.05723C9.24184 7.62876 8.69473 8.02625 8.06135 8.12611L2.68512 8.97371L6.53637 12.8195C6.9901 13.2726 7.19907 13.9157 7.09832 14.549L6.24309 19.924L11.0908 17.4497C11.6619 17.1581 12.3381 17.1581 12.9092 17.4497L17.7569 19.924L16.9017 14.549C16.8009 13.9157 17.0099 13.2726 17.4636 12.8195L21.3149 8.97371L15.9386 8.12611C15.3053 8.02625 14.7582 7.62876 14.4675 7.05723ZM12.8913 1.7527C12.5206 1.02384 11.4794 1.02384 11.1087 1.7527L8.64121 6.60387C8.49586 6.88963 8.2223 7.08838 7.90561 7.13831L2.52939 7.98591C1.72165 8.11326 1.39989 9.10351 1.97852 9.68132L5.82977 13.5271C6.05663 13.7536 6.16112 14.0752 6.11074 14.3918L5.25551 19.7669C5.12702 20.5744 5.96938 21.1864 6.69771 20.8147L11.5454 18.3403C11.8309 18.1946 12.1691 18.1946 12.4546 18.3403L17.3023 20.8147C18.0306 21.1864 18.873 20.5744 18.7445 19.7669L17.8893 14.3918C17.8389 14.0752 17.9434 13.7536 18.1702 13.5271L22.0215 9.68132C22.6001 9.10351 22.2783 8.11326 21.4706 7.98591L16.0944 7.13831C15.7777 7.08838 15.5041 6.88963 15.3588 6.60387L12.8913 1.7527Z"
                fill="currentColor"
            />
        </svg>
    )
}
