import React, { type FC } from "react"
import { useGetPartnersDataQuery } from "features/user"
import { PartnersCard } from "entities/viewer"
import { HeadingType, Typography } from "shared/ui"
import { StyledPatternPageBackground } from "shared/styles"
import { StyledPartners, StyledPartnersList } from "./styles"

export const Partners: FC = () => {
    const { data, isLoading } = useGetPartnersDataQuery("")

    if (!isLoading && data) {
        return (
            <StyledPartners>
                <StyledPatternPageBackground
                    $height={"1100px"}
                    $position={"right -1195px bottom -754px"}
                    $color={"yellowFill"}
                />
                <Typography.Heading typeUI={HeadingType.H1}>Партнеры</Typography.Heading>
                <StyledPartnersList>
                    {data.map((item, index) => (
                        <PartnersCard key={index} href={item.url} img={item.logo}>
                            {item.name}
                        </PartnersCard>
                    ))}
                </StyledPartnersList>
            </StyledPartners>
        )
    }
}
