import { type CardStatusType } from "shared/ui"

export enum IdeaFormType {
    TEAM = "TEAM",
    INDIVIDUAL = "INDIVIDUAL",
}
export interface IIdeaForm {
    title: string
    problem: string
    solution: string
    successReason: string
    locality: string
    ideaType: IdeaFormType
    results: string
}

export interface Ideas extends IIdeaForm {
    id: string
    status: CardStatusType
    createdByUserId: string
    createdAt: string
    updatedAt: string
}
