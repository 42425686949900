// export const formDataHandler = <T>(data: T, key: keyof T) => {
//     const formData = new FormData()
//     const fileField = data[key]
//     if (data && typeof data === "object" && Array.isArray(fileField)) {
//         fileField.forEach((item: File) => {
//             formData.append(key as string, item)
//         })
//
//         for (let [key, value] of Object.entries(data)) {
//             formData.append(key, value)
//         }
//         return formData
//     }
//     return data
// }

export const formDataHandler = <T>(data: T, key: keyof T, additionalKey?: keyof T) => {
    const formData = new FormData()
    const fileField = data[key]
    const objectField = additionalKey ? data[additionalKey] : null
    if (data && typeof data === "object" && Array.isArray(fileField)) {
        fileField.forEach((item: File) => {
            formData.append(key as string, item)
        })
        if (objectField) {
            formData.append(additionalKey as string, JSON.stringify(objectField))
        }
        for (let [keyTo, value] of Object.entries(data)) {
            if (key !== keyTo && keyTo !== additionalKey) {
                formData.append(keyTo, value)
            }
        }
        return formData
    }
    return data
}
