import { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, ButtonSize, ButtonType } from "shared/ui"

import { ProfileSvg } from "shared/assets/svg"
import { type AuthRedirectControlProps } from "./types"

export const AuthRedirectControl: FC<AuthRedirectControlProps> = ({ onButtonClick }) => {
    const navigate = useNavigate()

    const handleButtonClick = (path: string) => {
        onButtonClick()
        navigate(path)
    }

    return (
        <>
            <Button
                size={ButtonSize.M}
                typeUI={ButtonType.GHOST}
                onClick={() => {
                    handleButtonClick("/registration")
                }}
            >
                Зарегистрироваться
            </Button>
            <Button
                size={ButtonSize.M}
                typeUI={ButtonType.PRIMARY}
                leftIcon={() => <ProfileSvg />}
                onClick={() => {
                    handleButtonClick("/auth")
                }}
            >
                Войти
            </Button>
        </>
    )
}
