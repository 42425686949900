import { useMatch } from "react-router-dom"
import type React from "react"
import { useEffect, useState } from "react"

export const useGetTypeParameters = (): {
    countPage: number
    type: 0 | 1 | 2 | undefined
    setCountPage: React.Dispatch<React.SetStateAction<number>>
} => {
    const place = useMatch("/rubricator/place")
    const opportunities = useMatch("/rubricator/opportunities")
    const rubricator = useMatch("/rubricator")
    const [countPage, setCountPage] = useState<number>(0)
    const [type, setType] = useState<0 | 1 | 2>()
    useEffect(() => {
        setCountPage(0)
        const effType = () => {
            switch (true) {
                case place?.pathname === "/rubricator/place": {
                    return 1
                }
                case opportunities?.pathname === "/rubricator/opportunities": {
                    return 2
                }
                case rubricator?.pathname === "/rubricator": {
                    return 0
                }
                default: {
                    return undefined
                }
            }
        }
        setType(undefined)
        setTimeout(() => {
            setType(effType())
        }, 0)
    }, [place, opportunities, rubricator])

    return { type, countPage, setCountPage }
}
