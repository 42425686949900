import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type EditSvgProps } from "./types"

export const EditSvg: FC<EditSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 20 20" fill="none">
            <g id="Edit">
                <path
                    id="vector"
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M15 2.74414L17.2559 5.00006L5.17255 17.0834H2.91663V14.8275L15 2.74414ZM3.74996 15.1727V16.2501H4.82737L6.07737 15.0001L4.99996 13.9227L3.74996 15.1727ZM5.58922 13.3334L6.66663 14.4108L14.4107 6.66673L13.3333 5.58932L5.58922 13.3334ZM16.0774 5.00006L15 6.07747L13.9225 5.00006L15 3.92265L16.0774 5.00006Z"
                    fill="#3E3AED"
                />
            </g>
        </svg>
    )
}
