import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type RatingSvgProps } from "./types"

export const RatingSvg: FC<RatingSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 4.17969L16.2305 9.67938C16.7197 10.3152 17.6225 10.4537 18.2797 9.99366L23.0114 6.68143L18.7093 18.5124C18.4937 19.1051 17.9303 19.4997 17.2996 19.4997H6.70044C6.06968 19.4997 5.5063 19.1051 5.29075 18.5124L0.988586 6.68143L5.72035 9.99366C6.37754 10.4537 7.28036 10.3152 7.76948 9.67938L12 4.17969ZM12 5.81981L8.5621 10.2891C7.74691 11.3488 6.24221 11.5796 5.14689 10.8129L3.01143 9.31807L6.23054 18.1706C6.30239 18.3682 6.49018 18.4997 6.70044 18.4997H17.2996C17.5098 18.4997 17.6976 18.3682 17.7695 18.1706L20.9886 9.31807L18.8531 10.8129C17.7578 11.5796 16.2531 11.3488 15.4379 10.2891L12 5.81981Z"
                fill="black"
            />
        </svg>
    )
}
