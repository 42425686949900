import { CardStatusType } from "shared/ui"
import { conditions } from "shared/lib"

export const textModalUpload = (status: CardStatusType | undefined) => {
    const textWaitingDelete = "Удаление доверенности сбросит процесс проверки добавленной вами организации."
    const textAcceptDelete =
        "Удаление доверенности сбросит подтверждение вашей организации и вы временно утратите доступ к функционалу оператора возможностей."
    if (status) {
        return conditions<CardStatusType>(status, [
            [CardStatusType.WAITING, textWaitingDelete],
            [CardStatusType.ACCEPTED, textAcceptDelete],
        ])
    }
    return undefined
}
