import styled from "styled-components"

const StyledProgress = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-card-custom-gift-s-gap-inner, 4px);
    //flex: 1 0 0;
    width: 100%;
`

const StyledPoints = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    align-self: stretch;
    & > * {
        font-size: 16px;
    }
`

export { StyledProgress, StyledPoints }
