import styled from "styled-components"
import { getMediaVariable } from "shared/styles"
const StyledAboutList = styled.div`
    display: grid;
    //flex-wrap: wrap;
    grid-template-columns: repeat(auto-fit, 385px);

    gap: 12px;
    @media all and (max-width: ${getMediaVariable("laptop-l")}) {
        grid-template-columns: repeat(auto-fit, minmax(288px, 385px));
        justify-content: center;
        & > div {
            width: unset;
        }
    }
`

export { StyledAboutList }
