import { type FC } from "react"
import { OfferOpportunityForm } from "widgets/viewer"

export const OfferOpportunityFormPage: FC = () => {
    return (
        <>
            <OfferOpportunityForm />
        </>
    )
}
