import styled from "styled-components"

export const StyledNotificationCard = styled.div`
    display: flex;
    width: 100%;
    padding: var(--assets-card-notification-s-padding, 16px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-card-notification-s-gap, 8px);
`
export const StyledLink = styled.div`
    color: var(--layout-text-colors-link, #3e3aed);
    line-height: 18px;
    cursor: pointer;
`

export const StyledTitle = styled.div`
    display: flex;
    align-items: center;
    gap: var(--assets-card-notification-l-gap, 12px);
    align-self: stretch;
`

export const StyledDescription = styled.div`
    line-height: 18px;
`

export const StyledMarker = styled.span`
    padding-right: 8px;
`
