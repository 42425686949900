import React, { type FC, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { Button, ButtonSize, ButtonType, FormField } from "shared/ui"
import { StyledInput } from "shared/styles"
import { SizeSvg, TimeSvg } from "shared/assets/svg"
import { type InputTimeProps } from "./types"

export const InputTime: FC<InputTimeProps> = (props) => {
    const { labelText, name, required = true, ...other } = props
    const {
        register,
        formState: { isDirty },
    } = useFormContext()

    const inputRef = useRef<HTMLInputElement | null>(null)
    const { ref, onChange } = register(name)
    const handleLabelClick = () => {
        inputRef?.current?.showPicker()
    }

    const [wasClick, setClick] = useState<boolean>(false)
    return (
        <div
            tabIndex={1}
            onFocus={() => {
                setClick(true)
            }}
            onBlur={() => {
                setClick(false)
            }}
        >
            <FormField
                name={name}
                required={required}
                labelText={labelText}
                isSuccess={false}
                render={(focus) => (
                    <>
                        <StyledInput
                            {...other}
                            $isClean={!isDirty}
                            ref={(event) => {
                                inputRef.current = event
                                ref(event)
                            }}
                            onChange={onChange}
                            name={name}
                            type={wasClick ? "time" : "text"}
                            onFocus={focus}
                            onBlur={focus}
                        />
                        <Button
                            onClick={handleLabelClick}
                            size={ButtonSize.S}
                            hover={false}
                            onlyIcon={true}
                            typeUI={ButtonType.FREE}
                        >
                            <TimeSvg size={SizeSvg.M} />
                        </Button>
                    </>
                )}
            />
        </div>
    )
}
