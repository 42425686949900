import styled from "styled-components"

const StyledSelected = styled.div`
    display: inline-flex;
    height: var(--assets-button-common-s-height);
    padding: var(--assets-button-common-s-padding-vert) var(--assets-input-phone-country-selector-button-padding-hor);
    justify-content: center;
    align-items: center;
    gap: var(--assets-input-phone-country-selector-gap);
    flex-shrink: 0;
    border-radius: var(--assets-button-common-s-radius);

    &:hover {
        border-radius: var(--assets-button-common-s-radius);
        background: var(--assets-button-free-fill-hover, rgba(0, 0, 0, 0.06));
    }
`

export { StyledSelected }
