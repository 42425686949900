import React, { type FC } from "react"
import { NavLink } from "react-router-dom"
import { Choice, ChoiceGroup, ChoiceGroupSize, ChoiceSize, ChoiceType } from "shared/ui"
import { AccountSvg, FormSvg, OrganizationSvg, ProfileSvg, SizeSvg } from "shared/assets/svg"
import { useResize } from "shared/hook"
import { StyledEditChoices } from "./styles"

export const EditProfileChoice: FC = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return (
        <StyledEditChoices>
            <ChoiceGroup size={ChoiceGroupSize.M} scroll={width <= isTablet}>
                <NavLink to={"/profile-edit"} end={true}>
                    {({ isActive }) => (
                        <Choice selected={isActive} size={ChoiceSize.S} type={ChoiceType.NAVLINK}>
                            <ProfileSvg />
                            Персональные
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"account"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} size={ChoiceSize.S} type={ChoiceType.NAVLINK}>
                            <AccountSvg />
                            Аккаунт
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"work-sheet"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} size={ChoiceSize.S} type={ChoiceType.NAVLINK}>
                            <FormSvg />
                            Анкета
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"organization"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} size={ChoiceSize.S} type={ChoiceType.NAVLINK}>
                            <OrganizationSvg size={SizeSvg.M} />
                            Организация
                        </Choice>
                    )}
                </NavLink>
            </ChoiceGroup>
        </StyledEditChoices>
    )
}
