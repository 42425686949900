import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type ChevronDownSvgProps } from "./types"

export const ChevronDownSvg: FC<ChevronDownSvgProps> = (props) => {
    const { size = SizeSvg.L } = props
    return (
        <svg
            width={`${Number(size) - 1}`}
            height={size}
            viewBox="0 0 33 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.13025 13.8887L4.86985 12.7793L16.5 20.5328L28.1302 12.7793L28.8698 13.8887L16.5 22.1352L4.13025 13.8887Z"
                fill="white"
            />
        </svg>
    )
}
