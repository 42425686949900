import React, { type FC } from "react"
import { InputDate, InputTime } from "shared/ui"
import { StyledPeriod, StyledDash } from "./styles"
import { type PeriodProps } from "./types"

const Time: FC<PeriodProps> = (props) => {
    const { inputNameFinish, inputNameStart } = props
    return (
        <StyledPeriod $time={true}>
            <InputTime placeholder={"00:00"} name={inputNameStart} />
            <StyledDash />
            <InputTime placeholder={"00:00"} name={inputNameFinish} />
        </StyledPeriod>
    )
}

const Date: FC = () => {
    return (
        <StyledPeriod $time={false}>
            <InputDate name={"startDate"} labelText={"Начало"} />
            <InputDate name={"endDate"} labelText={"Завершение"} />
        </StyledPeriod>
    )
}

export const Period = {
    Date,
    Time,
}
