import { BASE_URL, mainApi } from "features/api"
import { type RequestConfig } from "shared/api"
import { transformUrlImages } from "shared/lib"
import { type AboutUsParams, type AboutUsResponse } from "./types"

export const aboutUsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getAboutUsData: builder.query<AboutUsResponse, AboutUsParams>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL.PUBLIC}/about-us`,
                method: "GET",
            }),
            transformResponse: (data: AboutUsResponse) => {
                return {
                    ...data,
                    images: data.images?.map((el) => ({
                        url: transformUrlImages(el.url),
                    })),
                }
            },
        }),
    }),
})

export const { useGetAboutUsDataQuery } = aboutUsApi
