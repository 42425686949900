import { type FC } from "react"
import { HeadingType, Typography } from "shared/ui"
import { StyledContactHeader } from "./styles"

export const ContactsHeader: FC = () => {
    return (
        <StyledContactHeader>
            <Typography.Heading typeUI={HeadingType.H1}>Контакты</Typography.Heading>
        </StyledContactHeader>
    )
}
