import { createBrowserRouter } from "react-router-dom"
import {
    AboutPage,
    AuthPage,
    ContactsPage,
    DashBoardPage,
    EditProfilePage,
    ForgotPasswordPage,
    IdeaPage,
    MainPage,
    NewPage,
    NewsPage,
    OfferOpportunityFormPage,
    OfferPlaceFormPage,
    OpportunityPage,
    PartnersPage,
    PersonalAccountPage,
    PlacePage,
    RegistrationPage,
    RestorePasswordPage,
    RubricatorPage,
    ShowIdea,
} from "pages"
import {
    EditAccount,
    EditOrganization,
    EditPersonal,
    EditWorkSheet,
    GoalsList,
    Idea,
    OpportunityDashboard,
    Potential,
    Rating,
    WorkSheet,
} from "widgets/viewer"
import { ContactsLists, RubricatorSection } from "widgets/user"
import { ProfileRole } from "features/viewer"
import { EventsCalendar } from "entities/viewer"
import { ContactsMap } from "entities/user"
import { PageLayout, PrivateRoute } from "./ui"

export const router = createBrowserRouter([
    {
        element: <PageLayout />,
        children: [
            {
                path: "/",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <MainPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/auth",
                element: (
                    <PrivateRoute role={[ProfileRole.PUBLIC]}>
                        <AuthPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/restore",
                element: <RestorePasswordPage />,
            },
            {
                path: "/forgot-password",
                element: <ForgotPasswordPage />,
            },
            {
                path: "/registration",
                element: <RegistrationPage />,
            },
            {
                path: "/partners",
                element: <PartnersPage />,
            },
            {
                path: "/personal-account",
                element: (
                    <PrivateRoute>
                        <PersonalAccountPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <WorkSheet />,
                    },
                    {
                        path: "idea",
                        element: <Idea />,
                    },
                    {
                        path: "potential",
                        element: <Potential />,
                    },
                ],
            },
            {
                path: "/profile-edit",
                element: (
                    <PrivateRoute>
                        <EditProfilePage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <EditPersonal />,
                    },
                    {
                        path: "account",
                        element: <EditAccount />,
                    },
                    {
                        path: "work-sheet",
                        element: <EditWorkSheet />,
                    },
                    {
                        path: "organization",
                        element: <EditOrganization />,
                    },
                ],
            },
            {
                path: "about",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <AboutPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/offer-opportunities-form",
                element: (
                    <PrivateRoute role={[ProfileRole.OPERATOR]}>
                        <OfferOpportunityFormPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/offer-place-form",
                element: (
                    <PrivateRoute role={[ProfileRole.OPERATOR]}>
                        <OfferPlaceFormPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/idea-form",
                element: (
                    <PrivateRoute>
                        <IdeaPage />
                    </PrivateRoute>
                ),
            },
            {
                path: "/idea/:id",
                element: (
                    <PrivateRoute>
                        <ShowIdea />
                    </PrivateRoute>
                ),
            },
            {
                path: "/opportunity",
                element: (
                    <PrivateRoute>
                        <OpportunityPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        path: ":operator/:id",
                        element: <OpportunityPage />,
                    },
                ],
            },
            {
                path: "/place",
                children: [
                    {
                        path: ":operator/:id",
                        element: <PlacePage />,
                    },
                ],
            },
            {
                path: "/dashboard",
                element: (
                    <PrivateRoute role={[ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}>
                        <DashBoardPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <GoalsList />,
                    },
                    {
                        path: "calendar",
                        element: <EventsCalendar />,
                    },
                    {
                        path: "rating",
                        element: <Rating />,
                    },
                    {
                        path: "opportunities",
                        element: <OpportunityDashboard />,
                    },
                ],
            },
            {
                path: "/contacts",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <ContactsPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <ContactsLists />,
                    },
                    {
                        path: "map",
                        children: [
                            {
                                index: true,
                                element: <ContactsMap />,
                            },
                            {
                                path: ":id/:coordinates",
                                element: <ContactsMap />,
                            },
                        ],
                    },
                ],
            },
            {
                path: "/rubricator/*",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <RubricatorPage />
                    </PrivateRoute>
                ),
                children: [
                    {
                        index: true,
                        element: <RubricatorSection />,
                    },
                    {
                        path: "place/*",
                        element: <RubricatorSection />,
                    },
                    {
                        path: "opportunities/*",
                        element: <RubricatorSection />,
                    },
                ],
            },
            {
                path: "/news",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <NewsPage />
                    </PrivateRoute>
                ),
            },

            {
                path: "/news/:id",
                element: (
                    <PrivateRoute
                        role={[ProfileRole.PUBLIC, ProfileRole.USER, ProfileRole.OPERATOR, ProfileRole.OPERATOR_NO_ORG]}
                    >
                        <NewPage />
                    </PrivateRoute>
                ),
            },
        ],
    },
])
