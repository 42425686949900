import { type UseFormReset } from "react-hook-form"
import { type IModalWorkFormValues, type Times } from "../../models"

export class ArrayAdditionalHandlers {
    constructor(
        readonly values: Times[],
        readonly reset: UseFormReset<IModalWorkFormValues>
    ) {
        this.values = values
        this.reset = reset
    }
    resetAddHandler = (index: number): void => {
        const arr = this.values.map((el, i) => {
            if (i === index && !el.breakTimes) return { ...el, breakTimes: ["", ""] }
            return el
        })
        this.reset({ times: [...(arr as Times[])] })
    }

    resetDeleteHandler = (index: number): void => {
        const arr = this.values.map((el, i) => {
            if (i === index && el.breakTimes)
                return {
                    defaultTimes: el.defaultTimes,
                }
            return el
        })
        this.reset({ times: [...(arr as Times[])] })
    }
}
