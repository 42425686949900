import { type HTMLAttributes, type PropsWithChildren, type ReactNode } from "react"

export enum BackButtonText {
    CANCEL = "Отмена",
    BACK = "Назад",
}

export interface ModalDefaultProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    title?: string
    body?: string
    footerBtn?: () => ReactNode
    back?: () => void
    backButton?: boolean
    backButtonText?: BackButtonText
    additionalHeadComponent?: () => ReactNode
    beforeAdditionalHeadComponent?: () => ReactNode
}
