import { type document, type SelectData } from "shared/ui"
import { type disabilityGroup } from "shared/api"

export enum ProfileRole {
    USER = "USER",
    OPERATOR = "OPERATOR",
    PUBLIC = "PUBLIC",
    OPERATOR_NO_ORG = "OPERATOR_NO_ORG",
}
export interface ProfileI {
    id: string
    firstname: string
    lastname: string
    midname: string
    phone: string | null
    email: string | null
    userVkId: string | null
    birthday: string
    locality: string
    withDisability: boolean
    disabilityGroup: disabilityGroup | null
    avatarFileId: string | null
    role: ProfileRole
    avatar: document | null
    interests: SelectData[]
    userCategories: SelectData[]
    points: number
    rating: {
        userRating: number
        total: number
    }
}

export interface IEditProfileData {
    firstname?: string
    lastname?: string
    midname?: string
    birthday?: string
    locality?: string
    avatar?: FileList | null
}
