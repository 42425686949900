import React, { type FC } from "react"
import { ProfileData } from "entities/viewer"
import { CategorySvg, useMediaSizeSvg } from "shared/assets/svg"

interface CategorySectionProps {
    categories: Array<{ id: string; name: string }>
}

export const CategorySection: FC<CategorySectionProps> = ({ categories }) => {
    const { fromMtoL } = useMediaSizeSvg()
    return (
        <>
            {categories.map(({ name, id }) => {
                return (
                    <ProfileData key={`${id}-categories`} caption={"Категория"}>
                        <CategorySvg size={fromMtoL} />
                        {name}
                    </ProfileData>
                )
            })}
        </>
    )
}
