import { type HTMLAttributes } from "react"

export enum CounterSize {
    L = "l",
    M = "m",
    S = "s",
}

export enum CounterColor {
    EVENTS = "events",
    WEEKEND = "events-weekend",
    ANOTHER_MONTH = "another-month",
    ANOTHER_MONTH_WEEKEND = "another-month-weekend",
}

export interface CounterProps extends HTMLAttributes<HTMLDivElement> {
    size?: CounterSize
    color?: CounterColor
    count?: number
}
