import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type OrganizationSvgProps } from "./types"

export const OrganizationSvg: FC<OrganizationSvgProps> = (props) => {
    const { size = SizeSvg.L } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 32 32" fill="none">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16 1.94824L30 7.54824V11.3329H27.3333V25.9996H30V29.9996H2V25.9996H4.66667V11.3329H2V7.54824L16 1.94824ZM3.33333 27.3329V28.6663H28.6667V27.3329H3.33333ZM24.6667 25.9996V16.6663C24.6667 16.141 24.5632 15.6208 24.3622 15.1355C24.1612 14.6502 23.8665 14.2093 23.4951 13.8378C23.1237 13.4664 22.6827 13.1718 22.1974 12.9707C21.7121 12.7697 21.192 12.6663 20.6667 12.6663C20.1414 12.6663 19.6212 12.7697 19.1359 12.9707C18.6506 13.1718 18.2097 13.4664 17.8382 13.8378C17.4668 14.2093 17.1722 14.6502 16.9711 15.1355C16.7701 15.6208 16.6667 16.141 16.6667 16.6663V25.9996H15.3333V16.6663C15.3333 16.141 15.2299 15.6208 15.0289 15.1355C14.8278 14.6502 14.5332 14.2093 14.1618 13.8378C13.7903 13.4664 13.3494 13.1718 12.8641 12.9707C12.3788 12.7697 11.8586 12.6663 11.3333 12.6663C10.808 12.6663 10.2879 12.7697 9.8026 12.9707C9.3173 13.1718 8.87634 13.4664 8.50491 13.8378C8.13347 14.2093 7.83883 14.6502 7.63781 15.1355C7.4368 15.6208 7.33333 16.141 7.33333 16.6663V25.9996H6V11.3329H26V25.9996H24.6667ZM23.3333 16.6663C23.3333 16.3161 23.2644 15.9693 23.1303 15.6458C22.9963 15.3222 22.7999 15.0283 22.5523 14.7806C22.3047 14.533 22.0107 14.3366 21.6872 14.2026C21.3636 14.0686 21.0169 13.9996 20.6667 13.9996C20.3165 13.9996 19.9697 14.0686 19.6462 14.2026C19.3226 14.3366 19.0287 14.533 18.781 14.7806C18.5334 15.0283 18.337 15.3222 18.203 15.6458C18.069 15.9693 18 16.3161 18 16.6663V25.9996H23.3333V16.6663ZM13.797 15.6458C13.931 15.9693 14 16.3161 14 16.6663V25.9996H8.66667V16.6663C8.66667 16.3161 8.73564 15.9693 8.86965 15.6458C9.00367 15.3222 9.20009 15.0283 9.44772 14.7806C9.69534 14.533 9.98931 14.3366 10.3128 14.2026C10.6364 14.0686 10.9831 13.9996 11.3333 13.9996C11.6835 13.9996 12.0303 14.0686 12.3538 14.2026C12.6774 14.3366 12.9713 14.533 13.219 14.7806C13.4666 15.0283 13.663 15.3222 13.797 15.6458ZM3.33333 8.45095V9.9996H28.6667V8.45095L16 3.38429L3.33333 8.45095Z"
                fill="white"
            />
        </svg>
    )
}
