import React, { type FC } from "react"
import { InputCheck } from "shared/ui"
import { useToggle } from "shared/hook"
import { StyledText } from "./styles"

export const AcceptLetterCheck: FC = () => {
    const toggle = useToggle(false)
    return (
        <>
            <InputCheck
                {...toggle}
                body={() => {
                    return (
                        <StyledText>
                            Я хочу получать новостную рассылку и уведомления от портала “Оренбуржье - регион для
                            молодых”.
                        </StyledText>
                    )
                }}
            />
        </>
    )
}
