import React, { type FC, useEffect, useState } from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { FormProvider, useForm, useFormContext } from "react-hook-form"
import { ScheduleList, ScheduleWrapper } from "entities/viewer"
import { EditSvg, PlusSvg } from "shared/assets/svg"
import { Button, ButtonSize, ButtonType, Modal, StyledError } from "shared/ui"
import { useModal } from "shared/hook"
import { ModalWork } from "./ui"
import { ArrayAdditionalHandlers, fieldsSchema, getScheduleData, useScheduleReducer, useToggleBreak } from "./lib"
import { type IModalWorkFormValues, type Times } from "./models"
import { defaultValuesWorkScheduleForm } from "./config"

export const WorkScheduleForm: FC = () => {
    const { state, ...others } = useScheduleReducer()
    const [submitFormData, setSubmitFormData] = useState<Times[]>([])

    const methods = useForm<IModalWorkFormValues>({
        defaultValues: defaultValuesWorkScheduleForm,
        resolver: yupResolver<IModalWorkFormValues>(fieldsSchema),
    })

    const numbersDay = Object.entries(state).flatMap(([key, value]) =>
        typeof value.arrayNumber === "number" ? [[value.arrayNumber, key]] : []
    )

    const { isActive, toggleActivity } = useModal()

    const { resetDeleteHandler, resetAddHandler } = new ArrayAdditionalHandlers(
        methods.getValues("times"),
        methods.reset
    )
    const toggleBreak = useToggleBreak(resetAddHandler, resetDeleteHandler)
    const {
        setError,
        setValue,
        formState: { errors, isDirty, isSubmitting },
        clearErrors,
    } = useFormContext()
    useEffect(() => {
        if (submitFormData.length) {
            clearErrors("workingHours")
            setValue("workingHours", getScheduleData(submitFormData)(numbersDay))
        }
        if (!submitFormData.length && isDirty) {
            setError("workingHours", { message: "Укажите режим работы" })
        }
    }, [submitFormData, isDirty])

    return (
        <>
            <ScheduleWrapper>
                {!submitFormData.length ? (
                    <Button
                        typeUI={ButtonType.GHOST}
                        size={ButtonSize.S}
                        leftIcon={() => <PlusSvg />}
                        onClick={() => {
                            toggleActivity()
                        }}
                    >
                        Добавить
                    </Button>
                ) : (
                    <>
                        <ScheduleList scheduleData={getScheduleData(submitFormData)(numbersDay)} />
                        <Button
                            typeUI={ButtonType.GHOST}
                            onClick={() => {
                                toggleActivity()
                            }}
                            size={ButtonSize.S}
                        >
                            <EditSvg />
                            Изменить
                        </Button>
                    </>
                )}
                {errors.workingHours?.message && errors && (
                    <StyledError>{errors.workingHours?.message as string}</StyledError>
                )}
            </ScheduleWrapper>
            <Modal position={"right"} bigHeight isActive={isActive} toggleActivity={toggleActivity}>
                <FormProvider {...methods}>
                    <ModalWork
                        {...toggleBreak}
                        toggleActivity={toggleActivity}
                        {...others}
                        state={state}
                        setSubmitFormData={setSubmitFormData}
                        selectedDaysCount={numbersDay.length}
                    />
                </FormProvider>
            </Modal>
        </>
    )
}
