import { type FC } from "react"
import { ForMeProvider } from "shared/context"
import { store } from "./store"
import GlobalStyle from "./styles"
import Provider from "./providers"

const App: FC = () => {
    return (
        <div id="app">
            <GlobalStyle />
            <Provider.Store store={store}>
                <Provider.Map>
                    <ForMeProvider>
                        <Provider.Router />
                    </ForMeProvider>
                </Provider.Map>
            </Provider.Store>
        </div>
    )
}

export default App
