import { type FC, useEffect, useRef, useState } from "react"
import { useGetNewsDataQuery } from "features/user"
import { NewsCard } from "entities/user"
import { ButtonSize, ButtonType, HeadingType, Typography } from "shared/ui"
import { ChevronDownSvg } from "shared/assets/svg"
import { StyledPatternPageBackground } from "shared/styles"
import { useResize } from "shared/hook"
import { StyledButtonNewsList, StyledNewsList, StyledTitle } from "./styles"

export const NewsList: FC = () => {
    const [countPage, setCountPage] = useState<number>(0)
    const takePart = 6

    const { data } = useGetNewsDataQuery({
        take: takePart,
        skip: countPage * takePart,
    })

    const ref = useRef<HTMLDivElement | null>(null)
    const [height, setHeight] = useState<string | null>(null)
    useEffect(() => {
        if (countPage === 1 && ref.current) {
            setHeight(`${document.documentElement.offsetHeight}`)
        }
    }, [countPage, ref.current?.scrollHeight, document.documentElement.offsetHeight])
    const {
        mediaScreenSize: { width },
    } = useResize()
    const countPx = () => {
        if (width > 1392) {
            return 975
        }
        return 1320
    }
    const position = width > 1392 ? "left -1296px top 40px" : "left -1296px top 390px"
    return (
        <>
            <StyledPatternPageBackground
                $height={`${countPage >= 1 ? countPx() * 1.45 : countPx()}px`}
                $position={countPage >= 1 ? position : "left -1296px bottom -590px"}
                $color={"newsFill"}
            />
            <StyledTitle>
                <Typography.Heading typeUI={HeadingType.H1}>Новости</Typography.Heading>
            </StyledTitle>
            {data && (
                <>
                    <StyledNewsList ref={ref}>
                        {data?.map(({ id, title, previewText, createdAt, images, updatedAt }) => {
                            const date = new Date(updatedAt ?? createdAt).toLocaleDateString("ru-RU", {
                                hour: "2-digit",
                                minute: "2-digit",
                            })
                            const splitDate = date.split(" ")
                            return (
                                <NewsCard
                                    key={id}
                                    id={id}
                                    title={title}
                                    imgSrc={images[0].url}
                                    body={previewText}
                                    date={splitDate[0].slice(0, -1)}
                                    time={splitDate[1]}
                                />
                            )
                        })}
                    </StyledNewsList>
                    {countPage * takePart < data.length && (
                        <StyledButtonNewsList
                            onClick={() => {
                                setCountPage((prevState) => prevState + 1)
                            }}
                            size={ButtonSize.L}
                            typeUI={ButtonType.PRIMARY}
                            leftIcon={() => <ChevronDownSvg />}
                        >
                            Показать еще
                        </StyledButtonNewsList>
                    )}
                </>
            )}
        </>
    )
}
