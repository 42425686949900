import React, { type FC, useMemo, useRef, useState } from "react"
import { useHorizontalScroll } from "shared/hook"
import { transformUrlVideoWithPreview } from "shared/lib"
import { StyledSlider, StyledSliderContent, StyledSliderImages } from "./styles"
import { type SliderProps } from "./types"
import { SliderItem } from "./ui"

export const Slider: FC<SliderProps> = (props) => {
    const { images, videoUrl } = props
    const [indexImg, setIndexImg] = useState<number>(0)
    const imagesRef = useRef<HTMLDivElement | null>(null)
    useHorizontalScroll(imagesRef)

    const formattedVideo = useMemo(() => {
        if (videoUrl) {
            if (!Array.isArray(videoUrl)) return [transformUrlVideoWithPreview(videoUrl)]
            return videoUrl.map((el) => transformUrlVideoWithPreview(el))
        }
        return null
    }, [videoUrl])
    return (
        <StyledSlider>
            <StyledSliderContent>
                {indexImg !== images?.length ? (
                    <img src={images?.map(({ url }) => url)[indexImg]} alt="" />
                ) : (
                    <iframe
                        key={indexImg}
                        src={
                            formattedVideo && formattedVideo.length ? formattedVideo[indexImg - images.length]?.url : ""
                        }
                        title="Video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    ></iframe>
                )}
            </StyledSliderContent>
            <StyledSliderImages ref={imagesRef}>
                {!!images?.length &&
                    images?.map(({ url }, index) => (
                        <SliderItem
                            key={`${index}sliderImages`}
                            src={url}
                            onClick={() => setIndexImg(index)}
                            current={index === indexImg}
                        />
                    ))}
                {formattedVideo &&
                    formattedVideo.map((el, index) =>
                        el && el.url ? (
                            <SliderItem
                                key={`${index}-slider-video`}
                                src={el.preview}
                                current={index === (images?.length ?? 0) + index}
                                onClick={() => {
                                    setIndexImg((images?.length ?? 0) + index)
                                }}
                            />
                        ) : null
                    )}
            </StyledSliderImages>
        </StyledSlider>
    )
}
