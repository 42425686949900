import React, { type FC } from "react"
import { Input } from "shared/ui"
import { StyledSection } from "shared/styles"
import { NameSecondNameArea } from "./styles"

export const UserDataFields: FC = () => {
    return (
        <StyledSection>
            <Input placeholder={"Иванов"} labelText={"Фамилия"} name={"lastname"} />
            <NameSecondNameArea>
                <Input placeholder={"Иванов"} labelText={"Имя"} name={"firstname"} />
                <Input placeholder={"Иванович"} labelText={"Отчество"} required={false} name={"midname"} />
            </NameSecondNameArea>
        </StyledSection>
    )
}
