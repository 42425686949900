import * as yup from "yup"
import { type ObjectSchema } from "yup"
import { type IEditWorkSheetForm } from "features/viewer"

export const EditWorkSheetSchema: ObjectSchema<IEditWorkSheetForm> = yup
    .object({
        userVkId: yup.string().nullable().required("Поле обязательно для заполнения"),
        interests: yup
            .array()
            .min(1, "Выберите хотябы 1 интерес")
            .nullable()
            .required("Поле обязательно для заполнения"),
        userCategories: yup
            .array()
            .min(1, "Выберите хотябы 1 статус")
            .nullable()
            .required("Поле обязательно для заполнения"),
        withDisability: yup.boolean(),
        disabilityGroup: yup.string().nullable(),
    })
    .required()
