import { type FC, useEffect } from "react"
import { useFormContext } from "react-hook-form"
import { Toggle, ToggleType } from "../Toggle"
import { StyledLabeledSwitch, StyledLabeledSwitchContent, StyledLabelSwitchCollapse } from "./styles"
import { type LabeledSwitchProps } from "./types"

export const LabeledSwitch: FC<LabeledSwitchProps> = (props) => {
    const { children, name = "", icon, isTurnOn, isNoWrap = false, title, handlerToggle } = props
    const { register, setValue, getValues } = useFormContext()
    useEffect(() => {
        setValue(name, isTurnOn)
    }, [isTurnOn])
    return (
        <StyledLabeledSwitch $isTurnOn={isTurnOn}>
            {name && <input hidden={true} type="checkbox" {...register(name)} />}
            <StyledLabeledSwitchContent>
                {icon?.()}
                <div style={isNoWrap ? { whiteSpace: "nowrap" } : {}}>{title}</div>
                <Toggle handlerToggle={handlerToggle} type={ToggleType.SWITCH} isTurnOn={isTurnOn} />
            </StyledLabeledSwitchContent>
            {children && (
                <StyledLabelSwitchCollapse $isToggle={isTurnOn}>
                    <div>{children}</div>
                </StyledLabelSwitchCollapse>
            )}
        </StyledLabeledSwitch>
    )
}
