import React, { type FC } from "react"
import { useFormContext } from "react-hook-form"
import { Requirement, RequirementStatus } from "shared/ui"
import { SizeSvg } from "shared/assets/svg"
import { StyledWindowRow, StyledWindowValid } from "./styles"
import { type WindowValidationProps } from "./types"

export const WindowValidation: FC<WindowValidationProps> = (props) => {
    const { name } = props
    const { formState, getFieldState } = useFormContext()
    const { error, isDirty } = getFieldState(name, formState)
    const StatusReq = {
        NumberAndLetter: () => handleStatusRequirement("NumberAndLetter"),
        min: () => handleStatusRequirement("min"),
        isNumber: () => handleStatusRequirement("IsNumber"),
        UpperCase: () => handleStatusRequirement("UpperCase"),
        LowerCase: () => handleStatusRequirement("LowerCase"),
    }
    const handleStatusRequirement = (name: string) => {
        const type = error?.types && error.types[name]
        if (!isDirty) {
            return RequirementStatus.EMPTY
        }
        return type ? RequirementStatus.ERROR : RequirementStatus.SUCCESS
    }

    return (
        <StyledWindowValid $statusReq={StatusReq}>
            <StyledWindowRow>
                <Requirement size={SizeSvg.S} status={StatusReq.NumberAndLetter()}>
                    Только латинские символы и цифры
                </Requirement>
            </StyledWindowRow>
            <StyledWindowRow>
                <Requirement size={SizeSvg.S} status={StatusReq.min()}>
                    8+ символов
                </Requirement>
                <Requirement size={SizeSvg.S} status={StatusReq.isNumber()}>
                    1+ цифра
                </Requirement>
            </StyledWindowRow>
            <StyledWindowRow>
                <Requirement size={SizeSvg.S} status={StatusReq.UpperCase()}>
                    1+ заглавная буква
                </Requirement>
                <Requirement size={SizeSvg.S} status={StatusReq.LowerCase()}>
                    1+ строчная буква
                </Requirement>
            </StyledWindowRow>
        </StyledWindowValid>
    )
}
