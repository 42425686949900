import styled from "styled-components"

const StyledRating = styled.div`
    display: flex;
    width: 100%;
    padding: var(--assets-card-custom-metric-sizes-s-padding-vert) var(--assets-card-custom-metric-sizes-s-padding)
        var(--assets-card-custom-metric-sizes-s-padding-vert) var(--assets-card-custom-metric-sizes-s-padding-left);
    align-items: center;
    gap: var(--assets-card-custom-metric-sizes-s-gap);
    border-radius: var(--assets-card-common-sizes-s-radius);
    background: var(--assets-card-common-colors-fill);
    &:hover {
        background: unset;
        outline: 1px solid var(--assets-card-common-colors-stroke-hover);
    }
`

const StyledSvg = styled.div`
    color: #ff8100;
`

const StyledRatingContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: var(--assets-card-common-sizes-s-title-gap, 4px);
    align-self: stretch;
    font-size: 24px;
    font-weight: 500;
`
const StyledRatingCount = styled.div`
    display: flex;
    align-items: baseline;
    gap: var(--assets-card-common-sizes-s-title-gap, 4px);
`
const StyledRatingFrom = styled.div`
    font-size: 14px;
`
const StyledRatingChildren = styled.div`
    color: var(--assets-card-common-colors-interactive, #3e3aed);
    font-size: 12px;
`

export { StyledRating, StyledRatingContent, StyledRatingCount, StyledRatingChildren, StyledRatingFrom, StyledSvg }
