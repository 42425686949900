import { css } from "styled-components"

export const variables = css`
    :root {
        --root-colors-main-black-basic: #000000ff;
        --root-colors-accent-yellow: #fff200ff;
        --root-colors-accent-electra: #3be2deff;
        --root-colors-accent-violett: #8e00eaff;
        --root-colors-accent-red: #e21414ff;
        --root-colors-main-black-dark: #000000e0;
        --root-colors-main-black-light: #000000b8;
        --root-colors-main-black-ghost: #0000000a;
        --root-colors-main-black-ghost-alt: #0000000f;
        --root-colors-main-white-basic: #ffffffff;
        --root-colors-main-white-dark: #0000000f;
        --root-colors-main-white-light: #00000005;
        --root-colors-main-white-ghost: #ffffff0a;
        --root-colors-main-white-ghost-alt: #ffffff0f;
        --root-colors-main-grey-basic: #0000005c;
        --root-colors-main-grey-dark: #0000007a;
        --root-colors-main-grey-light: #0000001f;
        --root-colors-main-grey-ghost: #ffffffeb;
        --root-colors-main-grey-ghost-alt: #ffffffe0;
        --root-colors-accent-crema: #f4efe9ff;
        --root-colors-main-green-basic: #5fdd4aff;
        --root-colors-main-green-dark: #5fdd4af0;
        --root-colors-main-green-light: #5fdd4acc;
        --root-colors-main-green-ghost: #5fdd4a0a;
        --root-colors-main-green-ghost-alt: #5fdd4a0f;
        --root-colors-main-red-basic: #ec008cff;
        --root-colors-main-red-dark: #ec008cf0;
        --root-colors-main-red-light: #ec008ccc;
        --root-colors-main-red-ghost: #ec008c0a;
        --root-colors-main-red-ghost-alt: #ec008c0f;
        --root-colors-main-orange-basic: #ff8100ff;
        --root-colors-main-orange-dark: #ff8100f0;
        --root-colors-main-orange-light: #ff8100cc;
        --root-colors-main-orange-ghost: #ff81000a;
        --root-colors-main-orange-ghost-alt: #ff81000f;
        --root-colors-main-blue-basic: #3e3aedff;
        --root-colors-main-blue-dark: #3e3aedf0;
        --root-colors-main-blue-light: #3e3aedcc;
        --root-colors-main-blue-ghost: #3e3aed0a;
        --root-colors-main-blue-ghost-alt: #3e3aed0f;
        --root-colors-main-timur-grey-basic: rgb(244, 239, 233);
        --root-sizes-1: 1px;
        --root-sizes-2: 2px;
        --root-sizes-4: 4px;
        --root-sizes-6: 6px;
        --root-sizes-8: 8px;
        --root-sizes-10: 10px;
        --root-sizes-12: 12px;
        --root-sizes-16: 16px;
        --root-sizes-20: 20px;
        --root-sizes-24: 24px;
        --root-sizes-28: 28px;
        --root-sizes-32: 32px;
        --root-sizes-36: 36px;
        --root-sizes-40: 40px;
        --root-sizes-42: 42px;
        --root-sizes-48: 48px;
        --root-sizes-56: 56px;
        --root-sizes-64: 64px;
        --root-sizes-72: 72px;
        --root-sizes-80: 80px;
        --root-sizes-96: 96px;
        --root-sizes-112: 112px;
        --root-sizes-128: 128px;
        --root-sizes-160: 160px;
        --root-sizes-192: 192px;
        --root-sizes-224: 224px;
        --root-sizes-256: 256px;
        --root-sizes-288: 288px;
        --root-sizes-320: 320px;
        --root-radius-round: 1024px;
        --devices-iphone-safari-colors-bakground: #f9f9f9f0;
        --devices-iphone-safari-colors-content: #000000ff;
        --devices-iphone-safari-colors-notch: #000000ff;
        --devices-iphone-safari-colors-inactive: #cececeff;
        --devices-iphone-safari-colors-url-background: #00000014;
        --devices-iphone-safari-colors-home: #000000ff;
        --devices-iphone-safari-colors-link: #2e7cf6ff;
        --assets-utility-socials-vk: #0077ffff;
        --assets-input-sizes-padding-button: 3px;
        --root-colors-main-black-paranja: #000000a3;
        --assets-input-phone-country-selector-button-padding-hor: 9px;
        --assets-status-colors-icon: #ffffffff;
        --assets-select-sizes-padding-vert: 9px;
        --assets-input-sizes-padding-clear: 9px;
        --root-colors-accent-red-ghost: #e214140a;
        --assets-input-textarea-sizes-padding-top: 15px;
        --assets-input-textarea-sizes-padding-bottom: 3px;
        --assets-input-textarea-sizes-padding-right: 3px;
        --assets-input-textarea-sizes-height: 158px;
        --assets-header-menu-common-desktop-width: 360px;
        --root-colors-accent-blue: #3cb7ffff;
        --assets-card-custom-goal-colors-color: #ffffffff;
        --assets-calendar-sizes-s-width: 46px;
        --assets-schedule-sizes-s-height: 22px;
        --assets-schedule-sizes-s-height-additional: 18px;
        --assets-opportunity-cards-sizes-s-width: 326px;
        --assets-opportunity-cards-sizes-s-height: 360px;
        --layout-desktop-form-col-width: 420px;
        --layout-desktop-content-width: 640px;
        --assets-opportunity-cards-sizes-s-width-min: 288px;
        --assets-opportunity-photos-height: 360px;
        --assets-schedule-sizes-l-height: 24px;
        --assets-schedule-sizes-l-height-additional: 22px;
        --assets-card-info-event-s-width-min: 288px;
        --assets-card-info-event-l-width-min: 288px;

        --assets-header-menu-line-sizes-gap: var(--root-sizes-8);
        --assets-header-menu-line-sizes-padding-bottom: var(--root-sizes-16);
        --assets-header-menu-line-sizes-num-width: var(--root-sizes-24);
        --assets-header-menu-line-sizes-divider-width: var(--root-sizes-32);
        --assets-header-menu-line-colors-title-basic: var(--root-colors-main-black-basic);
        --assets-header-menu-line-colors-title-active: var(--root-colors-main-blue-basic);
        --assets-header-menu-line-colors-num-basic: var(--root-colors-main-grey-dark);
        --assets-header-menu-line-colors-num-hover: var(--root-colors-main-black-light);
        --assets-header-menu-line-colors-num-active: var(--root-colors-main-blue-basic);
        --assets-header-menu-line-colors-divider: var(--root-colors-main-white-dark);
        --assets-user-avatar-radius-s: var(--root-radius-s-12);
        --root-radius-s-12: var(--root-sizes-12);
        --assets-user-avatar-size-s: var(--root-sizes-48);
        --assets-user-avatar-colors-background: var(--root-colors-main-black-basic);
        --assets-user-avatar-colors-text: var(--root-colors-main-white-basic);
        --assets-header-menu-personal-sizes-radius: var(--root-radius-l-24);
        --root-radius-m-16: var(--root-sizes-16);
        --root-radius-l-24: var(--root-sizes-24);
        --assets-header-menu-personal-sizes-padding: var(--root-sizes-12);
        --assets-header-menu-personal-sizes-gap: var(--root-sizes-12);
        --assets-header-menu-personal-name: var(--root-colors-main-black-basic);
        --assets-header-menu-personal-descriptor: var(--root-colors-main-blue-basic);
        --assets-header-menu-common-colors-background: var(--root-colors-main-white-basic);
        --layout-mobile-padding-hor: var(--root-sizes-32);
        --assets-header-menu-common-mobile-padding-hor: var(--layout-mobile-padding-hor);
        --assets-header-common-mobile-padding-hor: var(--layout-mobile-padding-hor);
        --assets-header-common-mobile-padding-vert: var(--root-sizes-16);
        --assets-header-common-mobile-logo-height: var(--root-sizes-48);
        --assets-utility-counter-colors-background: var(--root-colors-main-red-basic);
        --assets-utility-counter-colors-text: var(--root-colors-main-white-basic);
        --assets-utility-counter-sizes-s-width-min: var(--root-sizes-16);
        --assets-utility-counter-sizes-s-height: var(--root-sizes-16);
        --root-radius-xs-8: var(--root-sizes-8);
        --root-radius-xxs-6: var(--root-sizes-6);
        --root-radius-xxxs-4: var(--root-sizes-4);
        --assets-utility-counter-sizes-s-radius: var(--root-radius-xxxs-4);
        --assets-utility-counter-sizes-s-padding-hor: var(--root-sizes-2);
        --assets-header-menu-button-mobile-common-height: var(--assets-header-common-mobile-logo-height);
        --assets-header-menu-button-mobile-common-radius: var(--root-radius-s-12);
        --assets-header-menu-button-mobile-main-padding-hor: var(--root-sizes-12);
        --assets-header-menu-button-mobile-common-padding-vert: var(--root-sizes-4);
        --assets-header-menu-button-mobile-common-gap: var(--root-sizes-8);
        --assets-header-menu-button-mobile-main-2-padding-hor: var(--root-sizes-4);
        --assets-button-primary-fill: var(--root-colors-main-blue-basic);
        --assets-button-primary-content: var(--root-colors-main-white-basic);
        --assets-button-primary-fill-hover: var(--root-colors-main-blue-dark);
        --assets-button-primary-content-hover: var(--root-colors-main-white-basic);
        --assets-button-secondary-stroke: var(--root-colors-main-blue-basic);
        --assets-button-secondary-content: var(--root-colors-main-blue-basic);
        --assets-button-secondary-stroke-hover: var(--root-colors-main-blue-dark);
        --assets-button-secondary-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-ghost-fill: var(--root-colors-main-blue-ghost);
        --assets-button-ghost-content: var(--root-colors-main-blue-basic);
        --assets-button-ghost-fill-hover: var(--root-colors-main-blue-ghost-alt);
        --assets-button-ghost-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-free-content: var(--root-colors-main-blue-basic);
        --assets-button-free-fill-hover: var(--root-colors-main-blue-ghost);
        --assets-button-free-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-disabled-fill: var(--root-colors-main-white-dark);
        --assets-button-disabled-content: var(--root-colors-main-grey-dark);
        --assets-button-common-m-padding-hor: var(--assets-common-m-padding-hor);
        --assets-button-common-m-padding-vert: var(--assets-common-m-padding-vert);
        --assets-button-common-m-gap: var(--assets-common-m-gap);
        --assets-button-common-m-radius: var(--assets-common-m-radius);
        --assets-button-common-m-height: var(--assets-common-m-height);
        --assets-button-common-m-icon: var(--assets-common-m-icon);
        --assets-button-common-l-height: var(--assets-common-l-height);
        --assets-button-common-l-radius: var(--assets-common-l-radius);
        --assets-button-common-l-gap: var(--assets-common-l-gap);
        --assets-button-common-l-padding-hor: var(--assets-common-l-padding-hor);
        --assets-button-common-l-padding-vert: var(--assets-common-l-padding-vert);
        --assets-button-common-l-icon: var(--assets-common-l-icon);
        --assets-button-common-s-height: var(--assets-common-s-height);
        --assets-button-common-s-radius: var(--assets-common-s-radius);
        --assets-button-common-s-gap: var(--assets-common-s-gap);
        --assets-button-common-s-padding-hor: var(--assets-common-s-padding-hor);
        --assets-button-common-s-padding-vert: var(--assets-common-s-padding-vert);
        --assets-button-common-s-icon: var(--assets-common-s-icon);
        --assets-button-common-xs-height: var(--assets-common-xs-height);
        --assets-button-common-xs-radius: var(--assets-common-xs-radius);
        --assets-button-common-xs-gap: var(--assets-common-xs-gap);
        --assets-button-common-xs-padding-hor: var(--assets-common-xs-padding-hor);
        --assets-button-common-xs-padding-vert: var(--assets-common-xs-padding-vert);
        --assets-button-common-xs-icon: var(--assets-common-xs-icon);
        --layout-mobile-padding-vert: var(--root-sizes-16);
        --assets-button-primary-blue-fill: var(--root-colors-main-blue-basic);
        --assets-button-primary-blue-content: var(--root-colors-main-white-basic);
        --assets-button-primary-blue-fill-hover: var(--root-colors-main-blue-dark);
        --assets-button-primary-blue-content-hover: var(--root-colors-main-white-basic);
        --assets-button-primary-red-fill: var(--root-colors-main-red-basic);
        --assets-button-primary-red-content: var(--root-colors-main-white-basic);
        --assets-button-primary-red-fill-hover: var(--root-colors-main-red-dark);
        --assets-button-primary-red-content-hover: var(--root-colors-main-white-basic);
        --assets-button-primary-green-fill: var(--root-colors-main-green-basic);
        --assets-button-primary-green-content: var(--root-colors-main-white-basic);
        --assets-button-primary-green-fill-hover: var(--root-colors-main-green-dark);
        --assets-button-primary-green-content-hover: var(--root-colors-main-white-basic);
        --assets-button-primary-orange-fill: var(--root-colors-main-orange-basic);
        --assets-button-primary-orange-content: var(--root-colors-main-white-basic);
        --assets-button-primary-orange-fill-hover: var(--root-colors-main-orange-dark);
        --assets-button-primary-orange-content-hover: var(--root-colors-main-white-basic);
        --assets-button-secondary-blue-stroke: var(--root-colors-main-blue-basic);
        --assets-button-secondary-blue-content: var(--root-colors-main-blue-basic);
        --assets-button-secondary-blue-stroke-hover: var(--root-colors-main-blue-dark);
        --assets-button-secondary-blue-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-secondary-red-stroke: var(--root-colors-main-red-basic);
        --assets-button-secondary-red-content: var(--root-colors-main-red-basic);
        --assets-button-secondary-red-stroke-hover: var(--root-colors-main-red-dark);
        --assets-button-secondary-red-content-hover: var(--root-colors-main-red-dark);
        --assets-button-secondary-green-stroke: var(--root-colors-main-green-basic);
        --assets-button-secondary-green-content: var(--root-colors-main-green-basic);
        --assets-button-secondary-green-stroke-hover: var(--root-colors-main-green-dark);
        --assets-button-secondary-green-content-hover: var(--root-colors-main-green-dark);
        --assets-button-secondary-orange-stroke: var(--root-colors-main-orange-basic);
        --assets-button-secondary-orange-content: var(--root-colors-main-orange-basic);
        --assets-button-secondary-orange-stroke-hover: var(--root-colors-main-orange-dark);
        --assets-button-secondary-orange-content-hover: var(--root-colors-main-orange-dark);
        --assets-button-ghost-blue-fill: var(--root-colors-main-blue-ghost);
        --assets-button-ghost-blue-content: var(--root-colors-main-blue-basic);
        --assets-button-ghost-blue-fill-hover: var(--root-colors-main-blue-ghost-alt);
        --assets-button-ghost-blue-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-ghost-red-fill: var(--root-colors-main-red-ghost);
        --assets-button-ghost-red-content: var(--root-colors-main-red-basic);
        --assets-button-ghost-red-fill-hover: var(--root-colors-main-red-ghost-alt);
        --assets-button-ghost-red-content-hover: var(--root-colors-main-red-dark);
        --assets-button-ghost-green-fill: var(--root-colors-main-green-ghost);
        --assets-button-ghost-green-content: var(--root-colors-main-green-basic);
        --assets-button-ghost-green-fill-hover: var(--root-colors-main-green-ghost-alt);
        --assets-button-ghost-green-content-hover: var(--root-colors-main-green-dark);
        --assets-button-ghost-orange-fill: var(--root-colors-main-orange-ghost);
        --assets-button-ghost-orange-content: var(--root-colors-main-orange-basic);
        --assets-button-ghost-orange-fill-hover: var(--root-colors-main-orange-ghost-alt);
        --assets-button-ghost-orange-content-hover: var(--root-colors-main-orange-dark);
        --assets-button-free-blue-content: var(--root-colors-main-blue-basic);
        --assets-button-free-blue-fill-hover: var(--root-colors-main-blue-ghost-alt);
        --assets-button-free-blue-content-hover: var(--root-colors-main-blue-dark);
        --assets-button-free-red-content: var(--root-colors-main-red-basic);
        --assets-button-free-red-fill-hover: var(--root-colors-main-red-ghost-alt);
        --assets-button-free-red-content-hover: var(--root-colors-main-red-dark);
        --assets-button-free-green-content: var(--root-colors-main-green-basic);
        --assets-button-free-green-fill-hover: var(--root-colors-main-green-ghost-alt);
        --assets-button-free-green-content-hover: var(--root-colors-main-green-dark);
        --assets-button-free-orange-content: var(--root-colors-main-orange-basic);
        --assets-button-free-orange-fill-hover: var(--root-colors-main-orange-ghost-alt);
        --assets-button-free-orange-content-hover: var(--root-colors-main-orange-dark);
        --assets-oauth-sizes-icon: var(--root-sizes-32);
        --assets-oauth-colors-stroke: var(--root-colors-main-white-dark);
        --assets-oauth-colors-fill: var(--root-colors-main-white-basic);
        --assets-oauth-colors-stroke-hover: var(--root-colors-main-blue-basic);
        --assets-oauth-colors-text: var(--root-colors-main-black-basic);
        --layout-text-colors-primary: var(--root-colors-main-black-basic);
        --layout-text-colors-secondary: var(--root-colors-main-black-light);
        --layout-text-colors-link: var(--root-colors-main-blue-basic);
        --layout-text-colors-link-hover: var(--root-colors-main-blue-dark);
        --layout-text-colors-link-clicked: var(--root-colors-accent-violett);
        --layout-text-colors-additional: var(--root-colors-main-grey-dark);
        --layout-text-colors-header: var(--root-colors-main-black-basic);
        --assets-link-gap: var(--root-sizes-8);
        --assets-link-icon: var(--root-sizes-24);
        --assets-common-l-height: var(--root-sizes-56);
        --assets-common-l-radius: var(--root-sizes-16);
        --assets-common-l-gap: var(--root-sizes-12);
        --assets-common-l-padding-hor: var(--root-sizes-16);
        --assets-common-l-padding-vert: var(--root-sizes-6);
        --assets-common-l-icon: var(--root-sizes-32);
        --assets-common-m-height: var(--root-sizes-48);
        --assets-common-m-radius: var(--root-sizes-12);
        --assets-common-m-gap: var(--root-sizes-8);
        --assets-common-m-padding-hor: var(--root-sizes-12);
        --assets-common-m-padding-vert: var(--root-sizes-4);
        --assets-common-m-icon: var(--root-sizes-24);
        --assets-common-s-height: var(--root-sizes-42);
        --assets-common-s-radius: var(--root-sizes-10);
        --assets-common-s-gap: var(--root-sizes-8);
        --assets-common-s-padding-hor: var(--root-sizes-12);
        --assets-common-s-padding-vert: var(--root-sizes-2);
        --assets-common-s-icon: var(--root-sizes-24);
        --assets-common-xs-height: var(--root-sizes-32);
        --assets-common-xs-radius: var(--root-sizes-8);
        --assets-common-xs-gap: var(--root-sizes-6);
        --assets-common-xs-padding-hor: var(--root-sizes-8);
        --assets-common-xs-padding-vert: var(--root-sizes-2);
        --assets-common-xs-icon: var(--root-sizes-20);
        --assets-utility-counter-colors-background-mono: var(--root-colors-main-black-basic);
        --assets-utility-counter-sizes-m-width-min: var(--root-sizes-20);
        --assets-utility-counter-sizes-m-height: var(--root-sizes-20);
        --assets-utility-counter-sizes-m-radius: var(--root-radius-xxxs-4);
        --assets-utility-counter-sizes-m-padding-hor: var(--root-sizes-2);
        --assets-utility-counter-sizes-l-width-min: var(--root-sizes-24);
        --assets-utility-counter-sizes-l-height: var(--root-sizes-24);
        --assets-utility-counter-sizes-l-radius: var(--root-radius-xxs-6);
        --assets-utility-counter-sizes-l-padding-hor: var(--root-sizes-4);
        --layout-statuses-error: var(--root-colors-accent-red);
        --layout-statuses-normal: var(--root-colors-main-blue-basic);
        --layout-statuses-warning: var(--root-colors-main-orange-basic);
        --layout-statuses-success: var(--root-colors-main-green-basic);
        --assets-input-colors-placeholder: var(--root-colors-main-grey-basic);
        --assets-input-colors-icon: var(--root-colors-main-grey-basic);
        --assets-input-colors-icon-focused: var(--root-colors-main-black-light);
        --assets-input-colors-text: var(--root-colors-main-black-basic);
        --assets-input-colors-button: var(--root-colors-main-black-light);
        --assets-choice-group-sizes-l-height: var(--assets-common-l-height);
        --assets-choice-group-sizes-l-radius: var(--assets-common-l-radius);
        --assets-choice-group-sizes-l-gap: var(--assets-common-l-gap);
        --assets-choice-group-sizes-l-padding-hor: var(--assets-common-l-padding-hor);
        --assets-choice-group-sizes-l-padding-vert: var(--assets-common-l-padding-vert);
        --assets-choice-group-sizes-l-icon: var(--assets-common-l-icon);
        --assets-choice-group-sizes-m-height: var(--assets-common-m-height);
        --assets-choice-group-sizes-m-radius: var(--assets-common-m-radius);
        --assets-choice-group-sizes-m-gap: var(--assets-common-m-gap);
        --assets-choice-group-sizes-m-padding-hor: var(--assets-common-m-padding-hor);
        --assets-choice-group-sizes-m-padding-vert: var(--assets-common-m-padding-vert);
        --assets-choice-group-sizes-m-icon: var(--assets-common-m-icon);
        --assets-choice-group-sizes-s-height: var(--assets-common-s-height);
        --assets-choice-group-sizes-s-radius: var(--assets-common-s-radius);
        --assets-choice-group-sizes-s-gap: var(--assets-common-s-gap);
        --assets-choice-group-sizes-s-padding-hor: var(--assets-common-s-padding-hor);
        --assets-choice-group-sizes-s-padding-vert: var(--assets-common-s-padding-vert);
        --assets-choice-group-sizes-s-icon: var(--assets-common-s-icon);
        --assets-choice-group-sizes-xs-height: var(--assets-common-xs-height);
        --assets-choice-group-sizes-xs-radius: var(--assets-common-xs-radius);
        --assets-choice-group-sizes-xs-gap: var(--assets-common-xs-gap);
        --assets-choice-group-sizes-xs-padding-hor: var(--assets-common-xs-padding-hor);
        --assets-choice-group-sizes-xs-padding-vert: var(--assets-common-xs-padding-vert);
        --assets-choice-group-sizes-xs-icon: var(--assets-common-xs-icon);
        --assets-choice-group-active-fill: var(--root-colors-main-blue-basic);
        --assets-choice-group-active-content: var(--root-colors-main-white-basic);
        --assets-choice-group-active-blue-fill: var(--root-colors-main-blue-basic);
        --assets-choice-group-active-blue-content: var(--root-colors-main-white-basic);
        --assets-choice-group-active-red-fill: var(--root-colors-main-red-basic);
        --assets-choice-group-active-red-content: var(--root-colors-main-white-basic);
        --assets-choice-group-active-green-fill: var(--root-colors-main-green-basic);
        --assets-choice-group-active-green-content: var(--root-colors-main-white-basic);
        --assets-choice-group-active-orange-fill: var(--root-colors-main-orange-basic);
        --assets-choice-group-active-orange-content: var(--root-colors-main-white-basic);
        --assets-choice-group-sizes-l-row-gap: var(--root-sizes-8);
        --assets-choice-group-sizes-m-row-gap: var(--root-sizes-6);
        --assets-choice-group-sizes-s-row-gap: var(--root-sizes-6);
        --assets-choice-group-sizes-xs-row-gap: var(--root-sizes-4);
        --assets-choice-group-colors-fill: var(--root-colors-main-black-ghost);
        --assets-choice-group-colors-content: var(--root-colors-main-black-basic);
        --assets-choice-group-colors-fill-hover: var(--root-colors-main-black-ghost-alt);
        --assets-checkbox-checked-fill: var(--root-colors-main-blue-basic);
        --assets-checkbox-checked-content: var(--root-colors-main-white-basic);
        --assets-checkbox-checked-fill-hover: var(--root-colors-main-blue-dark);
        --assets-checkbox-checked-blue-fill: var(--root-colors-main-blue-basic);
        --assets-checkbox-checked-blue-content: var(--root-colors-main-white-basic);
        --assets-checkbox-checked-blue-fill-hover: var(--root-colors-main-blue-dark);
        --assets-checkbox-checked-red-fill: var(--root-colors-main-red-basic);
        --assets-checkbox-checked-red-content: var(--root-colors-main-white-basic);
        --assets-checkbox-checked-red-fill-hover: var(--root-colors-main-red-dark);
        --assets-checkbox-checked-green-fill: var(--root-colors-main-green-basic);
        --assets-checkbox-checked-green-content: var(--root-colors-main-white-basic);
        --assets-checkbox-checked-green-fill-hover: var(--root-colors-main-green-dark);
        --assets-checkbox-checked-orange-fill: var(--root-colors-main-orange-basic);
        --assets-checkbox-checked-orange-content: var(--root-colors-main-white-basic);
        --assets-checkbox-checked-orange-fill-hover: var(--root-colors-main-orange-dark);
        --assets-checkbox-sizes-radius: var(--root-sizes-8);
        --assets-checkbox-sizes-width: var(--root-sizes-24);
        --assets-checkbox-colors-stroke: var(--root-colors-main-grey-basic);
        --assets-checkbox-colors-fill-hover: var(--root-colors-main-black-ghost);
        --assets-input-sizes-radius: var(--assets-common-m-radius);
        --assets-input-colors-stroke-basic: var(--root-colors-main-grey-basic);
        --assets-input-sizes-height: var(--assets-common-m-height);
        --assets-input-colors-fill-hover: var(--root-colors-main-black-ghost);
        --assets-input-colors-stroke-success: var(--layout-statuses-success);
        --assets-input-colors-stroke-error: var(--layout-statuses-error);
        --assets-input-sizes-padding-hor: var(--assets-common-m-padding-hor);
        --assets-input-sizes-padding-vert: var(--assets-button-common-s-padding-vert);
        --assets-input-sizes-icon: var(--assets-common-m-icon);
        --assets-input-sizes-gap: var(--assets-common-m-gap);
        --assets-input-colors-stroke-focused: var(--root-colors-main-black-light);
        --assets-label-colors-basic: var(--root-colors-main-grey-dark);
        --assets-label-colors-focused: var(--root-colors-main-black-light);
        --assets-label-colors-success: var(--layout-statuses-success);
        --assets-label-colors-error: var(--layout-statuses-error);
        --assets-label-padding-hor: var(--assets-input-sizes-padding-hor);
        --assets-label-gap: var(--root-sizes-2);
        --assets-status-m-width: var(--root-sizes-24);
        --assets-status-sizes-radius: var(--root-radius-round);
        --assets-input-login-auth-gap: var(--root-sizes-4);
        --assets-input-password-auth-gap: var(--root-sizes-8);
        --assets-smartcaptcha-sizes-radius: var(--root-radius-s-12);
        --assets-smartcaptcha-sizes-padding: var(--root-sizes-12);
        --assets-smartcaptcha-sizes-gap: var(--root-sizes-12);
        --assets-smartcaptcha-sizes-icon-height: var(--root-sizes-16);
        --assets-smartcaptcha-sizes-gap-inner: var(--root-sizes-8);
        --assets-smartcaptcha-colors-stroke: var(--root-colors-main-white-dark);
        --assets-smartcaptcha-colors-title: var(--layout-text-colors-primary);
        --assets-smartcaptcha-colors-subtitle: var(--layout-text-colors-secondary);
        --assets-smartcaptcha-colors-bottom: var(--layout-text-colors-additional);
        --assets-smartcaptcha-colors-icon: var(--root-colors-main-black-light);
        --assets-oauth-sizes-height: var(--assets-common-m-height);
        --assets-oauth-sizes-radius: var(--assets-common-m-radius);
        --assets-oauth-sizes-gap: var(--assets-common-m-gap);
        --assets-oauth-sizes-padding-hor: var(--assets-common-m-padding-hor);
        --assets-oauth-sizes-padding-vert: var(--assets-common-m-padding-vert);
        --layout-mobile-controls-gap: var(--root-sizes-8);
        --assets-modals-informer-padding: var(--root-sizes-24);
        --assets-modals-informer-gap: var(--root-sizes-16);
        --assets-modals-informer-radius: var(--root-radius-l-24);
        --assets-label-required-gap: var(--root-sizes-2);
        --assets-label-colors-required: var(--root-colors-main-red-basic);
        --assets-input-phone-gap: var(--root-sizes-2);
        --assets-input-phone-country-selector-gap: var(--root-sizes-6);
        --assets-select-colors-chevron: var(--root-colors-main-black-light);
        --assets-status-s-width: var(--root-sizes-16);
        --assets-status-m-icon: var(--root-sizes-16);
        --assets-status-s-icon: var(--root-sizes-12);
        --assets-status-colors-empty: var(--root-colors-main-white-light);
        --assets-status-colors-empty-text: var(--root-colors-main-black-light);
        --assets-status-colors-success: var(--layout-statuses-success);
        --assets-status-colors-error: var(--layout-statuses-error);
        --assets-status-sizes-gap: var(--root-sizes-4);
        --assets-input-password-reg-sizes-gap: var(--root-sizes-8);
        --assets-input-password-reg-colors-stroke: var(--root-colors-main-white-dark);
        --assets-input-password-reg-colors-stroke-success: var(--layout-statuses-success);
        --assets-input-password-reg-colors-stroke-error: var(--layout-statuses-error);
        --assets-input-password-reg-sizes-padding: var(--assets-input-sizes-padding-hor);
        --assets-input-password-reg-sizes-radius: var(--assets-input-sizes-radius);
        --assets-input-password-reg-sizes-margin: var(--root-sizes-4);
        --assets-legal-colors-stroke: var(--root-colors-main-white-dark);
        --assets-legal-colors-fill: var(--root-colors-main-white-basic);
        --assets-legal-colors-stroke-hover: var(--root-colors-main-blue-basic);
        --assets-legal-colors-text: var(--root-colors-main-black-basic);
        --assets-legal-sizes-radius: var(--assets-common-m-radius);
        --assets-legal-sizes-gap: var(--root-sizes-12);
        --assets-legal-sizes-padding: var(--assets-common-m-padding-hor);
        --assets-legal-colors-stroke-error: var(--layout-statuses-error);
        --layout-mobile-gap: var(--root-sizes-24);
        --layout-mobile-form-gap: var(--root-sizes-24);
        --layout-mobile-section-gap: var(--root-sizes-12);
        --layout-mobile-row-gap: var(--root-sizes-8);
        --layout-mobile-title-gap: var(--root-sizes-8);
        --assets-select-colors-content: var(--root-colors-main-black-basic);
        --assets-select-colors-fill-hover: var(--root-colors-main-blue-ghost);
        --assets-select-colors-fill-active: var(--root-colors-main-blue-basic);
        --assets-select-colors-content-active: var(--root-colors-main-white-basic);
        --assets-select-sizes-height: var(--assets-common-s-height);
        --assets-select-sizes-padding-hor: var(--assets-button-common-m-padding-hor);
        --assets-select-sizes-icon: var(--assets-common-s-icon);
        --assets-select-sizes-gap: var(--assets-common-m-gap);
        --assets-select-sizes-radius: var(--root-radius-s-12);
        --assets-user-avatar-radius-m: var(--root-radius-s-12);
        --assets-user-avatar-radius-l: var(--root-radius-m-16);
        --assets-user-avatar-size-m: var(--root-sizes-64);
        --assets-user-avatar-size-l: var(--root-sizes-96);
        --assets-user-avatar-radius-xl: var(--root-radius-l-24);
        --assets-user-avatar-size-xl: var(--root-sizes-128);
        --assets-tag-sizes-m-padding-hor: var(--root-sizes-12);
        --assets-tag-sizes-m-icon: var(--root-sizes-20);
        --assets-tag-sizes-m-height: var(--root-sizes-32);
        --assets-tag-sizes-m-gap: var(--root-sizes-4);
        --assets-tag-sizes-m-radius: var(--root-radius-round);
        --assets-tag-colors-fill: var(--root-colors-main-blue-basic);
        --assets-tag-colors-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-hover: var(--root-colors-main-blue-dark);
        --assets-input-colors-text-focused: var(--root-colors-main-grey-dark);
        --assets-select-sizes-combo-margin: var(--root-sizes-4);
        --assets-select-sizes-combo-gap: var(--root-sizes-4);
        --assets-switch-colors-stroke: var(--root-colors-main-grey-basic);
        --assets-switch-sizes-radius: var(--root-radius-round);
        --assets-switch-colors-fill-hover: var(--root-colors-main-black-ghost);
        --assets-switch-sizes-height: var(--root-sizes-32);
        --assets-switch-checked-fill: var(--root-colors-main-blue-basic);
        --assets-switch-checked-grip: var(--root-colors-main-white-basic);
        --assets-switch-checked-fill-hover: var(--root-colors-main-blue-dark);
        --assets-switch-checked-blue-fill: var(--root-colors-main-blue-basic);
        --assets-switch-checked-blue-grip: var(--root-colors-main-white-basic);
        --assets-switch-checked-blue-fill-hover: var(--root-colors-main-blue-dark);
        --assets-switch-checked-red-fill: var(--root-colors-main-red-basic);
        --assets-switch-checked-red-grip: var(--root-colors-main-white-basic);
        --assets-switch-checked-red-fill-hover: var(--root-colors-main-red-dark);
        --assets-switch-checked-green-fill: var(--root-colors-main-green-basic);
        --assets-switch-checked-green-grip: var(--root-colors-main-white-basic);
        --assets-switch-checked-green-fill-hover: var(--root-colors-main-green-dark);
        --assets-switch-checked-orange-fill: var(--root-colors-main-orange-basic);
        --assets-switch-checked-orange-content: var(--root-colors-main-white-basic);
        --assets-switch-checked-orange-fill-hover: var(--root-colors-main-orange-dark);
        --assets-switch-colors-grip: var(--root-colors-main-grey-basic);
        --assets-switch-sizes-grip: var(--root-sizes-24);
        --assets-switch-sizes-padding: var(--root-sizes-4);
        --assets-switch-sizes-width: var(--root-sizes-56);
        --assets-tag-sizes-s-height: var(--root-sizes-24);
        --assets-tag-sizes-s-padding-hor: var(--root-sizes-8);
        --assets-tag-sizes-s-gap: var(--root-sizes-4);
        --assets-tag-sizes-s-icon: var(--root-sizes-16);
        --assets-tag-sizes-s-radius: var(--root-radius-round);
        --assets-select-sizes-separate-radius: var(--root-sizes-12);
        --assets-select-sizes-separate-gap: var(--root-sizes-8);
        --assets-switch-sizes-invalid-label-gap: var(--root-sizes-8);
        --assets-switch-sizes-invalid-gap: var(--root-sizes-8);
        --assets-switch-sizes-invalid-padding: var(--root-sizes-12);
        --assets-switch-sizes-invalid-radius: var(--root-radius-m-16);
        --assets-switch-colors-invalid-stroke: var(--root-colors-main-white-dark);
        --assets-switch-colors-invalid-stroke-checked: var(--root-colors-main-blue-basic);
        --assets-switch-colors-invalid-icon: var(--root-colors-main-black-light);
        --assets-user-profile-data-sizes-s-gap: var(--root-sizes-4);
        --assets-user-profile-data-sizes-s-padding: var(--root-sizes-12);
        --assets-user-profile-data-sizes-s-radius: var(--root-radius-m-16);
        --assets-user-profile-data-sizes-s-gap-tag: var(--root-sizes-8);
        --assets-user-profile-data-sizes-s-icon: var(--root-sizes-24);
        --assets-user-profile-data-colors-fill: var(--root-colors-main-white-light);
        --assets-user-profile-data-colors-label: var(--layout-text-colors-additional);
        --assets-user-profile-data-colors-icon: var(--root-colors-main-black-light);
        --assets-user-profile-data-sizes-s-grid-gap: var(--root-sizes-8);
        --assets-tag-colors-black-fill: var(--root-colors-main-black-basic);
        --assets-tag-colors-black-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-black-fill-hover: var(--root-colors-main-black-dark);
        --assets-tag-colors-red-fill: var(--root-colors-main-red-basic);
        --assets-tag-colors-red-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-red-fill-hover: var(--root-colors-main-red-dark);
        --assets-tag-colors-green-fill: var(--root-colors-main-green-basic);
        --assets-tag-colors-green-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-green-fill-hover: var(--root-colors-main-green-dark);
        --assets-tag-colors-orange-fill: var(--root-colors-main-orange-basic);
        --assets-tag-colors-orange-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-orange-fill-hover: var(--root-colors-main-orange-dark);
        --assets-informer-colors-basic-fill: var(--root-colors-main-blue-ghost);
        --assets-informer-colors-basic-stroke: var(--root-colors-main-blue-ghost);
        --assets-informer-colors-basic-icon-bg: var(--root-colors-main-blue-basic);
        --assets-informer-colors-icon: var(--root-colors-main-white-basic);
        --assets-informer-colors-text: var(--root-colors-main-black-basic);
        --assets-informer-colors-success-fill: var(--root-colors-main-green-ghost);
        --assets-informer-colors-success-stroke: var(--root-colors-main-green-ghost);
        --assets-informer-colors-success-icon-bg: var(--root-colors-main-green-basic);
        --assets-informer-colors-error-fill: var(--root-colors-accent-red-ghost);
        --assets-informer-colors-error-stroke: var(--root-colors-accent-red-ghost);
        --assets-informer-colors-error-icon-bg: var(--root-colors-accent-red);
        --assets-informer-colors-warning-fill: var(--root-colors-main-orange-ghost);
        --assets-informer-colors-warning-stroke: var(--root-colors-main-orange-ghost);
        --assets-informer-colors-warning-icon-bg: var(--root-colors-main-orange-basic);
        --assets-informer-sizes-padding: var(--root-sizes-12);
        --assets-informer-sizes-radius: var(--root-radius-s-12);
        --assets-informer-sizes-padding-left: var(--root-sizes-32);
        --assets-informer-sizes-icon: var(--root-sizes-20);
        --assets-informer-sizes-icon-bg: var(--root-sizes-24);
        --assets-informer-sizes-icon-radius: var(--root-radius-round);
        --assets-informer-sizes-gap: var(--root-sizes-4);
        --assets-informer-sizes-icon-margin: var(--root-sizes-8);
        --assets-progressbar-sizes-radius: var(--root-sizes-4);
        --assets-progressbar-sizes-height: var(--root-sizes-4);
        --assets-progressbar-sizes-padding: var(--root-sizes-2);
        --assets-progressbar-colors-fill: var(--root-colors-main-blue-basic);
        --assets-progressbar-colors-background: var(--root-colors-main-white-dark);
        --assets-file-card-sizes-padding: var(--root-sizes-4);
        --assets-file-card-sizes-gap: var(--root-sizes-8);
        --assets-file-card-sizes-gap-content: var(--root-sizes-4);
        --assets-file-card-sizes-icon-bg: var(--root-sizes-42);
        --assets-file-card-sizes-icon-bg-radius: var(--root-sizes-8);
        --assets-file-card-sizes-icon: var(--root-sizes-32);
        --assets-file-card-sizes-radius: var(--root-sizes-12);
        --assets-file-card-colors-fill: var(--root-colors-main-white-light);
        --assets-file-card-colors-fill-bg: var(--root-colors-main-white-basic);
        --assets-file-card-colors-main: var(--root-colors-main-blue-basic);
        --assets-file-card-colors-additional: var(--root-colors-main-grey-dark);
        --assets-file-load-sizes-s-padding: var(--root-sizes-24);
        --assets-file-load-sizes-s-radius: var(--root-radius-s-12);
        --assets-file-load-sizes-s-gap: var(--root-sizes-8);
        --assets-file-load-colors-stroke: var(--root-colors-main-blue-basic);
        --assets-file-load-colors-fill: var(--root-colors-main-blue-ghost);
        --assets-file-load-colors-content: var(--root-colors-main-blue-basic);
        --assets-file-load-colors-divider: var(--root-colors-main-blue-basic);
        --assets-file-custom-card-sizes-s-radius: var(--root-radius-m-16);
        --assets-file-custom-card-sizes-s-padding: var(--root-sizes-12);
        --assets-file-custom-card-sizes-s-gap: var(--root-sizes-12);
        --assets-file-custom-card-sizes-s-gap-buttons: var(--root-sizes-8);
        --assets-file-custom-card-colors-stroke: var(--root-colors-main-white-dark);
        --assets-user-profile-data-sizes-s-gap-title: var(--root-sizes-12);
        --assets-file-load-colors-error: var(--layout-statuses-error);
        --assets-tag-colors-error-fill: var(--layout-statuses-error);
        --assets-tag-colors-error-content: var(--root-colors-main-white-basic);
        --assets-tag-colors-error-fill-hover: var(--layout-statuses-error);
        --assets-input-textarea-sizes-gap: var(--root-sizes-8);
        --assets-input-textarea-sizes-padding-hor: var(--assets-input-sizes-padding-hor);
        --assets-input-textarea-colors-counter: var(--root-colors-main-grey-dark);
        --assets-input-textarea-sizes-radius: var(--assets-input-sizes-radius);
        --assets-input-textarea-colors-counter-focused: var(--root-colors-main-black-light);
        --assets-input-textarea-colors-counter-error: var(--layout-statuses-error);
        --assets-input-textarea-sizes-bottom-height: var(--assets-button-common-s-height);
        --assets-user-profile-card-sizes-padding: var(--root-sizes-8);
        --assets-user-profile-card-sizes-gap: var(--root-sizes-8);
        --assets-user-profile-card-colors-stroke: var(--root-colors-main-white-dark);
        --assets-user-profile-card-sizes-radius: var(--root-radius-l-24);
        --assets-header-menu-common-mobile-padding-vert: var(--root-sizes-32);
        --assets-header-menu-common-desktop-padding-hor: var(--layout-desktop-padding-hor);
        --assets-header-menu-common-desktop-padding-vert: var(--root-sizes-32);
        --layout-desktop-padding-hor: var(--root-sizes-128);
        --layout-desktop-padding-vert: var(--root-sizes-32);
        --layout-desktop-controls-gap: var(--root-sizes-8);
        --layout-desktop-gap: var(--root-sizes-32);
        --layout-desktop-form-gap: var(--root-sizes-24);
        --layout-desktop-section-gap: var(--root-sizes-12);
        --layout-desktop-row-gap: var(--root-sizes-8);
        --layout-desktop-title-gap: var(--root-sizes-8);
        --assets-header-menu-common-mobile-gap: var(--root-sizes-32);
        --assets-header-menu-common-desktop-gap: var(--root-sizes-64);
        --assets-card-common-colors-fill: var(--root-colors-main-white-light);
        --assets-card-common-colors-stroke-hover: var(--root-colors-main-blue-basic);
        --assets-card-common-colors-interactive: var(--layout-text-colors-link);
        --assets-card-common-colors-content: var(--layout-text-colors-primary);
        --assets-card-custom-organization-sizes-s-padding: var(--root-sizes-12);
        --assets-card-custom-organization-sizes-s-gap: var(--root-sizes-12);
        --assets-card-common-sizes-s-title-gap: var(--root-sizes-4);
        --assets-card-common-sizes-s-radius: var(--root-radius-l-24);
        --assets-card-custom-organization-sizes-s-pic: var(--root-sizes-48);
        --assets-card-custom-organization-sizes-s-icon: var(--root-sizes-32);
        --assets-card-custom-organization-sizes-s-pic-radius: var(--root-radius-s-12);
        --assets-card-custom-organization-colors-pic: var(--root-colors-main-black-basic);
        --assets-card-custom-organization-colors-icon: var(--root-colors-main-white-basic);
        --assets-card-common-colors-additional: var(--layout-text-colors-additional);
        --assets-card-custom-idea-sizes-s-gap: var(--root-sizes-24);
        --assets-card-custom-idea-sizes-s-padding: var(--root-sizes-24);
        --assets-card-custom-idea-sizes-s-pic: var(--root-sizes-256);
        --assets-card-custom-idea-sizes-s-data-gap: var(--root-radius-xs-8);
        --assets-card-custom-idea-sizes-s-icon: var(--root-sizes-16);
        --assets-card-custom-idea-colors-pic: var(--root-colors-main-black-ghost);
        --assets-card-custom-metric-sizes-s-gap: var(--root-sizes-8);
        --assets-card-custom-metric-sizes-s-padding: var(--root-sizes-24);
        --assets-card-custom-metric-sizes-s-padding-left: var(--root-sizes-12);
        --assets-card-custom-metric-sizes-s-icon: var(--root-sizes-32);
        --assets-card-custom-metric-colors-icon: var(--root-colors-main-orange-basic);
        --assets-header-menu-line-sizes-padding-bottom-l: var(--root-sizes-24);
        --assets-user-profile-data-sizes-l-gap: var(--root-sizes-12);
        --assets-user-profile-data-sizes-l-padding: var(--root-sizes-16);
        --assets-user-profile-data-sizes-l-radius: var(--root-radius-m-16);
        --assets-user-profile-data-sizes-l-gap-inner: var(--root-sizes-4);
        --assets-user-profile-data-sizes-l-icon: var(--root-sizes-32);
        --assets-user-profile-data-sizes-l-grid-gap: var(--root-sizes-12);
        --assets-user-profile-data-sizes-l-gap-title: var(--root-sizes-16);
        --assets-card-custom-goal-sizes-s-padding: var(--root-sizes-24);
        --assets-card-custom-goal-sizes-s-gap: var(--root-sizes-16);
        --assets-card-custom-goal-sizes-s-date-gap: var(--root-sizes-4);
        --assets-card-custom-goal-sizes-s-dash-width: var(--root-sizes-24);
        --assets-card-custom-goal-sizes-s-title-gap: var(--root-sizes-8);
        --assets-card-custom-goal-sizes-s-title-padding: var(--assets-card-common-sizes-s-radius);
        --assets-card-custom-goal-sizes-s-title-margin: var(--root-sizes-4);
        --assets-card-custom-goal-sizes-s-grid-gap: var(--root-sizes-16);
        --assets-input-links-sizes-gap: var(--root-sizes-8);
        --assets-input-links-sizes-margin: var(--root-sizes-12);
        --assets-input-links-sizes-padding: var(--root-sizes-12);
        --assets-input-links-sizes-radius: var(--root-radius-m-16);
        --assets-input-links-colors-title: var(--layout-text-colors-primary);
        --assets-input-links-colors-stroke: var(--root-colors-main-white-dark);
        --assets-card-custom-event-s-gap: var(--root-sizes-4);
        --assets-card-custom-event-s-padding: var(--root-sizes-16);
        --assets-card-custom-event-s-radius: var(--root-radius-m-16);
        --assets-card-custom-event-s-icon: var(--root-sizes-16);
        --assets-calendar-colors-title: var(--root-colors-main-grey-basic);
        --assets-calendar-colors-title-weekend: var(--root-colors-main-grey-light);
        --assets-calendar-colors-day: var(--root-colors-main-black-basic);
        --assets-calendar-colors-day-weekend: var(--root-colors-main-black-light);
        --assets-calendar-colors-events: var(--root-colors-main-blue-basic);
        --assets-calendar-colors-events-weekend: var(--root-colors-main-red-basic);
        --assets-calendar-colors-another-month: var(--root-colors-main-grey-dark);
        --assets-calendar-colors-another-month-weekend: var(--root-colors-main-grey-basic);
        --assets-calendar-colors-hover: var(--root-colors-main-black-ghost);
        --assets-calendar-colors-hover-event: var(--root-colors-main-blue-ghost);
        --assets-calendar-colors-hover-event-weekend: var(--root-colors-main-red-ghost);
        --assets-card-custom-metric-sizes-s-padding-vert: var(--root-sizes-12);
        --assets-card-custom-gift-s-padding: var(--root-sizes-8);
        --assets-card-custom-gift-s-padding-right: var(--root-sizes-24);
        --assets-card-custom-gift-s-padding-content: var(--root-sizes-4);
        --assets-card-custom-gift-s-gap-content: var(--root-sizes-8);
        --assets-card-custom-gift-s-gap: var(--root-sizes-16);
        --assets-card-custom-gift-s-gap-inner: var(--root-sizes-4);
        --assets-card-custom-gift-s-pic-size: var(--root-sizes-128);
        --assets-card-custom-gift-s-pic-radius: var(--root-radius-m-16);
        --layout-divider: var(--root-colors-main-white-dark);
        --assets-user-profile-card-sizes-padding-title: var(--root-sizes-16);
        --assets-user-profile-card-sizes-gap-title: var(--root-sizes-4);
        --assets-file-custom-card-sizes-s-gap-remove: var(--root-sizes-4);
        --assets-input-schedule-sizes-s-padding: var(--root-sizes-12);
        --assets-input-schedule-sizes-s-gap: var(--root-sizes-8);
        --assets-input-schedule-sizes-s-gap-title: var(--root-sizes-8);
        --assets-input-schedule-sizes-s-radius: var(--root-radius-m-16);
        --assets-schedule-sizes-s-gap: var(--root-sizes-8);
        --assets-schedule-sizes-s-height-gap: var(--root-sizes-12);
        --layout-text-colors-additional-alt: var(--root-colors-main-grey-basic);
        --assets-input-schedule-weekend-sizes-s-gap: var(--root-sizes-8);
        --assets-input-schedule-weekend-sizes-s-gap-inner: var(--root-sizes-16);
        --assets-input-schedule-form-sizes-s-padding: var(--root-sizes-12);
        --assets-input-schedule-form-sizes-s-radius: var(--root-sizes-24);
        --assets-input-schedule-form-sizes-s-gap: var(--root-sizes-16);
        --assets-input-schedule-form-sizes-s-gap-period: var(--root-sizes-8);
        --assets-input-schedule-form-sizes-s-margin-controls: var(--root-sizes-8);
        --assets-input-schedule-form-colors-stroke: var(--root-colors-main-white-dark);
        --assets-opportunity-cards-colors-fill: var(--root-colors-accent-crema);
        --assets-opportunity-cards-colors-accent-basic: var(--root-colors-accent-violett);
        --assets-opportunity-cards-colors-accent-place: var(--root-colors-accent-blue);
        --assets-opportunity-cards-colors-plate: var(--root-colors-main-white-basic);
        --assets-opportunity-cards-sizes-s-padding: var(--root-sizes-8);
        --assets-opportunity-cards-sizes-s-padding-tag: var(--root-sizes-4);
        --assets-opportunity-cards-sizes-s-radius: var(--root-sizes-24);
        --assets-opportunity-cards-sizes-s-radius-plate: var(--root-sizes-16);
        --assets-opportunity-cards-sizes-s-padding-plate: var(--root-sizes-8);
        --assets-opportunity-cards-sizes-s-padding-plate-bottom: var(--root-sizes-16);
        --assets-opportunity-cards-sizes-s-gap-plate: var(--root-sizes-8);
        --assets-opportunity-cards-sizes-s-gap-plate-content: var(--root-sizes-4);
        --assets-opportunity-cards-sizes-s-padding-plate-content: var(--root-sizes-8);
        --assets-opportunity-cards-colors-accent-news: var(--root-colors-accent-electra);
        --assets-card-custom-partner-s-padding: var(--root-sizes-16);
        --assets-card-custom-partner-s-gap: var(--root-sizes-16);
        --assets-map-marker-width: var(--root-sizes-20);
        --assets-map-colors-main: var(--root-colors-main-blue-basic);
        --assets-map-colors-fill: var(--root-colors-main-white-basic);
        --assets-map-cluster-width: var(--root-sizes-32);
        --assets-card-contacts-size-s-padding: var(--root-sizes-24);
        --assets-card-contacts-size-s-gap: var(--root-sizes-16);
        --assets-card-common-colors-stroke: var(--root-colors-main-black-ghost);
        --assets-opportunity-photos-width: var(--root-sizes-128);
        --assets-opportunity-photos-radius: var(--root-radius-l-24);
        --assets-schedule-sizes-l-gap: var(--root-sizes-8);
        --assets-schedule-sizes-l-height-gap: var(--root-sizes-16);
        --assets-input-schedule-sizes-l-radius: var(--root-radius-m-16);
        --assets-input-schedule-sizes-l-gap: var(--root-sizes-12);
        --assets-input-schedule-sizes-l-padding: var(--root-sizes-24);
        --assets-input-schedule-sizes-l-gap-title: var(--root-sizes-8);
        --assets-card-custom-goal-sizes-l-gap: var(--root-sizes-16);
        --assets-card-custom-goal-sizes-l-padding: var(--root-sizes-24);
        --assets-card-custom-goal-sizes-l-date-gap: var(--root-sizes-4);
        --assets-card-custom-goal-sizes-l-dash-width: var(--root-sizes-24);
        --assets-card-custom-goal-sizes-l-title-gap: var(--root-sizes-8);
        --assets-card-custom-goal-sizes-l-title-padding: var(--assets-card-common-sizes-s-radius);
        --assets-card-custom-goal-sizes-l-title-margin: var(--root-sizes-16);
        --assets-card-custom-goal-sizes-l-grid-gap: var(--root-sizes-16);
        --assets-calendar-sizes-l-width: var(--root-sizes-160);
        --assets-calendar-sizes-l-padding: var(--root-sizes-16);
        --assets-calendar-sizes-s-radius: var(--root-radius-xxs-6);
        --assets-calendar-sizes-l-radius: var(--root-sizes-16);
        --assets-card-custom-event-minified-gap: var(--root-sizes-2);
        --assets-card-custom-event-minified-padding: var(--root-sizes-8);
        --assets-card-custom-event-minified-radius: var(--root-radius-m-16);
        --assets-calendar-sizes-l-gap-events: var(--root-sizes-4);
        --assets-card-custom-event-l-gap: var(--root-sizes-8);
        --assets-card-custom-event-l-padding: var(--root-sizes-24);
        --assets-card-custom-event-l-radius: var(--root-radius-l-24);
        --assets-card-custom-event-l-icon: var(--root-sizes-24);
        --assets-card-leaderboard-sizes-s-gap: var(--root-sizes-16);
        --assets-card-leaderboard-sizes-s-radius: var(--root-radius-m-16);
        --assets-card-leaderboard-sizes-s-padding: var(--root-sizes-8);
        --assets-card-leaderboard-sizes-s-gap-inner: var(--root-sizes-4);
        --assets-card-leaderboard-sizes-l-radius: var(--root-radius-m-16);
        --assets-card-leaderboard-sizes-l-gap: var(--root-sizes-16);
        --assets-card-leaderboard-sizes-l-padding: var(--root-sizes-12);
        --assets-card-leaderboard-sizes-l-gap-inner: var(--root-sizes-8);
        --assets-card-leaderboard-sizes-s-icon: var(--root-sizes-16);
        --assets-card-leaderboard-sizes-l-icon: var(--root-sizes-24);
        --assets-card-leaderboard-sizes-s-width-num: var(--root-sizes-32);
        --assets-card-leaderboard-sizes-l-width-num: var(--root-sizes-48);
        --assets-card-custom-gift-l-gap: var(--root-sizes-16);
        --assets-card-custom-gift-l-padding: var(--root-sizes-8);
        --assets-card-custom-gift-l-padding-right: var(--root-sizes-24);
        --assets-card-custom-gift-l-padding-content: var(--root-sizes-4);
        --assets-card-custom-gift-l-gap-content: var(--root-sizes-8);
        --assets-card-custom-gift-l-gap-inner: var(--root-sizes-16);
        --assets-card-custom-gift-l-pic-size: var(--root-sizes-224);
        --assets-card-custom-gift-l-pic-radius: var(--root-radius-m-16);
        --assets-card-custom-partner-s-picture: var(--root-sizes-96);
        --assets-card-custom-partner-s-icon: var(--root-sizes-16);
        --assets-card-custom-partner-l-padding: var(--root-sizes-24);
        --assets-card-custom-partner-l-gap: var(--root-sizes-24);
        --assets-card-custom-partner-l-picture: var(--root-sizes-128);
        --assets-card-custom-partner-l-icon: var(--root-sizes-24);
        --assets-user-avatar-size-xxl: var(--root-sizes-160);
        --assets-card-info-event-s-indent: var(--root-sizes-16);
        --assets-card-info-event-l-indent: var(--root-sizes-24);
        --assets-card-notification-s-padding: var(--root-sizes-16);
        --assets-card-notification-s-gap: var(--root-sizes-8);
        --assets-card-notification-s-marker: var(--root-sizes-8);
        --assets-card-notification-l-padding: var(--root-sizes-24);
        --assets-card-notification-l-gap: var(--root-sizes-12);
        --assets-card-notification-l-marker: var(--root-sizes-12);
        --assets-input-code-colors-timer: var(--root-colors-main-blue-basic);
        --assets-input-code-colors-stroke: var(--root-colors-main-white-dark);
        --assets-input-code-sizes-gap: var(--root-sizes-24);
        --assets-input-code-sizes-margin: var(--root-sizes-8);
        --assets-input-code-sizes-padding: var(--root-sizes-12);
        --assets-input-code-sizes-radius: var(--root-radius-l-24);
        --assets-input-code-sizes-height-timer: var(--root-sizes-24);
    }
`
