import styled from "styled-components"

const StyledSchedule = styled.div`
    flex: 1;
    display: flex;
    padding: var(--assets-input-schedule-form-sizes-s-padding, 12px);
    flex-direction: column;
    align-items: flex-start;
    gap: var(--assets-input-schedule-form-sizes-s-gap, 16px);
    align-self: stretch;

    border-radius: var(--assets-input-schedule-form-sizes-s-radius, 24px);
    border: 1px solid var(--assets-input-schedule-form-colors-stroke, rgba(0, 0, 0, 0.06));
`

const StyledWeekendSwitch = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: var(--assets-input-schedule-weekend-sizes-s-gap-inner, 16px);
    align-self: stretch;
    font-size: 14px;
    line-height: 18px;
`

export { StyledWeekendSwitch, StyledSchedule }
