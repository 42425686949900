import React, { type FC, useEffect, useState } from "react"

import { useFormContext } from "react-hook-form"
import { FormFieldState, useFormField, type useFormFieldProps } from "shared/hook/useFormField"
import { Label, Requirement, RequirementStatus, type FormFieldProps } from "shared/ui"

import { SizeSvg } from "shared/assets/svg"
import { StyledError, StyledFormField, StyledParentInput } from "./styles"

export const FormField: FC<FormFieldProps> = (props) => {
    const {
        required = false,
        hasStatus = false,
        name,
        isSuccess = true,
        isInvalid = true,
        labelText,
        render,
        labelIcon,
        ...rest
    } = props
    const { getFieldState, formState } = useFormContext()
    const { isDirty, error, invalid } = getFieldState(name, formState)
    const [focused, setFocus] = useState<boolean>(false)
    const PropsState: useFormFieldProps = {
        valid: !invalid,
        filled: isDirty,
        focused,
        isOn: {
            [FormFieldState.BASIC]: true,
            [FormFieldState.FOCUSED]: true,
            [FormFieldState.SUCCESS]: isSuccess,
            [FormFieldState.INVALID]: isInvalid,
        },
    }
    const { LState, setPropsState } = useFormField(PropsState)

    const isStatus = hasStatus && (LState === FormFieldState.INVALID || LState === FormFieldState.SUCCESS)
    const isError = LState === FormFieldState.INVALID && error
    const status = LState === FormFieldState.SUCCESS ? RequirementStatus.SUCCESS : RequirementStatus.ERROR

    useEffect(() => {
        setPropsState(PropsState)
    }, [!invalid, focused, isDirty])

    const handleFocus = () => {
        setFocus(!focused)
    }

    return (
        <StyledFormField {...rest}>
            {labelText && (
                <Label state={LState} required={required}>
                    {labelText}
                    {labelIcon?.()}
                </Label>
            )}

            <StyledParentInput $state={LState}>
                {render(handleFocus, LState)}

                {isStatus && <Requirement status={status} size={SizeSvg.M}></Requirement>}
            </StyledParentInput>

            <StyledError>{error?.message}</StyledError>
        </StyledFormField>
    )
}
