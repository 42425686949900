import React, { type FC } from "react"
import { StyledGiftImageContainer, StyledGiftImage } from "./styles"
import img from "./img.png"
import { type GiftImageProps, GiftImageSize } from "./types"
export const GiftImage: FC<GiftImageProps> = (props) => {
    const { size = GiftImageSize.S } = props
    return (
        <StyledGiftImageContainer $size={size}>
            <StyledGiftImage src={img} alt={"gift"} />
        </StyledGiftImageContainer>
    )
}
