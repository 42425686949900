import { styled } from "styled-components"
import { getMediaVariable } from "shared/styles"

const StyledContactsCardHeader = styled.p<{
    $isModal: boolean
}>`
    color: var(--layout-text-colors-primary, #000);
    font-size: ${({ $isModal }) => ($isModal ? "24px" : "16px")};
    font-weight: 500;
    line-height: 120%;
    @media all and (min-width: ${getMediaVariable("laptop")}) {
        font-size: ${({ $isModal }) => ($isModal ? "24px" : "20px")};
    }
`

const StyledContactsCardContainer = styled.div<{
    $isModal: boolean
}>`
    overflow: auto;
    &::-webkit-scrollbar {
        display: none;
    }

    height: 100%;
    width: 100%;
    background-color: #fff;
    padding: 24px;
    display: flex;
    display: -webkit-flex;
    flex: auto;
    flex-direction: column;
    gap: 36px;
    justify-content: space-between;
    min-width: ${({ $isModal }) => (!$isModal ? "326px" : "100%")};
    max-width: ${({ $isModal }) => (!$isModal ? "350px" : "100%")};
    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
    border: ${({ $isModal }) =>
        !$isModal ? "1px solid var(--assets-card-common-colors-stroke, rgba(0, 0, 0, 0.04))" : "none"};
    @media all and (min-width: ${getMediaVariable("laptop")}) {
        min-width: ${({ $isModal }) => (!$isModal ? "339px" : "100%")};
    }
`

const StyledContactsCardBody = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
`

export { StyledContactsCardHeader, StyledContactsCardContainer, StyledContactsCardBody }
