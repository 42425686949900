import React, { type FC } from "react"
import { ProfileData, ProfileDataType } from "entities/viewer"
import { LinkSvg, useMediaSizeSvg, VkIconSvg } from "shared/assets/svg"
import { type LinkSectionProps } from "./types"

export const LinkSection: FC<LinkSectionProps> = ({ websiteUrl, vkSocialUrl }) => {
    const { fromMtoL } = useMediaSizeSvg()
    if (!websiteUrl && !vkSocialUrl) {
        return null
    }
    return (
        <>
            {websiteUrl && (
                <ProfileData
                    type={ProfileDataType.LINK}
                    target="_blank"
                    href={websiteUrl}
                    rel="noopener noreferrer"
                    caption={"Официальный сайт"}
                >
                    <LinkSvg size={fromMtoL} />
                    {`${websiteUrl.substring(0, 9)}...`}
                </ProfileData>
            )}
            {vkSocialUrl && (
                <ProfileData
                    target="_blank"
                    rel="noopener noreferrer"
                    href={vkSocialUrl}
                    type={ProfileDataType.LINK}
                    caption={"Страница ВКонтакте"}
                >
                    <VkIconSvg size={fromMtoL} />
                    {`${vkSocialUrl.split("https://vk.com/")[1]}`}
                </ProfileData>
            )}
        </>
    )
}
