import styled from "styled-components"

const StyledUserControl = styled.div`
    font-size: 16px;
    & > a {
        color: var(--assets-header-menu-personal-descriptor, #3e3aed);
        font-size: 14px;
    }
`

export { StyledUserControl }
