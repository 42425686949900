import { type PropsWithChildren } from "react"
import { type GoalStatusType } from "entities/viewer"

export enum SizeGoalTag {
    BIG = "big",
    SMALL = "small",
}
export interface OpportunityTagProps extends PropsWithChildren {
    typeCard: GoalStatusType
    size?: SizeGoalTag
}
