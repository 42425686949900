import { type HTMLAttributes, type ReactNode } from "react"

export enum TagSize {
    M = "m",
    S = "s",
}
export interface TagProps extends HTMLAttributes<HTMLDivElement> {
    icon?: () => ReactNode
    size?: TagSize
    bgColor?: string
    children: ReactNode
}
