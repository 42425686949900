import React, { type FC } from "react"
import { Group, GroupDirection, GroupSize } from "shared/ui"
import { FileUpload } from "shared/assets/svg"
import { StyledDocumentCard, StyledDocumentCardIcon, StyledDocumentCardName, StyledDocumentCardSize } from "./styles"
import { type DocumentCardProps } from "./types"

export const DocumentCard: FC<DocumentCardProps> = (props) => {
    const { size, name, ...other } = props
    const cutStr = (str: string) => {
        if (str.length > 28) {
            return str.substring(0, 25) + "..."
        }
        return str
    }
    return (
        <StyledDocumentCard {...other}>
            <Group size={GroupSize.S} direction={GroupDirection.ROW}>
                <StyledDocumentCardIcon>
                    <FileUpload />
                </StyledDocumentCardIcon>
                <StyledDocumentCardName>
                    {cutStr(name)}
                    <StyledDocumentCardSize>{size && (size / 1024 / 1024).toFixed(1)} мб</StyledDocumentCardSize>
                </StyledDocumentCardName>
            </Group>
        </StyledDocumentCard>
    )
}
