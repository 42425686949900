import { type FC } from "react"
import * as ReactDOMServer from "react-dom/server"
const PatternMain: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="1124" height="1124" viewBox="0 0 487 487" fill="none">
            <path
                d="M243.38 0C310.15 0 371.39 27.69 415.15 71.45C458.91 115.21 486.77 176.28 486.77 243.05C486.77 275.84 480.24 308.15 468.42 336.66C456.16 366.21 438.22 393.86 415.94 416.13C372.18 459.89 310.15 486.95 243.38 486.95C176.61 486.95 114.58 459.89 70.82 416.13C48.55 393.86 30.6 366.21 18.34 336.66C6.52 308.15 0 275.84 0 243.05C0 176.28 27.86 115.2 71.61 71.44C115.36 27.68 176.61 0 243.38 0Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 11.8906C306.64 11.8906 365.09 38.4706 406.55 79.9206C448.01 121.371 474.83 179.581 474.83 242.841C474.83 273.901 468.65 305.091 457.45 332.101C445.84 360.101 428.84 386.871 407.74 407.971C366.29 449.421 306.64 475.071 243.39 475.071C180.14 475.071 120.49 449.431 79.0402 407.971C57.9402 386.871 40.9402 360.101 29.3302 332.101C18.1302 305.091 11.9502 273.901 11.9502 242.841C11.9502 179.581 38.7802 121.381 80.2302 79.9206C121.68 38.4606 180.12 11.8906 243.38 11.8906Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 23.7812C303.12 23.7812 358.79 49.2413 397.94 88.4013C437.09 127.561 462.89 182.891 462.89 242.631C462.89 271.961 457.05 302.041 446.47 327.551C435.5 353.991 419.45 379.891 399.52 399.821C360.37 438.971 303.12 463.191 243.37 463.191C183.62 463.191 126.39 438.961 87.2401 399.811C67.3101 379.881 51.2501 353.981 40.2901 327.541C29.7101 302.031 23.8701 271.961 23.8701 242.621C23.8701 182.881 49.6701 127.541 88.8201 88.3913C127.97 49.2412 183.64 23.7812 243.38 23.7812Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 35.6602C299.611 35.6602 352.491 60.0102 389.341 96.8602C426.191 133.71 450.961 186.18 450.961 242.4C450.961 270.01 445.471 298.96 435.511 322.97C425.191 347.86 410.081 372.88 391.321 391.64C354.471 428.49 299.611 451.28 243.381 451.28C187.151 451.28 132.291 428.5 95.4408 391.65C76.6808 372.89 61.5708 347.87 51.2508 322.98C41.3008 298.97 35.8008 270.02 35.8008 242.41C35.8008 186.18 60.5708 133.71 97.4208 96.8702C134.271 60.0302 187.151 35.6602 243.381 35.6602Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 47.5508C296.1 47.5508 346.19 70.7908 380.74 105.341C415.29 139.891 439.02 189.481 439.02 242.201C439.02 268.081 433.87 295.921 424.54 318.421C414.86 341.751 400.7 365.901 383.11 383.491C348.56 418.041 296.1 439.401 243.38 439.401C190.66 439.401 138.2 418.031 103.65 383.491C86.0702 365.911 71.9002 341.761 62.2202 318.421C52.8902 295.911 47.7402 268.081 47.7402 242.201C47.7402 189.481 71.4802 139.891 106.02 105.341C140.56 70.7908 190.67 47.5508 243.38 47.5508Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 59.4395C292.581 59.4395 339.891 81.5695 372.131 113.809C404.371 146.049 427.081 192.779 427.081 241.979C427.081 266.139 422.271 292.849 413.561 313.859C404.531 335.639 391.311 358.909 374.891 375.319C342.651 407.559 292.571 427.509 243.371 427.509C194.171 427.509 144.101 407.569 111.861 375.319C95.4507 358.909 82.2207 335.629 73.1907 313.859C64.4807 292.849 59.6807 266.139 59.6807 241.979C59.6807 192.779 82.3907 146.049 114.631 113.809C146.871 81.5695 194.181 59.4395 243.381 59.4395Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 71.3301C289.071 71.3301 333.591 92.3401 363.531 122.28C393.471 152.22 415.151 196.08 415.151 241.76C415.151 264.19 410.691 289.78 402.601 309.29C394.211 329.51 381.931 351.91 366.701 367.15C336.761 397.09 289.071 415.61 243.391 415.61C197.711 415.61 150.021 397.09 120.081 367.15C104.841 351.91 92.5609 329.51 84.1809 309.29C76.0909 289.78 71.6309 264.19 71.6309 241.76C71.6309 196.07 93.3109 152.22 123.251 122.28C153.191 92.3401 197.691 71.3301 243.381 71.3301Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 83.2207C285.551 83.2207 327.291 103.121 354.931 130.761C382.571 158.401 403.221 199.381 403.221 241.561C403.221 262.271 399.101 286.741 391.631 304.741C383.891 323.411 372.551 344.931 358.491 359.001C330.851 386.641 285.561 403.731 243.391 403.731C201.221 403.731 155.921 386.641 128.291 359.001C114.221 344.931 102.891 323.411 95.1505 304.741C87.6805 286.731 83.5605 262.261 83.5605 241.561C83.5605 199.391 104.211 158.401 131.851 130.761C159.491 103.121 201.211 83.2207 243.381 83.2207Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 95.1113C282.04 95.1113 320.99 113.901 346.32 139.231C371.65 164.561 391.28 202.681 391.28 241.341C391.28 260.321 387.5 283.671 380.66 300.181C373.56 317.291 363.17 337.941 350.28 350.841C324.95 376.171 282.04 391.841 243.39 391.841C204.74 391.841 161.83 376.171 136.5 350.841C123.59 337.941 113.2 317.291 106.11 300.181C99.2702 283.671 95.4902 260.321 95.4902 241.341C95.4902 202.681 115.11 164.561 140.45 139.231C165.79 113.901 204.72 95.1113 243.38 95.1113Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 107C278.52 107 314.69 124.68 337.72 147.71C360.75 170.74 379.34 205.99 379.34 241.13C379.34 258.39 375.91 280.62 369.68 295.62C363.23 311.17 353.78 330.95 342.06 342.67C319.03 365.7 278.52 379.95 243.37 379.95C208.22 379.95 167.71 365.71 144.68 342.67C132.96 330.95 123.51 311.17 117.06 295.62C110.84 280.61 107.4 258.38 107.4 241.13C107.4 205.99 125.99 170.74 149.02 147.71C172.05 124.68 208.24 107 243.38 107Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 118.891C275.011 118.891 308.391 135.451 329.111 156.181C349.831 176.911 367.401 209.291 367.401 240.921C367.401 256.451 364.311 277.561 358.711 291.071C352.901 305.071 344.401 323.971 333.851 334.521C313.121 355.251 275.001 368.071 243.371 368.071C211.741 368.071 173.621 355.251 152.891 334.521C142.341 323.971 133.841 305.071 128.031 291.071C122.431 277.571 119.341 256.451 119.341 240.921C119.341 209.291 136.901 176.911 157.631 156.181C178.361 135.451 211.751 118.891 243.381 118.891Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 130.781C271.49 130.781 302.09 146.231 320.51 164.661C338.93 183.091 355.47 212.591 355.47 240.711C355.47 254.511 352.72 274.511 347.74 286.511C342.58 298.951 335.02 316.981 325.65 326.361C307.23 344.781 271.5 356.181 243.38 356.181C215.26 356.181 179.54 344.781 161.11 326.361C151.73 316.981 144.18 298.951 139.02 286.511C134.04 274.511 131.29 254.511 131.29 240.711C131.29 212.601 147.82 183.091 166.25 164.661C184.68 146.231 215.27 130.781 243.38 130.781Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 142.67C267.98 142.67 295.79 157.01 311.91 173.13C328.03 189.25 343.54 215.89 343.54 240.49C343.54 252.57 341.14 271.44 336.78 281.94C332.26 292.83 325.65 309.98 317.45 318.19C301.33 334.31 267.99 344.28 243.39 344.28C218.79 344.28 185.45 334.31 169.33 318.19C161.12 309.98 154.51 292.83 150 281.94C145.64 271.44 143.24 252.56 143.24 240.49C143.24 215.89 158.75 189.25 174.87 173.13C190.99 157.01 218.78 142.67 243.38 142.67Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 154.561C264.47 154.561 289.48 167.791 303.3 181.611C317.12 195.431 331.59 219.201 331.59 240.281C331.59 250.631 329.53 268.381 325.8 277.391C321.93 286.721 316.26 303.001 309.23 310.031C295.41 323.851 264.46 332.401 243.38 332.401C222.3 332.401 191.35 323.851 177.53 310.031C170.5 303.001 164.83 286.721 160.96 277.391C157.23 268.391 155.17 250.641 155.17 240.281C155.17 219.191 169.65 195.421 183.46 181.611C197.27 167.801 222.29 154.561 243.38 154.561Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 166.451C260.951 166.451 283.181 178.571 294.701 190.081C306.221 201.601 319.661 222.501 319.661 240.071C319.661 248.701 317.941 265.331 314.831 272.831C311.601 280.611 306.881 296.011 301.021 301.871C289.501 313.391 260.951 320.511 243.381 320.511C225.811 320.511 197.251 313.391 185.741 301.871C179.881 296.011 175.161 280.601 171.931 272.831C168.821 265.331 167.101 248.701 167.101 240.071C167.101 222.501 180.551 201.601 192.061 190.081C203.581 178.561 225.811 166.451 243.381 166.451Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 178.34C257.44 178.34 276.88 189.34 286.1 198.56C295.32 207.78 307.73 225.8 307.73 239.86C307.73 246.76 306.36 262.27 303.87 268.28C301.29 274.5 297.51 289.03 292.82 293.72C283.61 302.93 257.44 308.63 243.38 308.63C229.32 308.63 203.16 302.93 193.94 293.72C189.25 289.03 185.47 274.5 182.89 268.28C180.4 262.28 179.03 246.77 179.03 239.86C179.03 225.8 191.45 207.77 200.66 198.56C209.87 189.35 229.32 178.34 243.38 178.34Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 190.221C253.92 190.221 270.58 200.111 277.49 207.021C284.4 213.931 295.79 229.091 295.79 239.631C295.79 244.811 294.76 259.201 292.89 263.701C290.95 268.371 288.12 282.021 284.6 285.541C277.69 292.451 253.92 296.721 243.37 296.721C232.83 296.721 209.05 292.451 202.14 285.541C198.62 282.021 195.79 268.371 193.85 263.701C191.98 259.201 190.95 244.811 190.95 239.631C190.95 229.091 202.34 213.931 209.25 207.021C216.18 200.111 232.84 190.221 243.38 190.221Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 202.111C250.41 202.111 264.28 210.891 268.89 215.491C273.5 220.101 283.86 232.391 283.86 239.421C283.86 242.871 283.17 256.141 281.93 259.141C280.64 262.251 278.75 275.031 276.41 277.381C271.8 281.991 250.42 284.841 243.39 284.841C236.36 284.841 214.98 281.991 210.37 277.381C208.03 275.041 206.14 262.261 204.85 259.141C203.61 256.141 202.92 242.871 202.92 239.421C202.92 232.391 213.28 220.101 217.89 215.491C222.48 210.891 236.35 202.111 243.38 202.111Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 214C246.89 214 257.98 221.67 260.28 223.97C262.58 226.27 271.91 235.7 271.91 239.21C271.91 240.94 271.57 253.09 270.94 254.59C270.29 256.15 269.35 268.05 268.18 269.22C265.88 271.52 246.88 272.95 243.37 272.95C239.86 272.95 220.86 271.53 218.56 269.22C217.39 268.05 216.44 256.14 215.8 254.59C215.18 253.09 214.83 240.94 214.83 239.21C214.83 235.7 224.16 226.27 226.46 223.97C228.76 221.67 239.87 214 243.38 214Z"
                fill="#FF8100"
            />
            <path
                d="M259.98 261.061H243.38H226.78V250.031V239.001L243.38 225.891L259.98 239.001V250.031V261.061Z"
                fill="#8E00EA"
            />
        </svg>
    )
}
const PatternMainTablet: FC = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="850" height="850" viewBox="0 0 487 487" fill="none">
            <path
                d="M243.38 0C310.15 0 371.39 27.69 415.15 71.45C458.91 115.21 486.77 176.28 486.77 243.05C486.77 275.84 480.24 308.15 468.42 336.66C456.16 366.21 438.22 393.86 415.94 416.13C372.18 459.89 310.15 486.95 243.38 486.95C176.61 486.95 114.58 459.89 70.82 416.13C48.55 393.86 30.6 366.21 18.34 336.66C6.52 308.15 0 275.84 0 243.05C0 176.28 27.86 115.2 71.61 71.44C115.36 27.68 176.61 0 243.38 0Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 11.8906C306.64 11.8906 365.09 38.4706 406.55 79.9206C448.01 121.371 474.83 179.581 474.83 242.841C474.83 273.901 468.65 305.091 457.45 332.101C445.84 360.101 428.84 386.871 407.74 407.971C366.29 449.421 306.64 475.071 243.39 475.071C180.14 475.071 120.49 449.431 79.0402 407.971C57.9402 386.871 40.9402 360.101 29.3302 332.101C18.1302 305.091 11.9502 273.901 11.9502 242.841C11.9502 179.581 38.7802 121.381 80.2302 79.9206C121.68 38.4606 180.12 11.8906 243.38 11.8906Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 23.7812C303.12 23.7812 358.79 49.2413 397.94 88.4013C437.09 127.561 462.89 182.891 462.89 242.631C462.89 271.961 457.05 302.041 446.47 327.551C435.5 353.991 419.45 379.891 399.52 399.821C360.37 438.971 303.12 463.191 243.37 463.191C183.62 463.191 126.39 438.961 87.2401 399.811C67.3101 379.881 51.2501 353.981 40.2901 327.541C29.7101 302.031 23.8701 271.961 23.8701 242.621C23.8701 182.881 49.6701 127.541 88.8201 88.3913C127.97 49.2412 183.64 23.7812 243.38 23.7812Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 35.6602C299.611 35.6602 352.491 60.0102 389.341 96.8602C426.191 133.71 450.961 186.18 450.961 242.4C450.961 270.01 445.471 298.96 435.511 322.97C425.191 347.86 410.081 372.88 391.321 391.64C354.471 428.49 299.611 451.28 243.381 451.28C187.151 451.28 132.291 428.5 95.4408 391.65C76.6808 372.89 61.5708 347.87 51.2508 322.98C41.3008 298.97 35.8008 270.02 35.8008 242.41C35.8008 186.18 60.5708 133.71 97.4208 96.8702C134.271 60.0302 187.151 35.6602 243.381 35.6602Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 47.5508C296.1 47.5508 346.19 70.7908 380.74 105.341C415.29 139.891 439.02 189.481 439.02 242.201C439.02 268.081 433.87 295.921 424.54 318.421C414.86 341.751 400.7 365.901 383.11 383.491C348.56 418.041 296.1 439.401 243.38 439.401C190.66 439.401 138.2 418.031 103.65 383.491C86.0702 365.911 71.9002 341.761 62.2202 318.421C52.8902 295.911 47.7402 268.081 47.7402 242.201C47.7402 189.481 71.4802 139.891 106.02 105.341C140.56 70.7908 190.67 47.5508 243.38 47.5508Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 59.4395C292.581 59.4395 339.891 81.5695 372.131 113.809C404.371 146.049 427.081 192.779 427.081 241.979C427.081 266.139 422.271 292.849 413.561 313.859C404.531 335.639 391.311 358.909 374.891 375.319C342.651 407.559 292.571 427.509 243.371 427.509C194.171 427.509 144.101 407.569 111.861 375.319C95.4507 358.909 82.2207 335.629 73.1907 313.859C64.4807 292.849 59.6807 266.139 59.6807 241.979C59.6807 192.779 82.3907 146.049 114.631 113.809C146.871 81.5695 194.181 59.4395 243.381 59.4395Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 71.3301C289.071 71.3301 333.591 92.3401 363.531 122.28C393.471 152.22 415.151 196.08 415.151 241.76C415.151 264.19 410.691 289.78 402.601 309.29C394.211 329.51 381.931 351.91 366.701 367.15C336.761 397.09 289.071 415.61 243.391 415.61C197.711 415.61 150.021 397.09 120.081 367.15C104.841 351.91 92.5609 329.51 84.1809 309.29C76.0909 289.78 71.6309 264.19 71.6309 241.76C71.6309 196.07 93.3109 152.22 123.251 122.28C153.191 92.3401 197.691 71.3301 243.381 71.3301Z"
                fill="#FF8100"
            />
            <path
                d="M243.381 83.2207C285.551 83.2207 327.291 103.121 354.931 130.761C382.571 158.401 403.221 199.381 403.221 241.561C403.221 262.271 399.101 286.741 391.631 304.741C383.891 323.411 372.551 344.931 358.491 359.001C330.851 386.641 285.561 403.731 243.391 403.731C201.221 403.731 155.921 386.641 128.291 359.001C114.221 344.931 102.891 323.411 95.1505 304.741C87.6805 286.731 83.5605 262.261 83.5605 241.561C83.5605 199.391 104.211 158.401 131.851 130.761C159.491 103.121 201.211 83.2207 243.381 83.2207Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 95.1113C282.04 95.1113 320.99 113.901 346.32 139.231C371.65 164.561 391.28 202.681 391.28 241.341C391.28 260.321 387.5 283.671 380.66 300.181C373.56 317.291 363.17 337.941 350.28 350.841C324.95 376.171 282.04 391.841 243.39 391.841C204.74 391.841 161.83 376.171 136.5 350.841C123.59 337.941 113.2 317.291 106.11 300.181C99.2702 283.671 95.4902 260.321 95.4902 241.341C95.4902 202.681 115.11 164.561 140.45 139.231C165.79 113.901 204.72 95.1113 243.38 95.1113Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 107C278.52 107 314.69 124.68 337.72 147.71C360.75 170.74 379.34 205.99 379.34 241.13C379.34 258.39 375.91 280.62 369.68 295.62C363.23 311.17 353.78 330.95 342.06 342.67C319.03 365.7 278.52 379.95 243.37 379.95C208.22 379.95 167.71 365.71 144.68 342.67C132.96 330.95 123.51 311.17 117.06 295.62C110.84 280.61 107.4 258.38 107.4 241.13C107.4 205.99 125.99 170.74 149.02 147.71C172.05 124.68 208.24 107 243.38 107Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 118.891C275.011 118.891 308.391 135.451 329.111 156.181C349.831 176.911 367.401 209.291 367.401 240.921C367.401 256.451 364.311 277.561 358.711 291.071C352.901 305.071 344.401 323.971 333.851 334.521C313.121 355.251 275.001 368.071 243.371 368.071C211.741 368.071 173.621 355.251 152.891 334.521C142.341 323.971 133.841 305.071 128.031 291.071C122.431 277.571 119.341 256.451 119.341 240.921C119.341 209.291 136.901 176.911 157.631 156.181C178.361 135.451 211.751 118.891 243.381 118.891Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 130.781C271.49 130.781 302.09 146.231 320.51 164.661C338.93 183.091 355.47 212.591 355.47 240.711C355.47 254.511 352.72 274.511 347.74 286.511C342.58 298.951 335.02 316.981 325.65 326.361C307.23 344.781 271.5 356.181 243.38 356.181C215.26 356.181 179.54 344.781 161.11 326.361C151.73 316.981 144.18 298.951 139.02 286.511C134.04 274.511 131.29 254.511 131.29 240.711C131.29 212.601 147.82 183.091 166.25 164.661C184.68 146.231 215.27 130.781 243.38 130.781Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 142.67C267.98 142.67 295.79 157.01 311.91 173.13C328.03 189.25 343.54 215.89 343.54 240.49C343.54 252.57 341.14 271.44 336.78 281.94C332.26 292.83 325.65 309.98 317.45 318.19C301.33 334.31 267.99 344.28 243.39 344.28C218.79 344.28 185.45 334.31 169.33 318.19C161.12 309.98 154.51 292.83 150 281.94C145.64 271.44 143.24 252.56 143.24 240.49C143.24 215.89 158.75 189.25 174.87 173.13C190.99 157.01 218.78 142.67 243.38 142.67Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 154.561C264.47 154.561 289.48 167.791 303.3 181.611C317.12 195.431 331.59 219.201 331.59 240.281C331.59 250.631 329.53 268.381 325.8 277.391C321.93 286.721 316.26 303.001 309.23 310.031C295.41 323.851 264.46 332.401 243.38 332.401C222.3 332.401 191.35 323.851 177.53 310.031C170.5 303.001 164.83 286.721 160.96 277.391C157.23 268.391 155.17 250.641 155.17 240.281C155.17 219.191 169.65 195.421 183.46 181.611C197.27 167.801 222.29 154.561 243.38 154.561Z"
                fill="#8E00EA"
            />
            <path
                d="M243.381 166.451C260.951 166.451 283.181 178.571 294.701 190.081C306.221 201.601 319.661 222.501 319.661 240.071C319.661 248.701 317.941 265.331 314.831 272.831C311.601 280.611 306.881 296.011 301.021 301.871C289.501 313.391 260.951 320.511 243.381 320.511C225.811 320.511 197.251 313.391 185.741 301.871C179.881 296.011 175.161 280.601 171.931 272.831C168.821 265.331 167.101 248.701 167.101 240.071C167.101 222.501 180.551 201.601 192.061 190.081C203.581 178.561 225.811 166.451 243.381 166.451Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 178.34C257.44 178.34 276.88 189.34 286.1 198.56C295.32 207.78 307.73 225.8 307.73 239.86C307.73 246.76 306.36 262.27 303.87 268.28C301.29 274.5 297.51 289.03 292.82 293.72C283.61 302.93 257.44 308.63 243.38 308.63C229.32 308.63 203.16 302.93 193.94 293.72C189.25 289.03 185.47 274.5 182.89 268.28C180.4 262.28 179.03 246.77 179.03 239.86C179.03 225.8 191.45 207.77 200.66 198.56C209.87 189.35 229.32 178.34 243.38 178.34Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 190.221C253.92 190.221 270.58 200.111 277.49 207.021C284.4 213.931 295.79 229.091 295.79 239.631C295.79 244.811 294.76 259.201 292.89 263.701C290.95 268.371 288.12 282.021 284.6 285.541C277.69 292.451 253.92 296.721 243.37 296.721C232.83 296.721 209.05 292.451 202.14 285.541C198.62 282.021 195.79 268.371 193.85 263.701C191.98 259.201 190.95 244.811 190.95 239.631C190.95 229.091 202.34 213.931 209.25 207.021C216.18 200.111 232.84 190.221 243.38 190.221Z"
                fill="#FF8100"
            />
            <path
                d="M243.38 202.111C250.41 202.111 264.28 210.891 268.89 215.491C273.5 220.101 283.86 232.391 283.86 239.421C283.86 242.871 283.17 256.141 281.93 259.141C280.64 262.251 278.75 275.031 276.41 277.381C271.8 281.991 250.42 284.841 243.39 284.841C236.36 284.841 214.98 281.991 210.37 277.381C208.03 275.041 206.14 262.261 204.85 259.141C203.61 256.141 202.92 242.871 202.92 239.421C202.92 232.391 213.28 220.101 217.89 215.491C222.48 210.891 236.35 202.111 243.38 202.111Z"
                fill="#8E00EA"
            />
            <path
                d="M243.38 214C246.89 214 257.98 221.67 260.28 223.97C262.58 226.27 271.91 235.7 271.91 239.21C271.91 240.94 271.57 253.09 270.94 254.59C270.29 256.15 269.35 268.05 268.18 269.22C265.88 271.52 246.88 272.95 243.37 272.95C239.86 272.95 220.86 271.53 218.56 269.22C217.39 268.05 216.44 256.14 215.8 254.59C215.18 253.09 214.83 240.94 214.83 239.21C214.83 235.7 224.16 226.27 226.46 223.97C228.76 221.67 239.87 214 243.38 214Z"
                fill="#FF8100"
            />
            <path
                d="M259.98 261.061H243.38H226.78V250.031V239.001L243.38 225.891L259.98 239.001V250.031V261.061Z"
                fill="#8E00EA"
            />
        </svg>
    )
}

export const patternMainStatic = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PatternMain />))
export const patternMainStaticTablet = encodeURIComponent(ReactDOMServer.renderToStaticMarkup(<PatternMainTablet />))
