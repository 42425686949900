import { type FC } from "react"
import { type LabelProps } from "./types"
import { StyledLabelForm } from "./styles"

export const Label: FC<LabelProps> = (props) => {
    const { state, required = true, children } = props

    return (
        <StyledLabelForm $state={state}>
            {children}
            {required && <span>*</span>}
        </StyledLabelForm>
    )
}
