import { type HTMLAttributes, type ReactNode } from "react"
import { type SizeSvg } from "shared/assets/svg"
export enum ProfileDataSize {
    L = "l",
    S = "s",
}

export enum ProfileDataType {
    TEXT = "text",
    TAG = "tag",
    LINK = "link",
    ARTICLE = "article",
}
export interface ProfileDataProps extends HTMLAttributes<HTMLAnchorElement> {
    size?: ProfileDataSize
    caption: string
    children: ReactNode
    type?: ProfileDataType
    icon?: (size: SizeSvg) => ReactNode
    href?: string
    target?: string
    to?: string
}
