import { type FC } from "react"
import { SizeSvg } from "../../types"
import { type LinkSvgProps } from "./types"

export const LinkSvg: FC<LinkSvgProps> = (props) => {
    const { size = SizeSvg.M, ...rest } = props

    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
            <path
                d="M21.5 2.5H16V3.5H19.7929L11.6465 11.6464L12.3536 12.3536L20.5 4.20711V8H21.5V2.5Z"
                fill="#3E3AED"
            />
            <path d="M13.5556 4.5H4.5V19.5H19.5V10.4444H18.5V18.5H5.5V5.5H13.5556V4.5Z" fill="#3E3AED" />
        </svg>
    )
}
