import styled from "styled-components"
import { getMediaVariable } from "shared/styles"
const StyledGoalList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-self: stretch;
    @media all and (min-width: ${getMediaVariable("desktop")}) {
        flex-direction: row;
    }
    @media all and (max-width: ${getMediaVariable("desktop")}) {
        gap: var(--assets-card-custom-goal-sizes-s-grid-gap, 16px);
    }
`

const StyledCategoryTitle = styled.div`
    color: var(--root-colors-main-grey-dark, rgba(0, 0, 0, 0.48));
    margin-bottom: var(--assets-card-custom-goal-sizes-s-title-margin, 4px);
    font-size: clamp(1rem, 0.878rem + 0.536vw, 1.429rem);
    @media all and (min-width: ${getMediaVariable("desktop")}) {
        margin-bottom: var(--assets-card-custom-goal-sizes-l-title-margin, 16px);
    }
`

export { StyledGoalList, StyledCategoryTitle }
