import { mainApi, BASE_URL } from "features/api"
import { type RequestConfig } from "shared/api"
import { type CalendarItem } from "./types"
const BASE_URL_OPPORTUNITIES = `${BASE_URL.USER}/operator`

const calendarApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getCalendarEvents: builder.query<CalendarItem[], unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: BASE_URL_OPPORTUNITIES + "/events",
                method: "GET",
            }),
        }),
    }),
})

export const { useGetCalendarEventsQuery } = calendarApi
