import { mainApi, BASE_URL } from "features/api"
import { axiosInstanceUser, type RequestConfig } from "shared/api"
import {
    type IAuthDataByEmail,
    type IAuthDataByPhone,
    type RegistrationByEmail,
    type RegistrationByPhone,
    type AuthParamsByVk,
    type AuthResponse,
    type RegistrationResponse,
} from "./types"

const base_url_auth = `${BASE_URL.USER}/auth`
export const authEndpoints = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getAccessTokenByEmail: builder.mutation<AuthResponse, IAuthDataByEmail>({
            query: (credentials: IAuthDataByEmail): RequestConfig<IAuthDataByEmail, unknown> => ({
                url: base_url_auth + "/login/email",
                method: "POST",
                body: credentials,
            }),
            transformResponse: (res: AuthResponse) => {
                localStorage.removeItem("access_token")
                localStorage.setItem("access_token", res.access_token)
                axiosInstanceUser.defaults.headers["Authorization"] = `Bearer ${res.access_token}`
                return res
            },
            invalidatesTags: ["Profile"],
        }),
        getAccessTokenByPhone: builder.query<AuthResponse, IAuthDataByPhone>({
            query: (credentials: IAuthDataByPhone): RequestConfig<IAuthDataByPhone, unknown> => ({
                url: base_url_auth + "login/phone",
                method: "POST",
                body: credentials,
            }),
        }),
        getAuthByVk: builder.query<AuthResponse, AuthParamsByVk>({
            query: ({ token, uuid }: AuthParamsByVk): RequestConfig<unknown, AuthParamsByVk> => ({
                url: base_url_auth + "/vk",
                method: "GET",
                params: { token, uuid },
            }),
            transformResponse: (res: AuthResponse) => {
                localStorage.removeItem("access_token")
                localStorage.setItem("access_token", res.access_token)
                axiosInstanceUser.defaults.headers["Authorization"] = `Bearer ${res.access_token}`
                return res
            },
        }),
        getRegisterByEmail: builder.query<RegistrationResponse, RegistrationByEmail>({
            query: (credentials: RegistrationByEmail): RequestConfig<RegistrationByEmail, unknown> => ({
                url: base_url_auth + "/register/email",
                method: "POST",
                body: credentials,
            }),
        }),
        getRegisterByPhone: builder.query<RegistrationResponse, RegistrationByPhone>({
            query: (credentials: RegistrationByPhone): RequestConfig<RegistrationByPhone, unknown> => ({
                url: base_url_auth + "/register/phone",
                method: "POST",
                body: credentials,
            }),
        }),
    }),
})

export const {
    useGetAccessTokenByEmailMutation,
    useLazyGetAccessTokenByPhoneQuery,
    useLazyGetRegisterByEmailQuery,
    useLazyGetRegisterByPhoneQuery,
    useGetAuthByVkQuery,
} = authEndpoints
