import styled from "styled-components"

const StyledInput = styled.input<{
    $isClean?: boolean
}>`
    color: var(--assets-input-colors-text);
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    &::-webkit-calendar-picker-indicator {
        display: none;
    }

    &::-moz-placeholder {
        color: var(--assets-input-colors-placeholder, rgba(0, 0, 0, 0.36));
    }

    &::-webkit-input-placeholder {
        color: var(--assets-input-colors-placeholder, rgba(0, 0, 0, 0.36));
    }

    &::-webkit-datetime-edit-hour-field {
        letter-spacing: 0;
        padding: 0;
    }
    &::-webkit-datetime-edit-minute-field {
        letter-spacing: 0;
        padding: 0;
    }
`

const StyledInputWrapper = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    gap: 8px;
`

export { StyledInput, StyledInputWrapper }
