import process from "process"
import React, { type FC } from "react"
import { useGetAuthByVkQuery } from "features/user"
import { Button, ButtonType, useMediaSizeButton } from "shared/ui"
import { VkIconSvg } from "shared/assets/svg"
import { useSetExternalScript } from "shared/hook"
import { useAccessTokenRedirect } from "../../lib"
import { useGetVkFunctions, useInitVkConfig } from "./lib"
import { type AuthVkButtonProps } from "./types"

export const AuthVkButton: FC<AuthVkButtonProps> = (props) => {
    const { updateToken } = props
    const { fromMtoL } = useMediaSizeButton()
    useSetExternalScript("https://unpkg.com/@vkontakte/superappkit@1.57.0/dist/index-umd.js")
    const { Config, Connect } = useGetVkFunctions()
    const appId = parseInt(process.env["REACT_APP_API_VK_ID"] as string)
    const host = process.env["REACT_APP_HOST"] as string
    useInitVkConfig(appId, Config)
    const redirectAuthHandler = () => {
        if (Connect) {
            Connect.redirectAuth({
                url: `${host}/auth`,
                source: host,
            })
        }
    }
    const payload = new URLSearchParams(window.location.search)
    const { token, uuid } = JSON.parse(payload.get("payload") as string) ?? ""
    const { data } = useGetAuthByVkQuery({ token, uuid })
    useAccessTokenRedirect(updateToken, data)

    return (
        <>
            <Button
                onClick={() => {
                    redirectAuthHandler()
                    updateToken()
                }}
                size={fromMtoL}
                typeUI={ButtonType.AUTH}
                svgColor={"#0077FF"}
                leftIcon={() => <VkIconSvg />}
            >
                Войти с VK ID
            </Button>
        </>
    )
}
