export type Reducer<State, Action> = (state: State, action: Action) => State

export enum ActionKind {
    TOGGLE_DAY = "TOGGLE_DAY",
}

export interface Payload {
    dayName: string
    arrayNumber: number
}

export type Action = {
    type: ActionKind
    payload: Payload
}

export enum days {
    MONDAY = "Пн",
    TUESDAY = "Вт",
    WEDNESDAY = "Ср",
    THURSDAY = "Чт",
    FRIDAY = "Пт",
    SATURDAY = "Сб",
    SUNDAY = "Вс",
}

interface Day {
    wasSelected: boolean
    arrayNumber: number | null
}

export type State = Record<days, Day>
