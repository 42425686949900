import React, { type FC } from "react"
import { CrownSvg, DividerSvg, SizeSvg } from "shared/assets/svg"
import { StyledRank, StyledRankDescription, StyledRatingPlate } from "./styles"
import { type RatingPlateProps, RatingPlateSize, RatingPlateType } from "./types"

export const RatingPlate: FC<RatingPlateProps> = (props) => {
    const { type, size = RatingPlateSize.S, rating, points, name } = props
    const dataSizeCondition = size === RatingPlateSize.L ? SizeSvg.M : SizeSvg.XS

    return (
        <StyledRatingPlate $size={size} $type={type}>
            <StyledRank $size={size}>
                {type === RatingPlateType.LEADER && <CrownSvg size={dataSizeCondition} />}
                <div>{rating}</div>
            </StyledRank>
            <DividerSvg strokeOpacity={type === RatingPlateType.LEADER || type === RatingPlateType.USER ? 1 : 0.06} />
            <StyledRankDescription $type={type}>
                <div>{name}</div>
                <div>{points} балла</div>
            </StyledRankDescription>
        </StyledRatingPlate>
    )
}
