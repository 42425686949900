import { type UseFormProps } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

import { type IEditOrganization, type IGetOrganizationUser } from "features/viewer"
import { EditOrganizationSchema } from "./EditOrganizationSchema"

type responseOrg = Omit<IEditOrganization, "file">
export const EditOrganizationConfig = (
    defaultValue: IGetOrganizationUser | { examplePoaUrl: string } | undefined
): UseFormProps<IEditOrganization> => {
    const config: UseFormProps<IEditOrganization> = {
        defaultValues: {},
        mode: "all",
        criteriaMode: "all",
        resolver: yupResolver(EditOrganizationSchema),
    }
    if (defaultValue && "examplePoaUrl" in defaultValue) {
        return config
    }
    if (defaultValue) {
        const { taxId, title } = defaultValue
        config.values = {
            title,
            taxId,
        }

        return config
    }
    return config
}
