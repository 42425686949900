export enum RatingPlateType {
    LEADER = "leader",
    GET_PRESENT_USERS = "get-present-users",
    USER = "user",
}

export enum RatingPlateSize {
    L = "l",
    S = "s",
}

export interface RatingPlateProps {
    type?: RatingPlateType
    size?: RatingPlateSize
    name: string
    points: number
    rating: number
}
