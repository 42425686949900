import { css } from "styled-components"
import { FONT_STOLZL } from "./const"

export const fonts = css`
    @font-face {
        font-family: ${FONT_STOLZL.fontFamily};
        src: url(${FONT_STOLZL.types.thin});
        font-weight: 100;
    }

    @font-face {
        font-family: ${FONT_STOLZL.fontFamily};
        src: url(${FONT_STOLZL.types.light});
        font-weight: 300;
    }

    @font-face {
        font-family: ${FONT_STOLZL.fontFamily};
        src: url(${FONT_STOLZL.types.regular});
        font-weight: 400;
    }

    @font-face {
        font-family: ${FONT_STOLZL.fontFamily};
        src: url(${FONT_STOLZL.types.medium});
        font-weight: 500;
    }

    @font-face {
        font-family: ${FONT_STOLZL.fontFamily};
        src: url(${FONT_STOLZL.types.bold});
        font-weight: 700;
    }
`

export * from "./const"
