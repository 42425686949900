import React from "react"
import { type ButtonProps, ButtonSize, ButtonType } from "./types"
import { StyledButton } from "./styles"

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>((props, ref) => {
    const {
        leftIcon,
        rightIcon,
        typeUI = ButtonType.PRIMARY,
        size = ButtonSize.L,
        onlyIcon = false,
        hover = true,
        children,
        svgColor = null,
        type = "button",
        form,
        ...other
    } = props
    return (
        <StyledButton
            form={form}
            type={type}
            $onlyIcon={onlyIcon}
            $svgColor={svgColor}
            $type={typeUI}
            $size={size}
            $hover={hover}
            disabled={typeUI === ButtonType.DISABLED}
            {...other}
            ref={ref}
        >
            {leftIcon && leftIcon()}
            {children}
            {rightIcon && rightIcon()}
        </StyledButton>
    )
})
