import { type FC, useEffect, useMemo, useState } from "react"
import { createPortal } from "react-dom"
import { Clusterer, Placemark, useYMaps, YMaps } from "@pbe/react-yandex-maps"
import { useParams } from "react-router-dom"
import { type ContactsItem, useGetContactsQuery } from "features/user"
import { Modal } from "shared/ui"
import { useModal, useResize } from "shared/hook"
import { ContactsCard } from "../ContactsCard"
import { formatCoordinates } from "../../lib"
import { templateCluster } from "./config"
import { StyledBackground, StyledMap, StyledModalContainer, StyledModal } from "./styles"
import { type PortalContactsProps } from "./types"

export const ContactsMap: FC = () => {
    const ymaps = useYMaps([
        "templateLayoutFactory",
        "objectManager.addon.objectsBalloon",
        "objectManager.addon.objectsHint",
    ])

    const createClusterLayout = ymaps?.templateLayoutFactory.createClass(templateCluster)
    const { data } = useGetContactsQuery({})

    const [portalData, setPortalData] = useState<ContactsItem>()
    const {
        mediaScreenSize: { width, isLaptopL },
    } = useResize()
    const { id, coordinates } = useParams()
    const modal = useModal()
    const allContacts = useMemo(() => {
        return Object.values(data?.contacts ?? []).flatMap((contacts) => contacts)
    }, [data?.contacts])
    useEffect(() => {
        if (id && allContacts.length) {
            setPortalData(allContacts.find(({ id: contactId }) => id === String(contactId)) as ContactsItem)
            modal.toggleActivityFixSetting(true)
        }
    }, [id, allContacts, data?.contacts])

    return (
        <>
            <YMaps>
                <StyledMap
                    defaultState={{
                        zoom: id ? 14 : 9,
                        center: !id ? [51.7727, 55.0988] : coordinates?.split(",").map((el) => Number(el)),
                    }}
                >
                    <Clusterer
                        options={{
                            clusterIconLayout: createClusterLayout,
                            maxZoom: 13,
                        }}
                    >
                        {allContacts.map((contact, index) => {
                            return (
                                <Placemark
                                    key={index}
                                    modules={["geoObject.addon.balloon", "geoObject.addon.hint"]}
                                    properties={{
                                        hintContent: contact.name,
                                    }}
                                    geometry={formatCoordinates(contact.coordinates)}
                                    options={{
                                        preset: "islands#circleIcon",
                                        iconColor: "#3E3AED",
                                    }}
                                    onClick={() => {
                                        modal.toggleActivityFixSetting(true)
                                        setPortalData(contact)
                                    }}
                                />
                            )
                        })}
                    </Clusterer>
                </StyledMap>
            </YMaps>
            {portalData ? (
                width > isLaptopL ? (
                    <PortalContacts {...modal}>
                        <ContactsCard onBackClick={modal.toggleActivity} isModal {...portalData} />
                    </PortalContacts>
                ) : (
                    <Modal {...modal}>
                        <ContactsCard onBackClick={modal.toggleActivity} isModal isSmallModal {...portalData} />
                    </Modal>
                )
            ) : null}
        </>
    )
}

const PortalContacts: FC<PortalContactsProps> = ({ isActive, toggleActivity, children }) => {
    if (!isActive) return null
    const cardSelector = document.querySelector(".ymaps-2-1-79-map")
    return (
        <>
            {createPortal(
                <StyledModalContainer>
                    <StyledBackground onClick={toggleActivity} />
                    <StyledModal $isBodySelector={cardSelector === null}>{children}</StyledModal>
                </StyledModalContainer>,
                cardSelector ?? document.body
            )}
        </>
    )
}
