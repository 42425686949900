import React, { type FC } from "react"
import { SizeSvg } from "shared/assets/svg"
import { type FormSvgProps } from "./types"

export const FormSvg: FC<FormSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
            <path
                d="M9 6C9 6.55228 8.55228 7 8 7C7.44772 7 7 6.55228 7 6C7 5.44772 7.44772 5 8 5C8.55228 5 9 5.44772 9 6Z"
                fill="black"
            />
            <path
                d="M8 13C8.55228 13 9 12.5523 9 12C9 11.4477 8.55228 11 8 11C7.44772 11 7 11.4477 7 12C7 12.5523 7.44772 13 8 13Z"
                fill="black"
            />
            <path
                d="M9 18C9 18.5523 8.55228 19 8 19C7.44772 19 7 18.5523 7 18C7 17.4477 7.44772 17 8 17C8.55228 17 9 17.4477 9 18Z"
                fill="black"
            />
            <path d="M11 6.5H17V5.5H11V6.5Z" fill="black" />
            <path d="M17 12.5H11V11.5H17V12.5Z" fill="black" />
            <path d="M11 18.5H17V17.5H11V18.5Z" fill="black" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 1C4.89543 1 4 1.89543 4 3V21C4 22.1046 4.89543 23 6 23H18C19.1046 23 20 22.1046 20 21V3C20 1.89543 19.1046 1 18 1H6ZM18 2H6C5.44771 2 5 2.44772 5 3V21C5 21.5523 5.44772 22 6 22H18C18.5523 22 19 21.5523 19 21V3C19 2.44772 18.5523 2 18 2Z"
                fill="black"
            />
        </svg>
    )
}
