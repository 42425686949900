import styled from "styled-components"
import { SectionGapSize } from "./types"
export const StyledSection = styled.div<{
    $gapSize?: SectionGapSize
}>`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: ${({ $gapSize }) => $gapSize ?? SectionGapSize.M};
    & > div {
        width: 100%;
    }
`
