import { useReducer } from "react"
import { type Action, ActionKind, type days, type Payload, type Reducer, type State } from "./types"

// prettier-ignore
const initialDaysState: State = {
    "Пн": { wasSelected: false, arrayNumber: null, },
    "Вт": { wasSelected: false, arrayNumber: null, },
    "Ср": { wasSelected: false, arrayNumber: null, },
    "Чт": { wasSelected: false, arrayNumber: null, },
    "Пт": { wasSelected: false, arrayNumber: null, },
    "Сб": { wasSelected: false, arrayNumber: null, },
    "Вс": { wasSelected: false, arrayNumber: null, },
}

export const useScheduleReducer = () => {
    const dayReducer: Reducer<State, Action> = (state, action) => {
        const {
            type,
            payload: { dayName, arrayNumber },
        } = action
        switch (type) {
            case ActionKind.TOGGLE_DAY: {
                const day = state[dayName as days]
                const wasSelected = day.arrayNumber === arrayNumber ? !day.wasSelected : true
                const arrayNumberState = wasSelected ? arrayNumber : null
                return {
                    ...state,
                    [dayName as days]: {
                        wasSelected,
                        arrayNumber: arrayNumberState,
                    },
                }
            }
            default:
                return state
        }
    }

    const toggleDayAction = (payload: Payload): Action => ({
        type: ActionKind.TOGGLE_DAY,
        payload,
    })

    const [state, dispatch] = useReducer(dayReducer, initialDaysState)

    return { state, dispatch, toggleDayAction }
}
