import React, { type FC } from "react"
import { ContentDirection, GroupDirection, type GroupProps, GroupSize } from "./types"
import { StyledGroup } from "./styles"

export const Group: FC<GroupProps> = (props) => {
    const {
        children,
        contentDirection = ContentDirection.CENTER,
        direction = GroupDirection.COLUMN,
        size = GroupSize.S,
        wrap = false,
        ...other
    } = props
    return (
        <StyledGroup $wrap={wrap} $direction={direction} $contentDirection={contentDirection} $size={size} {...other}>
            {children}
        </StyledGroup>
    )
}
