import { type FC } from "react"
import dayjs from "dayjs"
import { formatNumber, daysOfWeek } from "shared/lib"
import { EventsCalendarItem } from "../EventsCalendarItem"
import { EventDaysList } from "../EventDaysList"
import { StyledEventsCalendarGrid } from "./styles"
import { type EventsCalendarBodyProps } from "./types"

export const EventsCalendarBody: FC<EventsCalendarBodyProps> = (props) => {
    const { onItemClick, monthNumber, yearNumber, datesEvent, formattedData } = props
    const firstDayOfMonth = dayjs(`${yearNumber}-${formatNumber(monthNumber)}-01`).startOf("month")
    const daysInMonth = firstDayOfMonth.daysInMonth()
    const startDayOfWeek = firstDayOfMonth.day() === 0 ? 6 : firstDayOfMonth.day() - 1
    const previousMonth = firstDayOfMonth.subtract(1, "month")
    const nextMonth = firstDayOfMonth.add(1, "month")
    return (
        <div>
            <StyledEventsCalendarGrid>
                {daysOfWeek.map((day) => (
                    <EventsCalendarItem.Header key={`day-of-week-${day}`}>{day}</EventsCalendarItem.Header>
                ))}
            </StyledEventsCalendarGrid>
            <StyledEventsCalendarGrid>
                <EventDaysList
                    formattedData={formattedData}
                    month={previousMonth}
                    arrayLength={startDayOfWeek}
                    datesEvent={datesEvent}
                    startDayOfWeek={startDayOfWeek}
                    isPrevious
                    isAnotherMonth
                    onItemClick={onItemClick}
                />
                <EventDaysList
                    formattedData={formattedData}
                    month={firstDayOfMonth}
                    arrayLength={daysInMonth}
                    datesEvent={datesEvent}
                    onItemClick={onItemClick}
                />
                {!!((startDayOfWeek + daysInMonth) % 7) && (
                    <EventDaysList
                        formattedData={formattedData}
                        month={nextMonth}
                        arrayLength={7 - ((startDayOfWeek + daysInMonth) % 7)}
                        datesEvent={datesEvent}
                        onItemClick={onItemClick}
                        isAnotherMonth
                    />
                )}
            </StyledEventsCalendarGrid>
        </div>
    )
}
