import React, { type FC } from "react"
import { NavLink } from "react-router-dom"
import { InputPassword } from "shared/ui"
import { Retry } from "shared/assets/svg"
import { StyledPasswordField, StyledRestorePasswordLink } from "../../styles"

export const PasswordField: FC = () => {
    return (
        <>
            <StyledPasswordField>
                <div>
                    <InputPassword required={false} name={"password"} placeholder={"Пароль"} labelText={"Пароль"} />
                </div>
                <NavLink to={"/restore"}>
                    <StyledRestorePasswordLink>
                        <Retry />
                        Восстановить пароль
                    </StyledRestorePasswordLink>
                </NavLink>
            </StyledPasswordField>
        </>
    )
}
