import { type FC } from "react"
import { AboutList, AboutMain } from "widgets/user"

export const AboutPage: FC = () => {
    return (
        <>
            <AboutMain />
            <AboutList />
        </>
    )
}
