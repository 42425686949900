import { type FC } from "react"
import { NavLink } from "react-router-dom"
import { Choice, ChoiceGroup, ChoiceType, useMediaSizeChoice, useMediaSizeChoiceGroup } from "shared/ui"
import { CalendarSvg, OpportunitySvg, RatingSvg, RuleSvg, StarSvg, useMediaSizeSvg } from "shared/assets/svg"
import { useResize } from "shared/hook"
import { StyledDashboardHeader } from "./styles"

export const DashboardHeaderChoice: FC = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    const { fromStoL } = useMediaSizeChoiceGroup()
    const { fromStoL: fromStoLChoice } = useMediaSizeChoice()
    const { fromMtoL: fromMtoLSvg } = useMediaSizeSvg()
    const scrollChoiceGroup = width <= isTablet
    return (
        <StyledDashboardHeader>
            <ChoiceGroup size={fromStoL} scroll={scrollChoiceGroup}>
                <NavLink to={""} end={true}>
                    {({ isActive }) => (
                        <Choice selected={isActive} type={ChoiceType.NAVLINK} size={fromStoLChoice}>
                            <StarSvg size={fromMtoLSvg} rule={RuleSvg.EVENODD} />
                            Задачи
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"calendar"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} type={ChoiceType.NAVLINK} size={fromStoLChoice}>
                            <CalendarSvg size={fromMtoLSvg} />
                            Календарь
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"rating"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} type={ChoiceType.NAVLINK} size={fromStoLChoice}>
                            <RatingSvg size={fromMtoLSvg} />
                            Рейтинг
                        </Choice>
                    )}
                </NavLink>
                <NavLink to={"opportunities"}>
                    {({ isActive }) => (
                        <Choice selected={isActive} type={ChoiceType.NAVLINK} size={fromStoLChoice}>
                            <OpportunitySvg size={fromMtoLSvg} />
                            Возможности
                        </Choice>
                    )}
                </NavLink>
            </ChoiceGroup>
        </StyledDashboardHeader>
    )
}
