import React, { type FC, useEffect, useRef, useState } from "react"
import { useFormContext } from "react-hook-form"
import { type CategoryItem } from "features/user"
import { Button, ButtonSize, ButtonType, FormField, Tag, Vector, VectorType, SelectList, useMediaTag } from "shared/ui"
import { DropDownChevron, FillSvg, SizeSvg } from "shared/assets/svg"
import { mapArrayToHash } from "shared/lib"
import { type SelectProps } from "./types"
import {
    StyledChevron,
    StyledSelect,
    StyledSelectContainer,
    StyledSelectedTags,
    StyledSelectParent,
    StyledSelectSelected,
} from "./styles"

export const Select: FC<SelectProps> = (props) => {
    const {
        labelText,
        name,
        setWasClear,
        hasIcon = true,
        multiple = false,
        data,
        clearable = false,
        start = [],
        required = true,
        initialValue = "Выберите",
        hasPlaceholder = false,
        wasClear = false,
        ...other
    } = props

    const { register, resetField, setValue, getValues, formState, getFieldState } = useFormContext()
    const { isDirty } = getFieldState(name, formState)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [selectData, setSelectData] = useState<Map<string | number, string>>(mapArrayToHash<CategoryItem[]>(start))
    const [selected, setSelected] = useState<string>(initialValue)
    const rootRef = useRef<HTMLDivElement>(null)
    const { TagSizeStoM } = useMediaTag()
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            const { target } = event
            if (target instanceof Node && !rootRef.current?.contains(target)) {
                setIsOpen(false)
            }
        }
        window.addEventListener("click", handleClick)
        return () => {
            window.removeEventListener("click", handleClick)
        }
    }, [isOpen])
    const handleClickOpen = () => {
        setIsOpen(!isOpen)
    }

    const handleClickClear = () => {
        resetField(name)
        setSelectData(new Map())
        setSelected(initialValue)
    }
    useEffect(() => {
        if (wasClear && setWasClear) {
            handleClickClear()
            setWasClear()
        }
    }, [wasClear])
    return (
        <StyledSelectContainer>
            <FormField
                name={name}
                required={required}
                labelText={labelText}
                isSuccess={false}
                render={(focus) => (
                    <StyledSelectParent ref={rootRef} onClick={handleClickOpen}>
                        <StyledSelect>
                            <StyledSelectSelected $isInitial={selected === initialValue && hasPlaceholder}>
                                {selected}
                                <input {...other} type="hidden" {...register(name)} />
                            </StyledSelectSelected>

                            {clearable && isDirty && (
                                <Button
                                    typeUI={ButtonType.FREE}
                                    hover={false}
                                    onlyIcon={true}
                                    size={ButtonSize.S}
                                    onClick={(event) => {
                                        handleClickClear()
                                        event.stopPropagation()
                                    }}
                                >
                                    <Vector size={SizeSvg.M} type={VectorType.CROSS} fill={FillSvg.BLACK} />
                                </Button>
                            )}
                            <StyledChevron $isOpen={isOpen}>
                                <DropDownChevron />
                            </StyledChevron>
                        </StyledSelect>
                        <SelectList
                            data={data}
                            isOpen={isOpen}
                            multiple={multiple}
                            name={name}
                            mapData={selectData}
                            setSelectData={setSelectData}
                            setSelected={setSelected}
                        />
                    </StyledSelectParent>
                )}
            />
            {multiple && (
                <StyledSelectedTags>
                    {Array.from(selectData.values()).map((item: string, index) => (
                        <Tag key={index} size={TagSizeStoM}>
                            {item}
                        </Tag>
                    ))}
                </StyledSelectedTags>
            )}
        </StyledSelectContainer>
    )
}
