import { type FC } from "react"
import { EventCard, EventCardSize } from "entities/viewer"
import { Modal, ModalDefault } from "shared/ui"
import { type EventsCalendarModalProps } from "./types"
import { EventsCalendarModalList } from "./ui/EventsCalendarModalList"

export const EventsCalendarModal: FC<EventsCalendarModalProps> = (props) => {
    const { day, toggleActivity, formattedData, ...rest } = props
    const dayFormatted = day
        ? new Date(day).toLocaleDateString("ru", { year: "numeric", month: "long", day: "numeric" }).slice(0, -2)
        : ""
    return (
        <Modal bigHeight position={"right"} toggleActivity={toggleActivity} {...rest}>
            <ModalDefault back={toggleActivity} title={dayFormatted}>
                <EventsCalendarModalList>
                    {formattedData?.[day]?.map(({ id, url, startDate, endDate, title }) => (
                        <EventCard
                            key={`modal-${id}`}
                            onClick={() => {
                                window.open(url)
                            }}
                            size={EventCardSize.M}
                            link={url}
                            startDate={startDate.split("T")[1].slice(0, 5)}
                            endDate={endDate.split("T")[1].slice(0, 5)}
                            text={title}
                        />
                    ))}
                </EventsCalendarModalList>
            </ModalDefault>
        </Modal>
    )
}
