import { css, styled } from "styled-components"

export const StyledCard = styled.div<{ $withHover: boolean }>`
    width: 99%;
    border-radius: var(--assets-card-common-sizes-s-radius, 24px);
    background: var(--assets-card-common-colors-fill, rgba(0, 0, 0, 0.02));
    outline: 1px solid transparent;
    margin: 1px;
    transition: all 0.2s linear;
    ${({ $withHover }) =>
        $withHover &&
        css`
            &:hover {
                background: white;
                outline: 1px solid var(--assets-card-common-colors-stroke-hover, #3e3aed);
            }
        `}
`
