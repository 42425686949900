import { type FC } from "react"

export const SearchSvg: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M21 9C21 12.3137 18.3137 15 15 15C13.5232 15 12.171 14.4664 11.1256 13.5815L3.35359 21.3536L2.64648 20.6464L10.4185 12.8744C9.53362 11.8291 9.00004 10.4769 9.00004 9C9.00004 5.68629 11.6863 3 15 3C18.3137 3 21 5.68629 21 9ZM20 9C20 11.7614 17.7615 14 15 14C12.2386 14 10 11.7614 10 9C10 6.23858 12.2386 4 15 4C17.7615 4 20 6.23858 20 9Z"
                fill="black"
                fillOpacity="0.36"
            />
        </svg>
    )
}
