import React, { type FC } from "react"
import { Outlet } from "react-router-dom"
import { DashboardHeader } from "widgets/viewer"

export const DashBoardPage: FC = () => {
    return (
        <>
            <DashboardHeader>
                <Outlet />
            </DashboardHeader>
        </>
    )
}
