import styled, { css } from "styled-components"
import { type Devices } from "shared/hook"
import { getMediaVariable } from "shared/styles"
import { patternMainStatic } from "shared/assets/svg"

const StyledPageLayout = styled.div<{
    $screen: Record<Devices, boolean>
    $isMain: boolean
}>`
    & * {
        scrollbar-width: none;
    }

    padding: var(--layout-desktop-padding-vert, 16px) var(--layout-desktop-padding-hor, 32px)
        var(--layout-desktop-padding-vert, 16px);
    background: ${({ $isMain }) =>
        $isMain ? "var(--root-colors-main-timur-grey-basic)" : "var(--root-colors-main-white-basic)"};
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--layout-desktop-gap, 24px);
    overflow-x: hidden;
    min-height: calc(100vh - 81px);

    @media all and (min-width: ${getMediaVariable("tablet")}) {
        ${({ $isMain }) => {
            return $isMain
                ? css`
                      background-image: url(${() => `data:image/svg+xml,${patternMainStatic}#opportunity`});
                      background-repeat: no-repeat;
                      background-position: right -382px bottom -626px;
                      @media all and (max-width: ${getMediaVariable("desktop")}) {
                          background-position: right -576px bottom -784px;
                      }
                      @media all and (max-width: ${getMediaVariable("laptop")}) {
                          background-image: none;
                      }
                  `
                : css``
        }};
    }

    @media all and (max-width: ${getMediaVariable("tablet")}) {
        padding: var(--layout-mobile-padding-vert, 16px) var(--layout-mobile-padding-hor, 32px)
            var(--layout-mobile-padding-vert, 16px);
        gap: var(--layout-mobile-gap, 24px);
    }
`

export { StyledPageLayout }
