import styled from "styled-components"

export const StyledDropDown = styled.div<{
    $isShow: boolean
}>`
    display: ${({ $isShow }) => ($isShow ? "unset" : "none")};
    border: 1px solid var(--assets-input-colors-stroke-basic);
    border-radius: var(--assets-input-sizes-radius);
    padding: var(--assets-input-sizes-padding-hor) var(--assets-input-sizes-padding-hor);
    width: 220px;
    color: var(--layout-text-colors-secondary, rgba(0, 0, 0, 0.72));
    font-size: 12px;
    word-break: break-word;
    position: absolute;
    background: #fff;

    top: 19px;
    right: 0;
    z-index: 99;
    cursor: default;
`

export const StyledInfoSvg = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: var(--assets-informer-sizes-icon-radius, 1024px);
    color: #fff;
    margin-left: 4px;
    margin-bottom: 4px;
    cursor: pointer;
    background: var(--assets-informer-colors-warning-icon-bg);
    position: relative;
    & > svg {
        min-width: 16px;
    }

    &:hover ${StyledDropDown} {
        display: unset;
    }
`
