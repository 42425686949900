import React, { type FC } from "react"
import { useGetProfileDataQuery } from "features/viewer"
import { Metric } from "./Metric"
import { StyledRow } from "./styles"

export const UserMetric: FC = () => {
    const { data, isLoading } = useGetProfileDataQuery("")
    if (!isLoading && data) {
        return (
            <StyledRow>
                <Metric rating={data.rating.userRating} fromRating={data.rating.total}>
                    Рейтинг
                </Metric>
                <Metric hasOverall={false} rating={data.points}>
                    Баллы
                </Metric>
            </StyledRow>
        )
    }
}
