import React, { type ChangeEvent, type FC } from "react"
import { useFormContext } from "react-hook-form"
import { MinusSvg, PlusSvg } from "shared/assets/svg"
import { ButtonSize, ButtonType } from "shared/ui/Button"
import { type InputCounterProps } from "./types"
import { StyledInputCounterInput, StyledButtonCounter, StyledFormFieldInputCounter } from "./styles"

export const InputCounter: FC<InputCounterProps> = (props) => {
    const { name, labelText, required, ...other } = props
    const { register, setValue, getValues } = useFormContext()

    const counter = (num: number) => {
        setValue(name, Number(getValues(name)) + num, {
            shouldValidate: true,
            shouldDirty: true,
        })
    }
    const limit = (event: ChangeEvent<HTMLInputElement>) => {
        if (Number(event.target.value) < 0 || Number(event.target.value) > 999) {
            setValue(name, event.target.value.slice(0, -1), {
                shouldValidate: true,
                shouldDirty: true,
            })
        }
    }
    const disabledIncrement = Number(getValues(name)) >= 999 ? ButtonType.DISABLED : ButtonType.FREE
    const disabledDecrement = Number(getValues(name)) <= 0 ? ButtonType.DISABLED : ButtonType.FREE

    return (
        <StyledFormFieldInputCounter
            className={props.className}
            name={name}
            labelText={labelText}
            required={required}
            isSuccess={false}
            render={(focus) => (
                <>
                    <StyledButtonCounter
                        className={props.className}
                        onClick={() => counter(-1)}
                        hover={false}
                        size={ButtonSize.S}
                        typeUI={disabledDecrement}
                        onlyIcon={true}
                    >
                        <MinusSvg />
                    </StyledButtonCounter>

                    <StyledInputCounterInput
                        {...other}
                        type="number"
                        {...register(name, {
                            onChange: limit,
                        })}
                        onFocus={focus}
                        onBlur={focus}
                    />

                    <StyledButtonCounter
                        onClick={() => counter(1)}
                        hover={false}
                        size={ButtonSize.S}
                        typeUI={disabledIncrement}
                        onlyIcon={true}
                    >
                        <PlusSvg />
                    </StyledButtonCounter>
                </>
            )}
        />
    )
}
