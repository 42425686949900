import styled from "styled-components"
import { StyledInputWrapper } from "shared/styles"

const StyledSelectContainer = styled.div``
const StyledSelectParent = styled.div`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    cursor: pointer;
`
const StyledSelect = styled(StyledInputWrapper)``

const StyledSelectSelected = styled.div<{
    $isInitial: boolean
}>`
    font-size: 14px;
    line-height: 18px;
    width: 100%;
    user-select: none;
    color: ${({ $isInitial }) =>
        $isInitial ? "var(--assets-input-colors-placeholder, rgba(0, 0, 0, 0.36))" : "var(--assets-input-colors-text)"};
`

const StyledChevron = styled.div<{
    $isOpen: boolean
}>`
    height: 8px;
    display: flex;
    align-items: center;
    transform: rotate(${({ $isOpen }) => ($isOpen ? "180deg" : "0deg")});
    transition: all 0.2s;
`

const StyledSelectedTags = styled.div`
    display: flex;
    padding-top: var(--assets-select-sizes-combo-margin);
    align-items: flex-start;
    align-content: flex-start;
    gap: 4px var(--assets-select-sizes-combo-gap);
    align-self: stretch;
    flex-wrap: wrap;
`
export {
    StyledSelectParent,
    StyledSelectedTags,
    StyledChevron,
    StyledSelect,
    StyledSelectSelected,
    StyledSelectContainer,
}
