import React, { type FC, useEffect, useState } from "react"
import { FormProvider, type SubmitHandler, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import { useCreateIdeaMutation, IdeaFormType, type IIdeaForm } from "features/viewer"

import { ProfileCard, ProfileCardSize } from "entities/viewer"
import {
    Button,
    ButtonType,
    Choice,
    ChoiceGroup,
    ChoiceGroupSize,
    ChoiceSize,
    FormLayout,
    Input,
    InputTextArea,
    Modal,
    ModalDefault,
    useMediaSizeButton,
} from "shared/ui"
import { useModal } from "shared/hook"
import { IdeaFormNames } from "./types"
import { StyledIdeaFormInputSection, StyledIdeaFormParent } from "./styles"
import { IdeaFormConfig } from "./lib/config"

export const IdeaForm: FC = () => {
    const methods = useForm<IIdeaForm>(IdeaFormConfig())
    const [fetch, state] = useCreateIdeaMutation()
    const [IdeaTypeState, setIdeaType] = useState<IdeaFormType>(IdeaFormType.TEAM)
    useEffect(() => {
        methods.setValue(IdeaFormNames.ideaType, IdeaTypeState)
    }, [IdeaTypeState])
    const mediaButton = useMediaSizeButton()
    const navigate = useNavigate()
    const { isActive, toggleActivity } = useModal(false)

    const handleSubmit: SubmitHandler<IIdeaForm> = async (data) => {
        await fetch(data)
        navigate("/personal-account/idea")
    }
    return (
        <FormProvider {...methods}>
            <FormLayout
                headerText={"Предложение идеи"}
                main={() => (
                    <StyledIdeaFormParent>
                        <StyledIdeaFormInputSection>
                            <ChoiceGroup scroll={false} size={ChoiceGroupSize.S}>
                                <Choice
                                    id={"TEAM"}
                                    selected={IdeaTypeState}
                                    size={ChoiceSize.XS}
                                    onClick={() => setIdeaType(IdeaFormType.TEAM)}
                                >
                                    Командная
                                </Choice>
                                <Choice
                                    id={"INDIVIDUAL"}
                                    selected={IdeaTypeState}
                                    size={ChoiceSize.XS}
                                    onClick={() => setIdeaType(IdeaFormType.INDIVIDUAL)}
                                >
                                    Индивидуальная
                                </Choice>
                            </ChoiceGroup>
                            <Input name={IdeaFormNames.title} labelText={"Название"} placeholder={"Идея на миллион"} />
                            <Input
                                name={IdeaFormNames.locality}
                                labelText={"Территория реализации"}
                                required={true}
                                placeholder={"ваш город"}
                            />
                        </StyledIdeaFormInputSection>
                        <InputTextArea
                            name={IdeaFormNames.problem}
                            labelText={"Какую проблему решает?"}
                            required={true}
                        />
                        <InputTextArea
                            name={IdeaFormNames.solution}
                            labelText={"Как можно решить проблему"}
                            required={true}
                        />
                        <InputTextArea
                            name={IdeaFormNames.successReason}
                            labelText={"Почему идея сработает?"}
                            required={true}
                        />
                        <InputTextArea
                            name={IdeaFormNames.results}
                            labelText={"Результаты реализации"}
                            required={true}
                        />
                    </StyledIdeaFormParent>
                )}
                footer={() => (
                    <>
                        <Button
                            typeUI={ButtonType.PRIMARY}
                            onClick={async () => {
                                await methods.trigger()
                                toggleActivity()
                            }}
                            size={mediaButton.fromMtoL}
                        >
                            Предложить
                        </Button>
                        <Modal isActive={isActive} position={"center"} toggleActivity={toggleActivity}>
                            <ModalDefault title={"Автор идеи"} back={toggleActivity}>
                                <ProfileCard cut={false} size={ProfileCardSize.S} />
                                <Button typeUI={ButtonType.PRIMARY} onClick={methods.handleSubmit(handleSubmit)}>
                                    Подтвердить
                                </Button>
                            </ModalDefault>
                        </Modal>
                        <Button onClick={() => navigate(-1)} typeUI={ButtonType.GHOST} size={mediaButton.fromStoM}>
                            Отмена
                        </Button>
                    </>
                )}
            />
        </FormProvider>
    )
}
