import React, { type FC } from "react"
import { SizeSvg } from "../../types"
import { type CalendarSvgProps } from "./types"

export const CalendarSvg: FC<CalendarSvgProps> = (props) => {
    const { size = SizeSvg.M } = props
    return (
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7 9C7 9.55229 6.55228 10 6 10C5.44772 10 5 9.55229 5 9C5 8.44772 5.44772 8 6 8C6.55228 8 7 8.44772 7 9Z"
                fill="black"
            />
            <path
                d="M6 14C6.55228 14 7 13.5523 7 13C7 12.4477 6.55228 12 6 12C5.44772 12 5 12.4477 5 13C5 13.5523 5.44772 14 6 14Z"
                fill="black"
            />
            <path
                d="M7 17C7 17.5523 6.55228 18 6 18C5.44772 18 5 17.5523 5 17C5 16.4477 5.44772 16 6 16C6.55228 16 7 16.4477 7 17Z"
                fill="black"
            />
            <path
                d="M10 10C10.5523 10 11 9.55229 11 9C11 8.44772 10.5523 8 10 8C9.44772 8 9 8.44772 9 9C9 9.55229 9.44772 10 10 10Z"
                fill="black"
            />
            <path
                d="M11 13C11 13.5523 10.5523 14 10 14C9.44772 14 9 13.5523 9 13C9 12.4477 9.44772 12 10 12C10.5523 12 11 12.4477 11 13Z"
                fill="black"
            />
            <path
                d="M10 18C10.5523 18 11 17.5523 11 17C11 16.4477 10.5523 16 10 16C9.44772 16 9 16.4477 9 17C9 17.5523 9.44772 18 10 18Z"
                fill="black"
            />
            <path
                d="M15 9C15 9.55229 14.5523 10 14 10C13.4477 10 13 9.55229 13 9C13 8.44772 13.4477 8 14 8C14.5523 8 15 8.44772 15 9Z"
                fill="black"
            />
            <path
                d="M14 14C14.5523 14 15 13.5523 15 13C15 12.4477 14.5523 12 14 12C13.4477 12 13 12.4477 13 13C13 13.5523 13.4477 14 14 14Z"
                fill="black"
            />
            <path
                d="M15 17C15 17.5523 14.5523 18 14 18C13.4477 18 13 17.5523 13 17C13 16.4477 13.4477 16 14 16C14.5523 16 15 16.4477 15 17Z"
                fill="black"
            />
            <path
                d="M18 10C18.5523 10 19 9.55229 19 9C19 8.44772 18.5523 8 18 8C17.4477 8 17 8.44772 17 9C17 9.55229 17.4477 10 18 10Z"
                fill="black"
            />
            <path
                d="M19 13C19 13.5523 18.5523 14 18 14C17.4477 14 17 13.5523 17 13C17 12.4477 17.4477 12 18 12C18.5523 12 19 12.4477 19 13Z"
                fill="black"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6.5 1.5L5.5 1.5V3H4C2.89543 3 2 3.89543 2 5V19C2 20.1046 2.89543 21 4 21H20C21.1046 21 22 20.1046 22 19V5C22 3.89543 21.1046 3 20 3H18.5V1.5H17.5V3H12.5V1.5H11.5V3H6.5V1.5ZM11.5 4H6.5V5.5C6.22386 5.5 5.5 5.5 5.5 5.5V4H4C3.44772 4 3 4.44772 3 5V19C3 19.5523 3.44772 20 4 20H20C20.5523 20 21 19.5523 21 19V5C21 4.44772 20.5523 4 20 4H18.5V5.5H17.5V4H12.5V5.5H11.5V4Z"
                fill="black"
            />
        </svg>
    )
}
