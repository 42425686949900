import React, { type FC, useRef } from "react"
import { useHorizontalScroll } from "../../hook"
import { StyledScrollGroup } from "./styles"
import { type ScrollGroupProps } from "./types"

export const ScrollGroup: FC<ScrollGroupProps> = (props) => {
    const { children, isModal = false, isScrollMode = true, ...rest } = props
    const ref = useRef<HTMLDivElement | null>(null)

    useHorizontalScroll(ref)
    return (
        <>
            {isScrollMode ? (
                <StyledScrollGroup ref={ref} {...rest} $isModal={isModal}>
                    {children}
                </StyledScrollGroup>
            ) : (
                <>{children}</>
            )}
        </>
    )
}
