import * as yup from "yup"
import { type ObjectSchema } from "yup"
import { IdeaFormType, type IIdeaForm } from "features/viewer"

export const IdeaFormSchema: ObjectSchema<IIdeaForm> = yup
    .object({
        title: yup.string().required("Поле обязательно для заполнения"),
        problem: yup.string().required("Поле обязательно для заполнения"),
        solution: yup.string().required("Поле обязательно для заполнения"),
        successReason: yup.string().required("Поле обязательно для заполнения"),
        locality: yup.string().required("Поле обязательно для заполнения"),
        ideaType: yup
            .mixed<IdeaFormType>()
            .test(
                "IdeaType",
                "Нет такое типа",
                (value) => value === IdeaFormType.TEAM || value === IdeaFormType.INDIVIDUAL
            )
            .required(),
        results: yup.string().required("Поле обязательно для заполнения"),
    })
    .required()
