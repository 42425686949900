import { type OpportunityItemWithType, type Type } from "./types"

type getRubricatorCardsType = (
    totalOpportunities: OpportunityItemWithType[],
    type?: Type,
    sort?: "asc" | "desc"
) => OpportunityItemWithType[]
export const getRubricatorCards: getRubricatorCardsType = (totalOpportunities, type, sort = "asc") => {
    switch (type) {
        case 0: {
            return totalOpportunities.sort((a, b) => {
                const aDate = new Date(a.createdAt ?? a.startDate).valueOf()
                const bDate = new Date(b.createdAt ?? b.startDate).valueOf()
                return sort === "asc" || !sort ? aDate - bDate : bDate - aDate
            }) as OpportunityItemWithType[]
        }
        default: {
            return totalOpportunities
        }
    }
}
