import styled from "styled-components"

const StyledImagesCard = styled.div`
    & button:first-child {
        width: unset;
    }
`
const StyledImagesCardImg = styled.div`
    background: var(--assets-user-avatar-colors-background, #000);
    &,
    & > img {
        border-radius: var(--assets-user-avatar-radius-xl, 24px);
        width: var(--assets-user-avatar-size-xl, 128px);
        height: var(--assets-user-avatar-size-xl, 128px);
        object-fit: cover;
    }
`

export { StyledImagesCard, StyledImagesCardImg }
