import { type FC, type PropsWithChildren } from "react"
import { DateSvg, SizeSvg } from "shared/assets/svg"
import { StyledLabeledWrap, StyledWorkSchedule } from "./styles"

export const ScheduleWrapper: FC<PropsWithChildren> = ({ children }) => {
    return (
        <StyledWorkSchedule>
            <StyledLabeledWrap>
                <DateSvg size={SizeSvg.M} />
                <div>Режим работы</div>
            </StyledLabeledWrap>
            {children}
        </StyledWorkSchedule>
    )
}
