import React, { type FC } from "react"

export const LogoutSvg: FC = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Logout">
                <g id="vector">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.5 22.6629V20.5H16.5L16.5 14H15.5L15.5 19.5H11.5L11.5 3.62285L7.57003 2.5H15.5L15.5 8H16.5L16.5 1.5H3.5V20.3772L11.5 22.6629ZM4.5 2.66286V19.6228L10.5 21.3371L10.5 4.37715L4.5 2.66286Z"
                        fill="#3E3AED"
                    />
                    <path
                        d="M20.7929 11.5H13.5V10.5H20.7929L19.6464 9.35355L20.3536 8.64645L22.7071 11L20.3536 13.3536L19.6464 12.6464L20.7929 11.5Z"
                        fill="#3E3AED"
                    />
                </g>
            </g>
        </svg>
    )
}
