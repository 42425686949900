import { useResize } from "shared/hook"
import { ProfileDataSize } from "./types"

export const useMediaProfileData = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        SizeStoL: width >= isTablet ? ProfileDataSize.L : ProfileDataSize.S,
    }
}
