import React, { type FC } from "react"

export const FilterSvg: FC = () => {
    return (
        <svg width="33" height="32" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.5 7.99935L19.1667 14.666V27.9993L13.8333 30.666V14.666L4.5 7.99935V2.66602H28.5V7.99935ZM17.8333 15.3327V27.1753L15.1667 28.5086V15.3327H17.8333ZM17.8333 13.9993V13.9799L27.1667 7.31319V3.99935H5.83333V7.31319L15.1667 13.9799V13.9993H17.8333Z"
                fill="#3E3AED"
            />
        </svg>
    )
}
