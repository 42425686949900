import { type FC } from "react"
import { useNavigate } from "react-router-dom"
import { Button, Modal, ModalDefault, useMediaSizeButton } from "shared/ui"
import { type ModalEmailProps } from "./types"

export const ModalEmail: FC<ModalEmailProps> = ({ isActive, toggleActivity }) => {
    const { fromMtoL } = useMediaSizeButton()
    const navigate = useNavigate()
    return (
        <Modal isActive={isActive} toggleActivity={toggleActivity}>
            <ModalDefault
                title={"Спасибо за регистрацию"}
                body={"На указанный вами адрес электронной почты была отправлена ссылка для подтверждения."}
                backButton={false}
                footerBtn={() => (
                    <Button
                        onClick={() => {
                            navigate("/auth")
                            toggleActivity()
                        }}
                        size={fromMtoL}
                    >
                        Хорошо
                    </Button>
                )}
            ></ModalDefault>
        </Modal>
    )
}
