import { type HTMLAttributes } from "react"

export enum GiftImageSize {
    S = "s",
    L = "l",
}

export interface GiftImageProps extends HTMLAttributes<HTMLDivElement> {
    size?: GiftImageSize
}
