import { type HTMLAttributes } from "react"

export enum ChoiceInputAuthType {
    EMAIL = "email",
    PHONE = "phone",
}
export interface ChoiceInputAuthProps extends HTMLAttributes<HTMLInputElement> {
    test?: string
    choice: ChoiceInputAuthType
    setChoiceHandler: (choice: ChoiceInputAuthType) => void
}
