import styled, { css } from "styled-components"
import { patternSvgStatic } from "shared/assets/svg"
import { getMediaVariable } from "shared/styles"

export const StyledNewsCard = styled.div<{ $bg?: string }>`
    display: flex;
    width: var(--assets-opportunity-cards-sizes-s-width, 326px);
    height: var(--assets-opportunity-cards-sizes-s-height, 360px);
    min-width: var(--assets-opportunity-cards-sizes-s-width-min, 288px);
    padding: var(--assets-opportunity-cards-sizes-s-padding, 8px);
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    flex-shrink: 0;

    border-radius: var(--assets-opportunity-cards-sizes-s-radius, 24px);
    position: relative;
    overflow: hidden;
    ${({ $bg }) =>
        $bg &&
        css`
            height: var(--assets-opportunity-cards-sizes-s-height, 360px);
        `}
    &:hover,
    &:active {
        &::before {
            background-color: transparent;
            ${({ $bg }) =>
                $bg &&
                css`
                    opacity: 0.8;
                `}
            ${({ $bg }) =>
                !$bg &&
                css`
                    background-image: none;
                `}
        }
    }
    &::before {
        border-radius: inherit;
        content: "";
        background: url(${({ $bg }) => (!$bg ? `data:image/svg+xml,${patternSvgStatic}#news` : $bg)})
            var(--root-colors-accent-crema, #f4efe9) 100% / cover no-repeat;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        background-size: ${({ $bg }) => (!$bg ? "1520px" : "100%")};
        ${({ $bg }) =>
            !$bg
                ? css`
                      background-position: top -434px right 760px;
                      background-repeat: no-repeat;
                      width: 1520px;
                      height: 1520px;
                  `
                : css`
                      background-size: cover;
                  `}
    }
    @media all and (max-width: ${getMediaVariable("tablet")}) {
        width: 100%;
        max-width: 326px;
    }
`

export const StyledContent = styled.div`
    display: flex;
    padding: var(--assets-opportunity-cards-sizes-s-padding-plate, 8px)
        var(--assets-opportunity-cards-sizes-s-padding-plate, 8px)
        var(--assets-opportunity-cards-sizes-s-padding-plate-bottom, 16px)
        var(--assets-opportunity-cards-sizes-s-padding-plate, 8px);
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: var(--assets-opportunity-cards-sizes-s-gap-plate, 8px);
    align-self: stretch;

    border-radius: var(--assets-opportunity-cards-sizes-s-radius-plate, 16px);
    background: var(--assets-opportunity-cards-colors-plate, #fff);
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
    margin-top: auto;
    margin-bottom: 0px;
    height: 162px;
    @media all and (max-width: ${getMediaVariable("tablet")}) {
        height: 165px;
    }
`

export const StyledDate = styled.div`
    display: flex;
    gap: 8px;
    width: 100%;
`

export const StyledDescription = styled.div<{
    $lineClampCount: number
}>`
    line-height: 120%;
    color: var(--layout-text-colors-secondary, rgba(0, 0, 0, 0.72));
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: ${({ $lineClampCount }) => $lineClampCount};
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    word-break: break-word;
`
