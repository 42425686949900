export enum FormFieldState {
    BASIC = "basic",
    FOCUSED = "focused",
    SUCCESS = "success",
    INVALID = "error",
}

export interface useFormFieldValueStatus {
    isOn: boolean
    isValid: boolean
    status: FormFieldState
}

export interface useFormFieldStatusSetting {
    [FormFieldState.BASIC]: boolean
    [FormFieldState.FOCUSED]: boolean
    [FormFieldState.SUCCESS]: boolean
    [FormFieldState.INVALID]: boolean
}
export interface useFormFieldProps {
    valid: boolean
    focused: boolean
    filled: boolean
    isOn: useFormFieldStatusSetting
}
