import { type SelectData } from "shared/ui"

export const disabilityGroup: SelectData[] = [
    {
        id: "I",
        name: "1 группа",
    },
    {
        id: "II",
        name: "2 группа",
    },
    {
        id: "III",
        name: "3 группа",
    },
]
