import { type HTMLAttributes } from "react"

export enum ToggleType {
    CHECKBOX = "checkbox",
    SWITCH = "switch",
}

export interface ToggleProps extends HTMLAttributes<HTMLDivElement> {
    isTurnOn?: boolean
    type?: ToggleType
    handlerToggle: () => void
}
