import React, { type FC } from "react"
import { ProfileData } from "entities/viewer"
import { ProfileSvg, SizeSvg } from "shared/assets/svg"

interface AgeSectionProps {
    ageRange?: [number, number]
}
export const AgeSection: FC<AgeSectionProps> = ({ ageRange }) => {
    return (
        <ProfileData caption={"Возраст"}>
            <ProfileSvg size={SizeSvg.M} />
            {ageRange?.[0] ?? 18} - {ageRange?.[1] || 35} лет
        </ProfileData>
    )
}
