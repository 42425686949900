import React, { type FC, useState } from "react"
import { useFormContext, useWatch } from "react-hook-form"
import { FormField } from "../FormField"
import { Button, ButtonSize, ButtonType } from "../Button"
import { Vector, VectorType } from "../Vector"
import { FillSvg, SizeSvg } from "../../assets/svg"
import { type InputTextAreaProps } from "./types"
import { StyledTextArea, StyledTextAreaBottom, StyledTextAreaCount, StyledTextAreaParent } from "./styles"

export const InputTextArea: FC<InputTextAreaProps> = (props) => {
    const { labelText, limit = 100, required = true, clearable = true, name, ...other } = props
    const { register, getValues, setValue, resetField } = useFormContext()

    const [length, setLength] = useState<number>(getValues(name).length)
    const InputValueResults = useWatch({ name })

    return (
        <FormField
            name={name}
            required={required}
            labelText={labelText}
            isSuccess={false}
            render={(focus, state) => (
                <StyledTextAreaParent>
                    <StyledTextArea
                        {...other}
                        {...register(name, {
                            onChange: (event) => {
                                if (event.target.value.length > limit) {
                                    setValue(name, event.target.value.slice(0, limit), {
                                        shouldValidate: true,
                                        shouldDirty: true,
                                    })
                                }
                                setLength(event.target.value.length)
                            },
                        })}
                        onFocus={focus}
                        onBlur={focus}
                    ></StyledTextArea>

                    <StyledTextAreaBottom>
                        <StyledTextAreaCount $state={state}>
                            {length} / {limit}
                        </StyledTextAreaCount>
                        {clearable && !!InputValueResults && (
                            <Button
                                typeUI={ButtonType.FREE}
                                hover={false}
                                onlyIcon={true}
                                size={ButtonSize.S}
                                onClick={() => {
                                    resetField(name)
                                    setLength(0)
                                }}
                            >
                                <Vector size={SizeSvg.M} type={VectorType.CROSS} fill={FillSvg.BLACK} />
                            </Button>
                        )}
                    </StyledTextAreaBottom>
                </StyledTextAreaParent>
            )}
        />
    )
}
