import { useResize } from "shared/hook"
import { GiftImageSize } from "./types"

export const useMediaGetImage = () => {
    const {
        mediaScreenSize: { width, isTablet },
    } = useResize()
    return {
        fromStoL: width >= isTablet ? GiftImageSize.L : GiftImageSize.S,
    }
}
