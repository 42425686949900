import React, { type FC } from "react"
import styled from "styled-components"
import { OpportunitySection } from "entities/user"
import { ProfileData } from "entities/viewer"
import { StyledProfileDataPattern, SectionGapSize, StyledSection } from "shared/styles"
import { Tag, useMediaTag } from "shared/ui"
interface OpportunityDescriptionProps {
    address: string
    categories: Array<{ id: string; name: string }>
    tags: Array<{ id: string; name: string }>
}

const StyledRow = styled.div`
    flex-wrap: wrap;
    display: flex;
    align-items: flex-start;
    gap: var(--assets-user-profile-data-sizes-s-grid-gap);
    align-self: stretch;
    align-content: center;
    justify-content: center;
`
export const OpportunityDescription: FC<OpportunityDescriptionProps> = ({ address, categories, tags }) => {
    const { TagSizeStoM } = useMediaTag()
    return (
        <>
            <StyledSection $gapSize={SectionGapSize.S}>
                <StyledRow>
                    <OpportunitySection.CategorySection categories={categories} />
                    <OpportunitySection.GeographySection address={address} />
                </StyledRow>
                <StyledProfileDataPattern $type={"interests"}>
                    <ProfileData caption={"Теги"}>
                        {tags.map((el) => (
                            <Tag key={el.id} size={TagSizeStoM}>
                                {el.name}
                            </Tag>
                        ))}
                    </ProfileData>
                </StyledProfileDataPattern>
            </StyledSection>
        </>
    )
}
