import { createGlobalStyle } from "styled-components"
import { normalize } from "./normalize"
import { variables } from "./variables"
import { fonts } from "./fonts"

const GlobalStyle = createGlobalStyle`
    ${fonts}
    ${variables}
    ${normalize}
`

export default GlobalStyle
