import React, { type FC } from "react"
import { useOutletContext } from "react-router-dom"
import { StyledContactsLists } from "widgets/user/Contacts/ContactsSection/styles"
import { type ContactsItem, useGetContactsQuery } from "features/user"
import { ContactsCard, ContactsLocationsList } from "entities/user"
import { StyledPatternPageBackground } from "shared/styles"
import { type ContactsListsProps } from "./types"
import { StyledContactsCardContainer } from "./styles"
export const ContactsLists: FC<ContactsListsProps> = (props) => {
    const { data } = useGetContactsQuery({})
    const { searchLocationData, filteredLocations } = useOutletContext<ContactsListsProps>()

    const locationAfterFilters = Object.entries(data?.contacts ?? []).reduce(
        (acc, [key, location]) => {
            console.log(filteredLocations?.includes(key))
            if (filteredLocations?.includes(key)) {
                return { ...acc, [key]: location }
            }
            return acc
        },
        {} as { [key: string]: Array<ContactsItem> }
    )

    return (
        <>
            <StyledPatternPageBackground $position={"right -1270px bottom -27px"} $color={"newsFill"} />
            <StyledContactsLists>
                {!searchLocationData && (
                    <ContactsLocationsList title={"Основные"}>
                        {data?.primary.map((contact) => <ContactsCard key={`primary-${contact.id}`} {...contact} />)}
                    </ContactsLocationsList>
                )}
                {data &&
                    "contacts" in data &&
                    Object.entries(
                        Object.keys(locationAfterFilters).length > 0 ? locationAfterFilters : data?.contacts
                    ).map(([key, contacts], index) => (
                        <ContactsLocationsList key={`location-${index}`} title={key}>
                            {contacts.map((contact) => (
                                <StyledContactsCardContainer key={`contacts-${contact.id}`}>
                                    <ContactsCard {...contact} />
                                </StyledContactsCardContainer>
                            ))}
                        </ContactsLocationsList>
                    ))}
            </StyledContactsLists>
        </>
    )
}
