import styled from "styled-components"
import { ScrollGroup } from "shared/ui"
import { getMediaVariable } from "shared/styles"

export const StyledGoalScrollGroup = styled(ScrollGroup)`
    @media all and (min-width: ${getMediaVariable("desktop")}) {
        flex-direction: column;
        position: static;
    }
`

export const StyledGoalsListRow = styled.div`
    height: fit-content;
    min-height: 179px;
    gap: var(--assets-card-custom-goal-sizes-s-title-margin, 4px);
    @media all and (min-width: ${getMediaVariable("desktop")}) {
        min-width: 322px;
        min-height: 500px;
        &:nth-child(2) {
            padding: 0 32px;
            border-right: 1px solid var(--layout-divider, rgba(0, 0, 0, 0.06));
            border-left: 1px solid var(--layout-divider, rgba(0, 0, 0, 0.06));
            min-height: 498px;
        }

        &:nth-child(3) {
            padding: 0 32px;
        }

        &:first-of-type {
            padding-right: 32px;
        }

        &:last-of-type {
            border-left: 1px solid var(--layout-divider, rgba(0, 0, 0, 0.06));
            padding-left: 32px;
        }
        gap: var(--layout-desktop-section-gap, 12px);
    }
`

export const StyledCategoryTitle = styled.div`
    color: var(--root-colors-main-grey-dark, rgba(0, 0, 0, 0.48));
    margin-bottom: var(--assets-card-custom-goal-sizes-s-title-margin, 4px);
    font-size: clamp(1rem, 0.878rem + 0.536vw, 1.429rem);
    @media all and (min-width: ${getMediaVariable("laptop-l")}) {
        margin-bottom: var(--assets-card-custom-goal-sizes-l-title-margin, 16px);
    }
`
