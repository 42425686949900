import * as yup from "yup"
import { boolean, mixed, string } from "yup"

export const validationSchema = yup.object().shape({
    name: string().required("Поле обязательно для заполнения"),
    categories: string().required("Поле обязательно для заполнения"),
    address: string().required("Поле обязательно для заполнения"),
    description: string().required("Поле обязательно для заполнения"),
    userCategories: yup.array().of(string().required()).required("Выберите категории").typeError("Выберите категории"),
    images: yup
        .mixed<FileList>()
        .optional()
        .test((value) => {
            return !value?.length || !(value && value[0].size > 5000000)
        }),
    websiteUrl: string().url("Введите корректную ссылку").optional(),
    vkSocialUrl: string().url("Введите корректную ссылку").optional(),
    accessible: boolean().required(" "),
    lowMobilityFriendly: boolean().required(" "),
    email: string().email("Введите корректный email").required("Поле обязательно для заполнения"),
    phone: string()
        .required("Поле обязательно для заполнения")
        //eslint-disable-next-line
        .matches(/^(\d{3})(\s)(\d{3})(\-)(\d{2})(\-)(\d{2})$/, "Введите корректный номер телефона"),
    workingHours: mixed<{ [key: string]: string }[]>().required("Укажите режим работы"),
})
