import { BASE_URL, mainApi } from "features/api"
import { transformUrlImages, transformUrlVideo } from "shared/lib"
import { type RequestConfig } from "shared/api"
import { type MainData } from "./types"

export const homePageApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getHomePageData: builder.query<MainData, unknown>({
            query: (): RequestConfig<unknown, unknown> => ({
                url: `${BASE_URL.PUBLIC}/homepage`,
                method: "GET",
            }),
            transformResponse: (data: MainData, ...rest) => {
                let videoUrl = transformUrlVideo(data.videoUrl) ?? ""
                return {
                    imageUrl: transformUrlImages(data.imageUrl),
                    videoUrl,
                }
            },
        }),
    }),
})

export const { useGetHomePageDataQuery } = homePageApi
