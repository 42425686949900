import { useState, useEffect } from "react"

type Screen = "isMobileSm" | "isMobileMd" | "isMobileL" | "isTablet" | "isLaptopSm" | "isLaptopL" | "desktop"

const mediaPoints: Record<Screen, number> = {
    isMobileSm: 320,
    isMobileMd: 375,
    isMobileL: 425,
    isTablet: 768,
    isLaptopSm: 1024,
    isLaptopL: 1440,
    desktop: 1920,
}

const { isMobileSm, isMobileMd, isMobileL, isTablet, isLaptopSm, isLaptopL, desktop } = mediaPoints
export const useResize = () => {
    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = (event: any) => {
            setWidth(event.target.innerWidth)
        }
        window.addEventListener("resize", handleResize)
        return () => {
            window.removeEventListener("resize", handleResize)
        }
    }, [])

    return {
        mediaPointsRecord: {
            isMobileSm: width <= isMobileSm,
            isMobileMd: width <= isMobileMd && width > isMobileSm,
            isMobileL: width <= isMobileL && width > isMobileMd,
            isTablet: width <= isTablet && width > isMobileL,
            isLaptopSm: width <= isLaptopSm && width > isTablet,
            isLaptopL: width <= isLaptopL && width > isLaptopSm,
            desktop: width <= desktop && width > isLaptopL,
        },
        mediaScreenSize: {
            width: width,
            ...mediaPoints,
        },
    }
}
