import { FormProvider, useForm } from "react-hook-form"
import React from "react"
import { yupResolver } from "@hookform/resolvers/yup"
import { type IOpportunityForm } from "features/viewer"
import { FileLoad, FileType, FormLayout } from "shared/ui"
import { StyledSection } from "shared/styles"
import {
    AboutFields,
    ConditionsFields,
    ForWhomFields,
    OpportunityFormButtonsControl,
    PersonalLinkFields,
    VideoLink,
} from "./ui"
import { formOpportunityDefaultValues } from "./config"
import { validationSchema } from "./lib"

export const OfferOpportunityForm = () => {
    const methods = useForm<IOpportunityForm>({
        defaultValues: formOpportunityDefaultValues,
        mode: "onSubmit",
        criteriaMode: "all",
        resolver: yupResolver<IOpportunityForm>(validationSchema),
    })
    return (
        <>
            <FormProvider {...methods}>
                <FormLayout
                    headerText={"Предложение возможности"}
                    main={() => (
                        <>
                            <AboutFields />
                            <ForWhomFields />
                            <ConditionsFields />
                            <StyledSection>
                                <FileLoad
                                    FLType={FileType.PICTURE_LIST}
                                    // accept={FileAccept.PICTURE}
                                    name={"images"}
                                    title={"Фотографии"}
                                />
                                <VideoLink />
                            </StyledSection>
                            <PersonalLinkFields />
                        </>
                    )}
                    footer={() => <OpportunityFormButtonsControl />}
                />
            </FormProvider>
        </>
    )
}
