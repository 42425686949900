import { BASE_URL, mainApi } from "features/api"
import { type RequestContactsConfig, type ContactsParams, type ContactsResponse } from "./types"

export const contactsApi = mainApi.injectEndpoints({
    endpoints: (builder) => ({
        getContacts: builder.query<ContactsResponse, ContactsParams>({
            query: (): RequestContactsConfig => ({
                url: `${BASE_URL.PUBLIC}/contacts`,
                method: "GET",
            }),
        }),
    }),
})

export const { useGetContactsQuery } = contactsApi
