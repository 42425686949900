import styled from "styled-components"
import { type CounterColor, type CounterSize } from "./types"

const StyledCounter = styled.div<{
    $size?: CounterSize
    $color?: CounterColor
}>`
    display: inline-flex;
    height: var(--assets-utility-counter-sizes-${(props) => props.$size}-height);
    min-width: var(--assets-utility-counter-sizes-${(props) => props.$size}-width-min);
    padding: 0px var(--assets-utility-counter-sizes-${(props) => props.$size}-padding-hor);
    border-radius: var(--assets-utility-counter-sizes-${(props) => props.$size}-radius);
    background: var(--assets-calendar-colors-${(props) => props.$color});
    color: var(--assets-utility-counter-colors-text);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
`

export { StyledCounter }
