import React, { type FC, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { PersonalRedirectControl, AuthRedirectControl } from "features/user"
import { LogoSvg } from "shared/assets/svg"
import { HeaderBurger } from "./Burger"
import { type HeaderProps } from "./types"
import {
    StyledDropdown,
    StyledHeader,
    StyledHeaderControls,
    StyledRedirectBtnPanel,
    StyledVector,
    StyledHeaderContainer,
    StyledLogoContainer,
} from "./styles"
import { HeaderNotification } from "./Notifications"
import { Navbar } from "./Navbar"

export const Header: FC<HeaderProps> = (props) => {
    const { updateToken, wasLogin } = props
    const [isShowMenu, setIsShowMenu] = useState<boolean>(false)
    const toggleMenuActivity = () => setIsShowMenu((prev) => !prev)

    const navigate = useNavigate()
    const location = useLocation()
    return (
        <StyledHeaderContainer>
            <StyledHeader $isMain={location.pathname === "/"} $isActive={isShowMenu}>
                <StyledLogoContainer
                    onClick={() => {
                        navigate("/")
                    }}
                >
                    <LogoSvg />
                </StyledLogoContainer>
                <StyledHeaderControls>
                    {wasLogin && <HeaderNotification />}
                    <HeaderBurger isShowMenu={isShowMenu} handleClickBurger={toggleMenuActivity} />
                </StyledHeaderControls>
            </StyledHeader>
            <StyledDropdown $isShow={isShowMenu}>
                <Navbar onNavLinkClick={toggleMenuActivity} />
                <StyledRedirectBtnPanel>
                    {wasLogin ? (
                        <PersonalRedirectControl updateToken={updateToken} />
                    ) : (
                        <AuthRedirectControl onButtonClick={toggleMenuActivity} />
                    )}
                </StyledRedirectBtnPanel>
                <StyledVector $isShow={isShowMenu} />
            </StyledDropdown>
        </StyledHeaderContainer>
    )
}
