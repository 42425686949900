import process from "process"
import { useEffect, useState } from "react"
import { type IVkFunctions } from "./types"

export const useGetVkFunctions = () => {
    const [vkFunctions, setVkFunctions] = useState<IVkFunctions>()
    useEffect(() => {
        if ("SuperAppKit" in window) {
            const { Connect, Config, ...rest } = (window as any).SuperAppKit
            setVkFunctions({ Connect, Config })
        }
    }, [(window as any).SuperAppKit])
    return { ...vkFunctions }
}

export const useInitVkConfig = (appId: number, Config: any) => {
    useEffect(() => {
        if (Config && appId) {
            Config.init({
                appId,
            })
        }
    }, [Config, appId])
}

export const redirectAuthHandler = (Connect: any) => () => {
    if (Connect) {
        Connect.redirectAuth({
            url: `${process.env.REACT_APP_HOST as string}/auth`,
            source: process.env.REACT_APP_HOST as string,
        })
    }
}
