import { useEffect, type MutableRefObject } from "react"
import { useResize } from "../useResize"
export const useHorizontalScroll = (ref: MutableRefObject<HTMLDivElement | null>) => {
    const {
        mediaScreenSize: { isTablet, width },
    } = useResize()
    useEffect(() => {
        const currentEl = ref!.current
        if (currentEl && width > isTablet) {
            const wheelScrollHelper = (evt: WheelEvent) => {
                const { scrollLeft, scrollWidth, offsetWidth } = currentEl
                if (evt.deltaY < 0 && scrollLeft === 0) {
                    /* empty */
                } else if (evt.deltaY > 0 && scrollWidth === scrollLeft + offsetWidth) {
                    /* empty */
                } else {
                    evt.preventDefault()
                }
                currentEl.scrollLeft += evt.deltaY
            }
            currentEl.addEventListener("wheel", wheelScrollHelper)
            return () => {
                currentEl.removeEventListener("wheel", wheelScrollHelper)
            }
        }
    }, [])

    useEffect(() => {
        const currentEl = ref!.current
        if (currentEl && width > isTablet) {
            let isMouseDown: boolean
            let startX: number
            const mouseScrollListener = (e: MouseEvent) => {
                e.preventDefault()
                if (!isMouseDown) {
                    return
                }
                const x = e.pageX - currentEl.offsetLeft
                const scroll = x - startX
                currentEl.scrollLeft = currentEl.scrollLeft - scroll
            }

            const mouseStartScrollListener = (e: MouseEvent) => {
                isMouseDown = true
                startX = e.pageX - currentEl.offsetLeft
            }

            const StopScrollListener = () => {
                isMouseDown = false
            }

            currentEl.addEventListener("mousemove", mouseScrollListener)
            currentEl.addEventListener("mousedown", mouseStartScrollListener)
            currentEl.addEventListener("mouseup", StopScrollListener)
            currentEl.addEventListener("mouseleave", StopScrollListener)

            return () => {
                currentEl.removeEventListener("mousemove", mouseScrollListener)
                currentEl.removeEventListener("mousedown", mouseStartScrollListener)
                currentEl.removeEventListener("mouseup", StopScrollListener)
                currentEl.removeEventListener("mouseleave", StopScrollListener)
            }
        }
    }, [])
}
