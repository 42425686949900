import React, { type FC } from "react"
import { type SliderItemProps } from "./types"
import { StyledSliderItem } from "./styles"

export const SliderItem: FC<SliderItemProps> = (props) => {
    const { src, current = false, ...other } = props
    return (
        <StyledSliderItem $current={current} {...other}>
            <img src={src} alt="" />
        </StyledSliderItem>
    )
}
