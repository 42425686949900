import { styled } from "styled-components"
import { Counter } from "shared/ui"

export const StyledEventsCalendarCounterContainer = styled(Counter)`
    bottom: 0;
    right: 0;
    position: absolute;

    @media all and (min-width: 1026px) {
        top: 16px;
        right: 16px;
    }
`
